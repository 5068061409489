import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";
import DefaultSubscriptionControl from "./_/DefaultSubscriptionControl";
import ButtonSubscriptionControl from "./_/ButtonSubscriptionControl";

const isChecked = subscription =>
  !isEmpty(subscription) &&
  (subscription.updateByTxt || subscription.updateByEmail);

const SubscriptionControl = ({ subscription, disabled, variant, ...rest }) => {
  const checked = isChecked(subscription);
  const checkedText = checked ? "ON" : "OFF";
  const props = {
    checked,
    checkedText,
    ...rest,
  };
  switch (variant) {
    case "button":
      return <ButtonSubscriptionControl {...props} />;
    default:
      return <DefaultSubscriptionControl {...props} />;
  }
};

/* istanbul ignore next */
SubscriptionControl.defaultProps = {
  disabled: false,
  subscription: null,
  variant: "",
};

SubscriptionControl.propTypes = {
  disabled: PropTypes.bool,
  subscription: PropTypes.shape({
    ticketId: PropTypes.string,
    updateByTxt: PropTypes.bool,
    updateByEmail: PropTypes.bool,
  }),
  handleToogleChange: PropTypes.func.isRequired,
  onClickChangeButton: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

export default SubscriptionControl;
