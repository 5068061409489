/* eslint-disable react/prop-types */
import { Box } from "@rebass/grid";
import React from "react";
import PropTypes from "prop-types";
import { or } from "airbnb-prop-types";
import styled, { css } from "styled-components/macro";
import { switchProp } from "styled-tools";
import { color, fontSize } from "styled-system";

const Notification = styled(Box)`
  ${fontSize};
  ${color};

  margin: 0;
  display: flex;
  align-items: flex-start;
  width: 100%;

  ${switchProp("variant", {
    warning: css`
      color: ${p => p.theme.colors.dark};
      background-color: ${p => p.theme.colors.orange};
    `,
    info: css`
      color: ${p => p.theme.colors.white};
      background-color: ${p => p.theme.colors.dark};
    `,
  })}
`;

const OutageStatusNotification = ({ variant, notification }) => (
  <Notification
    fontSize="16px"
    py={[10, 10, 10]}
    px={[20, 20, 25]}
    variant={variant}
  >
    <Box pr={[20, 20, 25]}>
      {typeof notification === "string" && <span>{notification}</span>}

      {typeof notification === "function" && notification()}
    </Box>
  </Notification>
);

OutageStatusNotification.propTypes = {
  variant: PropTypes.oneOf(["info", "warning"]),
  notification: or([PropTypes.string, PropTypes.func]).isRequired,
};

OutageStatusNotification.defaultProps = {
  variant: "info",
};

export default OutageStatusNotification;
