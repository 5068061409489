import PropTypes from "prop-types";
import React from "react";
import { Box } from "rebass";
import styled from "styled-components/macro";
import { ProgressStepper } from "../ProgressStepper";

const ProgressArea = styled(Box)`
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.12);
`;

const ReportOutageProgress = ({ data }) => (
  <ProgressArea width={1} pb={10}>
    <ProgressStepper data={data} />
  </ProgressArea>
);

ReportOutageProgress.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      icon: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
      type: PropTypes.string,
    }),
  ).isRequired,
};
ReportOutageProgress.defaultProps = {};

export default ReportOutageProgress;
