import { css } from "styled-components/macro";

export const focusStyling = css`
  &:focus {
    /* Adds a white border around the button, along with a blue glow. The white and blue have a color contrast ratio of at least 3:1, which means this will work against any background color. */
    box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px #3a97f9;

    /* NOTE: box-shadow is invisible in Windows high-contrast mode, so we need to add a transparent outline, which actually does show up in high-contrast mode. */
    outline: 2px dotted transparent;
    outline-offset: 2px;
  }
`;
