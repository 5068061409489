import { Box, Flex, Text } from "rebass";
import Macro, { Clone } from "macro-components";
import React from "react";
import styled from "styled-components/macro";
import { fontSize, space, minHeight } from "styled-system";
import { Button as BaseButton } from "../Button";

// Todo outageType to be enum.
const Action = styled(BaseButton)`
  ${space};
  ${fontSize};
  line-height: 1;
  padding-left: 0;
`;

const Label = styled(Text)`
  ${space};
  ${fontSize};
  margin: 0;
`;

const macro = Macro({
  Label,
  Action,
  Map: Box,
});

const Wrapper = styled(Flex)`
  background-color: ${props => props.theme.colors.light};
  ${minHeight}
`;

// eslint-disable-next-line no-shadow
const AddressBlock = macro(({ Label, Action, Map }) => (
  <Wrapper
    px={[20, null, 25]}
    pt={[12, null, 20]}
    pb={[0, null, 15]}
    minHeight="fit-content"
    data-testid="AddressBlock"
  >
    <Clone element={Map} pr={15} />
    <Box flex="1 1 0" data-testid="AddressBlock__Label">
      <Clone
        element={Label}
        fontSize={[3, null, 5]}
        lineHeight={[1.17, 1]}
        as="p"
      />
      <Clone element={Action} mt={2} fontSize={0} variant="text" />
    </Box>
  </Wrapper>
));

export default AddressBlock;
