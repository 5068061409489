/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { isLoggedIn } from "../../redux/modules/login";

export const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { redirectTo: props.match.url },
          }}
        />
      )
    }
  />
);
PrivateRoute.propTypes = {
  loggedIn: PropTypes.bool,
};
PrivateRoute.defaultProps = {
  loggedIn: false,
};

/* istanbul ignore next */
const enhance = compose(
  withRouter,
  connect(
    state => ({
      loggedIn: isLoggedIn(state),
    }),
    null,
  ),
);

/* istanbul ignore next */
export default enhance(PrivateRoute);
