import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import { Text } from "rebass";
import { ifProp } from "styled-tools";

const StrikedText = styled(Text)`
  ${ifProp(
    "striked",
    css`
      text-decoration: line-through;
      color: ${props => props.theme.colors.primary};
      opacity: 0.35;
    `,
  )};
`;

StrikedText.defaultProps = {
  striked: false,
};

StrikedText.propTypes = {
  striked: PropTypes.bool,
};

export default StrikedText;
