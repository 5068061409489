import { all, takeLatest, call, put, fork } from "redux-saga/effects";
import { handleRedirect } from "../../sagas/helpers";
import { OutageService } from "../../../services/OutageService";

import {
  REPORT_CREATE,
  reportFailure,
  reportFetchFailure,
  reportFetchRequest,
  reportFetchSuccess,
  reportSuccess,
} from "./reports.action";
import { loginRequest } from "../login";

export function* reportCreateFn(action) {
  try {
    const {
      data: { ticketId },
    } = yield call(OutageService.createReport, action.payload);

    yield put(reportSuccess(undefined, action.meta));

    const { registration } = action.payload;
    if (
      typeof registration !== "undefined" &&
      registration.email &&
      registration.password
    ) {
      yield put(
        loginRequest({
          email: registration.email,
          password: registration.password,
        }),
      );
    }
    yield fork(handleRedirect, {
      ...action,
      meta: {
        ...action.meta,
        redirectTo: `${action.meta.redirectTo}/${ticketId}`,
      },
    });
  } catch (e) {
    yield put(reportFailure(e.message));
  }
}

export function* requestReports() {
  try {
    const response = yield call(OutageService.getOutages);
    if (response.status === 200) {
      yield put(reportFetchSuccess(response.data));
    } else {
      throw new Error("Sorry, something has gone wrong. Please try again.");
    }
  } catch (e) {
    yield put(reportFetchFailure(e.message));
  }
}

export function* watchForReportRequests() {
  yield all([
    yield takeLatest(REPORT_CREATE, reportCreateFn),
    yield takeLatest(reportFetchRequest, requestReports),
  ]);
}
