/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Box, Flex, Text } from "rebass";
import { compose } from "recompose";
import { connect } from "react-redux";
import { connect as formikConnect } from "formik";
import { cancelForm } from "../../../redux/modules/forms";
import { Button } from "../../../components/Button";
import { Circle } from "../../../components/Circle";
import { trackReportEvent } from "../eventTracking";
import { ReactComponent as WarningIcon } from "./warning.svg";
import CancelButton from "./CancelButton";
import { verifyLoginState } from "../../../redux/modules/login";

export const Disclaimer = ({
  match: {
    url,
    params: { icpNumber },
  },
  formik: { resetForm },
  cancelForm,
  verifyLoginState,
}) => {
  useEffect(() => {
    // Clear form values as this marks a new journey
    // We may need to reconsider this approach when implementing the
    // "Saved Locations" with pre-fill of report forms feature CO-379
    if (typeof cancelForm === "function") {
      cancelForm(undefined, { formName: "createReport", subkey: icpNumber });
    }
    if (typeof resetForm === "function") {
      resetForm();
    }
    verifyLoginState();
  }, []);

  return (
    <Flex flexDirection="column" alignItems="center" width={1}>
      <Box>
        <Circle bg="error" color="white" size={90}>
          <WarningIcon />
        </Circle>
      </Box>
      <Box width={1} alignSelf="center">
        <Text as="h2" fontSize={5} mt={20} mb={2} textAlign="center">
          Safety
        </Text>
        <Text as="p" fontSize={2} mt={0} textAlign="center">
          If there is a risk to people or property (eg. a car has hit a power
          pole or there are downed power lines), please call us on
        </Text>
        <Button
          gaTrack={trackReportEvent(icpNumber, url, "Clicked phone number")}
          href="tel:0508832867"
          block
          variant="ghost"
          fontSize={5}
          py={10}
        >
          0508 832 867
        </Button>
        <Box my={20} width={1}>
          <Button
            gaTrack={trackReportEvent(icpNumber, url, "Clicked 'Next' button")}
            variant="dark"
            block
            type="submit"
          >
            NEXT
          </Button>
          <CancelButton
            gaTrack={trackReportEvent(
              icpNumber,
              url,
              "Clicked 'Cancel' button",
            )}
          />
        </Box>
        <Text as="p" fontSize={1} textAlign="center" mt={0}>
          If you are concerned regarding health and safety, please either
          contact the health professional responsible for your care immediately,
          or go to the local hospital, or if this is an emergency, call 111.
        </Text>
      </Box>
    </Flex>
  );
};
Disclaimer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      icpNumber: PropTypes.string.isRequired,
    }),
  }).isRequired,
  formik: PropTypes.object.isRequired,
  cancelForm: PropTypes.func.isRequired,
  verifyLoginState: PropTypes.func.isRequired,
};

const enhance = compose(
  withRouter,
  formikConnect,
  connect(
    null,
    { cancelForm, verifyLoginState },
  ),
);

export default enhance(Disclaimer);
