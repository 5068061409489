import { createAction } from "redux-actions";

// API Interactions
export const EVENT_HISTORY_REQUEST = "app/eventHistory/REQUEST";
export const EVENT_HISTORY_SUCCESS = "app/eventHistory/SUCCESS";
export const EVENT_HISTORY_FAILURE = "app/eventHistory/FAILURE";

// UI Actions
export const EVENT_HISTORY_TOGGLE = "app/eventHistory/TOGGLE";

export const requestEventHistory = createAction(
  EVENT_HISTORY_REQUEST,
  icpNumber => ({
    icpNumber,
  }),
);
export const requestEventHistorySuccess = createAction(
  EVENT_HISTORY_SUCCESS,
  data => data,
  (data, icpNumber) => ({
    icpNumber,
  }),
);
export const requestEventHistoryFailure = createAction(EVENT_HISTORY_FAILURE);
export const toggleEventHistoryDisplay = createAction(EVENT_HISTORY_TOGGLE);
