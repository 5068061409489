import { fork, call, put, takeLatest } from "redux-saga/effects";
import get from "lodash/get";
import LoginService from "../../../services/LoginService";
import { handleRedirect, handleTrackEvent } from "../../sagas/helpers";
import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  SEND_ONETIME_PASSWORD_REQUEST,
  loginFailure,
  loginSuccess,
  logoutFailure,
  logoutSuccess,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  userNotLoggedIn,
  verifyLoginState,
  oneTimePasswordSent,
  sendOneTimePasswordFailure,
} from "./login.actions";

import { getErrorMessageFromErrorStatus } from "./login.selectors";

export function* processLogout() {
  const response = yield call(LoginService.logout);

  if (response.status === 200) {
    yield put(logoutSuccess(response.data));
  } else {
    // Note: For now assume naively the call has failed.
    yield put(logoutFailure(response));
  }
}

export function* processResetPassword(action) {
  try {
    const response = yield call(LoginService.resetPassword, action.payload);
    if (response.status === 200) {
      yield fork(handleRedirect, action);
      yield put(forgotPasswordSuccess(response.data));
    } else {
      throw new Error("Sorry, something has gone wrong. Please try again.");
    }
  } catch (err) {
    yield put(forgotPasswordFailure(err));
  }
}

export function* processOneTimePasswordRequest(action) {
  try {
    yield call(LoginService.sendOnetimePassword, action.payload);
    yield put(oneTimePasswordSent(null, action.meta));
    yield fork(handleRedirect, action);
  } catch (err) {
    yield put(sendOneTimePasswordFailure(err, action.meta));
  }
}

export function* processLogin(action) {
  try {
    const response = yield call(LoginService.login, action.payload);
    const userInfo = yield call(LoginService.userInfo);

    if (response.status === 200 && userInfo.status === 200) {
      yield put(loginSuccess(userInfo.data, action.meta));
      yield fork(handleRedirect, action);
    } else {
      throw new Error("Sorry, something has gone wrong. Please try again.");
    }
  } catch (err) {
    yield put(loginFailure(err, action.meta));
  }
}

export function* isLoggedIn() {
  try {
    yield call(LoginService.isLoggedIn);
    yield put(loginSuccess());
  } catch (err) {
    yield put(userNotLoggedIn());
  }
}

export function* trackLoginSuccess({ meta }) {
  yield fork(handleTrackEvent, {
    category: get(meta, "gaCategory"),
    action: "service_request_success",
    label: "One time password successfully verified",
  });
}

export function* trackLoginFailure({ payload, meta }) {
  yield fork(handleTrackEvent, {
    category: get(meta, "gaCategory"),
    action: "service_request_failure",
    label: getErrorMessageFromErrorStatus(payload),
  });
}

export function* trackSendOTPSuccess({ meta }) {
  yield fork(handleTrackEvent, {
    category: get(meta, "gaCategory"),
    action: "service_request_success",
    label: "One time password sent successfully",
  });
}

export function* trackSendOTPFailure({ payload, meta }) {
  yield fork(handleTrackEvent, {
    category: meta.gaCategory,
    action: "service_request_failure",
    label: getErrorMessageFromErrorStatus(payload),
  });
}

export function* watchLoginRequest() {
  yield takeLatest(
    SEND_ONETIME_PASSWORD_REQUEST,
    processOneTimePasswordRequest,
  );
  yield takeLatest(LOGIN_REQUEST, processLogin);
  yield takeLatest(LOGOUT_REQUEST, processLogout);
  yield takeLatest(FORGOT_PASSWORD_REQUEST, processResetPassword);
  yield takeLatest(verifyLoginState, isLoggedIn);
  yield takeLatest(oneTimePasswordSent, trackSendOTPSuccess);
  yield takeLatest(sendOneTimePasswordFailure, trackSendOTPFailure);
  yield takeLatest(loginFailure, trackLoginFailure);
  yield takeLatest(loginSuccess, trackLoginSuccess);
}
