import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { Flex, Box, Text } from "rebass";
import { Formik, Form, Field } from "formik";
import styled from "styled-components/macro";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { TextField } from "../../components/Form";
import { Button } from "../../components/Button";
import {
  isRequired,
  isValidEmail,
  validateWith,
} from "../../components/RegistrationFormFields/validate";

import {
  isLoggedIn,
  clearLoginError,
  sendOneTimePasswordRequest,
  errorMessage,
} from "../../redux/modules/login";
import { trackEvent } from "../../common/analytics";
import { LoginErrorMessage } from "../../components/LoginErrorMessage";

const GA_CATEGORY_EMAIL = "Login_Email";

const FormRow = styled(Box).attrs({ py: 2 })``;

const formDefaultValues = {
  email: "",
};

export class LoginContainer extends React.Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    onSendOTP: PropTypes.func.isRequired,
    clearError: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    values: PropTypes.object,
  };

  static defaultProps = {
    error: null,
    isLoading: false,
    values: {},
  };

  handleRegisterClick = () => {
    const { history, location } = this.props;
    const redirectTo = get(location, "state.redirectTo", undefined);

    trackEvent({
      category: GA_CATEGORY_EMAIL,
      action: "Click",
      label: "Register",
    });

    history.push("/register", redirectTo ? { redirectTo } : undefined);
  };

  handleClearError = () => {
    const { clearError } = this.props;
    clearError();
  };

  handleSubmit = (values, { setSubmitting }) => {
    const { history, location, onSendOTP } = this.props;

    const navigateBack = get(location, "state.redirectTo", "/");
    const meta = {
      history,
      redirectTo: `${location.pathname}/auth`,
      gaCategory: "login",
      navigateBack,
    };

    const { email } = values;
    trackEvent({
      category: GA_CATEGORY_EMAIL,
      action: "Click",
      label: "Send_OTP_Next",
    });
    onSendOTP({ email }, meta);

    setSubmitting(false);
  };

  render() {
    // eslint-disable-next-line no-shadow
    const { values, error, history, isLoggedIn, isLoading } = this.props;

    if (isLoggedIn) {
      history.push("/");
    }

    const initialValues = {
      ...formDefaultValues,
      ...values,
    };

    return (
      <Flex justifyContent="center">
        <Box width={[1, null, 3 / 4]}>
          <Text
            data-testid="Login__Banner"
            as="p"
            fontSize="2"
            textAlign="center"
          >
            You no longer need a password to sign in!
            <br />
            For your convenience, we&apos;ll send a verification code via email
            instead.
          </Text>
          <Formik
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
            render={() => (
              <Form aria-label="Login Form" data-testid="LoginForm" noValidate>
                <Flex flexDirection="column" width={1}>
                  <LoginErrorMessage error={error} />
                  <Box>
                    <FormRow>
                      <Field
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        validate={validateWith(isRequired, isValidEmail)}
                        component={TextField}
                        required
                        autoComplete="off"
                        note="We'll send you a verification code to this email"
                      />
                    </FormRow>
                    <Box width={1} mt={4}>
                      <Button
                        // gaTrack
                        block
                        data-testid="LoginForm__Button--submit"
                        variant="dark"
                        type="submit"
                        disabled={isLoading}
                        isLoading={isLoading}
                      >
                        Next
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        block
                        variant="ghost"
                        onClick={this.handleRegisterClick}
                        fontSize={2}
                        lineHeight={1.2}
                        textTransform="none"
                        pl={0}
                        pr={0}
                        data-testid="LoginForm__RegistrationButton"
                      >
                        New user? Register now
                      </Button>
                    </Box>
                  </Box>
                </Flex>
              </Form>
            )}
          />
        </Box>
      </Flex>
    );
  }

  componentWillUnmount() {
    this.handleClearError();
  }
}

/* istanbul ignore next */
const enhance = compose(
  withErrorBoundary(),
  withRouter,
  connect(
    state => ({
      isLoggedIn: isLoggedIn(state),
      error: errorMessage(state),
      isLoading: state.auth.isLoading,
    }),
    {
      clearError: clearLoginError,
      onSendOTP: sendOneTimePasswordRequest,
    },
  ),
);

export default enhance(LoginContainer);
