import { Box, Text } from "rebass";
import React from "react";

export const NotificationLink = () => (
  <Box css="text-align: center;">
    <Text as="p" mb={0} fontSize={2} fontWeight="bold">
      Didn&apos;t receive a notification?
    </Text>

    <Text
      as="p"
      fontSize={1}
      mt={2}
      mb={3}
      fontWeight="bold"
      lineHeight={1.2}
      px={0}
    >
      Contact your energy retailer and make sure they have up-to-date contact
      details.
    </Text>
  </Box>
);
