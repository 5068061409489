/* eslint-disable react/prop-types */
import React from "react";
import styled, { css } from "styled-components/macro";
import { Flex, Box, Text } from "rebass";
import { ifProp } from "styled-tools";
// eslint-disable-next-line import/no-cycle
import { withContext } from ".";

const List = withContext(styled(Flex)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 60000;

  ${ifProp(
    "isOpen",
    css`
      overflow-x: hidden;
      overflow-y: scroll;
      height: ${p => `${p.size.height - 72}px`};
    `,
  )};
`);

const BaseItem = ({ children, isActive, ...props }) => (
  <Box py={10} px={15} {...props}>
    <Text fontSize={2}>{children}</Text>
  </Box>
);
export const Item = styled(BaseItem)`
  background-color: white;
  color: ${p => p.theme.colors.primary};
  border-bottom: 1px solid ${p => p.theme.colors.gray};
  &:first-child {
    border-top: 1px solid ${p => p.theme.colors.gray};
  }

  ${ifProp(
    "isActive",
    css`
      background: ${p => p.theme.colors.accent};
      color: white;
    `,
  )};
`;

export class BaseMenu extends React.Component {
  componentDidMount() {
    const { innerRef } = this.props;
    innerRef(this);
  }

  componentWillUnmount() {
    const { innerRef } = this.props;
    innerRef(undefined);
  }

  render() {
    const { children, innerRef, ...props } = this.props;
    return (
      <List
        width={1}
        data-testid="address-item"
        flexWrap="nowrap"
        flexDirection="column"
        {...props}
      >
        {children}
      </List>
    );
  }
}

export const Menu = styled(BaseMenu)``;
