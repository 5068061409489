import { createSelector } from "reselect";

export const getAllActiveStormZones = createSelector(
  [state => state.stormZones.stormZones],
  stormZones =>
    stormZones.map(zone => {
      const coords = zone.shape.features[0].geometry.coordinates[0];

      const newCoords = coords.map(coordinateSet => ({
        lng: coordinateSet[0],
        lat: coordinateSet[1],
      }));

      // eslint-disable-next-line no-param-reassign
      zone.shape = newCoords;
      return zone;
    }),
);
