import React from "react";
import PropTypes from "prop-types";
import { Box, Flex, Text } from "rebass";
import styled from "styled-components/macro";
import PlannedOutageDateRange from "./PlannedOutageDateRange";

const BoxWrapper = styled(Box)`
  flex-basis: 0;
  flex-grow: 0.5;
  min-width: 0;
`;

const HeaderWrapper = styled(Box)`
  display: none;

  ${p => p.theme.upFrom.lg`
    display: inline-block;
  `}
`;

const HeadingBorder = styled.hr`
  opacity: 0.5;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border: none;
`;

const RowBorder = styled.hr`
  opacity: 0.5;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border: none;
`;

const getKey = (index, startDate, endDate) => {
  const formattedStartDate = !startDate
    ? ""
    : startDate.replace("/ /gi", "_").replace("/:/gi", "_");

  const formattedEndDate = !endDate
    ? ""
    : endDate.replace("/ /gi", "_").replace("/:/gi", "_");

  return `${index}_${formattedStartDate}_${formattedEndDate}`;
};

const PlannedOutageDatesTable = ({ outageDates }) => (
  <Flex flexDirection="column" mt={3}>
    <HeaderWrapper>
      <Flex flexDirection="row">
        <BoxWrapper>
          <Text as="span" fontSize={0} fontWeight="500" lineHeight={1.7}>
            Start
          </Text>
        </BoxWrapper>

        <BoxWrapper>
          <Text as="span" fontSize={0} fontWeight="500" lineHeight={1.7}>
            End
          </Text>
        </BoxWrapper>
      </Flex>

      <HeadingBorder />
    </HeaderWrapper>

    {outageDates.map((outageDate, index) => (
      <React.Fragment
        key={getKey(index, outageDate.startDate, outageDate.endDate)}
      >
        <PlannedOutageDateRange
          startDate={outageDate.startDate}
          endDate={outageDate.endDate}
          isStriked={outageDate.isStriked}
        />

        <RowBorder />
      </React.Fragment>
    ))}
  </Flex>
);

PlannedOutageDatesTable.propTypes = {
  outageDates: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PlannedOutageDatesTable;
