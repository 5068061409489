import moment from "moment";
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { InfoWindow, OverlayView } from "@react-google-maps/api";
import { Flex, Box, Text } from "rebass";
import styled, { css } from "styled-components";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { ifProp } from "styled-tools";
import { connect } from "react-redux";
import { trackEvent } from "../../common/analytics";
import Loader from "../Loader/Loader";

export const GA_CATEGORY = "Outage_Zone_View_Details";

/* istanbul ignore next */
const Container = styled(Box)`
  font-family: Montserrat, monospace, sans-serif;
  min-height: 200px;
  max-height: 280px;
  border: none;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -12px;
    width: 0;
    height: 0;
    border-top: 24px solid white;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
  }
`;

const FlexContainer = styled(Flex)`
  min-height: 200px;
  max-height: 280px;
`;

/* istanbul ignore next */
const TitleText = styled(Text)`
  text-transform: lowercase;
  font-size: 12px;
  line-height: 18px;
  color: #97a0af;
  font-weight: 500;
`;

/* istanbul ignore next */
const ZoneName = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  color: #000840;
  font-weight: 500;
`;

/* istanbul ignore next */
const RestoredCustomersPercentage = styled(Text)`
  display: inline;
  font-size: 14px;
  line-height: 21px;
  color: #42526e;
  font-weight: 700;
`;

/* istanbul ignore next */
const RestoredCustomersText = styled(Text)`
  display: inline;
  font-size: 14px;
  line-height: 21px;
  color: #42526e;
  font-weight: 500;
`;

/* istanbul ignore next */
const RestoredEta = styled(Text)`
  font-size: 14px;
  line-height: 21px;
  color: #42526e;
  font-weight: 700;
`;

/* istanbul ignore next */
const LastUpdate = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  color: #97a0af;
  font-weight: 500;
`;

/* istanbul ignore next */
const Button = styled.button`
  text-transform: lowercase;
  text-decoration: none;
  padding: 3px 16px;
  margin-top: auto;
  margin-bottom: 0;
  border-radius: 4px;
  border: none;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.accent};
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  ${ifProp(
    "disabled",
    css`
      background-color: #bfc1cf;
    `,
  )};
`;

const StormZonePopup = ({
  zoneName,
  centerPoint,
  stormZoneDetailsLoading: loading,
  stormZoneDetails,
  history,
  onClose,
}) => {
  const trackClick = useCallback(args => {
    trackEvent({
      category: GA_CATEGORY,
      ...args,
    });
  }, []);

  const handleOnViewDetailsClick = () => {
    trackClick({
      action: "Click",
      label: `Outage Zone Details: ${stormZoneDetails.id}`,
    });

    history.push(`/storm-zone/${stormZoneDetails.id}`);
  };

  return (
    <InfoWindow
      position={{
        lat: centerPoint.latitude,
        lng: centerPoint.longitude,
      }}
      getPixelPositionOffset={x => {
        const newX = -(x / 2);
        const newY = -310;
        return { x: newX, y: newY };
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      onCloseClick={onClose}
      options={{ disableAutoPan: true, maxWidth: 240 }}
    >
      <Container bg="white" p={1} data-testid="Map__StormPopup">
        <FlexContainer flexDirection="column">
          <TitleText mb={2}>large scale event</TitleText>

          <ZoneName mb={3} data-testid="Map__StormPopup_Name">
            {zoneName}
          </ZoneName>

          {loading && (
            <Flex m="auto" mb={3} mt={0}>
              <Loader size={30} />
            </Flex>
          )}

          {stormZoneDetails && !loading && (
            <>
              {stormZoneDetails.restorationState && (
                <Flex>
                  <RestoredCustomersText
                    mb={2}
                    data-testid="Map__StormPopup_RestorationState_text"
                  >
                    <RestoredCustomersPercentage
                      mb={2}
                      data-testid="Map__StormPopup_RestorationState_percentage"
                    >
                      {stormZoneDetails.restorationState}%
                    </RestoredCustomersPercentage>{" "}
                    of customers restored
                  </RestoredCustomersText>
                </Flex>
              )}

              <RestoredEta mb={2} data-testid="Map__StormPopup_Etr">
                {stormZoneDetails.estimatedTimeRestoration
                  ? stormZoneDetails.estimatedTimeRestoration
                  : "We'll keep you updated as soon as we know more"}
              </RestoredEta>

              <LastUpdate mb={3} data-testid="Map__StormPopup_LastUpdate">
                Last updated:{" "}
                {moment(stormZoneDetails.updatedAt).format("ddd. D MMM, h:ma")}
              </LastUpdate>
            </>
          )}

          <Flex mx="auto">
            <Button
              type="button"
              variant=""
              data-testid="Map__ViewDetails"
              onClick={handleOnViewDetailsClick}
              disabled={loading}
            >
              view details
            </Button>
          </Flex>
        </FlexContainer>
      </Container>
    </InfoWindow>
  );
};

StormZonePopup.defaultProps = {
  stormZoneDetails: {},
  stormZoneDetailsLoading: false,
};

StormZonePopup.propTypes = {
  stormZoneDetailsLoading: PropTypes.bool,
  zoneName: PropTypes.string.isRequired,
  centerPoint: PropTypes.object.isRequired,
  stormZoneDetails: PropTypes.object,
  history: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

/* istanbul ignore next */
const enhance = compose(
  withRouter,
  connect(state => ({
    stormZoneDetails: state.stormZonesId.stormZone,
    stormZoneDetailsLoading: state.stormZonesId.isFetching,
  })),
);

export default enhance(StormZonePopup);
