/* eslint-disable no-unused-vars */
import client from "../client";
import mock from "./__fixture__/lsevent";

export const ENDPOINT = "/outagereporter/lses";

export class LSEventService {
  static getStatus = function getStatus() {
    // Mock Response
    // return mock.LSE2;

    // Real Response
    // eslint-disable-next-line no-unreachable
    const url = `${ENDPOINT}`;

    return client.get(url);
  };
}
