/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React from "react";
import PropTypes from "prop-types";
import { or, explicitNull } from "airbnb-prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Box, Flex, Text } from "rebass";
import { compose } from "recompose";
import styled, { css } from "styled-components/macro";
import { ifProp } from "styled-tools";
import find from "lodash/find";
import { trackEvent } from "../../common/analytics";
import { AddressBlock } from "../../components/AddressBlock";
import { DateFormat } from "../../components/DateFormat";
import { Dialog } from "../../components/Dialog";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { ErrorMessage } from "../../components/ErrorMessage";
import { PLANNED, UNKNOWN, UNPLANNED } from "../../components/EventType";
import { Loader } from "../../components/Loader";
import { LSE_MODAL_ID } from "../../components/LSEWarningContent/LSEModal";
import { Map } from "../../components/Map";
import { ProgressBar } from "../../components/ProgressBar";
import { StormWarning } from "../../components/StormWarning";
import {
  getIsUpdatingTicket,
  getTicketStatus,
  outagesUpdateTicket,
  outagesUpdateTicketDone,
} from "../../redux/modules/outages";
import { openModal } from "../../redux/modules/modal";
import { EventHistoryContainer } from "../EventHistoryContainer";
import { LSEventConsumer } from "../LSEvent";
import { OutageInfo } from "./_/OutageInfo";
import { OutageStatusBar } from "./_/OutageStatusBar";
import { OutageUpdateControl } from "./_/OutageUpdateControl";
import {
  IconTruck,
  IconHat,
  IconClock,
  IconTick,
  IconQuestion,
} from "../../assets/icons";

import { Button } from "../../components/Button";
import { AddressPlannedOutagesContainer } from "../AddressPlannedOutagesContainer";
import { NotificationLink } from "../../components/NotificationLink/NotificationLink";
import { ThematicBreak } from "../../components/ThematicBreak";
import { LSENotificationContainer } from "../LSENotificationContainer";
import { getCurrentWorkStatus } from "./_/GetCurrentWorkStatus";
import { PageWrapper } from "../../components/PageWrapper";
import { getOutageFromIcpStatus } from "../../redux/modules/icps";

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.light};
  padding: 20px;
  padding-bottom: 10px;
`;

const Paper = styled(Flex)`
  position: relative;
  z-index: 2;
  box-shadow: 0px 2px 9px ${props => props.theme.colors.shadow};
  background-color: ${props => props.theme.colors.white};

  ${ifProp(
    "fill",
    css`
      flex: 1 1 auto;
    `,
  )}
`;

const getIconByType = (type, isActive, isComplete) => {
  if (isComplete) {
    return <IconTick />;
  }
  if (isActive) {
    switch (type) {
      case "REPORTED":
      case "RESOLVED":
        return <IconTick />;
      case "ENROUTE":
        return <IconTruck />;
      case "WORKING":
        return <IconHat />;
      case "INVESTIGATING":
        return <IconClock />;
      default:
        return null;
    }
  }

  return null;
};

const deriveProgressItemType = (isActive, isComplete) => {
  if (isActive) return "active";
  if (isComplete) {
    return "complete";
  }
  return "incomplete";
};

// TODO: Don't rely on the display value "label" to set this text
export const TOOLTIP_MAP = {
  "Outage reported":
    "When an outage is reported by a customer in the Outage Centre",
  "Outage processed": "When an outage is received and processed by Vector",
  "On our way": "When a crew is assigned and are about to leave",
  Investigating: "When a crew is in the area investigating the outage",
  Resolving:
    "When the crew knows what the issue is and have provided an estimated time of restoration",
  "Outage resolved":
    "When our crew have resolved an outage and power has been restored",
};
export const setTooltipTextByLabel = label => TOOLTIP_MAP[label] || false;

const getProgressBarData = (statuses, startText) =>
  statuses.map(({ label, active, complete, type }, index) => ({
    id: index,
    label,
    type: deriveProgressItemType(active, complete),
    icon: getIconByType(type, active, complete),
    // Start date for first item
    timestamp: index === 0 ? startText : null,
    // Decides the display size of the icon
    isActive: active,
    tooltip: setTooltipTextByLabel(label),
  }));

const getMapByType = (eventType, hasAvailableProducts) => {
  if (!hasAvailableProducts) {
    return <Map type="unplanned" />;
  }
  switch (eventType) {
    case PLANNED:
      return <Map type="planned" />;
    case UNPLANNED:
      return <Map type="unplanned" />;
    case UNKNOWN:
    default:
      return <Map type="unknown" />;
  }
};

export class OutageContainer extends React.Component {
  static propTypes = {
    outage: PropTypes.shape({
      id: PropTypes.string,
      address: PropTypes.shape({
        street: PropTypes.string,
        suburb: PropTypes.string,
      }).isRequired,
      subscription: PropTypes.shape({
        ticketId: PropTypes.string.isRequired,
        updateByTxt: PropTypes.bool.isRequired,
        updateByEmail: PropTypes.bool.isRequired,
      }),
      progressionStatus: PropTypes.string.isRequired,
      outageReason: PropTypes.string,
    }),
    ticket: PropTypes.shape({
      status: or([explicitNull(), PropTypes.string]),
      error: or([explicitNull(), PropTypes.string]),
    }).isRequired,
    outagesUpdateTicket: PropTypes.func.isRequired,
    outagesUpdateTicketDone: PropTypes.func.isRequired,
    onOpenModal: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isUpdatingTicket: PropTypes.bool,
    isLoggedIn: PropTypes.bool.isRequired,
    error: PropTypes.string,
    history: PropTypes.object.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  static defaultProps = {
    outage: null,
    error: undefined,
    isUpdatingTicket: false,
  };

  handleRedirect = (path, redirectTo) => {
    const { history } = this.props;
    history.push(path, redirectTo ? { redirectTo } : undefined);
  };

  handleReportUnknown = () => {
    const { match } = this.props;
    this.handleRedirect(`/report/${match.params.icpNumber}/create`, match.url);
  };

  handleLoginRedirect = () => {
    const { outage } = this.props;
    this.handleRedirect("/login", `/address/${outage.id}`);
  };

  handleRegisterRedirect = () => {
    const { outage } = this.props;
    this.handleRedirect("/register", `/address/${outage.id}`);
  };

  handleChangeButtonClick = () => {
    const { outage, match } = this.props;
    this.handleRedirect(`/address/${outage.id}/updates`, match.url);
  };

  handleCheckAnotherAddressClick = () => {
    this.handleRedirect(`/address`);
  };

  handleLSEModalOpen = () => {
    const { onOpenModal } = this.props;
    onOpenModal(LSE_MODAL_ID);
  };

  state = { isDialogOpen: true };

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  handleTicketDone = () => {
    const {
      outagesUpdateTicketDone,
      match: {
        params: { icpNumber },
      },
    } = this.props;

    outagesUpdateTicketDone(icpNumber);
    this.handleCloseDialog();
  };

  handleOutageResolvedConfirmation = isResolved => () => {
    const {
      outagesUpdateTicket,
      outage: { outageId },
      match: {
        params: { icpNumber },
      },
    } = this.props;

    trackEvent({
      category: `Outage Resolved`,
      action: `Outage ID: ${outageId}`,
      label: `${
        isResolved
          ? "Yes, the outage was confirmed as resolved by the customer"
          : "No, the outage was marked as unresolved by the customer"
      }`,
    });

    if (isResolved) {
      outagesUpdateTicket(outageId, icpNumber, isResolved);
    } else {
      this.handleRedirect(`/report/${icpNumber}/create/details`);
    }
    // Note: CO-479 will implement the full "No" handling, for now we just redirect to create report
  };

  renderLoader = () => (
    <Box width={1}>
      <Loader />
    </Box>
  );

  render() {
    // eslint-disable-next-line no-shadow, react/prop-types
    const {
      isLoading,
      isLoggedIn,
      error,
      outage,
      history,
      match,
      ticket,
      isUpdatingTicket,
    } = this.props;

    const { isDialogOpen } = this.state;

    /**
     * Render <Loader />
     */
    if (isLoading) {
      return this.renderLoader();
    }

    /**
     * Render a container level error (nothing else is shown)
     */
    if (error || !outage) {
      return (
        <PageWrapper
          metaTitle="Location Error"
          title="Check an address"
          history={history}
        >
          <ErrorMessage message={error} shouldReveal />
        </PageWrapper>
      );
    }

    const {
      params: { icpNumber },
    } = match;

    const {
      address,
      eventType,
      outageStart,
      workStatus,
      progressionStatus,
      outageId,
      subscription,
    } = outage;

    const { street, hasAvailableProducts } = address;

    const currentWorkStatus = getCurrentWorkStatus(workStatus);

    const workStatusResolvedItem = find(workStatus, {
      type: "RESOLVED",
      complete: true,
    });
    const isResolved = typeof workStatusResolvedItem !== "undefined";
    const yesNoDialogEnabled = isResolved && progressionStatus === "ROLLED_UP";

    const unPlannedResolveText = isResolved
      ? "OUTAGE RESOLVED"
      : "ESTIMATED RESTORATION";

    /**
     * Render a regular (PLANNED|UNPLANNED) outage type
     */
    return (
      <LSEventConsumer>
        {lsEvent => (
          <PageWrapper
            metaTitle={`${street}`}
            title="Check an address"
            history={history}
          >
            {/* Yes/No Dialog */}
            {/* Dialog: Question */}
            {yesNoDialogEnabled && ticket.status === "PROMPT" && (
              <Dialog
                isOpen={isDialogOpen}
                onClose={isUpdatingTicket ? null : this.handleCloseDialog}
              >
                <Dialog.Error>{ticket.error}</Dialog.Error>
                <Dialog.Icon size={90} bg="accent">
                  <IconQuestion width="auto" height="40px" />
                </Dialog.Icon>
                <Dialog.Title>This outage has been resolved</Dialog.Title>
                <Dialog.Copy>Is your power back on?</Dialog.Copy>
                <Dialog.Actions>
                  <Button
                    block
                    disabled={isUpdatingTicket}
                    isLoading={isUpdatingTicket}
                    variant="dark"
                    onClick={this.handleOutageResolvedConfirmation(true)}
                    mr={2}
                    data-testid="Dialog__Action--yes"
                  >
                    Yes
                  </Button>
                  <Button
                    block
                    disabled={isUpdatingTicket}
                    variant="dark"
                    onClick={this.handleOutageResolvedConfirmation(false)}
                    ml={2}
                    data-testid="Dialog__Action--no"
                  >
                    No
                  </Button>
                </Dialog.Actions>
                <Dialog.Fineprint width={[9 / 10, 3 / 4]}>
                  If your hot water was affected, please allow up to 6 hours for
                  your water tank to reheat.
                </Dialog.Fineprint>
              </Dialog>
            )}

            {/* Dialog: Yes Confirmation */}
            {isResolved && ticket.status === "RESOLVED" && (
              <Dialog isOpen={isDialogOpen} onClose={this.handleTicketDone}>
                <Dialog.Icon size={90} bg="success">
                  <IconTick width="auto" height="40px" />
                </Dialog.Icon>
                <Dialog.Title>Thanks for letting us know.</Dialog.Title>
                <Dialog.Copy>We&apos;ve closed your report.</Dialog.Copy>
                <Dialog.Actions>
                  <Button
                    block
                    variant="dark"
                    onClick={this.handleTicketDone}
                    data-testid="Dialog__Action--continue"
                  >
                    Continue
                  </Button>
                </Dialog.Actions>
                <Dialog.Fineprint width={[9 / 10, 3 / 4]}>
                  Please note that power may be intermittent for short periods
                  during the restoration process.
                </Dialog.Fineprint>
              </Dialog>
            )}
            {/* Dialog: No Confirmation (TBC) */}

            {/* Address Display */}
            <AddressBlock>
              <AddressBlock.Label>{street}</AddressBlock.Label>
              <AddressBlock.Map>
                {getMapByType(eventType, hasAvailableProducts)}
              </AddressBlock.Map>
            </AddressBlock>

            {/* Storm Warning (show if there is a LSE) */}
            {lsEvent.code > 0 && (
              <LSENotificationContainer
                outageId={outageId}
                lsEvent={lsEvent}
                onActionClick={this.handleLSEModalOpen}
              />
            )}

            {/* Outage status notification (do not show if there is a LSE) */}
            {lsEvent.code <= 0 && (
              <OutageStatusBar
                eventType={eventType}
                outageId={outageId}
                outsideVectorNetwork={!hasAvailableProducts}
              />
            )}

            {/* Page Content */}
            {hasAvailableProducts && (
              <Flex width={1} flexDirection="column" css="min-height:90%;">
                {/* Outage Status, for Planned/Unplanned outages in LSE Mode 0 and 1 ONLY */}
                {eventType !== UNKNOWN && (
                  <Wrapper>
                    <ProgressBar
                      data={getProgressBarData(
                        workStatus,
                        <DateFormat time={outageStart} />,
                      )}
                    />
                    <ThematicBreak color="gray" height="0px" />
                    <OutageInfo
                      subscription={subscription}
                      outage={outage}
                      isResolved={isResolved}
                      unPlannedResolveText={unPlannedResolveText}
                      workStatus={currentWorkStatus}
                    />
                  </Wrapper>
                )}

                {/* For all LSE modes */}
                <Paper flexDirection="column" p={20} fill>
                  <Box>
                    <OutageUpdateControl
                      eventType={eventType}
                      lseCode={lsEvent.code}
                      subscription={subscription}
                      isLoggedIn={isLoggedIn}
                      isResolved={isResolved}
                      isOutage={!!outageId}
                      isUnknownOutage={eventType === UNKNOWN}
                      isUnplannedOutage={eventType === UNPLANNED}
                      onChangePreferences={this.handleChangeButtonClick}
                      onLogin={this.handleLoginRedirect}
                      onRegister={this.handleRegisterRedirect}
                      onStartReport={this.handleReportUnknown}
                      progressionStatus={progressionStatus}
                    />
                  </Box>
                  {eventType === UNPLANNED && (
                    <Box py={10}>
                      <EventHistoryContainer icpNumber={icpNumber} />
                    </Box>
                  )}
                  {eventType === PLANNED && (
                    <Box py={10}>
                      <NotificationLink />
                    </Box>
                  )}

                  <AddressPlannedOutagesContainer
                    icpNumber={icpNumber}
                    mt={3}
                  />

                  {lsEvent.code > 0 && (
                    <StormWarning onClick={this.handleLSEModalOpen} />
                  )}
                </Paper>
              </Flex>
            )}

            {!hasAvailableProducts && (
              <Flex width={1} flexDirection="column" css="min-height:100%;">
                <Wrapper>
                  <Text as="p" mt={1} fontSize={2}>
                    This address is outside our network.
                  </Text>
                  <Text as="p" mt={3} fontSize={2}>
                    Please contact your local lines company or your power
                    company.
                  </Text>
                </Wrapper>
                <Box mx={20} mt={10}>
                  <Button
                    variant="dark"
                    block
                    onClick={this.handleCheckAnotherAddressClick}
                  >
                    CHECK ANOTHER ADDRESS
                  </Button>
                </Box>
              </Flex>
            )}
          </PageWrapper>
        )}
      </LSEventConsumer>
    );
  }
}

/* istanbul ignore next */
const enhance = compose(
  withErrorBoundary(),
  // Get Routing Props for outage id
  withRouter,
  // Then run redux connect to query for said ID in store
  connect(
    (state, ownProps) => ({
      // TODO: Refactor to use selectors
      isLoading: state.outages.isLoading,
      error: state.outages.error,
      outage: getOutageFromIcpStatus(state),
      ticket: getTicketStatus(state, ownProps.match.params.icpNumber),
      isUpdatingTicket: getIsUpdatingTicket(state),
      isLoggedIn: state.auth.loggedIn,
    }),
    {
      outagesUpdateTicket,
      outagesUpdateTicketDone,
      onOpenModal: openModal,
    },
  ),
);

export default enhance(OutageContainer);
