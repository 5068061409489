import moment from "moment";
import React from "react";
import PropTypes from "prop-types";
import { Box, Flex, Link, Text } from "rebass";
import styled from "styled-components/macro";
import { ReactComponent as HealthSafetyIcon } from "./health_and_safety.svg";
import { EMERGENCY_CONTACT_NUMBER } from "../../common/constants";
import { Button } from "../Button";

const LSEBanner = styled(Box)`
  background-color: ${props => props.theme.colors.urgentRed};
  color: ${props => props.theme.colors.white};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

const StormZoneDetailsWrapper = styled(Box)`
  background-color: ${props => props.theme.colors.white};
  height: 100%;
`;

const DetailsContainer = styled(Flex)`
  max-width: 510px;
`;

const StormZoneName = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  color: #000840;
  font-weight: 500;
`;

const RestorationText = styled(Text)`
  font-size: 14px;
  line-height: 21px;
  color: #42526e;
  font-weight: 500;
`;

const ETAText = styled(Text)`
  font-size: 14px;
  line-height: 21px;
  color: #42526e;
  font-weight: 500;
`;

const LastUpdate = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  color: #97a0af;
  font-weight: 500;
`;

const DetailsTitle = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  color: #000840;
  font-weight: 500;
`;

const SafetyConcernsWrapper = styled(Flex)`
  background-color: #1f79ff1a;
  border-radius: 12px;
  align-items: start;
`;

const SafetyIcon = styled(HealthSafetyIcon)`
  path {
    color: #0053de;
  }
`;

const SafetyConcernsTitle = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  color: #000840;
  font-weight: 700;
`;

const SafetyConcernsText = styled(Text)`
  font-size: 14px;
  line-height: 21px;
  color: #42526e;
  font-weight: 500;
`;

const CallUsButton = styled(Button)`
  background-color: #1f79ff1a;
  color: #0053de;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  min-width: 100px;
  width: unset;
`;

const DetailsMessage = styled(Text)`
  font-size: 14px;
  line-height: 21px;
  color: #42526e;
  font-weight: 500;
`;

const StormZoneDetails = ({ stormZoneDetails, showZoneName }) => (
  <>
    <LSEBanner py={[10, 10, 10]} px={[20, 20, 25]}>
      <Text>large scale outages in your area</Text>
    </LSEBanner>

    <StormZoneDetailsWrapper
      px={4}
      py={[24, 24, 24]}
      data-testid="StormZone_Details"
    >
      <DetailsContainer flexDirection="column">
        {showZoneName && stormZoneDetails.name && (
          <StormZoneName mb={3} data-testid="Details__StormZone_Name">
            {stormZoneDetails.name}
          </StormZoneName>
        )}

        {stormZoneDetails.restorationState && (
          <RestorationText
            mb={2}
            data-testid="Details__StormZone_RestorationState"
          >
            Current state of restoration: {stormZoneDetails.restorationState}%{" "}
            restored
          </RestorationText>
        )}

        <ETAText mb={2} data-testid="Details__StormZone_RestorationEtr">
          {stormZoneDetails.estimatedTimeRestoration
            ? stormZoneDetails.estimatedTimeRestoration
            : "We'll keep you updated as soon as we know more"}
        </ETAText>

        {stormZoneDetails.updatedAt && (
          <LastUpdate mb={4} data-testid="Details__StormZone_LastUpdate">
            Last updated:{" "}
            {moment(stormZoneDetails.updatedAt).format("ddd. D MMM, h:ma")}
          </LastUpdate>
        )}

        {stormZoneDetails.message && (
          <>
            <DetailsTitle mb={2}>Details</DetailsTitle>
            <DetailsMessage mb={4} data-testid="Details__StormZone_Message">
              {stormZoneDetails.message.split("\n").map(line => (
                <p>{line}</p>
              ))}
            </DetailsMessage>
          </>
        )}

        <SafetyConcernsWrapper p={3} mb={4}>
          <Flex
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
            css="height:100%;"
          >
            <SafetyIcon />
          </Flex>

          <Flex flexDirection={["column", "row", "row"]} alignItems="start">
            <Flex flexDirection="column" mx={2} mb={[2, 0, 0]}>
              <SafetyConcernsTitle mt={0} mb={2}>
                Safety concerns
              </SafetyConcernsTitle>

              <SafetyConcernsText>
                If there is a risk to people or property, please call us or{" "}
                <Link href="https://www.vector.co.nz/personal/help-safety/in-an-emergency">
                  get more information
                </Link>
              </SafetyConcernsText>
            </Flex>

            <CallUsButton
              id="submit"
              block
              type="submit"
              href={`tel:${EMERGENCY_CONTACT_NUMBER}`}
              py={1}
              px={2}
            >
              Call us
            </CallUsButton>
          </Flex>
        </SafetyConcernsWrapper>
      </DetailsContainer>
    </StormZoneDetailsWrapper>
  </>
);

StormZoneDetails.defaultProps = {
  stormZoneDetails: {},
  showZoneName: false,
};

StormZoneDetails.propTypes = {
  stormZoneDetails: PropTypes.object,
  showZoneName: PropTypes.bool,
};

export default StormZoneDetails;
