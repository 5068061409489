import { combineActions, handleActions } from "redux-actions";
import { withApiErrors } from "../errors";
import {
  reportCreate,
  reportSuccess,
  reportFailure,
  reportErrorReset,
  reportFetchRequest,
  reportFetchFailure,
  reportFetchSuccess,
} from "./reports.action";

const initialState = {
  loading: false,
  error: undefined,
  outages: [],
};

const reducer = handleActions(
  {
    [reportCreate]: {
      next: state => ({
        ...state,
        loading: true,
      }),
      throw: (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }),
    },
    [reportSuccess]: {
      next: state => ({
        ...state,
        loading: false,
        error: undefined,
      }),
    },
    [reportErrorReset]: state => ({
      ...state,
      error: undefined,
    }),
    [reportFetchRequest]: state => ({
      ...state,
      loading: true,
    }),
    [reportFetchSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      outages: payload.allIds,
    }),
    [combineActions(reportFetchFailure, reportFailure)]: (
      state,
      { payload },
    ) => ({
      ...state,
      error: payload,
      loading: false,
    }),
  },
  initialState,
);

export default withApiErrors(reducer, "reports");
