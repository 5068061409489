import { createSelector } from "reselect";

export const getAddresses = createSelector(
  [state => state.addresses.allIds, state => state.addresses.byId],
  (ids = [], data = {}) => ids.map(id => data[id]),
);

export const getSelectedAddress = createSelector(
  [state => state.addresses.selected, state => state.addresses.byId],
  (id, data) => {
    if (!id) {
      return null;
    }

    return data[id]; // returns the data object from the store
  },
);

export const getSelectedAddressId = createSelector(
  [state => state.addresses.selected],
  id => id,
);

export const getAddressText = createSelector(
  [state => state.addresses.text],
  label => label,
);

export const getSelectedAddressLabel = createSelector(
  [getSelectedAddress],
  item => (item ? item.label : ""),
);

export const getAddressIsFetching = createSelector(
  [state => state.addresses.isFetching],
  isFetching => isFetching,
);

export const getUserAddress = createSelector(
  [state => state.addresses.selected],
  addressId => addressId || null,
);

export const getCurrentAddressCoords = createSelector(
  [state => state.addresses.coordinates, state => state.addresses.selected],
  (coordinates, icpNumber) => coordinates[icpNumber] || {},
);
