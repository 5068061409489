import pickBy from "lodash/pickBy";
import isPlainObject from "lodash/isPlainObject";
import invariant from "tiny-invariant";

// Detect handlers by on* prefix;
const isEvent = key => /^on[A-Z][A-Za-z]+$/.test(key);

/**
 * Returns the key: fn pair from the object if it passes the isEvent func
 * @param obj
 * @return {Object} - on* : handler()
 */
export const getEventHandlers = obj => pickBy(obj, (fn, key) => isEvent(key));

/**
 *
 * @param {...object} maps - argument list of event handler objects
 * @return {[string]} - Array of strings describing the event handler types
 */
export const extractEventHandlerKeys = (...maps) =>
  maps.reduce((acc, obj) => {
    invariant(
      isPlainObject(obj),
      `extractEventHandlerKeys must be called with a list of objects but "${typeof obj}" was given.`,
    );

    const keys = Object.keys(obj)
      // ensure we're only handling event keys
      .filter(isEvent)
      // only add the key if not already in the resulting array, this ensures keys are added in order
      .filter(key => !acc.includes(key));
    return [...acc, ...keys];
  }, []);
