import { Flex } from "rebass";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components/macro";
import { switchProp } from "styled-tools";
import { ReactComponent as MapPin } from "./MapPin.svg";

const Pin = styled(MapPin)`
  ${switchProp("type", {
    planned: css`
      #droplet-background path {
        fill: #0dcf5a;
      }
    `,
    unplanned: css`
      #droplet-background path {
        fill: #ff6c0e;
      }
    `,
    myreport: css`
      #droplet-background path {
        fill: #fac80f;
      }
    `,
    unknown: css`
      #droplet-background path {
        fill: #000840;
      }
      #inner-background {
        fill: #ffffff;
      }
    `,
  })};
`;

const Map = ({ type }) => (
  <Flex
    flexWrap="wrap"
    alignItems="center"
    justifyContent="center"
    width={[1, null, 1]}
    height={[1, null, 1]}
    css="height:100%;"
  >
    <Pin type={type} />
  </Flex>
);
Map.propTypes = {
  type: PropTypes.oneOf(["planned", "unplanned", "myreport", "unknown"])
    .isRequired,
};
export default Map;
