import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { PageWrapper } from "../PageWrapper";
import StormZoneDetails from "../StormZoneDetails/StormZoneDetails";
import { AddressBlock } from "../AddressBlock";
import { Map } from "../Map";

const StormZoneDetailsMapSearch = ({ history, stormZoneDetails }) => (
  <PageWrapper
    metaTitle={stormZoneDetails.name}
    title="outage map"
    history={history}
    p={4}
  >
    <AddressBlock>
      <AddressBlock.Label>{stormZoneDetails.name}</AddressBlock.Label>
      <AddressBlock.Map>
        <Map type="unknown" />
      </AddressBlock.Map>
    </AddressBlock>

    <StormZoneDetails
      stormZoneDetails={stormZoneDetails}
      showZoneName={false}
    />
  </PageWrapper>
);

StormZoneDetailsMapSearch.defaultProps = {
  stormZoneDetails: {},
};

StormZoneDetailsMapSearch.propTypes = {
  stormZoneDetails: PropTypes.object,
  history: PropTypes.object.isRequired,
};

const enhance = compose(withRouter);

export default enhance(StormZoneDetailsMapSearch);
