/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import {
  clearAddress,
  getCurrentAddressCoords,
} from "../../redux/modules/addresses";
import StormZoneShapes from "./_/StormZoneShapes";
import { clearStormZoneDetails } from "../../redux/modules/storm-zones-id/storm-zones-id.actions";
import StormZonePopupContainer from "../StormZonePopupContainer/StormZonePopupContainer";
import { trackEvent } from "../../common/analytics";

export const StormZoneContainer = ({
  zoomLevel,
  addressCoords,
  stormZones,
  clearCoordinates,
  clearStormZone,
}) => {
  const [selectedStormZone, setSelectedStormZone] = useState(null);

  const GA_CATEGORY = "Outage_Zone";

  useEffect(() => {
    if (addressCoords.latitude) {
      setSelectedStormZone(null);
      clearStormZone();
    }
  }, [addressCoords]);

  const handleOnZoneClick = stormZone => {
    clearCoordinates();
    setSelectedStormZone(stormZone);

    trackEvent({
      category: GA_CATEGORY,
      action: "Click",
      label: `Outage Zone: ${stormZone.id}`,
    });
  };

  const handleOnPopupClose = () => {
    setSelectedStormZone(null);
    clearStormZone();
  };

  const showPopUp =
    selectedStormZone !== undefined && selectedStormZone !== null;

  return (
    <>
      <StormZoneShapes
        stormZones={stormZones}
        zoomLevel={zoomLevel}
        onZoneClick={handleOnZoneClick}
      />

      {showPopUp && (
        <StormZonePopupContainer
          zoomLevel={zoomLevel}
          zoneId={selectedStormZone.id}
          zoneName={selectedStormZone.name}
          zoneCenterPoint={selectedStormZone.centerPoint}
          onClose={handleOnPopupClose}
        />
      )}
    </>
  );
};

StormZoneContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  zoomLevel: PropTypes.number.isRequired,
  addressCoords: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  stormZones: PropTypes.array,
  clearStormZone: PropTypes.func.isRequired,
  clearCoordinates: PropTypes.func.isRequired,
};

StormZoneContainer.defaultProps = {
  stormZones: [],
  addressCoords: {},
};

/* istanbul ignore next */
const enhance = compose(
  withRouter,
  connect(
    state => ({
      addressCoords: getCurrentAddressCoords(state),
    }),
    {
      clearStormZone: clearStormZoneDetails,
      clearCoordinates: clearAddress,
    },
  ),
);

export default enhance(StormZoneContainer);
