import get from "lodash/get";
import reduceReducers from "reduce-reducers";
import { combineActions, handleAction } from "redux-actions";

import { clearApiError } from "./errors.actions";

const clearErrorsReducer = (sliceName, actions) =>
  handleAction(
    combineActions(clearApiError, ...actions),
    (state, action) => {
      // istanbul ignore next
      const meta = get(action, "meta", {});
      if (
        (typeof meta.slice !== "undefined" && meta.slice === sliceName) ||
        typeof meta.slice === "undefined"
      ) {
        return {
          ...state,
          error: undefined,
        };
      }

      return state;
    },
    {},
  );

const captureErrorsReducer = actions =>
  handleAction(
    combineActions(...actions),
    {
      throw: (state, { payload: err }) => ({
        ...state,
        error: err.message,
      }),
    },
    { error: undefined },
  );

export const withApiErrors = (
  reducerFn,
  sliceName,
  actions = { capture: [], clear: [] },
) => {
  const captureActions = get(actions, "capture", []);
  const clearActions = get(actions, "clear", []);

  const reducers = [reducerFn, clearErrorsReducer(sliceName, clearActions)];

  if (captureActions.length) {
    reducers.push(captureErrorsReducer(captureActions));
  }

  return reduceReducers(...reducers);
};
