/* eslint-disable react/prop-types */
import { Box } from "@rebass/grid";
import React from "react";
import MediaQuery from "react-responsive";
import styled, { ThemeConsumer } from "styled-components";
import { color, fontSize } from "styled-system";
import { OutageStatusNotification } from "../OutageStatusNotification";
import { ThematicBreak } from "../ThematicBreak";

const OutageIdText = styled.div`
  ${fontSize};
  ${color};
  margin: 0;
  text-transform: uppercase;
`;

const MobileOutageIdWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
`;

const DesktopOutageIdWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
`;

const VerticalThematicBreak = styled(ThematicBreak)`
  margin-top: 0;
  margin-bottom: 0;
`;

const KnownOutageEventNotification = ({
  outageId,
  outageStatus,
  mediaQueryValues,
}) => (
  <ThemeConsumer>
    {theme => (
      <>
        {/* MOBILE/TABLET */}
        <MediaQuery
          query={`(max-width: ${theme.breakpoints[1]}px)`}
          values={mediaQueryValues}
        >
          <OutageStatusNotification
            variant="info"
            notification={outageStatus}
          />

          <MobileOutageIdWrapper py={10} pl={20}>
            <span> {`outage id: ${outageId}`} </span>
          </MobileOutageIdWrapper>
        </MediaQuery>

        {/* DESKTOP */}
        <MediaQuery
          query={`(min-width: ${theme.breakpoints[1]}px)`}
          values={mediaQueryValues}
        >
          <OutageStatusNotification
            variant="info"
            notification={() => (
              <DesktopOutageIdWrapper>
                <Box pr={[20, null, 25]}>
                  <span>{outageStatus}</span>
                </Box>

                <VerticalThematicBreak
                  marginRight="20px"
                  height="20px"
                  width="0px"
                  display="inline-block"
                  padding={0}
                />

                <Box pr={[5, 10, 10]}>
                  <span>outage id:</span>
                </Box>

                <OutageIdText>{outageId}</OutageIdText>
              </DesktopOutageIdWrapper>
            )}
          />
        </MediaQuery>
      </>
    )}
  </ThemeConsumer>
);

export default KnownOutageEventNotification;
