import { handleActions, combineActions } from "redux-actions";
import {
  requestEventHistory,
  requestEventHistorySuccess,
  requestEventHistoryFailure,
  toggleEventHistoryDisplay,
} from "./event-history.actions";

export const INITIAL_STATE = {
  isFetching: false,
  isOpen: false,
  byId: {},
  allIds: {},
};
export const reducer = handleActions(
  {
    [requestEventHistory]: state => ({
      ...state,
      isFetching: true,
    }),
    [combineActions(
      requestEventHistorySuccess,
      requestEventHistoryFailure,
    )]: state => ({ ...state, isFetching: false }),
    [requestEventHistorySuccess]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        ...action.payload.byId,
      },
      allIds: {
        ...state.allIds,
        [action.meta.icpNumber]: action.payload.allIds,
      },
    }),
    [toggleEventHistoryDisplay]: state => ({
      ...state,
      isOpen: !state.isOpen,
    }),
  },
  INITIAL_STATE,
);

export default reducer;
