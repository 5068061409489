import { createSelector } from "reselect";
import get from "lodash/get";

export const isLoggedIn = createSelector(
  [state => state.auth.loggedIn],
  loggedIn => !!loggedIn,
);

export const getErrorMessageFromErrorStatus = error => {
  let message;
  if (error) {
    if (error.status === 404) {
      message =
        "We don't seem to have an account with that email. You can create an account below.";
    } else if (error.status === 401) {
      message =
        "Sorry, we don’t recognise that code or it has expired. Please enter the code again or request a new code.";
    } else {
      message = "An unexpected error occurred.  Please try again later.";
    }
  }
  return message;
};

const getError = state => state.auth.error;
export const errorMessage = createSelector(
  [getError],
  error => getErrorMessageFromErrorStatus(error),
);

const getEmail = state => get(state, ["auth", "email"], undefined);
export const authEmail = createSelector(
  [getEmail],
  email => email,
);
