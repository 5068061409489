import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "rebass";
import styled from "styled-components/macro";
import { Exclamation } from "../../../components/ErrorMessage";
import { IconClose } from "../../../assets/icons";

const ErrorContainer = styled(Flex)`
  background-color: ${p => p.theme.colors.white};
  border: 2px solid ${p => p.theme.colors.error};
  border-radius: 4px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.14);
`;

const CloseIcon = styled(IconClose)`
  path {
    fill: ${p => p.theme.colors.gray};
  }
`;

const Button = styled.button`
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
`;

export const Errors = ({ errors }) => {
  const [showError, setShowError] = useState(true);

  return (
    <Flex
      flexDirection="column"
      width={[1, null, 1]}
      pt={[1, null, 1]}
      role="alert"
      data-testid="MapAddress__Errors"
    >
      {showError && errors && errors.length > 0 && (
        <ErrorContainer
          flexDirection="row"
          justifyContent="space-between"
          width={1}
          p={20}
          my={2}
        >
          <Exclamation />
          <Box css="flex-grow: 1;" mx={2} mt={2}>
            {errors.map(err => (
              <Text
                key={err}
                dangerouslySetInnerHTML={{ __html: err }}
                as="p"
                fontSize={2}
                mx={1}
                mt={1}
              />
            ))}
          </Box>
          <Box>
            <Button
              aria-label="Close"
              data-testid="MapAddress__ErrorsClose"
              onClick={() => setShowError(false)}
              p={1}
            >
              <CloseIcon width={12} height={12} aria-hidden="true" />
            </Button>
          </Box>
        </ErrorContainer>
      )}
    </Flex>
  );
};

Errors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

Errors.defaultProps = {
  errors: [],
};

export default Errors;
