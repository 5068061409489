/* eslint-disable no-shadow */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { or, explicitNull } from "airbnb-prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import styled, { withTheme } from "styled-components/macro";
import { Flex, Box, Text } from "rebass";
import { Formik, Form, Field } from "formik";
import { Circle } from "../../components/Circle";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { Loader } from "../../components/Loader";
import { TextField } from "../../components/Form";
import { Button } from "../../components/Button";
import {
  isRequired,
  isValidEmail,
  isValidPhone,
  maxLength,
  validateWith,
} from "../../components/RegistrationFormFields/validate";
import {
  clearCustomerUpdateMessage,
  getCustomer,
  getCustomerError,
  getCustomerUpdateSuccessMessage,
  getIsFetching,
  requestCustomer,
} from "../../redux/modules/customers";
import {
  sendOneTimePasswordRequest,
  logoutRequest,
} from "../../redux/modules/login";
import { ReactComponent as TickIcon } from "./_/tick.svg";
import { trackEvent } from "../../common/analytics";
import LoginRedirectMessage from "../../components/LoginRedirectMessage/LoginRedirectMessage";

const GA_CATEGORY_CODE = "MyAccount_Code";

const FormRow = styled(Box).attrs({ py: 2 })``;

export class MyAccountContainer extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onGetCustomer: PropTypes.func.isRequired,
    onSendOTP: PropTypes.func.isRequired,
    onLoginRedirect: PropTypes.func.isRequired,
    clearCustomerUpdateMessage: PropTypes.func.isRequired,
    error: or([explicitNull(), PropTypes.string]).isRequired,
    successMessage: or([explicitNull(), PropTypes.string]).isRequired,
    isFetching: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    customer: or([
      explicitNull(),
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phoneNumber: PropTypes.string,
        emailAddress: PropTypes.string,
      }),
    ]).isRequired,
  };

  componentDidMount() {
    const { onGetCustomer } = this.props;
    onGetCustomer();
  }

  componentWillUnmount() {
    const { clearCustomerUpdateMessage } = this.props;
    clearCustomerUpdateMessage();
  }

  handleSubmit = values => {
    const { customer: oldValues, history, onSendOTP, match } = this.props;

    trackEvent({
      category: GA_CATEGORY_CODE,
      action: "Click",
      label: "Send_OTP_Next",
    });

    const meta = {
      history,
      redirectTo: "/my-account/auth",
      navigateBack: match.url,
      customerValues: {
        values,
        oldValues,
      },
    };
    const { emailAddress } = oldValues;
    onSendOTP({ email: emailAddress }, meta);
  };

  render() {
    const {
      isFetching,
      isLoading,
      error,
      successMessage,
      customer,
      onLoginRedirect,
    } = this.props;

    if (isFetching || !customer) {
      return <Loader data-testid="MyAccount__Loader" />;
    }

    const showLoginRedirect =
      error && error.includes("already have an account");

    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        data-testid="MyAccount"
        py={[3, null, 4]}
        px={[3]}
      >
        <Box width={[1, null, 3 / 4]} role="alert">
          {successMessage && (
            <Flex
              flexDirection="row"
              flexWrap="nowrap"
              alignItems="center"
              justifyContent="center"
            >
              <Circle bg="secondary" color="white" size={25}>
                <TickIcon width="auto" height="13px" />
              </Circle>
              <Text
                data-testid="MyAccount__Success"
                as="p"
                ml={10}
                fontSize="2"
                textAlign="center"
              >
                {successMessage}
              </Text>
            </Flex>
          )}
        </Box>
        <Box width={[1, null, 3 / 4]} role="alert">
          {error && (
            <Text
              data-testid="MyAccount__Error"
              as="p"
              fontSize="2"
              textAlign="center"
              color="error"
            >
              {error}
            </Text>
          )}
          {showLoginRedirect && (
            <LoginRedirectMessage onClick={onLoginRedirect} />
          )}
        </Box>
        <Box width={[1, null, 3 / 4]}>
          <Formik
            onSubmit={this.handleSubmit}
            initialValues={customer}
            render={({ dirty, isValid }) => (
              <Form
                aria-label="My Account Details Form"
                noValidate
                data-testid="MyAccount__Form"
              >
                <FormRow>
                  <Field
                    required
                    placeholder="First name"
                    name="firstName"
                    validate={validateWith(isRequired)}
                    component={TextField}
                  />
                </FormRow>
                <FormRow>
                  <Field
                    required
                    placeholder="Last name"
                    name="lastName"
                    validate={validateWith(isRequired)}
                    component={TextField}
                  />
                </FormRow>
                <FormRow>
                  <Field
                    required
                    placeholder="Mobile phone"
                    name="phoneNumber"
                    component={TextField}
                    validate={validateWith(
                      isRequired,
                      isValidPhone,
                      maxLength(20),
                    )}
                    note="Our repair crew may need to contact you plus we can TXT updates to this number if you choose"
                  />
                </FormRow>
                <FormRow>
                  <Field
                    required
                    placeholder="Email Address"
                    name="emailAddress"
                    component={TextField}
                    validate={validateWith(isRequired, isValidEmail)}
                    note="This is what you use to login plus we can email updates if you choose. If you change your email address, we’ll ask you to verify this change by sending the verification code to your previous email"
                  />
                </FormRow>
                <FormRow>
                  <Button
                    mt={20}
                    block
                    disabled={!dirty || !isValid || isLoading}
                    isLoading={isLoading}
                    variant="dark"
                    type="submit"
                    data-testid="MyAccount__Button--submit"
                  >
                    Next
                  </Button>
                </FormRow>
              </Form>
            )}
          />
        </Box>
      </Flex>
    );
  }
}

// istanbul ignore next
const enhance = compose(
  withTheme,
  withErrorBoundary(),
  withRouter,
  connect(
    state => ({
      isFetching: getIsFetching(state),
      customer: getCustomer(state),
      error: getCustomerError(state),
      successMessage: getCustomerUpdateSuccessMessage(state),
      isLoading: state.auth.isLoading,
    }),
    {
      onGetCustomer: requestCustomer,
      onSendOTP: sendOneTimePasswordRequest,
      clearCustomerUpdateMessage,
      onLoginRedirect: logoutRequest,
    },
  ),
);

export default enhance(MyAccountContainer);
