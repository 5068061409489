/* eslint-disable no-shadow */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Flex, Box, Text } from "rebass";
import styled from "styled-components/macro";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import {
  plannedOutagesRequest,
  clearPlannedOutages,
} from "../../redux/modules/planned-outages";
import { AddressSearchContextContainer } from "../AddressSearchContextContainer";
import {
  clearAddress,
  getSelectedAddress,
} from "../../redux/modules/addresses";
import { PlannedOutagesList } from "./_/PlannedOutagesList";
import { ReactComponent as IconViewAll } from "./view-all.svg";
import Button from "../../components/Button/Button";
import { trackEvent } from "../../common/analytics";
import { NotificationLink } from "../../components/NotificationLink/NotificationLink";

export const GA_PLANNED_CATEGORY = "Planned";

const ErrorText = styled(Text)`
  color: ${props => props.theme.colors.error};
  font-weight: normal;
  text-align: center;
  font-size: ${props => props.theme.fontSizes[2]}px;
  padding-left: ${props => props.theme.space[3]}px;
  padding-right: ${props => props.theme.space[3]}px;
`;

export class PlannedOutagesContainer extends Component {
  static propTypes = {
    plannedOutagesRequest: PropTypes.func.isRequired,
    clearAddress: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    outages: PropTypes.arrayOf(PropTypes.object),
    size: PropTypes.object,
    selectedAddress: PropTypes.shape({
      id: PropTypes.string,
      Label: PropTypes.string,
    }),
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    error: PropTypes.string,
    clearPlannedOutages: PropTypes.func.isRequired,
  };

  static defaultProps = {
    outages: [],
    isFetching: false,
    size: { width: 500, height: 500 },
    selectedAddress: undefined,
    error: null,
  };

  getAddressQueryParam = () => {
    const { location } = this.props;
    const params = queryString.parse(location.search);
    return params.address;
  };

  componentDidMount() {
    const { clearPlannedOutages, selectedAddress } = this.props;

    clearPlannedOutages();

    const address = this.getAddressQueryParam() || get(selectedAddress, "id");
    this.setAddress(address);
  }

  componentDidUpdate(prevProps) {
    this.handleChangeSearchAddress(prevProps.selectedAddress);
    this.trackErrorEvent(prevProps.error);
  }

  handleChangeSearchAddress = previousSelectedAddress => {
    const { selectedAddress } = this.props;
    if (selectedAddress && selectedAddress !== previousSelectedAddress) {
      this.setAddress(selectedAddress.id);
    }
  };

  setAddress(address) {
    const { plannedOutagesRequest, history } = this.props;
    if (address) {
      plannedOutagesRequest(address);
      history.push(`/planned?address=${address}`);
    }
  }

  loadAllPlannedOutages = () => {
    const { plannedOutagesRequest, clearAddress } = this.props;
    plannedOutagesRequest();
    clearAddress();
  };

  trackErrorEvent = previousError => {
    const { selectedAddress, error } = this.props;
    if (selectedAddress && error && error !== previousError) {
      trackEvent({
        category: GA_PLANNED_CATEGORY,
        action: "Error - When searching planned outages for address",
        label: `IcpNumber: ${selectedAddress.id} | Error Message: ${error}`,
      });
    }
  };

  render() {
    const { outages, isFetching, size, error } = this.props;
    const address = this.getAddressQueryParam();

    return (
      <>
        <Box width={1} mb={2}>
          <Text
            textAlign="center"
            as="p"
            mx={2}
            my={0}
            lineHeight={1.5}
            fontSize={1}
          >
            Enter an address below to view planned outages for your property
          </Text>
        </Box>
        <Flex width={1}>
          <AddressSearchContextContainer
            p={[2, null, 3]}
            category={GA_PLANNED_CATEGORY}
            size={size}
            isAbsolute={false}
          />
        </Flex>
        {error && (
          <>
            <Box width={1} mt={2} css="text-align: center;">
              <ErrorText>{error}</ErrorText>
              <Text
                as="p"
                textAlign="center"
                fontSize={2}
                mt={3}
                lineHeight={1.4}
              >
                Please{" "}
                <Button
                  variant="text"
                  href="https://www.vector.co.nz/contact-us"
                >
                  contact us
                </Button>{" "}
                so we can help.
              </Text>
            </Box>
          </>
        )}

        {(address || !isEmpty(outages) || isFetching) && (
          <>
            <PlannedOutagesList
              isFetching={isFetching}
              outages={outages}
              error={error}
            />
          </>
        )}

        {!isEmpty(outages) && !isFetching && <NotificationLink />}

        {isEmpty(outages) && !isFetching && !error && (
          <Flex mt={3}>
            <Button
              data-testid="ViewAllPlannedOutages__Button"
              onClick={this.loadAllPlannedOutages}
              variant="text"
              gaTrack={
                /* istanbul ignore next */ () =>
                  trackEvent({
                    category: GA_PLANNED_CATEGORY,
                    action: "Clicked - View All Planned Outages",
                    label: "View All Planned Outages",
                  })
              }
            >
              <Flex columns={2} alignItems="center">
                <IconViewAll />
                <Text ml={2}>view all</Text>
              </Flex>
            </Button>
          </Flex>
        )}
      </>
    );
  }
}

/* istanbul ignore next */
const enhance = compose(
  withErrorBoundary(),
  withRouter,
  connect(
    state => ({
      selectedAddress: getSelectedAddress(state),
      isFetching: state.plannedOutages.isFetching,
      outages: state.plannedOutages.outages,
      error: state.plannedOutages.error,
    }),
    {
      plannedOutagesRequest,
      clearAddress,
      clearPlannedOutages,
    },
  ),
);

export default enhance(PlannedOutagesContainer);
