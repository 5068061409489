import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import { transparentize } from "polished";
import { Link } from "react-router-dom";
import {
  fontSize,
  textAlign,
  lineHeight,
  color,
  propTypes,
  space,
  style,
  border,
  bgColor,
} from "styled-system";
import { ifProp, switchProp } from "styled-tools";
import { focusStyling } from "../../common/styles";
import { isExternalUrl, isLocalProtocol } from "../../common/url-helpers";
import { withGATracking } from "../../common/withGATracking";
import { Loader } from "../Loader";

const MyLoader = styled(Loader)`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

/* eslint-disable react/prop-types, no-shadow */
const Base = ({
  variant,
  children,
  href,
  type,
  fontSize,
  textAlign,
  color,
  bg,
  border,
  block,
  lineHeight,
  isLoading,
  textTransform,
  ...props
}) => {
  function makeElement(href, type) {
    if (!href) {
      return {
        type: "button",
        attrs: {
          type,
        },
      };
    }

    const isExternal = isExternalUrl(href);
    const isProtocol = isLocalProtocol(href);

    if (!isExternal && !isProtocol) {
      // local url (/uri)
      return {
        type: Link,
        attrs: {
          to: href,
        },
      };
    }

    // External URL or a[href] protocol url (tel:...)
    return {
      type: "a",
      attrs: {
        href,
        target: !isProtocol ? "_blank" : null,
      },
    };
  }

  const element = makeElement(href, type);

  return React.createElement(element.type, { ...element.attrs, ...props }, [
    isLoading && <MyLoader key="loader" color="white" size={25} />,
    children,
  ]);
};

const textTransform = style({
  prop: "textTransform",
  cssProperty: "textTransform",
});

const Button = styled(Base)`
  position: relative;

  appearance: none;
  background: none;
  cursor: pointer;

  ${fontSize};
  ${textAlign};
  ${color};
  ${bgColor};

  font-weight: normal;
  box-shadow: none;
  ${lineHeight};
  ${textTransform};

  ${ifProp(
    "block",
    css`
      display: block;
      width: 100%;
      overflow: hidden;
      text-transform: lowercase;
    `,
  )};

  ${ifProp(
    "disabled",
    css`
      color: grey;
    `,
  )};

  ${switchProp("variant", {
    default: css`
      padding: 20px 25px;
      border-radius: 4px;
      border: 1px solid ${props => props.theme.colors.gray};
      background-color: ${p => p.theme.colors.white};
      text-decoration: none;
      ${focusStyling};
    `,
    dark: css`
      text-decoration: none;
      padding: 20px 25px;
      border-radius: 4px;
      border: none;
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.accent};
      ${ifProp(
        "disabled",
        css`
          background-color: ${props =>
            transparentize(0.6, props.theme.colors.accent)};
        `,
      )};
      ${focusStyling};
    `,
    darkBordered: css`
      text-decoration: none;
      padding: 20px 25px;
      border-radius: 4px;
      border: 2px solid ${props => props.theme.colors.accent};
      color: ${props => props.theme.colors.accent};
      background-color: ${props => props.theme.colors.light};
      ${focusStyling};
    `,
    text: css`
      border: none;
      background-color: transparent;
      text-transform: inherit;
      //font-weight: inherit;
      font-size: inherit;
      text-decoration: none;

      &:focus {
        text-decoration: underline;
        outline: 0;
      }

      ${ifProp(
        "isLoading",
        css`
          color: ${p => p.theme.colors.disabled};
        `,
      )};

      &:hover {
        text-decoration: underline;
      }
    `,
    alert: css`
      border-radius: 4px;
      border: none;
      text-decoration: none;
      background-color: ${/* istanbul ignore next */ props =>
        props.theme.colors.orange};
      color: ${/* istanbul ignore next */ props => props.theme.colors.white};
      text-transform: inherit;
      ${focusStyling};
    `,
    stormDefault: css`
      padding: 20px 25px;
      border-radius: 4px;
      border: 1px solid ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.orange};
      text-decoration: none;
      ${focusStyling};
    `,
    stormDark: css`
      padding: 20px 25px;
      border-radius: 4px;
      border: 1px solid ${props => props.theme.colors.white};
      background-color: ${p => p.theme.colors.orange};
      color: ${props => props.theme.colors.white};
      text-decoration: none;
      ${focusStyling};
    `,
    eventWarning: css`
      padding: 20px 25px;
      border-radius: 4px;
      background-color: ${/* istanbul ignore next */ ({ theme }) =>
        theme.colors.white};
      border: 2px solid
        ${/* istanbul ignore next */ ({ theme }) => theme.colors.white};
      color: currentColor;
      text-decoration: none;
      ${focusStyling};
    `,
    eventTransparent: css`
      padding: 20px 25px;
      border-radius: 4px;
      background-color: transparent;
      border: 2px solid currentColor;
      color: currentColor;
      text-decoration: none;
      ${focusStyling};
    `,
    ghost: css`
      padding: 20px 25px;
      text-decoration: none;
      border: none;
      color: ${({ theme }) => theme.colors.accent};

      &:focus {
        text-decoration: underline;
        outline: 0;
      }
    `,
  })};

  ${ifProp(
    "isLoading",
    css`
      color: ${p => {
        const clrName = "white"; // TODO: Look at implementing a per-instance override
        const clrValue = p.theme.colors[clrName];

        return transparentize(0.65, clrValue);
      }};
    `,
  )};

  ${space};
  ${bgColor};
  ${border};
`;

Button.defaultProps = {
  type: "button",
  variant: "default",
  block: false,
  fontSize: 2,
  textAlign: "center",
  color: "link",
  isLoading: false,
};
Button.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.oneOf([
    "text",
    "default",
    "dark",
    "darkBordered",
    "alert",
    "stormDefault",
    "stormDark",
    "ghost",
    "eventWarning",
    "eventTransparent",
  ]),
  block: PropTypes.bool,
  fontSize: propTypes.responsive,
  textAlign: propTypes.responsive,
  color: propTypes.responsive,
  isLoading: PropTypes.bool,
};

export default withGATracking(Button);
