import { createAction } from "redux-actions";

// Action Types
export const SEND_ONETIME_PASSWORD_REQUEST = "app/login/OTP_REQUEST";
export const SEND_ONETIME_PASSWORD_FAILURE =
  "app/login/SEND_ONETIME_PASSWORD_FAILURE";
export const ONETIME_PASSWORD_SENT = "app/login/OTP_SENT";
export const LOGIN_REQUEST = "app/login/REQUEST";
export const LOGIN_SUCCESS = "app/login/SUCCESS";
export const LOGIN_FAILURE = "app/login/FAILURE";
export const LOGIN_USER_NOT_LOGGED_IN = "app/login/USER_NOT_LOGGED_IN";
export const LOGIN_ERROR_CLEAR = "app/login/error/CLEAR";
export const LOGOUT_REQUEST = "app/logout/REQUEST";
export const LOGOUT_SUCCESS = "app/logout/SUCCESS";
export const LOGOUT_FAILURE = "app/logout/FAILURE";

export const FORGOT_PASSWORD_REQUEST = "app/forgotPassword/REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "app/forgotPassword/SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "app/forgotPassword/FAILURE";

export const VERIFY_LOGIN_STATE = "app/login/verify";

// Action Creators

export const sendOneTimePasswordRequest = createAction(
  SEND_ONETIME_PASSWORD_REQUEST,
  ({ email }) => ({
    email,
  }),
  (_, meta) => meta,
);

export const loginRequest = createAction(
  LOGIN_REQUEST,
  ({ email, password, passwordless }) => ({
    email,
    password,
    passwordless,
  }),
  (_, meta) => meta,
);
export const oneTimePasswordSent = createAction(
  ONETIME_PASSWORD_SENT,
  () => {},
  (_, meta) => meta,
);
export const sendOneTimePasswordFailure = createAction(
  SEND_ONETIME_PASSWORD_FAILURE,
  error => error,
  (_, meta) => meta,
);
export const loginSuccess = createAction(
  LOGIN_SUCCESS,
  userInfo => userInfo,
  (_, meta) => meta,
);
export const loginFailure = createAction(
  LOGIN_FAILURE,
  error => error,
  (_, meta) => meta,
);
export const userNotLoggedIn = createAction(LOGIN_USER_NOT_LOGGED_IN);
export const clearLoginError = createAction(LOGIN_ERROR_CLEAR);
export const logoutRequest = createAction(LOGOUT_REQUEST);
export const logoutSuccess = createAction(LOGOUT_SUCCESS);
export const logoutFailure = createAction(LOGOUT_FAILURE);
export const verifyLoginState = createAction(VERIFY_LOGIN_STATE);

export const forgotPasswordRequest = createAction(
  FORGOT_PASSWORD_REQUEST,
  email => ({
    email,
  }),
  (_, meta) => meta,
);
export const forgotPasswordSuccess = createAction(FORGOT_PASSWORD_SUCCESS);
export const forgotPasswordFailure = createAction(FORGOT_PASSWORD_FAILURE);
