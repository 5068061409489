import React from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";

export const EventHistoryList = ({ children, ...props }) => (
  <Flex
    data-testid="EventHistory__List"
    {...props}
    flexDirection="column"
    flexWrap="nowrap"
    alignItems="stretch"
  >
    {children}
  </Flex>
);

EventHistoryList.propTypes = {
  children: PropTypes.node.isRequired,
};
