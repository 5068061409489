import React from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "rebass";
import { Field, Formik, Form } from "formik";
import styled from "styled-components/macro";

import { TextField } from "../../components/Form";
import { Button } from "../../components/Button";
import { trackEvent } from "../../common/analytics";
import { HeaderText } from "../../components/HeaderText";
import { LoginMessage } from "../../components/LoginMessage";

const FormRow = styled(Box).attrs({ py: 2 })``;

const formDefaultValues = {
  password: "",
};

export class AuthenticationContainer extends React.Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    onSendOTP: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    gaCode: PropTypes.string.isRequired,
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    successMessage: PropTypes.string,
    values: PropTypes.object,
  };

  static defaultProps = {
    error: null,
    successMessage: null,
    isLoading: false,
    values: {},
  };

  handleSubmit = (values, { setSubmitting, setFieldValue }) => {
    const { onSubmit, gaCode } = this.props;
    const { password } = values;

    trackEvent({
      category: gaCode,
      action: "Click",
      label: "Verify_Next",
    });

    onSubmit(password);

    setFieldValue("password", "");
    setSubmitting(false);
  };

  render() {
    const {
      email,
      isLoading,
      values,
      error,
      // eslint-disable-next-line no-shadow
      successMessage,
      onSendOTP,
    } = this.props;

    const initialValues = {
      ...formDefaultValues,
      ...values,
    };

    return (
      <Flex justifyContent="center">
        <Box width={[1, null, 3 / 4]}>
          <Formik
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
            render={({ setFieldValue }) => (
              <Form aria-label="Login Form" data-testid="LoginForm" noValidate>
                <Flex flexDirection="column" width={1}>
                  <LoginMessage error={error} success={successMessage} />
                  <Box>
                    <Box width={1}>
                      <HeaderText fontSize={6}>
                        Enter your verification code
                      </HeaderText>
                    </Box>
                    <Box width={1} mt={2}>
                      <HeaderText fontSize={3}>
                        we&apos;ve sent your code to
                      </HeaderText>
                      <HeaderText
                        fontSize={3}
                        css="overflow-wrap: anywhere; font-weight: bold;"
                      >
                        {email}
                      </HeaderText>
                    </Box>
                    <Box width={1} mt={4}>
                      <FormRow>
                        <Field
                          name="password"
                          placeholder="Enter code here"
                          inputmode="numeric"
                          component={TextField}
                          autoComplete="off"
                          required
                          note="This one time verification code will allow you to continue"
                        />
                      </FormRow>
                      <FormRow mt={4}>
                        <Button
                          variant="dark"
                          type="submit"
                          block
                          disabled={isLoading}
                          isLoading={isLoading}
                          data-testid="LoginForm__Button--submit"
                        >
                          next
                        </Button>
                      </FormRow>
                      <FormRow mt={1}>
                        <Button
                          variant="ghost"
                          block
                          onClick={() => onSendOTP(setFieldValue)}
                          disabled={isLoading}
                          fontSize={2}
                          lineHeight={1.2}
                          textTransform="none"
                          p={0}
                          data-testid="LoginForm__ResendCodeButton"
                        >
                          Resend code
                        </Button>
                      </FormRow>
                    </Box>
                  </Box>
                </Flex>
              </Form>
            )}
          />
        </Box>
      </Flex>
    );
  }
}

export default AuthenticationContainer;
