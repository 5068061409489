import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import { AuthenticationContainer } from "../AuthenticationContainer";
import { withErrorBoundary } from "../../components/ErrorBoundary";

import {
  isLoggedIn,
  loginRequest,
  clearLoginError,
  sendOneTimePasswordRequest,
  errorMessage,
  authEmail,
} from "../../redux/modules/login";
import {
  successMessage,
  registerClearMessages,
} from "../../redux/modules/registration";
import { trackEvent } from "../../common/analytics";

const GA_CATEGORY_CODE = "Login_Code";

export class LoginAuthenticationContainer extends React.Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    onSendOTP: PropTypes.func.isRequired,
    submitRequest: PropTypes.func.isRequired,
    clearError: PropTypes.func.isRequired,
    clearRegisterError: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    successMessage: PropTypes.string,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    error: null,
    successMessage: null,
    isLoading: false,
  };

  handleSubmit = password => {
    const { email, history, location, submitRequest } = this.props;

    const redirectTo = get(location, "state.redirectTo", "/");
    const gaCategory = get(location, "state.gaCategory", "login");
    submitRequest(
      { email, password, passwordless: true },
      { history, redirectTo, gaCategory },
    );
  };

  handleResendCode = setFieldValue => {
    const { email, onSendOTP } = this.props;

    trackEvent({
      category: GA_CATEGORY_CODE,
      action: "Click",
      label: "Resend Code",
    });
    onSendOTP({ email }, {});
    setFieldValue("password", "");
  };

  handleClearError = () => {
    const { clearError, clearRegisterError } = this.props;
    clearError();
    clearRegisterError();
  };

  render() {
    const {
      email,
      error,
      history,
      // eslint-disable-next-line no-shadow
      isLoggedIn,
      isLoading,
      clearError,
      // eslint-disable-next-line no-shadow
      successMessage,
    } = this.props;

    if (isLoggedIn) {
      history.push("/");
    } else if (!email) {
      history.push("/login");
    }

    return (
      <AuthenticationContainer
        successMessage={successMessage}
        error={error}
        email={email}
        isLoading={isLoading}
        gaCode={GA_CATEGORY_CODE}
        onSubmit={this.handleSubmit}
        clearError={clearError}
        onSendOTP={this.handleResendCode}
      />
    );
  }

  componentWillUnmount() {
    this.handleClearError();
  }
}

/* istanbul ignore next */
const enhance = compose(
  withErrorBoundary(),
  withRouter,
  connect(
    state => ({
      isLoggedIn: isLoggedIn(state),
      error: errorMessage(state),
      isLoading: state.auth.isLoading,
      email: authEmail(state),
      successMessage: successMessage(state),
    }),
    {
      submitRequest: loginRequest,
      clearError: clearLoginError,
      clearRegisterError: registerClearMessages,
      onSendOTP: sendOneTimePasswordRequest,
    },
  ),
);

export default enhance(LoginAuthenticationContainer);
