import reducer from "./addresses.reducer";

export {
  ADDRESS_BY_COORDS_REQUEST,
  ADDRESS_BY_COORDS_SUCCESS,
  ADDRESS_BY_COORDS_FAILURE,
  requestAddressByCoords,
  successAddressByCoords,
  failureAddressByCoords,
  ADDRESS_BY_TEXT_REQUEST,
  ADDRESS_BY_TEXT_SUCCESS,
  ADDRESS_BY_TEXT_FAILURE,
  requestAddressByText,
  successAddressByText,
  failureAddressByText,
  SELECT_ADDRESS,
  selectAddress,
  CLEAR_ADDRESS,
  clearAddress,
  requestAddressCoords,
  requestAddressCoordsFailure,
  requestAddressCoordsSuccess,
} from "./addresses.actions";
export {
  getAddresses,
  getSelectedAddress,
  getSelectedAddressLabel,
  getAddressIsFetching,
  getCurrentAddressCoords,
} from "./addresses.selectors";
// eslint-disable-next-line import/no-cycle
export { watchForAddressQueries } from "./addresses.saga";

export default reducer;
