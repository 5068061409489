/* eslint-disable react/prop-types */
import { Formik, Form, Field } from "formik";
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Flex, Box } from "rebass";
import Downshift from "downshift";
import { connect } from "react-redux";
import { compose } from "recompose";
import { TextField } from "../../components/Form";
import { Item, Menu, Buttons, withContext } from "./_";
import {
  requestAddressByText,
  selectAddress,
  getAddresses,
  getSelectedAddress,
  clearAddress,
  getAddressIsFetching,
} from "../../redux/modules/addresses";
import {
  getDeviceCoordsRequest,
  getLocationEnabled,
} from "../../redux/modules/location";
import { trackEvent } from "../../common/analytics";
import {
  clearAddressId,
  updateAddressText,
} from "../../redux/modules/addresses/addresses.actions";
import {
  getSelectedAddressId,
  getAddressText,
} from "../../redux/modules/addresses/addresses.selectors";

const FieldWrapper = styled(Box)`
  position: relative;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.14);
`;

/*
 * AddressSearchContainer:
 * 1. Manage Data
 * 2. Manage data fetch via saga
 * 3. Manage active value
 * 4. Manage selection callback
 * 5. Render input field and list
 * 6. Render buttons with callbacks
 * */
export class AddressSearchContainer extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    selected: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
    size: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    addressText: PropTypes.string,
    getGPSCoords: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    getMatches: PropTypes.func.isRequired,
    selectItem: PropTypes.func.isRequired,
    clearSelectedAddressId: PropTypes.func.isRequired,
    clearAddressLabel: PropTypes.func.isRequired,
    updateAddressLabel: PropTypes.func.isRequired,
    onEnterKey: PropTypes.func,
    isFetching: PropTypes.bool.isRequired,
    locationEnabled: PropTypes.bool.isRequired,
    setOverlay: PropTypes.func.isRequired,
  };

  static defaultProps = {
    items: [],
    selected: null,
    addressText: "",
    size: { width: 500, height: 500 },
    onEnterKey: () => {},
    handleSubmit: null,
  };

  constructor(props) {
    super(props);
    this.searchRef = React.createRef();
    this.state = { value: { id: null, label: "" }, isSearching: false };
  }

  /**
   * Fires on every interaction made with the typeahead field.
   * We use this to determine if a match has occurred or if the user is still
   * typing into the field and then passing the typed value through as free text
   *
   * @param changes
   */
  handleStateChange = changes => {
    const {
      getMatches,
      selectItem,
      clearSelectedAddressId,
      category,
      updateAddressLabel,
    } = this.props;
    const { inputValue, selectedItem } = changes;

    // eslint-disable-next-line default-case
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEscape:
        this.openMenu(false);
        break;
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        // Explicit Selection of an item in the list
        selectItem(selectedItem.id);
        updateAddressLabel(selectedItem.label);
        this.openMenu(false);
        trackEvent({
          category,
          action: "Address Search - Select Address",
          label: `${selectedItem.id} | ${selectedItem.label}`,
        });
        this.searchRef.current.blur();
        break;
      case Downshift.stateChangeTypes.changeInput:
        // Typing free-text into the field
        // Get API byText results
        if (inputValue && inputValue.length >= 3) {
          getMatches(inputValue);
        }
        updateAddressLabel(inputValue);
        // clearSelectedAddressId clears the "addresses.selected" key
        clearSelectedAddressId();
        // This line will ensure the typed text appears in the input field
        this.setState({ value: { id: null, label: inputValue } });
        break;
    }
  };

  /**
   * Fires when clear address button is clicked
   */
  clearAddressField = () => {
    const { clearAddressLabel } = this.props;
    clearAddressLabel();
    this.searchRef.current.focus();
  };

  /**
   * Manages overlay and clearAddress button state
   */
  openMenu = isOpen => {
    const { setOverlay } = this.props;
    setOverlay(isOpen);
    // this ish is to solve how the onBlur event interacts with the clearAddress button
    setTimeout(() => this.setState({ isSearching: isOpen }), 200);
  };

  static getDerivedStateFromProps(props) {
    // If a selection is made, pass the object down
    // otherwise pass the string value through
    return {
      value: props.selected || {
        id: props.addressId,
        label: props.addressText,
      },
    };
  }

  render() {
    const { value, isSearching } = this.state;
    const {
      items,
      isFetching,
      onEnterKey,
      getGPSCoords,
      handleSubmit,
      locationEnabled,
    } = this.props;

    return (
      <Downshift
        onStateChange={this.handleStateChange}
        selectedItem={value}
        itemToString={item => item.label}
      >
        {({
          getLabelProps,
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          selectedItem,
        }) => (
          <div>
            <Flex
              role="search"
              aria-label="Address"
              flexDirection="column"
              css="position: relative;"
            >
              <Box width={1}>
                <Formik
                  onSubmit={/* istanbul ignore next */ () => {}}
                  enableReinitialize
                  initialValues={{ searchString: value.label }}
                  render={() => (
                    <Form>
                      <FieldWrapper>
                        <Field
                          name="searchString"
                          label="Search an address"
                          innerRef={this.searchRef}
                          touchOnChange={false}
                          labelProps={getLabelProps()}
                          component={TextField}
                          fontSize={2}
                          tabIndex={0}
                          data-testid="MapAddress_Searchbar"
                          {...getInputProps({
                            onKeyDown: e => onEnterKey(e, { isOpen }),
                            onFocus: () => {
                              window.scrollTo(0, 0);
                              this.openMenu(true);
                            },
                            onBlur: () => this.openMenu(false),
                          })}
                        />
                        <Buttons
                          showClearAddress={
                            !isFetching && isSearching && value.label.length > 0
                          }
                          showDetails={
                            !!handleSubmit &&
                            !isFetching &&
                            !isSearching &&
                            selectedItem.id !== null
                          }
                          showFetching={isFetching}
                          showLocation={
                            !isFetching && !isSearching && locationEnabled
                          }
                          clearAddress={this.clearAddressField}
                          getAddressDetails={handleSubmit}
                          getCurrentLocation={getGPSCoords}
                          gaLabel={`${selectedItem.id} | ${selectedItem.label}`}
                        />
                      </FieldWrapper>
                    </Form>
                  )}
                />
              </Box>
              <Box width={1}>
                <Menu {...getMenuProps({ refKey: "innerRef" })} isOpen={isOpen}>
                  {!isOpen
                    ? null
                    : items
                        // Produce list of rendered items
                        .map((item, index) => (
                          <Item
                            {...getItemProps({
                              item,
                              index,
                              key: item.id,
                              isActive: highlightedIndex === index,
                              isSelected: selectedItem.id === item.id,
                              "data-testid": item.id,
                            })}
                          >
                            {item.label}
                          </Item>
                        ))}
                </Menu>
              </Box>
            </Flex>
          </div>
        )}
      </Downshift>
    );
  }
}

// istanbul ignore next
const enhance = compose(
  withContext,
  connect(
    state => ({
      items: getAddresses(state),
      selected: getSelectedAddress(state),
      addressText: getAddressText(state),
      addressId: getSelectedAddressId(state),
      isFetching: getAddressIsFetching(state),
      locationEnabled: getLocationEnabled(state),
    }),
    {
      clearSelectedAddressId: clearAddressId,
      clearAddressLabel: clearAddress,
      getGPSCoords: getDeviceCoordsRequest,
      getMatches: requestAddressByText,
      selectItem: selectAddress,
      updateAddressLabel: updateAddressText,
    },
  ),
);

export default enhance(AddressSearchContainer);
