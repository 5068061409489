import React from "react";
import PropTypes from "prop-types";
import { Text, Flex } from "rebass";
import { Button } from "../../Button";
import { usePlannedOutageDateToggle } from "../usePlannedOutageDateToggle";
import PlannedOutageEventInfo from "./PlannedOutageEventInfo";
import PlannedOutageDatesTable from "./PlannedOutageDatesTable";

const PlannedOutageDates = ({
  switchingProposalNumber,
  advertisedStartDate,
  advertisedEndDate,
  alternativeStartDate,
  alternativeEndDate,
  advertisedTimes,
  alternativeTimes,
  status,
  isPostponed,
}) => {
  const [
    toggleButtonLabel,
    toggleDate,
    isCancelled,
    showAlternateDateToggleButton,
    plannedOutageDateTimes,
    advertisedOutageDateTimes,
  ] = usePlannedOutageDateToggle(
    advertisedStartDate,
    advertisedEndDate,
    alternativeStartDate,
    alternativeEndDate,
    advertisedTimes,
    alternativeTimes,
    status,
    isPostponed,
  );

  const outageDates = [];

  // Add the original dates
  if (isPostponed && !isCancelled) {
    advertisedOutageDateTimes.forEach(advertisedOutageDateTime => {
      outageDates.push({
        switchingProposalNumber,
        startDate: advertisedOutageDateTime.startDate,
        endDate: advertisedOutageDateTime.endDate,
        isStriked: isPostponed,
      });
    });
  }

  // Add the planned dates
  plannedOutageDateTimes.forEach(plannedOutageDateTime => {
    outageDates.push({
      switchingProposalNumber,
      startDate: plannedOutageDateTime.startDate,
      endDate: plannedOutageDateTime.endDate,
      isStriked: isCancelled,
    });
  });

  return (
    <Flex
      mt={3}
      flexDirection="column"
      justifyContent="flex-start"
      flex="1 1 auto"
    >
      <PlannedOutageEventInfo
        label="outage date(s)"
        fontWeight="bold"
        contentMargin={1}
      >
        <PlannedOutageDatesTable outageDates={outageDates} />

        {showAlternateDateToggleButton && (
          <Button
            variant="text"
            lineHeight={1.2}
            mt={1}
            px={0}
            onClick={() => toggleDate()}
          >
            <Text as="span" fontSize={0}>
              {toggleButtonLabel}
            </Text>
          </Button>
        )}
      </PlannedOutageEventInfo>
    </Flex>
  );
};

PlannedOutageDates.defaultProps = {
  isPostponed: false,
  alternativeStartDate: undefined,
  alternativeEndDate: undefined,
};

PlannedOutageDates.propTypes = {
  switchingProposalNumber: PropTypes.string.isRequired,
  advertisedStartDate: PropTypes.string.isRequired,
  advertisedEndDate: PropTypes.string.isRequired,
  alternativeStartDate: PropTypes.string,
  alternativeEndDate: PropTypes.string,
  advertisedTimes: PropTypes.arrayOf(PropTypes.object).isRequired,
  alternativeTimes: PropTypes.arrayOf(PropTypes.object).isRequired,
  status: PropTypes.string.isRequired,
  isPostponed: PropTypes.bool,
};

export default PlannedOutageDates;
