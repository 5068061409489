import React from "react";
import PropTypes from "prop-types";
import { or, explicitNull } from "airbnb-prop-types";
import { Text } from "rebass";
import { Button } from "../../../components/Button";
import { LoginRedirectMessage } from "../../../components/LoginRedirectMessage";
import { SubscriptionControl } from "../../../components/SubscriptionControl";

export const OutageUpdateControl = ({
  // Flags
  isLoggedIn,
  isResolved,
  isOutage,
  isUnknownOutage,
  isUnplannedOutage,
  // Data
  subscription,
  lseCode,
  // Functions
  onStartReport,
  onLogin,
  onRegister,
  onChangePreferences,
  progressionStatus,
}) => {
  const isLSE = lseCode > 0;

  /**
   * Show subscription control
   */
  if (isLoggedIn && isOutage && isUnplannedOutage && (isLSE || !isResolved)) {
    return (
      <>
        <SubscriptionControl
          subscription={subscription}
          onClickChangeButton={onChangePreferences}
          handleToogleChange={onChangePreferences}
          variant="button"
        />
        {/* <Text as="p">
          If you&apos;ve subscribed for updates, we will inform you when
          something changes. Alternatively, you can check the address again for
          updates.
        </Text> */}
      </>
    );
  }

  /**
   * Show a default "Report" button for unknown outages
   *    Conditions:
   *    - Outage Type is "Unknown" (neither "Planned" nor "UnPlanned")
   */
  if (isUnknownOutage) {
    return (
      <>
        <Button
          variant="dark"
          block
          onClick={onStartReport}
          aria-describedby="reportDescription"
        >
          REPORT NOW
        </Button>
        <Text id="reportDescription" fontSize={1} textAlign="center" mt={20}>
          If you are a commercial customer and the power outage is with your own
          electrical equipment (ie. equipment that is not part of Vector&apos;s
          network), your retailer may charge you a call out fee.
        </Text>
      </>
    );
  }

  /**
   * Show re-report button
   *    Conditions:
   *    - Outage is "resolved"
   *    - Outage must have been rolled up, we don't show the button if outage is referred
   */
  if (isResolved && progressionStatus === "ROLLED_UP") {
    return (
      <>
        <Text as="p" fontSize={1} m={0}>
          UPDATES
        </Text>
        <Text as="p" fontSize={2} mt={1}>
          This outage has been resolved. Is your power back on? If not, please
          click on &quot;Report Outage&quot; and report now.
        </Text>
        <Button onClick={onStartReport} variant="dark" block>
          Report Outage
        </Button>
      </>
    );
  }

  /**
   * Prompt for login/register
   *    Conditions:
   *    - User is NOT logged in
   */
  if (!isLoggedIn && isUnplannedOutage) {
    return (
      <>
        <Text
          as="p"
          fontSize={1}
          m={0}
          css={`
             {
              text-transform: lowercase;
            }
          `}
        >
          UPDATES
        </Text>
        <Text as="p" fontSize={2} mt={1}>
          You need to login to see or change your preferences.
        </Text>
        <Button onClick={onRegister} variant="dark" block>
          Sign up
        </Button>
        <LoginRedirectMessage onClick={onLogin} mt={15} />
      </>
    );
  }

  /**
   * If none of the scenarios have matched, nothing should how up at all.
   *
   * Specifically, if we have a PLANNED outage, we should not allow reports.
   */
  return null;
};

OutageUpdateControl.propTypes = {
  subscription: PropTypes.shape({
    ticketId: or([explicitNull(), PropTypes.string]),
    updateByTxt: or([explicitNull(), PropTypes.bool]),
    updateByEmail: or([explicitNull(), PropTypes.bool]),
  }),
  lseCode: PropTypes.number,
  isUnknownOutage: PropTypes.bool,
  isUnplannedOutage: PropTypes.bool,
  isResolved: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isOutage: PropTypes.bool,
  onStartReport: PropTypes.func,
  onLogin: PropTypes.func,
  onRegister: PropTypes.func,
  onChangePreferences: PropTypes.func,
  progressionStatus: PropTypes.string,
};
OutageUpdateControl.defaultProps = {
  subscription: {
    ticketId: null,
    updateByTxt: null,
    updateByEmail: null,
  },
  lseCode: 0,
  isUnknownOutage: false,
  isUnplannedOutage: false,
  isResolved: false,
  isLoggedIn: false,
  isOutage: false,
  progressionStatus: "ACTIVE",
  onStartReport: /* istanbul ignore next */ () => {},
  onLogin: /* istanbul ignore next */ () => {},
  onRegister: /* istanbul ignore next */ () => {},
  onChangePreferences: /* istanbul ignore next */ () => {},
};
