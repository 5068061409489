import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form, getIn } from "formik";
import { connect } from "react-redux";
import { Flex, Box, Text } from "rebass";

import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { Button } from "../../components/Button";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { PrivacyPolicy } from "../../components/PrivacyPolicy";
import { clearApiError } from "../../redux/modules/errors";

import {
  DEFAULT_FIELD_VALUES,
  RegistrationFormFields,
} from "../../components/RegistrationFormFields";
import { registerRequest } from "../../redux/modules/registration";
import { ErrorMessage } from "../../components/ErrorMessage";
import { HeaderText } from "../../components/HeaderText";
import LoginRedirectMessage from "../../components/LoginRedirectMessage/LoginRedirectMessage";

export class RegistrationContainer extends Component {
  handleSubmit = (values, { setSubmitting }) => {
    const { onSubmit, history, navigateBack } = this.props;
    const formName = "registration";

    onSubmit(values.registration, {
      formName,
      history,
      redirectTo: "/login/auth",
      gaCategory: "register",
      navigateBack,
    });

    setSubmitting(false);
  };

  componentWillUnmount() {
    const { clearError } = this.props;
    clearError(null, { slice: "register" });
  }

  handleRedirect = path => () => {
    const { location, match, history } = this.props;
    const redirectUrl =
      getIn(location, "state.redirectTo", undefined) || match.url;

    history.push(path, { redirectTo: redirectUrl });
  };

  render() {
    const { error, isLoading } = this.props;

    return (
      <Flex flexDirection="column" alignItems="center" width={1}>
        <Box width={1}>
          <HeaderText fontSize={6}>create an account</HeaderText>
        </Box>
        <Box width={[1, null, 3 / 4]} role="alert" aria-atomic="true">
          <ErrorMessage message={error} shouldReveal />
        </Box>
        <LoginRedirectMessage onClick={this.handleRedirect("/login")} />
        <Box width={[1, null, 3 / 4]}>
          <Formik
            initialValues={DEFAULT_FIELD_VALUES}
            onSubmit={this.handleSubmit}
            render={() => (
              <Form
                aria-label="Registration Form"
                noValidate
                data-testid="RegistrationForm"
              >
                <Flex flexDirection="column" width={1}>
                  <Box>
                    <RegistrationFormFields />
                  </Box>
                  <Box>
                    <Button
                      block
                      type="submit"
                      variant="dark"
                      isLoading={isLoading}
                      disabled={isLoading}
                      data-testid="RegistrationForm__Button--submit"
                    >
                      Next
                    </Button>
                  </Box>
                  <Box my={20}>
                    <Text textAlign="center" fontSize={0}>
                      By continuing you agree to Vector&apos;s
                      <br />
                      <Button
                        variant="text"
                        href="https://www.vector.co.nz/terms-of-use"
                      >
                        terms of use
                      </Button>{" "}
                      and{" "}
                      <Button
                        variant="text"
                        href="https://www.vector.co.nz/privacy-policy"
                      >
                        privacy policy
                      </Button>
                    </Text>
                  </Box>
                  <Box>
                    <PrivacyPolicy />
                  </Box>
                </Flex>
              </Form>
            )}
          />
        </Box>
      </Flex>
    );
  }
}

RegistrationContainer.propTypes = {
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  navigateBack: PropTypes.string,
};
RegistrationContainer.defaultProps = {
  isLoading: false,
  error: "",
  navigateBack: undefined,
};

/* istanbul ignore next */
const enhance = compose(
  withErrorBoundary(),
  withRouter,
  connect(
    state => ({
      error: state.register.error,
      isLoading: state.register.loading,
    }),
    {
      onSubmit: registerRequest,
      clearError: clearApiError,
    },
  ),
);

export default enhance(RegistrationContainer);
