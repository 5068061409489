import get from "lodash/get";
import isNil from "lodash/isNil";
import findIndex from "lodash/findIndex";
import { createSelector } from "reselect";

export const getOutageByIcpNumber = createSelector(
  [state => state.outages.byId, (state, icpNumber) => icpNumber],
  (dataById, icpNumber) => {
    if (!dataById || !icpNumber) {
      return null;
    }

    return dataById[icpNumber];
  },
);

export const getSubscriptionDetailsByIcpNumber = createSelector(
  [getOutageByIcpNumber],
  outage => {
    if (!outage) {
      return null;
    }

    return outage.subscription;
  },
);

export const getOutageIdByIcpNumber = createSelector(
  [getOutageByIcpNumber],
  outage => {
    if (!outage) {
      return null;
    }

    return outage.outageId;
  },
);

export const getTicketStatus = createSelector(
  [
    getOutageByIcpNumber,
    (state, icpNumber) => get(state, `outages.tickets.${icpNumber}`),
  ],
  (outage, ticket) => {
    const subscription = get(outage, "subscription");
    const statuses = get(outage, "workStatus", []);

    const ticketStatus = get(ticket, "status", null);
    const ticketError = get(ticket, "error", null);

    const isResolved =
      findIndex(statuses, {
        type: "RESOLVED",
        complete: true,
      }) !== -1;

    // If there is no subscription data on the outage, the user has never subscribed to the outage
    // or is not logged in. In this case do not prompt the user for anything
    let status = null;

    // If the user is subscribed: prompt the user for YES/NO
    if (isResolved && !isNil(subscription)) status = "PROMPT";

    // If we have a known ticketStatus from a previous action, return it to render the appropriate view
    if (isResolved && !isNil(ticketStatus)) status = ticketStatus;

    return {
      status,
      error: ticketError,
    };
  },
);

export const getIsUpdatingTicket = createSelector(
  [state => state.outages.isUpdatingTicket],
  status => status,
);

export const getOutageShapes = createSelector(
  [state => state.outages.shapes],
  shapes => shapes,
);
