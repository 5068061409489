import { handleActions } from "redux-actions";
import { withApiErrors } from "../errors";
import {
  getDeviceCoordsRequest,
  getDeviceCoordsReceive,
  getDeviceCoordsFailure,
  getClickCoordsReceive,
} from "./location.actions";
import { clearAddress } from "../addresses/addresses.actions";

export const reducer = handleActions(
  {
    [getDeviceCoordsReceive]: (state, action) => ({
      ...state,
      coords: action.payload,
      enabled: false,
    }),
    [getClickCoordsReceive]: (state, action) => ({
      ...state,
      coords: action.payload,
      enabled: true,
    }),
    [getDeviceCoordsFailure]: state => ({
      ...state,
      coords: null,
      enabled: false,
    }),
    [clearAddress]: state => ({
      ...state,
      coords: null,
      enabled: true,
    }),
  },
  { coords: null, enabled: true },
);

export default withApiErrors(reducer, "location", {
  clear: [getDeviceCoordsRequest, getDeviceCoordsReceive],
  capture: [getDeviceCoordsFailure],
});
