import { takeLatest, call, put } from "redux-saga/effects";
import {
  plannedOutagesRequest,
  plannedOutagesSuccess,
  plannedOutagesFailure,
} from "./planned-outages.actions";
import { PlannedOutageService } from "../../../services/PlannedOutageService";

export function* getPlannedOutages(action) {
  const {
    payload: { icpNumber },
  } = action;

  try {
    const data = yield call(PlannedOutageService.getPlannedOutages, icpNumber);
    yield put(plannedOutagesSuccess(data));
  } catch (err) {
    yield put(plannedOutagesFailure(err.message));
  }
}

export function* watchForPlannedOutagesRequest() {
  yield takeLatest(plannedOutagesRequest, getPlannedOutages);
}
