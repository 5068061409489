import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage as FormikError } from "formik";
import { Text } from "rebass";

export const ErrorMessage = ({ name, ...props }) => (
  <FormikError
    name={name}
    render={error => (
      <Text as="p" fontSize={1} color="error" mt={10} mb={0} {...props}>
        {error}
      </Text>
    )}
  />
);
ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
  "data-testid": PropTypes.string,
};
ErrorMessage.defaultProps = {
  "data-testid": "ErrorMessage",
};
