import { Box } from "rebass";
import styled from "styled-components/macro";

export default styled(Box)`
  position: relative;
  width: 100%;
  max-width: 385px;
  max-height: 90vh;
  pointer-events: all;
`;
