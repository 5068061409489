/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "formik";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { Flex, Box, Text } from "rebass";
import { Button } from "../../../components/Button";
import { Circle } from "../../../components/Circle";
import { ReactComponent as TickIcon } from "./tick.svg";
import { ReactComponent as QuestionMarkIcon } from "./question.svg";

export class ConfirmationFields extends React.Component {
  static propTypes = {
    isDuplicate: PropTypes.bool,
    match: PropTypes.shape({
      params: PropTypes.shape({
        reportId: PropTypes.string,
      }),
    }).isRequired,
    history: PropTypes.object.isRequired,
    formik: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isDuplicate: false,
  };

  componentDidMount() {
    const {
      formik: { resetForm },
    } = this.props;

    /**
     * ensures that the local state formik values are cleared when successfully
     * submitted which in turns ensures that a user using the Back button can't
     * re-submit the report form with locally stored information.
     *
     * Note that the redux cache (redux-persist) is cleared by the dispatch of
     * the "reportSuccess" action using the forms.reducer
     */
    resetForm();
  }

  handleNext = () => {
    const {
      history,
      match: {
        params: { icpNumber },
      },
    } = this.props;

    history.push(`/address/${icpNumber}`);
  };

  render() {
    const {
      isDuplicate,
      match: {
        params: { reportId },
      },
    } = this.props;

    return (
      <Flex flexDirection="column">
        <Box flex="1 1 0" width={1}>
          <Flex
            flexDirection="column"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
          >
            {/* Content */}
            <Box mt={10}>
              <Circle
                color="white"
                bg={isDuplicate ? "accent" : "secondary"}
                size={90}
              >
                {isDuplicate ? (
                  <QuestionMarkIcon />
                ) : (
                  <TickIcon width="100%" height={40} />
                )}
              </Circle>
            </Box>
            <Box width={1} pt={20} pb={30}>
              <Text textAlign="center" fontSize={5} mb={20} fontWeight="bold">
                {isDuplicate
                  ? `Looks like you have already let us know about this?`
                  : "Thank you for your report."}
              </Text>
              {!isDuplicate && (
                <Flex
                  flexDirection="column"
                  flexWrap="wrap"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text textAlign="center" fontSize={3} mb={20}>
                    We’ll send out crew to help you as soon as practicable.
                  </Text>
                  <Text textAlign="center" fontSize={3} mb={20}>
                    Once the crew are on their way, they usually arrive in the
                    area within 75 minutes, but it may take longer when traffic
                    is heavy.
                  </Text>
                </Flex>
              )}
              <Text textAlign="center" fontSize={3} fontWeight="bold">
                Here&apos;s your report ID
              </Text>
              <Text textAlign="center" fontSize={3} fontWeight="bold" mb={20}>
                {reportId}
              </Text>
              {!isDuplicate && (
                <Text textAlign="center" fontSize={3}>
                  Please use this ID whenever you get in touch with us about
                  your outage
                </Text>
              )}
            </Box>
            <Box width={1}>
              <Button variant="dark" block onClick={this.handleNext}>
                View Outage
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    );
  }
}

const enhance = compose(
  withRouter,
  connect, // formik's connect
);

export default enhance(ConfirmationFields);
