import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { or, explicitNull, mutuallyExclusiveProps } from "airbnb-prop-types";
import uniqueId from "lodash/uniqueId";
import { Box, Flex, Text } from "rebass";
import { ErrorMessage } from "../FieldErrors";
import { Field } from "./_/Field";
import { FieldContainer } from "./_/FieldContainer";
import { InlineError } from "./_/InlineError";
import { InlineHint } from "./_/InlineHint";
import { Label } from "./_/Label";
import { Outline } from "./_/Outline";
import { VisibilityToggle } from "./_/VisibilityToggle";
import { useFieldHandlers } from "./useFieldHandlers";
import { useFieldState } from "./useFieldState";
import { useNotchedLabel } from "./useNotchedLabel";
import { usePasswordVisibilityToggle } from "./usePasswordVisibilityToggle";

export const TextField = ({
  field,
  form,
  note,
  defaultValue,
  label,
  labelProps,
  placeholder,
  required,
  as,
  multiline,
  type: fieldType,
  touchOnChange,
  innerRef,
  ...rest
}) => {
  const uid = useMemo(() => uniqueId("TextField"), []);
  const element = multiline ? "textarea" : "input";
  const fieldLabel = label || placeholder; // placeholder is here for backwards compatibility
  const value = typeof field.value !== "undefined" ? field.value : defaultValue;
  const fieldState = useFieldState(form, field);
  const {
    variant,
    hasFocus,
    setFocus,
    hasBeenTouched,
    hasError,
    shouldShowError,
  } = fieldState;

  // Attach additional event handlers to the input instance, these automatically
  // get wrapped to also fire any core events that would otherwise be overwritten
  const eventHandlers = useFieldHandlers(
    field, // Formik internal handlers (onChange, onBlur)
    {
      onFocus: () => setFocus(true),
      onBlur: () => setFocus(false),
      onChange: e => {
        if (touchOnChange && !hasBeenTouched) {
          form.setFieldTouched(field.name, true);
        }
        field.onChange(e);
      },
    },
    rest, // binds any on* event handlers from the <Field /> component
  );

  // Floating Input Labels:
  const [resizeListener, notchWidth, isFloating] = useNotchedLabel(fieldState);

  // Password Visibility Toggle:
  const [type, toggleLabel, toggleVisibility] = usePasswordVisibilityToggle(
    fieldType,
  );

  return (
    <Flex data-testid={`Field--${field.name}`} flexDirection="column" width={1}>
      <FieldContainer>
        <Field
          fontSize={2}
          data-testid={`Field__Input--${field.name}`}
          {...field}
          id={uid}
          as={element}
          ref={innerRef}
          type={type}
          variant={variant}
          value={value}
          required={required}
          {...rest}
          {...eventHandlers}
          aria-invalid={shouldShowError}
          aria-errormessage={`Field__ErrorMessage--${uid}`}
        />

        {/* Field Label with animated outlines */}
        <Outline notchWidth={notchWidth} variant={variant}>
          {fieldLabel && (
            <Label
              ref={resizeListener}
              data-testid={`Field__Label--${field.name}`}
              htmlFor={uid}
              isFloating={isFloating}
              {...labelProps}
            >
              {fieldLabel}
            </Label>
          )}
        </Outline>

        {/* Error Icon (in-field) */}
        {fieldType !== "password" && shouldShowError && (
          <InlineError
            name={field.name}
            bottom={10}
            data-testid={`Field__ErrorIcon--${field.name}`}
          />
        )}

        {/* Password Field ONLY, inline Show/Hide toggle */}
        {fieldType === "password" && !!value.length && (
          <VisibilityToggle
            data-testid="Field__VisibilityToggle"
            onClick={toggleVisibility}
          >
            {toggleLabel}
          </VisibilityToggle>
        )}

        {/* Bottom Right Corner Validation Hints */}
        {required &&
          !hasFocus &&
          (!hasError || !hasBeenTouched) &&
          !value.length && (
            <InlineHint data-testid={`Field__InlineHint--${field.name}`}>
              Required
            </InlineHint>
          )}
      </FieldContainer>
      {/* Error Message: We must have an always rendered outer element for ARIA */}
      <Box aria-atomic="true" role="alert" id={`Field__ErrorMessage--${uid}`}>
        {shouldShowError && (
          // Error Message
          <ErrorMessage
            data-testid={`Field__ErrorMessage--${field.name}`}
            name={field.name}
            pl={14}
          />
        )}
      </Box>
      {/* Field Note */}
      {!shouldShowError && note && (
        <Box>
          <Text as="p" fontSize={0} pl={14} mb={0}>
            {note}
          </Text>
        </Box>
      )}
    </Flex>
  );
};
TextField.propTypes = {
  ...Box.propTypes,
  defaultValue: PropTypes.string,
  multiline: PropTypes.bool,
  note: or([explicitNull(), PropTypes.string]),
  label: mutuallyExclusiveProps(PropTypes.string, "label", "placeholder"),
  labelProps: PropTypes.object,
  placeholder: mutuallyExclusiveProps(PropTypes.string, "label", "placeholder"),
  touchOnChange: PropTypes.bool,
};
TextField.defaultProps = {
  defaultValue: "",
  multiline: false,
  note: null,
  label: null,
  labelProps: {},
  placeholder: null,
  touchOnChange: true,
};

export default TextField;
