import React from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "rebass";
import { SuccessIcon } from "./_/SuccessIcon";

const SuccessMessage = ({ success, ...rest }) => (
  <Box width={1} my={20} role="alert" aria-atomic="true" {...rest}>
    {success && (
      <Flex justifyContent="center" alignItems="center" width={1}>
        <Box mr={10}>
          <SuccessIcon />
        </Box>
        <Box>
          <Text
            as="p"
            m={0}
            fontSize={2}
            color="success"
            data-testid="SucccessMessage__text"
          >
            {success}
          </Text>
        </Box>
      </Flex>
    )}
  </Box>
);

SuccessMessage.propTypes = {
  success: PropTypes.string,
};

SuccessMessage.defaultProps = {
  success: undefined,
};

export default SuccessMessage;
