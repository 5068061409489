import { createAction } from "redux-actions";

export const STORM_ZONE_BY_ID_REQUEST = "app/stormZones/byId/REQUEST";
export const STORM_ZONE_BY_ID_SUCCESS = "app/stormZones/byId/SUCCESS";
export const STORM_ZONE_BY_ID_FAILURE = "app/stormZones/byId/FAILURE";
export const CLEAR_STORM_ZONE_DETAILS = "app/stormZones/CLEAR";

export const stormZoneByIdRequest = createAction(
  STORM_ZONE_BY_ID_REQUEST,
  id => ({ id }),
);

export const stormZoneByIdSuccess = createAction(
  STORM_ZONE_BY_ID_SUCCESS,
  data => ({ zoneDetails: data }),
);

export const stormZoneByIdFailure = createAction(STORM_ZONE_BY_ID_FAILURE);

export const clearStormZoneDetails = createAction(CLEAR_STORM_ZONE_DETAILS);
