import React from "react";
import PropTypes from "prop-types";
import { or, explicitNull } from "airbnb-prop-types";
import { Text, Flex, Box } from "rebass";
import Macro, { Clone } from "macro-components";
import { Circle } from "../Circle";

import DialogOverlay from "./_/DialogOverlay";
import DialogCurtain from "./_/DialogCurtain";
import DialogContainer from "./_/DialogContainer";
import CloseButton from "./_/DialogCloseButton";

const macro = Macro({
  Error: Text,
  Icon: Circle,
  Title: Text,
  Copy: Text,
  Actions: Flex,
  Fineprint: Text,
});
export const Dialog = macro(
  (
    { Icon, Title, Copy, Actions, Fineprint, Error },
    { isOpen, onClose, ...props },
  ) =>
    isOpen ? (
      <>
        <DialogOverlay
          role="dialog"
          aria-labelledby="Dialog_Title"
          data-testid="Dialog"
          {...props}
        >
          <DialogContainer data-testid="Dialog__Container">
            <Flex
              flexDirection="column"
              flexWrap="nowrap"
              alignItems="center"
              justifyContent="center"
              p={[20, 0]}
            >
              <Box alignSelf="flex-end">
                <CloseButton data-testid="Dialog__Close" onClick={onClose} />
              </Box>
              <Clone
                data-testid="Dialog__Error"
                element={Error}
                as="p"
                color="error"
                fontSize={2}
              />
              <Clone element={Icon} data-testid="Dialog__Icon" />
              <Clone
                element={Title}
                as="h1"
                id="Dialog_Title"
                data-testid="Dialog__Title"
                fontSize={4}
                textAlign="center"
                mt={20}
                mb={10}
              />
              <Clone
                element={Copy}
                as="p"
                data-testid="Dialog__Copy"
                fontSize={4}
                textAlign="center"
                my={10}
              />
              <Clone
                element={Actions}
                data-testid="Dialog__Actions"
                my={10}
                flexDirection="row"
                flexWrap="nowrap"
                justifyContent="space-between"
                width={9 / 10}
              />
              {Fineprint && (
                <Clone
                  element={Fineprint}
                  as="p"
                  data-testid="Dialog__Fineprint"
                  fontSize={2}
                  lineHeight={1.38}
                  textAlign="center"
                  mt={10}
                  mb={0}
                />
              )}
            </Flex>
          </DialogContainer>
        </DialogOverlay>
        {/* Close on click background */}
        <DialogCurtain data-testid="Dialog__Curtain" onClick={onClose} />
      </>
    ) : null,
);

Dialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: or([PropTypes.func, explicitNull()]).isRequired,
};

Dialog.defaultProps = {
  isOpen: false,
};
