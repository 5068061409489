import { handleActions } from "redux-actions";
import { withApiErrors } from "../errors";
import {
  registerRequest,
  registerSuccess,
  registerFailure,
  registerClearMessages,
} from "./registration.actions";

const initialState = {
  loading: false,
  error: undefined,
  success: undefined,
};

const reducer = handleActions(
  {
    [registerRequest]: {
      next: state => ({
        ...state,
        success: undefined,
        loading: true,
      }),
      throw: (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }),
    },
    [registerSuccess]: {
      next: state => ({
        ...state,
        success: true,
        loading: false,
      }),
    },
    [registerFailure]: {
      next: (state, action) => ({
        ...state,
        success: false,
        error: action.payload,
        loading: false,
      }),
    },
    [registerClearMessages]: {
      next: state => ({
        ...state,
        ...initialState,
      }),
    },
  },
  initialState,
);

export default withApiErrors(reducer, "register");
