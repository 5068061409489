import { createAction } from "redux-actions";

export const STORM_ZONE_REQUEST = "app/stormZones/REQUEST";
export const STORM_ZONE_SUCCESS = "app/stormZones/SUCCESS";
export const STORM_ZONE_FAILURE = "app/stormZones/FAILURE";

export const stormZonesRequest = createAction(STORM_ZONE_REQUEST);
export const stormZonesSuccess = createAction(
  STORM_ZONE_SUCCESS,
  stormZones => ({ stormZones }),
);
export const stormZonesFailure = createAction(STORM_ZONE_FAILURE);
