import PropTypes from "prop-types";
import React from "react";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import { Box, Flex, Text } from "rebass";
import styled, { css } from "styled-components/macro";
import { ifProp, prop } from "styled-tools";
import { Tooltip } from "../Tooltip";
import ProgressIcon, { ShapeWrapper } from "./_/ProgressIcon";
import { VisuallyHidden } from "../VisuallyHidden";

const ProgressItem = styled(Box)`
  overflow: hidden;

  // Hide the left line on the first, right line on the last item entirely
  &:last-child ${ShapeWrapper}:after, &:first-child ${ShapeWrapper}:before {
    display: none;
  }

  // The Tooltip component adds [tabindex] attributes, the following styles the :focus of these
  & [tabindex]:focus {
    text-decoration: underline;
    outline: 0;
  }
`;

function getAriaText(type) {
  switch (type) {
    case "default":
      return "Current: ";
    case "complete":
      return "Completed: ";
    default:
      return "";
  }
}

function withSiblings(data) {
  return (
    Array.isArray(data) &&
    data.map((item, i, list) => ({
      ...item,
      previous: list[i - 1],
      next: list[i + 1],
    }))
  );
}

/**
 * Rules:
 * - START is complete and END is complete = dark
 * - START is complete and END is default = dark
 * - START is complete and END is incomplete = disabled
 * - START is incomplete and END is complete = disabled
 * - START is incomplete and END is default = disabled
 * - START is incomplete and END is incomplete = disabled
 * - START is default and END is complete = dark
 * - START is default and END is default = dark
 * - START is default and END is incomplete = disabled
 */
function getLineColor(start, end) {
  // When no END is specified we are dealing with the first or last element
  // which has no line at all
  if (typeof end === "undefined" || typeof start === "undefined") {
    return null;
  }

  // Default state
  let color = "dark";

  // Check for conditions that should set the disabled colour:
  const conditionsForDisabled = [
    ["complete", "incomplete"],
    ["incomplete", "incomplete"],
    ["incomplete", "complete"],
    ["incomplete", "default"],
    ["default", "incomplete"],
  ];

  conditionsForDisabled.forEach(([startCondition, endCondition]) => {
    if (start.type === startCondition && end.type === endCondition) {
      color = "disabled";
    }
  });

  return color;
}
function deriveItemColors(item) {
  const { next, previous, type } = item;

  const text = type === "incomplete" ? "disabled" : "dark";
  const right = getLineColor(item, next);
  const left = getLineColor(item, previous);

  return {
    left,
    right,
    text,
  };
}

// Setup area on page
const Container = styled.div`
  position: relative;
  overflow: hidden;
  overflow-x: auto;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;

  // TODO: Update OutageContainer layout not to have padding
  //width: 100%;
  //max-width: 100%;
  margin: 0 -20px;

  ${ifProp(
    "height",
    css`
      height: ${prop("height")}px;
    `,
  )};
`;
// Setup offsetable element to slide
const Content = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
`;
// Create a regular inner context that can be used to fill with content and give <Content /> its innerWidth
const ContentInner = styled.div`
  position: relative;
  // TODO: Fixme once OutageContainer is refactored
`;

class ProgressBar extends React.Component {
  state = { data: null, height: null };

  constructor(props) {
    super(props);

    this.scrollableElement = React.createRef();
    this.contentPanel = React.createRef();
    this.activeStep = React.createRef();
  }

  /* istanbul ignore next */
  syncHeight = /* istanbul ignore next */ () => {
    /* istanbul ignore next */
    if (this.contentPanel.current) {
      const { height } = this.contentPanel.current.getBoundingClientRect();
      this.setState({ height });
    }
  };

  /* istanbul ignore next */
  makeActiveCentre = () => {
    if (this.contentPanel.current && this.activeStep.current) {
      const screenWidth = window.innerWidth;
      const contentWidth = this.contentPanel.current.scrollWidth;

      /* istanbul ignore next */
      if (screenWidth < contentWidth) {
        // get the position of the active item in the DOM
        const dimensions = this.activeStep.current.getBoundingClientRect();
        const activePositionLeft =
          dimensions.left + dimensions.width / 2 - screenWidth / 2;

        if (this.scrollableElement && this.scrollableElement.current) {
          this.scrollableElement.current.scrollTo(activePositionLeft, 0);
        }
      }
    }
  };

  componentDidMount() {
    // FIXME: We have a rendering race condition where the browser lags behind
    //        in updating the actual dimensions so this setTimeout is the only
    //        way currently to wait for the final layout
    setTimeout(this.syncHeight, 50);
    this.makeActiveCentre();
  }

  /* istanbul ignore next */
  componentDidUpdate(prevProps) {
    const prevData = get(prevProps, "data", null);
    const data = get(this.props, "data", null);

    // If data has changed
    if (!isEqual(data, prevData)) {
      // recalculate height
      // FIXME: We have a rendering race condition where the browser lags behind
      //        in updating the actual dimensions so this setTimeout is the only
      //        way currently to wait for the final layout
      setTimeout(this.syncHeight, 50);

      // center the active one
      this.makeActiveCentre();
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      data: withSiblings(props.data),
    };
  }

  render() {
    const { data, height } = this.state;
    if (!data) {
      return null;
    }

    return (
      <Container height={height} ref={this.scrollableElement}>
        <Content width={[null, 1]} ref={this.contentPanel}>
          <ContentInner>
            <Flex>
              {Array.isArray(data) &&
                data.map((item, i) => {
                  const {
                    id,
                    icon,
                    label,
                    timestamp,
                    type,
                    isActive,
                    tooltip,
                    ...props
                  } = item;
                  const colors = deriveItemColors(item);

                  return (
                    <ProgressItem
                      key={id}
                      ref={isActive ? this.activeStep : null}
                      width={[130, 1 / data.length]}
                      pt={10}
                      pb={20}
                      data-step={i}
                      data-testid={`${label}-${type}`}
                    >
                      <Flex alignItems="center" flexDirection="column">
                        <ProgressIcon
                          type={type}
                          lineColorLeft={colors.left}
                          lineColorRight={colors.right}
                          isActive={isActive}
                          {...props}
                        >
                          {icon}
                        </ProgressIcon>
                        <Tooltip
                          isEnabled={!!tooltip}
                          content={tooltip}
                          maxWidth={200}
                        >
                          <Text
                            as="p"
                            m={0}
                            mt={10}
                            pl={10}
                            pr={10}
                            fontSize={isActive ? 2 : 1}
                            color={colors.text}
                            textAlign="center"
                            css={`
                              text-transform: lowercase;
                              & a {
                                text-decoration: none;
                                color: inherit;
                              }
                            `}
                          >
                            <VisuallyHidden>{getAriaText(type)}</VisuallyHidden>
                            {label}
                          </Text>
                        </Tooltip>
                        {timestamp && (
                          <Text
                            as="p"
                            m={0}
                            mt={1}
                            textAlign="center"
                            fontSize={isActive ? 2 : 1}
                          >
                            {timestamp}
                          </Text>
                        )}
                      </Flex>
                    </ProgressItem>
                  );
                })}
            </Flex>
          </ContentInner>
        </Content>
      </Container>
    );
  }
}

ProgressBar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      type: PropTypes.oneOf(["default", "active", "incomplete", "complete"]),
      isActive: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      timestamp: PropTypes.string,
      tooltip: PropTypes.string,
    }),
  ).isRequired,
};
export default ProgressBar;
