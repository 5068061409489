/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Box, Flex } from "rebass";
import { EventInfo } from "../../../components/EventInfo";
import { PLANNED } from "../../../components/EventType";
import { ETRRange } from "../../../components/ETRRange";
import { OutageReason } from "../../../components/OutageReason";
import { HOLDING_MESSAGE_TYPE } from "../../../constants/holdingMessageType";
import { Button } from "../../../components/Button";

export const OutageInfo = ({
  subscription,
  isResolved,
  unPlannedResolveText,
  outage: {
    etrStart,
    etrEnd,
    eventType,
    outageId,
    outageStart,
    outageReasonDescription,
    holdingMessage,
  },
  workStatus,
}) => {
  let label = unPlannedResolveText;
  let startTime = etrStart;
  if (eventType === PLANNED) {
    label = "OUTAGE TIME(S)";
    startTime = outageStart;
  }
  if (isResolved) {
    label = "OUTAGE RESOLVED";
  }

  const scrollToLatestMessage = () => {
    const target = document.getElementById("latestEventItem");
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  };

  const renderOutageShortMessage = () => {
    if (!isResolved) {
      if (etrEnd) {
        return (
          <span data-testid="OutageInfo__ETRRange">
            <ETRRange start={startTime} end={etrEnd} />
          </span>
        );
      }
      if (holdingMessage) {
        return (
          <Button
            variant="text"
            textAlign="left"
            style={{ padding: 0 }}
            onClick={scrollToLatestMessage}
            data-testid="OutageInfo__EstimatedRestorationMessage"
          >
            {holdingMessage.shortMessage.toLowerCase()}
          </Button>
        );
      }

      return (
        <span data-testid="OutageInfo__EstimatedRestorationMessage">
          to be confirmed
        </span>
      );
    }

    return <></>;
  };

  return (
    <Flex
      justifyContent={["space-between", null, "start"]}
      mb={10}
      flexDirection="column"
    >
      <Box py={[null, null, 10]} pr={[20, null, 25]} width={1} mt={3}>
        <EventInfo label={label} mb={2} fontWeight="bold">
          {renderOutageShortMessage()}
        </EventInfo>
      </Box>

      <OutageReason
        subscription={subscription}
        outage={{ eventType, outageId, outageReasonDescription, workStatus }}
      />
    </Flex>
  );
};

OutageInfo.propTypes = {
  subscription: PropTypes.shape({
    ticketId: PropTypes.string,
  }).isRequired,
  isResolved: PropTypes.bool.isRequired,
  unPlannedResolveText: PropTypes.string.isRequired,
  outage: PropTypes.shape({
    etrStart: PropTypes.string,
    etrEnd: PropTypes.string,
    eventType: PropTypes.string,
    outageId: PropTypes.string,
    holdingMessage: PropTypes.shape({
      type: PropTypes.oneOf(Object.values(HOLDING_MESSAGE_TYPE)),
      shortMessage: PropTypes.string,
      longMessage: PropTypes.string,
    }),
  }).isRequired,
  workStatus: PropTypes.shape({
    label: PropTypes.oneOf([
      "Outage reported",
      "On our way",
      "Investigating",
      "Resolving",
      "Outage resolved",
    ]),
    type: PropTypes.oneOf([
      "REPORTED",
      "ENROUTE",
      "INVESTIGATING",
      "WORKING",
      "RESOLVED",
    ]),
    active: PropTypes.bool,
    complete: PropTypes.bool,
  }).isRequired,
};
