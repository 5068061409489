/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { explicitNull } from "airbnb-prop-types";
import { createGlobalStyle } from "styled-components/macro";
import Tippy from "@tippy.js/react";

// eslint-disable-next-line import/no-extraneous-dependencies
import "tippy.js/dist/tippy.css";

// Vector Tippy.js Theme:
const TIPPY_THEME_NAME = "vector-dark";
const TippyThemeStyles = createGlobalStyle`
  // Background Styles
  .tippy-tooltip.${TIPPY_THEME_NAME}-theme {
    background: ${p => p.theme.colors.dark};
    text-align: left;
  }

  // Sharp Arrow Styling
  .tippy-popper[x-placement^="top"]
    .tippy-tooltip.${TIPPY_THEME_NAME}-theme
    .tippy-arrow {
    border-top-color: ${p => p.theme.colors.dark};
  }
  .tippy-popper[x-placement^="bottom"]
    .tippy-tooltip.${TIPPY_THEME_NAME}-theme
    .tippy-arrow {
    border-bottom-color: ${p => p.theme.colors.dark};
  }
  .tippy-popper[x-placement^="left"]
    .tippy-tooltip.${TIPPY_THEME_NAME}-theme
    .tippy-arrow {
    border-left-color: ${p => p.theme.colors.dark};
  }
  .tippy-popper[x-placement^="right"]
    .tippy-tooltip.${TIPPY_THEME_NAME}-theme
    .tippy-arrow {
    border-right-color: ${p => p.theme.colors.dark};
  }
`;

/* istanbul ignore next: 3rd party lib + CSS colours, does not need further testing */
export const Tooltip = ({ children, ...props }) => (
  <>
    <TippyThemeStyles />
    <Tippy
      theme={TIPPY_THEME_NAME}
      boundary="window"
      arrow
      placement="bottom"
      {...props}
    >
      {children}
    </Tippy>
  </>
);
Tooltip.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    explicitNull(),
  ]).isRequired,
};
