import styled from "styled-components";
import { fontSize, fontWeight } from "styled-system";
import { Flex } from "rebass";

const Label = styled(Flex)`
  display: block;
  line-height: 1;
  ${fontWeight};
  ${fontSize};
`;

Label.defaultProps = {
  as: "label",
  fontSize: 2,
};

Label.propTypes = {};

export default Label;
