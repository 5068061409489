import PropTypes from "prop-types";
import { or, explicitNull } from "airbnb-prop-types";

export const propTypes = PropTypes.shape({
  hash: or([PropTypes.string, PropTypes.number]).isRequired,
  trigger: or([explicitNull(), PropTypes.string]).isRequired,
  type: or([explicitNull(), PropTypes.string]).isRequired,
  content: or([
    explicitNull(),
    PropTypes.shape({
      title: or([PropTypes.string, explicitNull()]),
      description: or([PropTypes.array, PropTypes.arrayOf(PropTypes.string)]),
      actionLabel: or([PropTypes.string, explicitNull()]),
      actionUrl: or([PropTypes.string, explicitNull()]),
      updatedAt: or([PropTypes.string, explicitNull()]),
    }),
  ]).isRequired,
});

export {
  lsEventRequest,
  LSEVENT_REQUEST,
  lsEventSuccess,
  LSEVENT_SUCCESS,
  lsEventFailure,
  LSEVENT_FAILURE,
  lsEventDismiss,
  LSEVENT_DISMISS,
} from "./lsevent.actions";
export {
  getCurrentLSE,
  getIsDismissed,
  getHash,
  getEventLevel,
} from "./lsevent.selectors";
// eslint-disable-next-line import/no-cycle
export { default, INITIAL_STATE, GLOBAL_KEY } from "./lsevent.reducer";
export { watchForLsEventActions } from "./lsevent.saga";
