import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { PageWrapper } from "../../components/PageWrapper";
import {
  getStormZoneDetails,
  stormZoneByIdRequest,
} from "../../redux/modules/storm-zones-id";
import { Loader } from "../../components/Loader";
import { getIcpLocation } from "../../redux/modules/icps";
import StormZoneDetailsAddressSearch from "../../components/StormZoneDetailsAddressSearch/StormZoneDetailsAddressSearch";
import StormZoneDetailsMapSearch from "../../components/StormZoneDetailsMapSearch/StormZoneDetailsMapSearch";

const StormZoneDetailsContainer = ({
  history,
  match,
  origin,
  stormZoneDetails,
  stormZoneDetailsLoading,
  icpLocation,
  getStormZoneById,
}) => {
  const {
    params: { id },
  } = match;

  useEffect(() => {
    if (!stormZoneDetails || !stormZoneDetails.name) {
      getStormZoneById(id);
    }
  }, [id]);

  if (stormZoneDetailsLoading) {
    return (
      <PageWrapper
        metaTitle="outage map"
        title="outage map"
        history={history}
        p={4}
      >
        <Flex flexDirection="column" height="100%">
          <Flex m="auto">
            <Loader size={30} />
          </Flex>
        </Flex>
      </PageWrapper>
    );
  }

  return (
    <>
      {stormZoneDetails && stormZoneDetails.name && (
        <>
          {origin === "map-search" && (
            <StormZoneDetailsMapSearch stormZoneDetails={stormZoneDetails} />
          )}

          {origin === "address-search" && (
            <StormZoneDetailsAddressSearch
              stormZoneDetails={stormZoneDetails}
              icpLocation={icpLocation}
            />
          )}
        </>
      )}
    </>
  );
};

StormZoneDetailsContainer.defaultProps = {
  origin: "map-search",
  stormZoneDetailsLoading: false,
  stormZoneDetails: null,
  icpLocation: null,
};

StormZoneDetailsContainer.propTypes = {
  origin: PropTypes.oneOf(["map-search", "address-search"]),
  stormZoneDetails: PropTypes.object,
  stormZoneDetailsLoading: PropTypes.bool,
  icpLocation: PropTypes.shape({
    icpNumber: PropTypes.string,
    locationDisplay: PropTypes.string,
    locationPrefix: PropTypes.string,
    street: PropTypes.string,
    suburb: PropTypes.string,
    city: PropTypes.string,
    propertySeparator: PropTypes.string,
    transformerId: PropTypes.string,
    feederId: PropTypes.string,
    stormZoneId: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  getStormZoneById: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  connect(
    state => ({
      icpLocation: getIcpLocation(state),
      stormZoneDetails: getStormZoneDetails(state),
      stormZoneDetailsLoading: state.stormZonesId.isFetching,
    }),
    {
      getStormZoneById: stormZoneByIdRequest,
    },
  ),
);

export default enhance(StormZoneDetailsContainer);
