import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import titleCase from "voca/title_case";

/* istanbul ignore next: this is just a wrapper around a tested plugin */
export const Page = ({ title }) => (
  <Helmet>{title && <title>{titleCase(title)}</title>}</Helmet>
);

Page.propTypes = {
  title: PropTypes.string.isRequired,
};
Page.defaultTypes = {
  title: null,
};
