import { createAction } from "redux-actions";

export const PLANNED_OUTAGES_REQUEST = "app/plannedOutages/REQUEST";
export const PLANNED_OUTAGES_SUCCESS = "app/plannedOutages/SUCCESS";
export const PLANNED_OUTAGES_FAILURE = "app/plannedOutages/FAILURE";
export const PLANNED_OUTAGES_CLEAR = "app/plannedOutages/CLEAR";

export const plannedOutagesRequest = createAction(
  PLANNED_OUTAGES_REQUEST,
  icpNumber => ({ icpNumber }),
);
export const plannedOutagesSuccess = createAction(PLANNED_OUTAGES_SUCCESS);
export const plannedOutagesFailure = createAction(PLANNED_OUTAGES_FAILURE);
export const clearPlannedOutages = createAction(PLANNED_OUTAGES_CLEAR);
