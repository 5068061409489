/* eslint-disable react/prop-types */
import React from "react";
import { Box, Flex } from "rebass";
import { withTheme } from "styled-components/macro";
import Spinner from "@material-ui/core/CircularProgress";

// FIXME: Refactor data-testid of loader to be specified by each instance
const Loader = ({ color, size, stroke, duration, theme, ...props }) => {
  const colorValue = color || theme.colors.accent;
  const colorString = `color: ${colorValue};`;

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      css="height: 100%;"
      {...props}
    >
      <Box
        data-testid="spinLoader"
        css={colorString}
        aria-live="assertive"
        aria-label="Loading..."
        role="alert"
      >
        <Spinner
          aria-hidden="true"
          color="inherit"
          thickness={stroke || 3.6}
          size={size || 65}
        />
      </Box>
    </Flex>
  );
};

export default withTheme(Loader);
