/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Flex, Box, Text } from "rebass";
import { SizeMe } from "react-sizeme";
import { Button } from "../../components/Button";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { Image } from "../../components/Image";
import {
  getSelectedAddress,
  clearAddress,
} from "../../redux/modules/addresses";
import { AddressSearchContextContainer } from "../AddressSearchContextContainer";
import { trackEvent } from "../../common/analytics";

export const GA_CATEGORY = "Check Address";

export class CheckAddressContainer extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    selectedAddress: PropTypes.shape({
      id: PropTypes.string,
      Label: PropTypes.string,
    }),
    clearSelectedAddress: PropTypes.func,
  };

  static defaultProps = {
    selectedAddress: null,
    clearSelectedAddress: () => {},
  };

  componentWillMount() {
    const { clearSelectedAddress } = this.props;
    clearSelectedAddress();
  }

  handleSubmit = () => {
    const { history, selectedAddress } = this.props;
    history.push(`/address/${selectedAddress.id}`);
  };

  handleAnalytics = args => trackEvent({ category: GA_CATEGORY, ...args });

  render() {
    const { selectedAddress } = this.props;

    return (
      <Flex flexDirection="column" flex="1" alignItems="center" width={1}>
        {/* TODO: List of saved locations in a separate component */}

        {/* Typeahead Search Field */}
        <SizeMe monitorHeight>
          {({ size }) => (
            <Flex
              flexDirection="column"
              flex="1"
              alignItems="center"
              width={1}
              p={25}
            >
              <AddressSearchContextContainer
                size={size}
                handleSubmit={this.handleSubmit}
                p={25}
                category={GA_CATEGORY}
              />
              <Box width={1} pt={60}>
                {/* Conditional Map CTA */}
                {!selectedAddress && (
                  <Box>
                    <Text as="p" textAlign="center" lineHeight={1.5}>
                      <b>OR</b>
                    </Text>
                    <Button
                      variant="dark"
                      block
                      href="/map"
                      gaTrack={
                        /* istanbul ignore next */ () =>
                          this.handleAnalytics({ action: "Clicked Map CTA" })
                      }
                    >
                      view outage map
                    </Button>
                    <Text as="p" my={20} textAlign="center" lineHeight={1.5}>
                      Please be aware that outage areas are{" "}
                      <Button
                        variant="text"
                        href="https://www.vector.co.nz/personal/outages/popular-questions/outage-map-things-to-know"
                        gaTrack={
                          /* istanbul ignore next */ () =>
                            this.handleAnalytics({
                              action: "Clicked 'approximate only' link",
                            })
                        }
                      >
                        approximate only
                      </Button>
                      . Using the map may require extra WiFi or cellular data.
                    </Text>
                  </Box>
                )}
                {/* Conditional Submit button if address is selected */}
                {selectedAddress && (
                  <Box my={20}>
                    <Button
                      variant="dark"
                      block
                      onClick={this.handleSubmit}
                      gaTrack={
                        /* istanbul ignore next */ () =>
                          this.handleAnalytics({
                            action: "Check Address Now",
                            label: `${selectedAddress.id} | ${
                              selectedAddress.label
                            }`,
                          })
                      }
                    >
                      Check address now
                    </Button>
                  </Box>
                )}
              </Box>

              {/* Content */}
              <Box justifyContent="center">
                <Image
                  center
                  src="/assets/images/oc-checkaddress.png"
                  alt="Map with location marker illustration"
                />
              </Box>

              {/* CO-487 Notice about the fence */}
              <Box>
                <Text as="p" m={0} textAlign="center" lineHeight={1.5}>
                  <Button
                    variant="text"
                    href="https://blob-static.vector.co.nz/blob/vector/media/documents/vector-electricity-network-supply-area-map-2019.pdf"
                    gaTrack={
                      /* istanbul ignore next */ () =>
                        this.handleAnalytics({ action: "Download Network PDF" })
                    }
                  >
                    Our network
                  </Button>{" "}
                  extends from just north of Wellsford to Papakura and Manukau
                  in the south.
                </Text>
              </Box>
            </Flex>
          )}
        </SizeMe>
      </Flex>
    );
  }
}

/* istanbul ignore next */
const enhance = compose(
  withErrorBoundary(),
  withRouter,
  connect(
    state => ({
      selectedAddress: getSelectedAddress(state),
    }),
    {
      clearSelectedAddress: clearAddress,
    },
  ),
);

export default enhance(CheckAddressContainer);
