import { createAction } from "redux-actions";

export const CUSTOMERS_REQUEST = "app/customers/REQUEST";
export const CUSTOMERS_REQUEST_SUCCESS = "app/customers/SUCCESS";
export const CUSTOMERS_REQUEST_FAILURE = "app/customers/FAILURE";

export const requestCustomer = createAction(CUSTOMERS_REQUEST, () => undefined);
export const requestCustomerSuccess = createAction(CUSTOMERS_REQUEST_SUCCESS);
export const requestCustomerFailure = createAction(CUSTOMERS_REQUEST_FAILURE);

export const CUSTOMERS_UPDATE = "app/customers/UPDATE";
export const CUSTOMERS_UPDATE_SUCCESS = "app/customers/UPDATE_SUCCESS";
export const CUSTOMERS_UPDATE_FAILURE = "app/customers/UPDATE_FAILURE";

export const CUSTOMERS_UPDATE_MESSAGE_CLEAR =
  "app/customers/CLEAR_UPDATE_MESSAGES";

export const updateCustomer = createAction(
  CUSTOMERS_UPDATE,
  ({ values, oldValues, currentPassword, passwordless }) => ({
    currentPassword,
    values,
    oldValues,
    passwordless,
  }),
  (_, meta) => meta,
);
export const updateCustomerSuccess = createAction(CUSTOMERS_UPDATE_SUCCESS);
export const updateCustomerFailure = createAction(CUSTOMERS_UPDATE_FAILURE);
export const clearCustomerUpdateMessage = createAction(
  CUSTOMERS_UPDATE_MESSAGE_CLEAR,
);
