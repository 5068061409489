import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { createSelector } from "reselect";

export const getAllEvents = createSelector(
  [
    state => get(state, "eventHistory.byId", null),
    state => get(state, "eventHistory.allIds", null),
    (state, icpNumber) => icpNumber,
  ],
  (byId, allIds, icpNumber) => {
    const ids = get(allIds, icpNumber, []);

    if (
      isEmpty(byId) ||
      isEmpty(allIds) ||
      isEmpty(icpNumber) ||
      isEmpty(ids)
    ) {
      return [];
    }

    return ids.map(id => byId[id]);
  },
);

export const getLatestEvent = createSelector(
  [getAllEvents],
  items => (items.length ? items[0] : null),
);

export const getHistoricEvents = createSelector(
  [getAllEvents],
  items => (items.length > 1 ? items.slice(1) : []),
);

export const getIsOpen = state => state.eventHistory.isOpen;
export const getIsFetching = state => state.eventHistory.isFetching;
