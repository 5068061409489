import lodash from "lodash";

export const getCurrentWorkStatus = workStatuses => {
  if (!workStatuses || workStatuses.length <= 0) {
    return null;
  }

  const currentActiveWorkStatus = workStatuses.find(ws => ws.active);
  let currentWorkStatus = currentActiveWorkStatus;

  if (!currentActiveWorkStatus) {
    currentWorkStatus = lodash.findLast(workStatuses, ws => ws.complete);
  }

  if (!currentWorkStatus) {
    // eslint-disable-next-line prefer-destructuring
    currentWorkStatus = workStatuses[0];
  }

  return currentWorkStatus;
};
