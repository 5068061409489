/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { or, explicitNull } from "airbnb-prop-types";
import styled, { css } from "styled-components/macro";
import { Box } from "rebass";
import { fontSize } from "styled-system";
import { prop, switchProp } from "styled-tools";
import { Button as BaseButton } from "../../../components/Button";

const StormNotificationBox = styled(Box)`
  ${switchProp(prop("type"), {
    WARNING: css`
      color: ${({ theme }) => theme.colors.text};
      background-color: ${({ theme }) => theme.colors.warning};
    `,
    ALERT: css`
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.error};
    `,
  })};
`;

const Button = styled(BaseButton)`
  display: block;
  color: currentColor;
  padding-bottom: 10px;
  padding-top: 10px;
`;
const NotificationText = styled.p`
  ${fontSize};
  margin: 5px 0px 5px 0px;
`;

const ButtonBox = styled(Box)`
  padding-bottom: 10px;

  ${switchProp(prop("type"), {
    ALERT: css`
      color: ${({ theme }) => theme.colors.accent};
    `,
  })};
`;
const StormNotification = ({
  onClick,
  actionLabel,
  children,
  type,
  ...props
}) => (
  <StormNotificationBox type={type} px={[20, null, 25]} py={2} {...props}>
    <NotificationText fontSize={1}>{children}</NotificationText>
    <ButtonBox type={type}>
      <Button fontSize={1} onClick={onClick} block>
        {actionLabel}
      </Button>
    </ButtonBox>
  </StormNotificationBox>
);

StormNotification.propTypes = {
  actionLabel: PropTypes.string,
  children: PropTypes.element.isRequired,
  onClick: or([explicitNull(), PropTypes.func]),
};

StormNotification.defaultProps = {
  actionLabel: "FIND OUT MORE",
  onClick: null,
};

export default StormNotification;
