import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import { color, fontSize, textAlign } from "styled-system";
import { ifProp, switchProp } from "styled-tools";
import { getIn } from "formik";
import { focusStyling } from "../../common/styles";
import { withGATracking } from "../../common/withGATracking";
import { useFieldState } from "../Form";

/* eslint-disable react/prop-types, no-shadow */
const Base = ({
  children,
  type,
  fontSize,
  textAlign,
  color,
  isSelected,
  ...props
}) => {
  const attrs = {
    type,
  };

  return React.createElement(
    "label",
    {
      ...attrs,
      ...props,
    },
    children,
  );
};

const Input = styled.input`
  display: inline-block;
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
`;

const Label = styled(Base)`
  appearance: none;
  background: none;
  cursor: pointer;
  font-weight: normal;
  padding: 20px 20px;
  border-radius: 4px;
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.dark};
  text-decoration: none;
  ${fontSize};
  ${textAlign};
  ${color};
  display: block;
  width: 100%;
  ${focusStyling};

  ${switchProp("variant", {
    ghost: css`
      text-decoration: none;
      border: 1px solid #e4e4e4;
      border-radius: 5px;
      box-shadow: 0px 4px 9px #dadada;
      color: #1f79ff;

      &:focus {
        outline: 0;
      }
    `,
  })}

  ${ifProp(
    "isSelected",
    css`
      background: ${props => props.theme.colors.accent};
      color: ${props => props.theme.colors.white};
    `,
  )};

  ${ifProp(
    props => props.isSelected && props.variant === "ghost",
    css`
      background: ${props => props.theme.colors.white};
      border-color: ${props => props.theme.colors.accent};
      color: ${props => props.theme.colors.accent};
      border: 2px solid;
    `,
  )};

  ${ifProp(
    "shouldShowError",
    css`
      border-color: ${props => props.theme.colors.orange};
    `,
  )};
`;

export const RadioButton = ({
  field,
  form,
  label,
  labelProps,
  variant,
  ...props
}) => {
  const { values } = form;
  const isSelected = field.value === getIn(values, field.name);

  const fieldState = useFieldState(form, field);
  const { shouldShowError } = fieldState;

  return (
    <Label
      textAlign="center"
      fontSize={2}
      color="primary"
      shouldShowError={shouldShowError}
      isSelected={isSelected}
      variant={variant}
      {...labelProps}
    >
      <Input {...props} {...field} checked={isSelected} />
      {label}
    </Label>
  );
};

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.object,
};
RadioButton.defaultProps = {
  labelProps: {},
};

export default withGATracking(RadioButton);
