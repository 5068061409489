import PropTypes from "prop-types";
import React from "react";
import { Box, Flex, Text } from "rebass";
import styled from "styled-components/macro";
import ProgressIcon, { ShapeWrapper } from "./_/ProgressIcon";
import { VisuallyHidden } from "../VisuallyHidden";

const ProgressItem = styled(Box)`
  overflow: hidden;

  // Hide the left line on the first, right line on the last item entirely
  &:last-child ${ShapeWrapper}:after, &:first-child ${ShapeWrapper}:before {
    display: none;
  }
`;

function getAriaText(type) {
  switch (type) {
    case "default":
      return "Current: ";
    case "complete":
      return "Completed: ";
    default:
      return "";
  }
}

function withSiblings(data) {
  return (
    Array.isArray(data) &&
    data.map((item, i, list) => ({
      ...item,
      previous: list[i - 1],
      next: list[i + 1],
    }))
  );
}

/**
 * Rules:
 * - START is complete and END is complete = dark
 * - START is complete and END is default = dark
 * - START is complete and END is incomplete = disabled
 * - START is incomplete and END is complete = disabled
 * - START is incomplete and END is default = disabled
 * - START is incomplete and END is incomplete = disabled
 * - START is default and END is complete = dark
 * - START is default and END is default = dark
 * - START is default and END is incomplete = disabled
 */
function getLineColor(start, end) {
  // When no END is specified we are dealing with the first or last element
  // which has no line at all
  if (typeof end === "undefined" || typeof start === "undefined") {
    return null;
  }

  // Default state
  let color = "dark";

  // Check for conditions that should set the disabled colour:
  const conditionsForDisabled = [
    ["complete", "incomplete"],
    ["incomplete", "incomplete"],
    ["incomplete", "complete"],
    ["incomplete", "default"],
    ["default", "incomplete"],
  ];

  conditionsForDisabled.forEach(([startCondition, endCondition]) => {
    if (start.type === startCondition && end.type === endCondition) {
      color = "disabled";
    }
  });

  return color;
}
function deriveItemColors(item) {
  const { next, previous, type } = item;

  const text = type === "incomplete" ? "disabled" : "dark";
  const right = getLineColor(item, next);
  const left = getLineColor(item, previous);

  return {
    left,
    right,
    text,
  };
}

class ProgressBar extends React.Component {
  state = { data: null };

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      data: withSiblings(props.data),
    };
  }

  render() {
    const { data } = this.state;
    if (!data) {
      return null;
    }

    return (
      <Flex>
        {Array.isArray(data) &&
          // istanbul ignore next
          data.map((item, i) => {
            const {
              id,
              icon,
              label,
              timestamp,
              type,
              isActive,
              tooltip,
              ...props
            } = item;
            const colors = deriveItemColors(item);

            return (
              <ProgressItem
                key={id}
                ref={isActive ? this.activeStep : null}
                width={1 / data.length}
                pt={10}
                pb={10}
                data-step={i}
                data-testid={`${label}-${type}`}
              >
                <Flex alignItems="center" flexDirection="column">
                  <ProgressIcon
                    type={type}
                    lineColorLeft={colors.left}
                    lineColorRight={colors.right}
                    isActive={isActive}
                    {...props}
                  >
                    {icon}
                  </ProgressIcon>

                  <Text
                    as="p"
                    m={0}
                    mt={10}
                    pl={10}
                    pr={10}
                    fontSize={isActive ? 2 : 1}
                    color={colors.text}
                    textAlign="center"
                    css={`
                      text-transform: lowercase;
                      & a {
                        text-decoration: none;
                        color: inherit;
                      }
                    `}
                  >
                    <VisuallyHidden>{getAriaText(type)}</VisuallyHidden>
                    {label}
                  </Text>
                  {timestamp && (
                    <Text
                      as="p"
                      m={0}
                      textAlign="center"
                      fontSize={isActive ? 2 : 1}
                    >
                      {timestamp}
                    </Text>
                  )}
                </Flex>
              </ProgressItem>
            );
          })}
      </Flex>
    );
  }
}

ProgressBar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      type: PropTypes.oneOf(["default", "active", "incomplete", "complete"]),
      isActive: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      timestamp: PropTypes.string,
      tooltip: PropTypes.string,
    }),
  ).isRequired,
};
export default ProgressBar;
