/* eslint-disable no-unused-vars,arrow-body-style */
import client from "../client";
import { ICP_STATUS } from "./__fixture__/icp-status";

export const ENDPOINT = "outagereporter/icp-locations";

export class IcpsService {
  static getIcpStatus = async icpNumber => {
    // return ICP_STATUS[1];

    const { data } = await client.get(`${ENDPOINT}/${icpNumber}/status`);
    return data;
  };
}
