/* eslint-disable no-shadow */
import React, { createContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Flex, Box } from "rebass";
import styled, { css } from "styled-components/macro";
import { ifProp } from "styled-tools";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { getSelectedAddress } from "../../redux/modules/addresses/addresses.selectors";
import { getErrorMessage } from "../../redux/modules/errors";
import { AddressSearchContainer } from "../AddressSearchContainer";
import { Errors } from "./_/Errors";

const Container = styled(Flex)`
  ${ifProp(
    "isAbsolute",
    css`
      position: absolute;
    `,
  )};
  top: 0;
  left: 0;
  z-index: 1000;
`;
const Overlay = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: ${p => p.theme.colors.white};
`;

export const AddressSearchContext = createContext({ size: {}, category: null });

export class AddressSearchContextContainer extends React.Component {
  static propTypes = {
    category: PropTypes.string,
    handleSubmit: PropTypes.func,
    selectedAddress: PropTypes.shape({
      id: PropTypes.string,
      Label: PropTypes.string,
    }),
    size: PropTypes.object,
    addressError: PropTypes.string,
    locationError: PropTypes.string,
    otherError: PropTypes.string,
    isAbsolute: PropTypes.bool,
  };

  static defaultProps = {
    category: null,
    selectedAddress: null,
    size: { width: 500, height: 500 },
    addressError: undefined,
    locationError: undefined,
    otherError: undefined,
    handleSubmit: null,
    isAbsolute: true,
  };

  state = { isMenuOpen: false };

  handleKeyDown = (e, state) => {
    const { isOpen } = state;
    const { selectedAddress, handleSubmit } = this.props;

    if (!isOpen && selectedAddress && selectedAddress.id && e.keyCode === 13) {
      this.setState({ isMenuOpen: false });
      if (handleSubmit) {
        handleSubmit();
      }
    }
  };

  render() {
    const {
      addressError,
      locationError,
      otherError,
      handleSubmit,
      size,
      category,
      ...p
    } = this.props;
    const { isMenuOpen } = this.state;

    const errors = [addressError, locationError, otherError].filter(
      item => typeof item !== "undefined" && item !== null,
    );

    return (
      <AddressSearchContext.Provider value={{ size, category }}>
        {isMenuOpen && <Overlay data-testid="MapAddress__Overlay" />}
        <Container
          flexDirection="column"
          flex="1 1 auto"
          alignItems="center"
          width={1}
          {...p}
        >
          <Flex
            flexDirection="column"
            flex="1 1 auto"
            alignItems="center"
            width={1}
          >
            <Box width={[1, null, 1]}>
              <AddressSearchContainer
                onEnterKey={this.handleKeyDown}
                handleSubmit={handleSubmit}
                setOverlay={isOpen => this.setState({ isMenuOpen: isOpen })}
              />
            </Box>
          </Flex>

          {/* Error Messages */}
          <Errors errors={errors} />
        </Container>
      </AddressSearchContext.Provider>
    );
  }
}

/* istanbul ignore next */
const enhance = compose(
  withErrorBoundary(),
  connect(state => ({
    selectedAddress: getSelectedAddress(state),
    addressError: getErrorMessage(state, "addresses"),
    locationError: getErrorMessage(state, "location"),
  })),
);

export default enhance(AddressSearchContextContainer);
