import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Box, Flex } from "rebass";
import { AppBar } from "../components/AppBar";
import { Page } from "../components/Page";
import { UpdateMeAboutOutageContainer } from "../containers/UpdateMeAboutOutageContainer";

const UpdateMeAboutOutageView = ({ history }) => (
  <Flex flexDirection="column" width={1}>
    <Box width={1}>
      <Page title="Outage Updates" />
      <AppBar>
        <AppBar.Button onClick={history.goBack}>
          <span aria-hidden="true">&lt; </span>BACK
        </AppBar.Button>
        <AppBar.Title>UPDATES</AppBar.Title>
      </AppBar>
    </Box>
    <Flex
      flexDirection="column"
      alignItems="center"
      flex="1 1 auto"
      width={1}
      p={25}
      bg="light"
    >
      <UpdateMeAboutOutageContainer />
    </Flex>
  </Flex>
);
UpdateMeAboutOutageView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(UpdateMeAboutOutageView);
