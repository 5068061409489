import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Flex, Box } from "rebass";
import { AppBar } from "../components/AppBar";
import { Page } from "../components/Page";
import { LoginContainer } from "../containers/LoginContainer";

const LoginView = ({ history }) => (
  <Flex flexDirection="column" width={1}>
    <Box flex="0 1 0">
      <Page title="Login" />
      <AppBar>
        <AppBar.Button onClick={history.goBack}>
          <span aria-hidden="true">&lt; </span>BACK
        </AppBar.Button>
        <AppBar.Title>LOGIN</AppBar.Title>
      </AppBar>
    </Box>
    <Box flex="1 1 auto" width={1} p={20}>
      <LoginContainer />
    </Box>
  </Flex>
);

LoginView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(LoginView);
