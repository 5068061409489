/* eslint-disable react/prop-types */
import { getIn } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { Box, Flex, Text } from "rebass";
import styled from "styled-components/macro";
import { ErrorMessage } from "../FieldErrors";
import { useFieldHandlers } from "../Form";

const StyledInput = styled.div`
  height: 25px;
  width: 25px;
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.gray};
  border-radius: 5px;
  box-shadow: 0 2px 5px ${p => p.theme.colors.gray};

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 50%;
    top: 50%;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform-origin: 25% -10%;
    transform: rotate(45deg) translate3d(-50%, -50%, 0);
  }
`;

const Input = styled.input.attrs({
  type: "checkbox",
})`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:focus:not(:checked)
    ~ ${StyledInput},
    &:active:not(:checked)
    ~ ${StyledInput} {
    border-color: ${p => p.theme.colors.accent};
  }

  &:checked ~ ${StyledInput} {
    // Active state
    background-color: ${p => p.theme.colors.dark};

    &:after {
      display: block;
    }
  }
`;

const Label = styled(Flex)`
  cursor: pointer;
  line-height: 1;

  &:hover ${Input}:not(:checked) ~ ${StyledInput} {
    background-color: ${p => p.theme.colors.light};
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

// eslint-disable-next-line react/prefer-stateless-function
function Checkbox({ field, form, label, multiline, ...props }) {
  const eventHandlers = useFieldHandlers(field, props);

  return (
    <Flex flexDirection="column" width={1}>
      <Box>
        <Label as="label" alignItems="center">
          <Box flex="0 1 0" mr={10} alignSelf={multiline ? "flex-start" : null}>
            <InputWrapper>
              {/* TODO: Implement a fix of the checked and value attributes as part of OSD-279 */}
              <Input
                data-testid={`Field__Input--${field.name}`}
                {...field}
                {...props}
                {...eventHandlers}
                checked={getIn(form.values, field.name)}
              />
              <StyledInput />
            </InputWrapper>
          </Box>
          <Box>
            {typeof label === "string" ? (
              <Text fontSize={2}>{label}</Text>
            ) : (
              label
            )}
          </Box>
        </Label>
      </Box>
      <ErrorMessage name={field.name} />
    </Flex>
  );
}

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  multiline: PropTypes.bool,
};

Checkbox.defaultProps = {
  multiline: false,
};

export default Checkbox;
