import { useMemo } from "react";
import { getEventHandlers, extractEventHandlerKeys } from "./helpers";

export const useFieldHandlers = (...handlers) => {
  const eventMap = handlers.map(getEventHandlers);

  // Register multiple handlers, then call the from the inside out
  return useMemo(() => {
    const uniqueEventTypes = extractEventHandlerKeys(...eventMap);
    return uniqueEventTypes.reduce(
      (acc, event) => ({
        ...acc,
        [event]: e => {
          // Fire all handlers, these are naturally in the correct order due to
          // how the createEventHandlerMap function accepts and returns objects
          eventMap.forEach(events => {
            const fn = events[event]; // try to access the current event type within the event objects
            if (typeof fn === "function") {
              fn(e); // fire the handler if found
            }
          });
        },
      }),
      {},
    );
  }, [...eventMap]);
};
