const PLANNED_OUTAGES = {
  futurePlannedOutages: [
    {
      switchingProposalNumber: "1-ABC",
      status: "Planning Approved",
      suburb: "Ponsonby",
      reason: "Pole Replacement",
      changeReason: "The ground needs to be reinforced",
      updatedAt: new Date().toString(),
      advertisedStartDate: "2020-02-14",
      advertisedEndDate: "2020-02-14",
      alternativeStartDate: "2020-02-15",
      alternativeEndDate: "2020-02-15",
      advertisedTimes: [
        {
          start: "11:30:00",
          end: "15:30:00",
        },
      ],
      alternativeTimes: [
        {
          start: "12:30:00",
          end: "16:30:00",
        },
      ],
    },
    {
      switchingProposalNumber: "1-DFE",
      status: "Cancelled",
      suburb: "Ponsonby",
      reason: "Pole Replacement",
      changeReason: "The ground needs to be reinforced",
      updatedAt: new Date().toString(),
      advertisedStartDate: "2020-02-14",
      advertisedEndDate: "2020-02-14",
      alternativeStartDate: "2020-02-15",
      alternativeEndDate: "2020-02-15",
      advertisedTimes: [
        {
          start: "11:30:00",
          end: "15:30:00",
        },
      ],
      alternativeTimes: [
        {
          start: "12:30:00",
          end: "16:30:00",
        },
      ],
    },
    {
      switchingProposalNumber: "1-XYZ",
      status: "Postponed",
      postponed: true,
      suburb: "Ponsonby",
      reason: "Pole Replacement",
      changeReason: "The ground needs to be reinforced",
      updatedAt: new Date().toString(),
      advertisedStartDate: "2020-02-14",
      advertisedEndDate: "2020-02-14",
      alternativeStartDate: "2020-02-15",
      alternativeEndDate: "2020-02-15",
      advertisedTimes: [
        {
          start: "11:30:00",
          end: "15:30:00",
        },
      ],
      alternativeTimes: [
        {
          start: "12:30:00",
          end: "16:30:00",
        },
      ],
    },
    {
      switchingProposalNumber: "1-XYX-OTHER",
      status: "OTHER",
      suburb: "Ponsonby",
      reason: "Pole Replacement",
      updatedAt: new Date().toString(),
      advertisedStartDate: "2020-02-14",
      advertisedEndDate: "2020-02-14",
      alternativeStartDate: "2020-02-15",
      alternativeEndDate: "2020-02-15",
      advertisedTimes: [
        {
          start: "11:30:00",
          end: "15:30:00",
        },
      ],
      alternativeTimes: [
        {
          start: "12:30:00",
          end: "16:30:00",
        },
      ],
    },
    {
      switchingProposalNumber: "1-XYX-OTHER2",
      status: "Planning Approved",
      suburb: "Ponsonby",
      advertisedStartDate: "2020-02-14",
      advertisedEndDate: "2020-02-14",
      advertisedTimes: [
        {
          start: "11:30:00",
          end: "15:30:00",
        },
      ],
      alternativeTimes: [],
      reason: "Pole Replacement",
      updatedAt: new Date().toString(),
    },
    {
      switchingProposalNumber: "1-2DAYS",
      status: "Planning Approved",
      suburb: "Ponsonby",
      reason: "Pole Replacement",
      changeReason: "The ground needs to be reinforced",
      updatedAt: new Date().toString(),
      advertisedStartDate: "2020-02-14",
      advertisedEndDate: "2020-02-17",
      alternativeStartDate: "2020-02-17",
      alternativeEndDate: "2020-02-17",
      advertisedTimes: [
        {
          start: "22:30:00",
          end: "02:30:00",
        },
      ],
      alternativeTimes: [
        {
          start: "02:30:00",
          end: "05:30:00",
        },
      ],
    },
    {
      switchingProposalNumber: "1-CNCELPSTPND",
      status: "Cancelled",
      postponed: true,
      suburb: "Ponsonby",
      reason: "Pole Replacement",
      changeReason: "The ground needs to be reinforced",
      updatedAt: new Date().toString(),
      advertisedStartDate: "2020-02-14",
      advertisedEndDate: "2020-02-14",
      alternativeStartDate: "2020-02-15",
      alternativeEndDate: "2020-02-15",
      advertisedTimes: [
        {
          start: "11:30:00",
          end: "15:30:00",
        },
      ],
      alternativeTimes: [
        {
          start: "12:30:00",
          end: "16:30:00",
        },
      ],
    },
    {
      switchingProposalNumber: "1-MULTIPLE",
      status: "Planning Approved",
      suburb: "Ponsonby",
      reason: "Pole Replacement",
      changeReason: "The ground needs to be reinforced",
      updatedAt: new Date().toString(),
      advertisedStartDate: "2020-02-14",
      advertisedEndDate: "2020-02-14",
      alternativeStartDate: "2020-02-15",
      alternativeEndDate: "2020-02-15",
      advertisedTimes: [
        {
          start: "04:30:00",
          end: "05:30:00",
        },
        {
          start: "09:30:00",
          end: "10:30:00",
        },
      ],
      alternativeTimes: [
        {
          start: "08:30:00",
          end: "09:30:00",
        },
        {
          start: "09:30:00",
          end: "10:30:00",
        },
      ],
    },
  ],
};

const EMPTY_PLANNED_OUTAGES = {
  futurePlannedOutages: [],
};
export default {
  PLANNED_OUTAGES,
  EMPTY_PLANNED_OUTAGES,
};
