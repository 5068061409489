import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Box, Flex } from "rebass";
import { get } from "lodash";
import { AppBar } from "../components/AppBar";
import { Page } from "../components/Page";
import { RegistrationContainer } from "../containers/RegistrationContainer";

const RegistrationView = ({ history, location }) => {
  const redirectTo = get(location, "state.redirectTo");
  return (
    <Flex flexDirection="column" width={1}>
      <Box flex="0 1 0">
        <Page title="Register" />
        <AppBar>
          <AppBar.Button onClick={history.goBack}>
            <span aria-hidden="true">&lt; </span>BACK
          </AppBar.Button>
          <AppBar.Title>REGISTER</AppBar.Title>
        </AppBar>
      </Box>
      <Box flex="1 1 auto" p={20}>
        <RegistrationContainer navigateBack={redirectTo} />
      </Box>
    </Flex>
  );
};

RegistrationView.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(RegistrationView);
