import React from "react";
import PropTypes from "prop-types";
import { color, display } from "styled-system";
import styled, { css } from "styled-components";

const LineWrapper = styled.hr`
  ${display};
  ${color};
  ${props => css`
    margin-right: ${props.marginRight}
    height: ${props.height}
    width: ${props.width}
    opacity: ${0.25}
  `}
`;

const ThematicBreak = ({ ...props }) => <LineWrapper {...props} />;

ThematicBreak.defaultProps = {
  color: "gray",
  marginLeft: "0",
  marginRight: "0",
  display: "block",
  height: "100%",
  width: "100%",
};
ThematicBreak.propTypes = {
  color: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  display: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default ThematicBreak;
