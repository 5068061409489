import React from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import styled, { css } from "styled-components/macro";
import { ifProp, ifNotProp } from "styled-tools";
import { ReactComponent as CloseIcon } from "../Modal/_/icon-close.svg";

const Container = styled(Flex)`
  ${ifNotProp(
    "icononly",
    css`
      color: ${props => props.theme.colors.white};
      border: 2px solid ${props => props.theme.colors.white};
      border-radius: 100%;
    `,
  )}
  width: 48px;
  height: 48px;
`;
const Button = styled.button.attrs({
  "data-testid": "CloseButton",
})`
  position: absolute;
  top: ${ifProp("icononly", "15px", "30px")};
  right: ${ifProp("icononly", "15px", "20px")};

  ${p => p.theme.upFrom.md`
    right: 30px;
  `};

  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
`;

export const CloseButton = ({ onClick, icononly, ...props }) => (
  <Button {...props} onClick={onClick} icononly={icononly}>
    <Container alignItems="center" justifyContent="center" icononly={icononly}>
      <CloseIcon />
    </Container>
  </Button>
);

CloseButton.defaultProps = {
  icononly: false,
};

CloseButton.propTypes = {
  icononly: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
