import PropTypes from "prop-types";
import { Box } from "rebass";
import { minHeight } from "styled-system";
import styled, { css } from "styled-components/macro";
import { ifProp, switchProp } from "styled-tools";

export const Field = styled(Box)`
  display: block;
  width: 100%;
  padding: 17px 14px;
  border: 1px solid transparent;
  border-radius: 5px;

  outline: none;
  box-shadow: none; // Fixes a Firefox display issue when the field is in error mode

  // Field States
  // Focus/Active
  &:focus,
  &:active {
    outline: none;
  }
  &:webkit-autofill {
    font-family: Montserrat, monospace, sans-serif;
    background-color: transparent !important;
  }

  ${switchProp("as", {
    input: css`
      line-height: 1;
      height: 56px;
    `,
    textarea: css`
      appearance: none;
      max-width: 100%;
      min-width: 100%;
      ${minHeight};
      line-height: 1.3;
      padding-bottom: 30px;
    `,
  })};

  // Error
  ${switchProp("variant", {
    default: css``,
    focus: css``,
    error: css`
      padding-right: 55px;
    `,
  })};

  ${ifProp(
    { type: "password" },
    css`
      &::-ms-reveal {
        display: none;
      }
    `,
  )}

  // Disabled
  &:disabled {
    cursor: not-allowed;
    color: ${p => p.theme.colors.gray};
    background-color: ${p => p.theme.colors.light};
    box-shadow: none;

    &::placeholder {
      color: ${p => p.theme.colors.gray};
    }
  }
`;
Field.propTypes = {
  ...Box.propTypes,
  "data-testid": PropTypes.string,
  as: PropTypes.oneOf(["input", "textarea"]), // TODO: Add <select> support?
  variant: PropTypes.oneOf(["default", "focus", "error"]),
  // Base requirements
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.string,
};
Field.defaultProps = {
  "data-testid": "Field__Input",
  as: "input",
  fontSize: 2,
  variant: "default",
  value: "",
};
