import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Flex, Box } from "rebass";
import { ReactComponent as CloseIcon } from "./icon-close.svg";

const Button = styled.button`
  border: 2px solid ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.dark};
  height: 34px;
  width: 34px;
  border-radius: 100%;
  background-color: transparent;
  line-height: 0;
  cursor: pointer;

  & svg {
    fill: currentColor;
  }
`;

const CloseButton = ({ onClick, ...props }) => (
  <Button onClick={onClick} {...props}>
    <Flex alignItems="center" justifyContent="center">
      <Box>
        <CloseIcon />
      </Box>
    </Flex>
  </Button>
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
