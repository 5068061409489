import { Box } from "rebass";
import styled from "styled-components/macro";

export const FieldContainer = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  text-transform: lowercase;
`;
FieldContainer.propTypes = {
  ...Box.propTypes,
};
