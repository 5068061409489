import React from "react";
import PropTypes from "prop-types";
import { OverlayView } from "@react-google-maps/api";
import { Box } from "rebass";
import styled from "styled-components";

/* istanbul ignore next */
const Container = styled(Box)`
  border-radius: 4px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.14);
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -12px;
    width: 0;
    height: 0;
    border-bottom: 24px solid ${p => p.theme.colors.white};
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
  }
`;
/* istanbul ignore next */
const Button = styled.button`
  min-width: 216px;
  display: block;
  width: 100%;
  overflow: hidden;
  text-transform: lowercase;
  text-decoration: none;
  padding: 20px 25px;
  border-radius: 4px;
  border: none;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.accent};
  font-size: 18px;
  cursor: pointer;
`;

const ViewDetailsButton = /* istanbul ignore next */ ({ coords, onClick }) => (
  <OverlayView
    position={{
      lat: coords.latitude,
      lng: coords.longitude,
    }}
    getPixelPositionOffset={x => {
      const newX = -(x / 2);
      const newY = 24;
      return { x: newX, y: newY };
    }}
    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  >
    <Container bg="white" p={2}>
      <Button
        type="button"
        ref={ref => {
          // fixes console error when ref is null
          setTimeout(() => {
            if (ref) window.google.maps.OverlayView.preventMapHitsFrom(ref);
          }, 500);
        }}
        onClick={onClick}
        data-testid="Map__ViewDetails"
      >
        view details
      </Button>
    </Container>
  </OverlayView>
);

ViewDetailsButton.propTypes = {
  coords: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ViewDetailsButton;
