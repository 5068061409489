import PropTypes from "prop-types";
import styled from "styled-components/macro";
import {
  bottom,
  fontSize,
  left,
  position,
  right,
  space,
  top,
} from "styled-system";
import { Button } from "../../Button";

export const VisibilityToggle = styled(Button)`
  transform: translate3d(0, -50%, 0);

  ${position};
  ${top};
  ${right};
  ${bottom};
  ${left};
  ${fontSize};
  ${space};
`;
VisibilityToggle.propTypes = {
  ...Button.propTypes,
  ...fontSize.propTypes,
  ...position.propTypes,
  ...top.propTypes,
  ...right.propTypes,
  ...bottom.propTypes,
  ...left.propTypes,
  ...space.propTypes,
  "data-testid": PropTypes.string,
};
VisibilityToggle.defaultProps = {
  "data-testid": "VisibilityToggle",
  type: "button",
  variant: "text",
  fontSize: 0,
  position: "absolute",
  top: "50%",
  right: 15,
  py: 3,
};
