import { memo, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const Portal = ({ id, children }) => {
  const el = useRef(
    document.getElementById(id) || document.createElement("div"),
  );

  useEffect(() => {
    el.current.id = id;
    document.body.appendChild(el.current);

    return () => {
      /* istanbul ignore next */
      if (el.current.parentElement) {
        el.current.parentElement.removeChild(el.current);
      }
    };
  }, [id]);

  return createPortal(children, el.current);
};

export default memo(Portal);
