import React, { Component } from "react";
import PropTypes from "prop-types";
import { Box, Flex, Text } from "rebass";
import styled from "styled-components/macro";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { Image } from "../Image";

const Round = styled(Flex)`
  border: 2px solid;
  border-radius: 100%;
  border-color: ${({ theme }) => theme.colors.error};
  width: 35px;
  height: 35px;
  background-color: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.error};
  flex-shrink: 0;
`;

const ErrorText = styled(Text)`
  color: ${props => props.theme.colors.error};
  font-weight: normal;
`;

export const Exclamation = () => (
  <Round alignItems="center" justifyContent="center" mr={2}>
    <Box>
      <Image
        data-testid="error-message-image"
        src="/assets/images/exclamation.svg"
      />
    </Box>
  </Round>
);

class ErrorMessage extends Component {
  static propTypes = {
    message: PropTypes.string,
    fontSize: PropTypes.number,
    shouldReveal: PropTypes.bool,
  };

  static defaultProps = {
    message: "",
    fontSize: 1,
    shouldReveal: false,
  };

  handleMessageAppearing = (curr, prev = null) => {
    const { shouldReveal } = this.props;

    if (
      shouldReveal && // check the option being enabled
      !isEqual(prev, curr) && // ensure the message is different
      typeof curr === "string" && // ensure we have a current message
      !isEmpty(curr) // ensure the message isn't empty (i.e. removal of an error returns an empty string)
    ) {
      // Force the browser scroll position to the top to show error
      window.scrollTo(0, 0);
    }
  };

  componentDidMount() {
    const { message } = this.props;
    this.handleMessageAppearing(message);
  }

  componentDidUpdate(prevProps) {
    const { message } = this.props;
    const { message: prevMessage } = prevProps;
    this.handleMessageAppearing(message, prevMessage);
  }

  render() {
    const { message, fontSize } = this.props;

    return !isEmpty(message) ? (
      <Flex justifyContent="center" alignItems="center" mt={3} ml={4}>
        <Exclamation />
        <Box>
          <ErrorText data-testid="error-message-text" fontSize={fontSize}>
            {message}
          </ErrorText>
        </Box>
      </Flex>
    ) : null;
  }
}

export default ErrorMessage;
