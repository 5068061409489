import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Flex, Box } from "rebass";
import { Button } from "../../Button";
import TextLowercase from "./TextLowercase";

const Divider = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  color: #d8d8d8;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
  height: 100%;
`;

const ReportButtons = ({ icpNumber, ...rest }) => (
  <Box mt={20}>
    <Divider />
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      my={15}
      {...rest}
    >
      <Box width={[null, null, null, 1 / 2]}>
        <Button
          variant="text"
          lineHeight={1.2}
          href={`/address/${icpNumber}`}
          data-testid="Report__Button--view-report"
        >
          <TextLowercase as="span" fontSize={1}>
            VIEW OUTAGE DETAILS
          </TextLowercase>
        </Button>
      </Box>
      <Box width={[null, null, null, 1 / 2]}>
        <Button
          variant="text"
          lineHeight={1.2}
          href="/my-reports/cancel"
          data-testid="Report__Button--cancel-report"
        >
          <TextLowercase as="span" fontSize={1}>
            CANCEL REPORT
          </TextLowercase>
        </Button>
      </Box>
    </Flex>
    <Divider />
  </Box>
);

ReportButtons.propTypes = {
  icpNumber: PropTypes.string.isRequired,
};

export default ReportButtons;
