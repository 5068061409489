import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { rgba } from "polished";
import { Box, Flex, Text } from "rebass";

const LegendContainer = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  color: black;
  border-radius: 4px;
  overflow: hidden;
  z-index: 990;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.14);
  max-width: 75%;
`;

const LegendIcon = styled(Box)`
  border-radius: 100%;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  @media (min-width: 400px) {
    width: 16px;
    height: 16px;
  }
  background-color: ${p => rgba(p.bg, p.opacity)};
  border-style: ${p => p.borderStyle};
  border-color: ${p => p.borderColor};
`;

export const Legend = ({
  planned,
  unplanned,
  stormZone,
  isStormZoneActive,
}) => (
  <LegendContainer
    bg="white"
    m={3}
    data-testid="LegendContainer"
    fontSize={[0, null, 1]}
  >
    <Box bg="primary" color="white" p={2}>
      <Text>approximate outage areas</Text>
    </Box>

    <Flex
      columns={3}
      alignItems="center"
      p={[1, null, 2]}
      justifyContent="space-between"
    >
      {isStormZoneActive && (
        <Flex
          columns={2}
          alignItems="center"
          data-testid="LegendContainer__StormZone"
          p={1}
        >
          <LegendIcon
            bg={stormZone.bg}
            opacity={stormZone.opacity}
            borderStyle={stormZone.borderStyle}
            borderColor={stormZone.borderColor}
          />
          <Text ml={2}>large scale event</Text>
        </Flex>
      )}
      <Flex
        columns={2}
        alignItems="center"
        data-testid="LegendContainer__Unplanned"
        p={1}
      >
        <LegendIcon
          bg={unplanned.bg}
          opacity={unplanned.opacity}
          borderStyle={unplanned.borderStyle}
          borderColor={unplanned.borderColor}
        />
        <Text ml={2}>unplanned outage</Text>
      </Flex>
      <Flex
        columns={2}
        alignItems="center"
        data-testid="LegendContainer__Planned"
        p={1}
      >
        <LegendIcon
          bg={planned.bg}
          opacity={planned.opacity}
          borderStyle={planned.borderStyle}
          borderColor={planned.borderColor}
        />
        <Text ml={2}>planned work</Text>
      </Flex>
    </Flex>
  </LegendContainer>
);

Legend.propTypes = {
  planned: PropTypes.object.isRequired,
  unplanned: PropTypes.object.isRequired,
  stormZone: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  isStormZoneActive: PropTypes.bool.isRequired,
};
