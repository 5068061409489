import React from "react";
import PropTypes from "prop-types";
import { or, explicitNull } from "airbnb-prop-types";
import { Box, Flex } from "rebass";
import styled, { css } from "styled-components/macro";
import { switchProp } from "styled-tools";

const Container = styled(Flex).attrs({
  flexDirection: "row",
  flexWrap: "nowrap",
})`
  position: absolute;
  z-index: 2;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
const Item = styled(Box)`
  border-width: 1px;
  border-style: solid;
  border-color: ${p => p.theme.colors.gray};

  ${switchProp("variant", {
    focus: css`
      border-width: 2px;
      border-color: ${props => props.theme.colors.accent};
    `,
    error: css`
      border-width: 2px;
      border-color: ${props => props.theme.colors.error};
    `,
  })};

  // NOTE: transitioning the width looks nice but has a performance hit due to browser repaints
  transition: border-color 0.15s ease-in-out, border-width 0.15s ease-in-out,
    width 0.1s ease-in-out;

  &:first-child {
    flex: 0 1 9px;
    border-radius: 4px 0 0 4px;
    border-right: 0;
  }

  &:nth-child(2) {
    flex: 0 0 auto;
    border-left: 0;
    border-right: 0;
    border-top: 0;
  }

  &:last-child {
    flex: 1 1 auto;
    border-radius: 0 4px 4px 0;
    border-left: 0;
  }
`;

export const Outline = ({ variant, notchWidth, children, ...props }) => (
  <Container {...props}>
    <Item variant={variant} />
    <Item variant={variant} width={notchWidth}>
      {children}
    </Item>
    <Item variant={variant} />
  </Container>
);

Outline.propTypes = {
  variant: PropTypes.oneOf(["default", "focus", "error"]),
  notchWidth: or([PropTypes.number, explicitNull()]),
  children: PropTypes.node,
};
Outline.defaultProps = {
  variant: "default",
  notchWidth: null,
  children: null,
};
