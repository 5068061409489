import { call, put, takeLatest, fork } from "redux-saga/effects";
import { handleRedirect } from "../../sagas/helpers";
import {
  saveSubscriptionPreferencesSuccess,
  saveSubscriptionPreferencesFailure,
  SAVE_SUBSCRIPTION_REQUEST,
  getSubscriptionRequest,
  getSubscriptionSuccess,
  getSubscriptionFailure,
} from "./subscription.actions";
import { OutageService } from "../../../services/OutageService";

export function* processSaveSubscriptionRequest(action) {
  try {
    const values = action.payload;
    const { data } = yield call(
      OutageService.saveSubscriptionPreferences,
      values,
    );
    yield fork(handleRedirect, action);
    yield put(saveSubscriptionPreferencesSuccess(data));
  } catch (err) {
    yield put(saveSubscriptionPreferencesFailure(err.message));
  }
}

export function* processGetSubscriptionRequest(action) {
  const {
    payload: { icpNumber },
  } = action;

  try {
    const response = yield call(OutageService.getSubscription, icpNumber);
    if (response.status === 200) {
      yield put(getSubscriptionSuccess(icpNumber, response.data));
    } else if (response.status === 204) {
      yield fork(handleRedirect, action);
      yield put(getSubscriptionFailure());
    } else {
      throw new Error("Sorry, something has gone wrong. Please try again.");
    }
  } catch (e) {
    yield put(getSubscriptionFailure(e));
  }
}

export function* watchForSaveSubscriptionRequest() {
  yield takeLatest(SAVE_SUBSCRIPTION_REQUEST, processSaveSubscriptionRequest);
  yield takeLatest(getSubscriptionRequest, processGetSubscriptionRequest);
}
