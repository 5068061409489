import { or } from "airbnb-prop-types";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose, shouldUpdate } from "recompose";
import {
  getCurrentLSE,
  getEventLevel,
  getIsDismissed,
  propTypes,
} from "../../redux/modules/lsevent";
import { LSEventContext } from "./LSEventContext";

export function LSEventProvider({ children, lse, isDismissed, code }) {
  const data = {
    ...lse,
    isDismissed,
    code,
  };

  return (
    <LSEventContext.Provider value={data}>{children}</LSEventContext.Provider>
  );
}

LSEventProvider.propTypes = {
  lse: or([PropTypes.object, propTypes]),
  isDismissed: PropTypes.bool,
  code: PropTypes.oneOf([0, 1, 2]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
};
LSEventProvider.defaultProps = {
  lse: {},
  isDismissed: false,
  code: 0,
};

/* istanbul ignore next */
const enhance = compose(
  connect(state => ({
    lse: getCurrentLSE(state),
    isDismissed: getIsDismissed(state),
    code: getEventLevel(state),
  })),
  shouldUpdate((props, nextProps) => !isEqual(props, nextProps)),
);

export default enhance(LSEventProvider);
