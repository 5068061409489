import { useState, useCallback } from "react";

export const usePasswordVisibilityToggle = originalFieldType => {
  const isPasswordField = originalFieldType === "password";

  if (!isPasswordField) {
    return [originalFieldType, null, null];
  }

  const [isVisible, setVisibility] = useState(false);
  const toggleVisibility = useCallback(() => {
    setVisibility(!isVisible);
  }, [isVisible]);

  const type = isVisible ? "text" : originalFieldType;
  const label = isVisible ? "HIDE" : "SHOW";

  return [type, label, toggleVisibility];
};
