import { Flex } from "rebass";
import styled from "styled-components/macro";
import { transparentize } from "polished";

export default styled(Flex).attrs({
  flexDirection: "column",
  flexWrap: "nowrap",
  alignItems: "center",
  justifyContent: ["flex-start", "center"],
})`
  position: fixed;
  z-index: 20001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  background: ${({ theme }) => transparentize(0.05, theme.colors.light)};
`;
