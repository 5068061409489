import client from "../client";

class RegisterService {
  static register({ email, password, firstName, lastName, phone }) {
    return client.post("outagereporter/v2/users", {
      username: email,
      email,
      password,
      name: firstName,
      lastName,
      phone,
    });
  }
}

export default RegisterService;
