import PropTypes from "prop-types";
import { Text } from "rebass";
import styled from "styled-components/macro";

export const InlineHint = styled(Text)`
  position: absolute;
  bottom: 10px;
  right: 15px;
  text-transform: lowercase;
`;
InlineHint.propTypes = {
  ...Text.propTypes,
  "data-testid": PropTypes.string,
};
InlineHint.defaultProps = {
  "data-testid": "InlineHint",
  as: "p",
  fontSize: 0,
  m: 0,
};
