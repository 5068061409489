import reducer from "./registration.reducer";

export { watchForRegisterRequest } from "./registration.saga";
export {
  registerRequest,
  registerSuccess,
  registerFailure,
  registerClearMessages,
} from "./registration.actions";

export { successMessage } from "./registration.selectors";
export default reducer;
