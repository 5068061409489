/* istanbul ignore file */
// Global CSS Styles
// Used for super generic baseline stuff or CSS Resets for example
import { createGlobalStyle } from "styled-components/macro";
import normalize from "styled-normalize";

export const GlobalCSS = createGlobalStyle`
  ${normalize}
  
  * { box-sizing: border-box; }

  html {
    text-rendering: optimizeLegibility;
    //-webkit-font-smoothing: antialiased; /* significantly makes the text appear thinner */
    -moz-osx-font-smoothing: grayscale;
    
    font-family: Montserrat, monospace, sans-serif;
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.background};
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }
`;
