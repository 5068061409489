/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Polygon } from "@react-google-maps/api";
import { shapeStyles } from "../../containers/MapContainer/_";

export const StormZonePolygon = ({ shape, zoomLevel, onClick }) => {
  const { stormZone: stormZoneStyles } = shapeStyles;

  const [polygonOpacity, setPolygonOpacity] = useState(stormZoneStyles.opacity);

  const handleOnMouseOver = () => {
    setPolygonOpacity(stormZoneStyles.hooverOpacity);
  };

  const handleOnMouseOut = () => {
    setPolygonOpacity(stormZoneStyles.opacity);
  };

  // eslint-disable-next-line no-shadow
  const handleOnClick = () => {
    onClick();
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <Polygon
        path={shape}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
        onClick={handleOnClick}
        options={{
          zIndex: 9999,
          fillColor: stormZoneStyles.borderColor,
          strokeColor: stormZoneStyles.bg,
          fillOpacity: polygonOpacity,
          strokeOpacity: stormZoneStyles.borderOpacity,
          strokeWeight: zoomLevel - 12 > 0 ? zoomLevel - 12 : 1.5,
          draggable: false,
          editable: false,
        }}
      />
    </>
  );
};

StormZonePolygon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  shape: PropTypes.array,
  zoomLevel: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

StormZonePolygon.defaultProps = {
  shape: [],
};

export default StormZonePolygon;
