import { handleActions } from "redux-actions";
import {
  icpStatusFailure,
  icpStatusRequest,
  icpStatusSuccess,
} from "./icps.actions";

export const INITIAL_STATE = {
  isLoading: false,
  error: undefined,
  icpStatus: {
    icpNumber: "",
    icpLocation: null,
    outage: null,
    stormZone: null,
  },
};

export const reducer = handleActions(
  {
    [icpStatusRequest]: state => ({
      ...state,
      isLoading: true,
      error: undefined,
    }),
    [icpStatusSuccess]: (state, action) => ({
      ...state,
      icpStatus: action.payload.icpStatus,
      isLoading: false,
      error: undefined,
    }),
    [icpStatusFailure]: (state, action) => ({
      ...state,
      isLoading: false,
      icpStatus: {
        icpNumber: "",
        icpLocation: null,
        outage: null,
        stormZone: null,
      },
      error: action.payload,
    }),
  },
  INITIAL_STATE,
);

export default reducer;
