/* eslint-disable react/default-props-match-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { bottom, right, top, left } from "styled-system";
import styled from "styled-components/macro";
import { ErrorIcon } from "../../FieldErrors";

const Container = styled(Flex)`
  line-height: 0; // reset of line-height helps visually center things vertically
  position: absolute;
  right: 0;
  ${top};
  ${right};
  ${bottom};
  ${left};
`;

export const InlineError = ({ name, ...props }) => (
  <Container {...props}>
    <ErrorIcon />
  </Container>
);
InlineError.propTypes = {
  ...Flex.propTypes,
  name: PropTypes.string.isRequired,
  "data-testid": PropTypes.string,
};
InlineError.defaultProps = {
  justifyContent: "center",
  alignItems: "center",
  "data-testid": "InlineError",
};
