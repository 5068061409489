import get from "lodash/get";
import { createSelector } from "reselect";

export const getIsFetching = createSelector(
  [state => state.customers.isFetching],
  isFetching => !!isFetching,
);

export const getCustomer = createSelector(
  [state => state.customers.byId, state => state.customers.selected],
  (customers, id) => get(customers, id, null),
);

export const getCustomerError = createSelector(
  [state => state.customers.error],
  errorMessage => errorMessage,
);

export const getCustomerUpdateSuccessMessage = createSelector(
  [state => state.customers.successMessage],
  message => message || null,
);
