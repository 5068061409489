import { handleActions } from "redux-actions";
import {
  stormZoneByIdSuccess,
  stormZoneByIdFailure,
  stormZoneByIdRequest,
  clearStormZoneDetails,
} from "./storm-zones-id.actions";

export const INITIAL_STATE = {
  isFetching: false,
  stormZone: null,
  error: undefined,
};

export const reducer = handleActions(
  {
    [stormZoneByIdRequest]: state => ({
      ...state,
      isFetching: true,
      error: undefined,
    }),
    [stormZoneByIdSuccess]: (state, action) => ({
      ...state,
      isFetching: false,
      stormZone: action.payload.zoneDetails,
      error: undefined,
    }),
    [stormZoneByIdFailure]: (state, action) => ({
      ...state,
      isFetching: false,
      stormZone: null,
      error: action.payload,
    }),
    [clearStormZoneDetails]: state => ({
      ...state,
      isFetching: false,
      stormZone: null,
      error: undefined,
    }),
  },
  INITIAL_STATE,
);

export default reducer;
