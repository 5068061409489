/* eslint-disable no-unreachable,arrow-body-style */
import client from "../client";

export const ENDPOINT = "/outagereporter/customers";

export class CustomersService {
  static getCustomer = () => client.get(ENDPOINT);

  static saveDetails = (values, oldValues, currentPassword, passwordless) => {
    // return new Promise(resolve => {
    //   setTimeout(() => resolve({ status: 200 }), 2000);
    // });

    return client.put(ENDPOINT, {
      currentPassword,
      values,
      oldValues,
      passwordless,
    });
  };
}
