import styled, { css } from "styled-components/macro";
import { ifProp } from "styled-tools";

const Image = styled.img`
  display: block;
  max-width: 100%;
  height: auto;

  ${ifProp(
    "center",
    css`
      margin-left: auto;
      margin-right: auto;
    `,
  )};
`;

export default Image;
