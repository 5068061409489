import React from "react";
import PropTypes from "prop-types";
import { or, explicitNull } from "airbnb-prop-types";
import { Flex, Box, Text } from "rebass";
import { Button } from "../../../components/Button";
import { DateFormat } from "../../../components/DateFormat";
import { DATE_FORMAT_FULL } from "../../../common/date-formatter";

export const EventHistoryItem = ({
  timestamp,
  url,
  id,
  children,
  ...props
}) => (
  <Flex
    aria-label="Outage Event"
    aria-describedby="eventLabel"
    data-testid="EventHistory__Item"
    my={3}
    {...props}
    flexDirection="column"
    flexWrap="nowrap"
    alignItems="stretch"
  >
    <Box>
      <Text as="p" fontSize={0} mt={0} mb={1} id={id}>
        <DateFormat format={DATE_FORMAT_FULL} time={timestamp} />
      </Text>
    </Box>
    <Box>
      <Text id="eventLabel" as="p" fontSize={16} lineHeight={1.375} m={0}>
        {children}
      </Text>
    </Box>
    {url && (
      <Box>
        <Text as="p" fontSize={0} mb={0} mt={1}>
          <Button variant="text" href={url}>
            FIND OUT MORE &gt;
          </Button>
        </Text>
      </Box>
    )}
  </Flex>
);

export const propTypes = {
  timestamp: PropTypes.string.isRequired,
  url: or([explicitNull(), PropTypes.string]).isRequired,
  children: PropTypes.node.isRequired,
};

EventHistoryItem.propTypes = propTypes;
