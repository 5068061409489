/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "rebass";
import { or, explicitNull } from "airbnb-prop-types";
import ReportHeader from "./_/ReportHeader";
import ReportDetail from "./_/ReportDetail";
import ReportButtons from "./_/ReportButtons";

export const Report = ({
  outage: {
    outageId,
    outageStart,
    address,
    etrStart,
    etrEnd,
    updatedAt,
    subscription,
    id,
  },
  onSubscriptionClick,
  type,
  label,
}) => (
  <Flex as="article" flexDirection="column" data-testid="Report">
    <Box>
      <ReportHeader
        subscription={subscription}
        outageId={outageId}
        outageStart={outageStart}
      />
    </Box>
    <Box>
      <ReportDetail
        address={address}
        etrStart={etrStart}
        etrEnd={etrEnd}
        updatedAt={updatedAt}
        subscription={subscription}
        type={type}
        label={label}
        onSubscriptionClick={onSubscriptionClick}
      />
    </Box>
    <Box>
      <ReportButtons icpNumber={id} />
    </Box>
  </Flex>
);

Report.propTypes = {
  outage: PropTypes.shape({
    id: PropTypes.string,
    etrStart: or([explicitNull(), PropTypes.string]).isRequired,
    etrEnd: or([explicitNull(), PropTypes.string]).isRequired,
    updatedAt: or([explicitNull(), PropTypes.string]).isRequired,
    address: PropTypes.shape({
      street: PropTypes.string,
      suburb: PropTypes.string,
    }).isRequired,
    subscription: PropTypes.shape({
      ticketId: PropTypes.string.isRequired,
      updateByTxt: PropTypes.bool.isRequired,
      updateByEmail: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  onSubscriptionClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Report;
