import PropTypes from "prop-types";
import { or, explicitNull } from "airbnb-prop-types";
import { getFormattedDateTimeRange } from "../../common/date-formatter";

export const getWorkCompleteStatus = ({ start = null, end = null }) => {
  // exit early
  // TODO - refactor to return an empty string or undefined instead when returning early, as returning a non-date string or a date
  //  string in the same function is surprising. The non-date values should be handled by the function's caller
  if (end === null) {
    return "to be confirmed";
  }

  // build correct UI markup
  return getFormattedDateTimeRange(start, end).toLowerCase();
};

export const ETRRange = ({ start, end }) =>
  getWorkCompleteStatus({ start, end });

ETRRange.propTypes = {
  start: or([PropTypes.string, explicitNull()]),
  end: or([PropTypes.string, explicitNull()]),
};
ETRRange.defaultProps = {
  start: null,
  end: null,
};
