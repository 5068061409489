import React from "react";
import PropTypes from "prop-types";
import { Box } from "rebass";
import { LoginErrorMessage } from "../LoginErrorMessage";
import { SuccessMessage } from "../SuccessMessage";

const LoginMessage = ({ error, success }) => {
  if (error) {
    return <LoginErrorMessage error={error} />;
  }
  if (success) {
    return <SuccessMessage success={success} />;
  }
  return <Box width={1} my={20} role="alert" aria-atomic="true" />;
};

LoginMessage.propTypes = {
  success: PropTypes.string,
  error: PropTypes.string,
};

LoginMessage.defaultProps = {
  success: undefined,
  error: undefined,
};

export default LoginMessage;
