/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Text, Box, Flex } from "rebass";
import { connect as formikConnect, FastField as Field } from "formik";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Checkbox } from "../../../components/Checkbox";
import { Button } from "../../../components/Button";

import { NotificationTimesMessage } from "../../../components/NotificationTimesMessage";
import { PrivacyPolicy } from "../../../components/PrivacyPolicy";
import { isLoggedIn } from "../../../redux/modules/login";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { trackReportEvent } from "../eventTracking";
import CancelButton from "./CancelButton";

export const DEFAULT_FIELD_VALUES = {
  notifications: {
    byTxt: undefined,
    byEmail: undefined,
  },
};

export class NotificationFields extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  handleSubmit = async () => {
    const {
      formik,
      match: {
        url,
        params: { icpNumber },
      },
    } = this.props;

    const formValidity = formik.isValid;
    const errs = formValidity ? "" : `- ${JSON.stringify(formik.errors)}`;

    await Promise.all([
      trackReportEvent(icpNumber, url, "Clicked 'Send Report' button")(),
      trackReportEvent(
        icpNumber,
        url,
        `Form Submission: ${formValidity ? "Valid" : "Invalid"} ${errs}`,
        true,
      )(),
    ]);
  };

  render() {
    // eslint-disable-next-line no-shadow
    const { isLoggedIn, error, isLoading } = this.props;

    if (!isLoggedIn) {
      return null;
    }

    return (
      <Flex
        flexDirection="column"
        data-testid="ReportOutage__Form__Notifications"
      >
        <Box width={1} mb={20} role="alert">
          <ErrorMessage message={error} fontSize={1} shouldReveal />
        </Box>
        <Box width={1} mb={10}>
          <Text textAlign="center" fontSize={5}>
            Nearly done!
          </Text>
        </Box>

        <Box width={1} mt={10}>
          <Text fontSize={2} mb={15}>
            Keep me updated via...
          </Text>
          <Flex width={1}>
            <Box mr={10}>
              <Field
                name="notifications.byTxt"
                label="TXT message"
                component={Checkbox}
              />
            </Box>
            <Box>
              <Field
                name="notifications.byEmail"
                label="email"
                component={Checkbox}
              />
            </Box>
          </Flex>
        </Box>
        <Box width={1}>
          <NotificationTimesMessage />
        </Box>
        <Box width={1} my={20}>
          <PrivacyPolicy />
        </Box>

        <Box alignSelf="center" width={1}>
          <Button
            onClick={this.handleSubmit}
            key="submit"
            type="submit"
            variant="dark"
            block
            isLoading={isLoading}
            disabled={isLoading}
          >
            Send Report
          </Button>
        </Box>
        <Box width={1} my={20}>
          <Text textAlign="center" fontSize={0}>
            By continuing you agree to Vector&apos;s
            <br />
            <Button
              key="termsOfUse"
              variant="text"
              href="https://www.vector.co.nz/terms-of-use"
            >
              terms of use
            </Button>{" "}
            and{" "}
            <Button
              key="privacyPolicy"
              variant="text"
              href="https://www.vector.co.nz/privacy-policy"
            >
              privacy policy
            </Button>
          </Text>
        </Box>
        <CancelButton />
      </Flex>
    );
  }
}
NotificationFields.propTypes = {
  match: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
};

NotificationFields.defaultProps = {
  isLoggedIn: false,
  error: undefined,
  isLoading: false,
};

const enhance = compose(
  withRouter,
  formikConnect,
  connect(state => ({
    isLoggedIn: isLoggedIn(state),
    error: state.reports.error,
    isLoading: state.reports.loading,
  })),
);

export default enhance(NotificationFields);
