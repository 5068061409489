/* istanbul ignore file the */
import { combineReducers } from "redux";

// Import Slice Reducers from ./modules/** folders
import auth from "./login";
import outages from "./outages";
import location from "./location";
import addresses from "./addresses";
import customers from "./customers";
import register from "./registration";
import modal from "./modal";
import forms from "./forms";
import reports from "./reports";
import subscription from "./subscription";
import lsevents from "./lsevent";
import eventHistory from "./event-history";
import overlays from "./overlays";
import plannedOutages from "./planned-outages";
import stormZones from "./storm-zones";
import stormZonesId from "./storm-zones-id";
import icpStatus from "./icps";

// Export that is passed into the createStore fn
export const rootReducer = combineReducers({
  auth,
  location,
  addresses,
  customers,
  outages,
  register,
  modal,
  forms,
  reports,
  subscription,
  lsevents,
  eventHistory,
  overlays,
  plannedOutages,
  stormZones,
  stormZonesId,
  icpStatus,
});
