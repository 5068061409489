import { Box, Flex, Text } from "rebass";
import PropTypes from "prop-types";
import React from "react";
import { Button } from "../../Button";
import StyledSwitch from "./StyledSwitch";

const ButtonSubscriptionControl = ({
  disabled,
  handleToogleChange,
  onClickChangeButton,
  checked,
  checkedText,
}) => (
  <Flex
    flex="1 1 auto"
    flexDirection="column"
    data-testid="SubscriptionControl--button"
  >
    <Flex flex="0 1 auto" alignItems="center">
      <Box pr={15}>
        <StyledSwitch
          color="default"
          checked={checked}
          disabled={disabled}
          inputProps={{
            "data-testid": "toogle",
          }}
          onChange={handleToogleChange}
        />
      </Box>
      <Box>
        <Text fontSize={2}>
          updates to text or email are
          <Text as="span" ml={1} fontWeight="bold">
            {checkedText}
          </Text>
        </Text>
      </Box>
    </Flex>
    <Box mt={2}>
      <Button
        variant="dark"
        onClick={onClickChangeButton}
        block
        data-testid="subscriptionControl__change_btn"
      >
        get updates for this outage
      </Button>
    </Box>
  </Flex>
);

/* istanbul ignore next */
ButtonSubscriptionControl.defaultProps = {
  disabled: false,
};

ButtonSubscriptionControl.propTypes = {
  disabled: PropTypes.bool,
  handleToogleChange: PropTypes.func.isRequired,
  onClickChangeButton: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  checkedText: PropTypes.string.isRequired,
};

export default ButtonSubscriptionControl;
