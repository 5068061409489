import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { logoutRequest } from "../../redux/modules/login";

export class LogoutLinkContainer extends React.Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { logout } = this.props;
    logout();
  }

  render() {
    return <></>;
  }
}

/* istanbul ignore next */
const enhance = compose(
  // Get Routing Props for outage id
  withRouter,
  // Then run redux connect to query for said ID in store
  connect(
    () => ({}),
    {
      logout: logoutRequest,
    },
  ),
);

export default enhance(LogoutLinkContainer);
