import React from "react";
import PropTypes from "prop-types";
import { or } from "airbnb-prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import {
  getCurrentLSE,
  lsEventDismiss,
  propTypes,
} from "../../redux/modules/lsevent";
import {
  getHash,
  getIsDismissed,
} from "../../redux/modules/lsevent/lsevent.selectors";
import { Modal } from "../Modal";
import LSEWarningContent from "./LSEWarningContent";

export const LSE_MODAL_ID = "lse-warning";

export class LSEModal extends React.PureComponent {
  static propTypes = {
    hash: or([PropTypes.string, PropTypes.number]).isRequired,
    lse: or([PropTypes.object, propTypes]).isRequired,
    isDismissed: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  handleMount = modalState => {
    modalState.open();
  };

  handleClose = () => {
    const { hash, onClose } = this.props;
    onClose(hash);
  };

  render() {
    const {
      lse: { content },
      isDismissed,
    } = this.props;
    const shouldMount = !isDismissed && !isEmpty(content);

    return (
      shouldMount && (
        <Modal
          name={LSE_MODAL_ID}
          onMount={this.handleMount}
          onClose={this.handleClose}
        >
          {({ close }) => <LSEWarningContent close={close} content={content} />}
        </Modal>
      )
    );
  }
}

// istanbul ignore next
const enhance = compose(
  connect(
    state => ({
      lse: getCurrentLSE(state),
      hash: getHash(state),
      isDismissed: getIsDismissed(state),
    }),
    {
      onClose: lsEventDismiss,
    },
  ),
);

export default enhance(LSEModal);
