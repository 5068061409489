import { createAction } from "redux-actions";
import invariant from "tiny-invariant";
import isPlainObject from "lodash/isPlainObject";

export const SAVE_SUBSCRIPTION_REQUEST = "app/subscriptions/SAVE/REQUEST";
export const SAVE_SUBSCRIPTION_SUCCESS = "app/subscriptions/SAVE/SUCCESS";
export const SAVE_SUBSCRIPTION_FAILURE = "app/subscriptions/SAVE/FAILURE";

export const GET_SUBSCRIPTION_REQUEST = "app/subscriptions/GET/REQUEST";
export const GET_SUBSCRIPTION_SUCCESS = "app/subscriptions/GET/SUCCESS";
export const GET_SUBSCRIPTION_FAILURE = "app/subscriptions/GET/FAILURE";

export const saveSubscriptionPreferencesRequest = createAction(
  SAVE_SUBSCRIPTION_REQUEST,
  ({ outageId, icpNumber, channels }) => ({
    outageId,
    icpNumber,
    channels,
  }),
  (_, meta) => meta,
);

export const saveSubscriptionPreferencesSuccess = createAction(
  SAVE_SUBSCRIPTION_SUCCESS,
);

export const saveSubscriptionPreferencesFailure = createAction(
  SAVE_SUBSCRIPTION_FAILURE,
);

export const getSubscriptionRequest = createAction(
  GET_SUBSCRIPTION_REQUEST,
  icpNumber => ({ icpNumber }),
  (_, meta) => meta,
);

export const getSubscriptionSuccess = createAction(
  GET_SUBSCRIPTION_SUCCESS,
  (icpNumber, data) => {
    invariant(
      ["string", "number"].includes(typeof icpNumber),
      "Must provide an icp ID when fetching subscription data",
    );

    invariant(
      isPlainObject(data),
      `data must be an object but was called with "${typeof data}" .`,
    );

    return {
      icpNumber,
      ...data,
    };
  },
);

export const getSubscriptionFailure = createAction(GET_SUBSCRIPTION_FAILURE);
