import { createAction } from "redux-actions";

// These actions are used to talk to the location API with GPS coords as params
export const ADDRESS_BY_COORDS_REQUEST = "address/byCoords/REQUEST";
export const ADDRESS_BY_COORDS_SUCCESS = "address/byCoords/SUCCESS";
export const ADDRESS_BY_COORDS_FAILURE = "address/byCoords/FAILURE";
export const requestAddressByCoords = createAction(ADDRESS_BY_COORDS_REQUEST);
export const successAddressByCoords = createAction(ADDRESS_BY_COORDS_SUCCESS);
export const failureAddressByCoords = createAction(ADDRESS_BY_COORDS_FAILURE);

// These actions are used to talk to the location API with partial address data for the type-ahead field values
export const ADDRESS_BY_TEXT_REQUEST = "address/byText/REQUEST";
export const ADDRESS_BY_TEXT_SUCCESS = "address/byText/SUCCESS";
export const ADDRESS_BY_TEXT_FAILURE = "address/byText/FAILURE";
export const requestAddressByText = createAction(ADDRESS_BY_TEXT_REQUEST);
export const successAddressByText = createAction(ADDRESS_BY_TEXT_SUCCESS);
export const failureAddressByText = createAction(ADDRESS_BY_TEXT_FAILURE);

export const SELECT_ADDRESS = "address/SELECT";
export const selectAddress = createAction(SELECT_ADDRESS, id => ({ id }));
export const CLEAR_ADDRESS = "address/CLEAR";
export const clearAddress = createAction(CLEAR_ADDRESS);
export const CLEAR_ADDRESS_ID = "address/id/CLEAR";
export const clearAddressId = createAction(CLEAR_ADDRESS_ID);

export const ADDRESS_COORDS_REQUEST = "address/coordinates/REQUEST";
export const ADDRESS_COORDS_SUCCESS = "address/coordinates/SUCCESS";
export const ADDRESS_COORDS_FAILURE = "address/coordinates/FAILURE";

export const UPDATE_ADDRESS_TEXT = "address/text/UPDATE";
export const updateAddressText = createAction(UPDATE_ADDRESS_TEXT, address => ({
  address,
}));

export const requestAddressCoords = createAction(
  ADDRESS_COORDS_REQUEST,
  id => ({ id }),
);

export const requestAddressCoordsSuccess = createAction(
  ADDRESS_COORDS_SUCCESS,
  data => data,
  (data, icpNumber) => ({
    icpNumber,
  }),
);

export const requestAddressCoordsFailure = createAction(ADDRESS_COORDS_FAILURE);
