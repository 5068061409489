/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { lsEventRequest } from "../redux/modules/lsevent";

const RouteWrapper = WrappedComponent => props => {
  const { checkLSE } = props;

  useEffect(() => {
    checkLSE();
  }, []);

  return <WrappedComponent {...props} />;
};

export const withLSECheck = compose(
  withRouter,
  connect(null, {
    checkLSE: lsEventRequest,
  }),
  RouteWrapper,
);
