import React from "react";
import { Box, Flex } from "rebass";
import { SizeMe } from "react-sizeme";
import styled from "styled-components";
import { AppBar } from "../components/AppBar/index";
import { Page } from "../components/Page";
import { MapContainer } from "../containers/MapContainer";

const AppBarButton = styled(AppBar.Button)`
  text-transform: lowercase;
`;

const AppBarTitle = styled(AppBar.Title)`
  font-weight: normal;
  text-transform: lowercase;
`;

const MapAddressView = () => (
  <>
    <Flex flexDirection="column" width={1}>
      <Box flex="0 1 0">
        <Page title="Outage Map" />
        <AppBar>
          <AppBarButton href="/">Home</AppBarButton>
          <AppBarTitle>Outage Map</AppBarTitle>
        </AppBar>
      </Box>
      <SizeMe monitorHeight>
        {/* istanbul ignore next */ ({ size }) => (
          <Flex
            flexDirection="column"
            alignItems="center"
            flex="1 1 auto"
            width={1}
            height={1}
            bg="light"
            css="position: relative;"
          >
            <MapContainer size={size} />
          </Flex>
        )}
      </SizeMe>
    </Flex>
  </>
);
MapAddressView.propTypes = {};

export default MapAddressView;
