import { css } from "styled-components/macro";
import { rgba } from "polished";
import merge from "lodash/merge";

export const makeTheme = (base, ...exts) => {
  let theme = base;

  if (exts.length) {
    theme = exts.reduce(
      (t, ext) => merge(t, typeof ext === "function" ? ext(t) : ext),
      theme,
    );
  }

  return theme;
};

export const createBreakpoints = theme => {
  const pointValues = theme.breakpoints;
  const pointNames = theme.meta.responsiveKeys;

  // Individual MQ Function
  function makeQuery(match, label, size) {
    return (...args) => css`
      @media (${match}: ${size / 16}em) {
        ${css(...args)}
      }
    `;
  }

  // Uses individual func to generate a set for all points
  function makeQuerySet(matcher, labels = pointNames, sizes = pointValues) {
    return sizes.reduce((acc, size, i) => {
      const label = labels[i];
      return {
        ...acc,
        [label]: makeQuery(matcher, label, size),
      };
    }, {});
  }

  // key names indicate usage, e.g.
  //  - props.theme.upFrom.sm`display:block` => show on small and up
  //  - props.theme.upTo.sm`display:none` => hide on small devices, not larger
  return {
    upTo: makeQuerySet("max-width"),
    upFrom: makeQuerySet("min-width"),
  };
};

const base = {
  name: "OutageApp",
  meta: {
    responsiveKeys: ["sm", "md", "lg"],
  },
  breakpoints: [
    // Same as Bootstrap 4
    // (https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints)
    576, // Roughly mobile in landscape mode
    768, // Roughly tablet portrait
    992, // Roughly tablet landscape and larger
  ],
  fontSizes: [12, 14, 18, 20, 22, 24, 26, 40],
  colors: {
    primary: "#000840",
    secondary: "#0DCF5A",
    accent: "#1F79FF",
    accessible: "#1E6EFA",
    white: "#ffffff",
    light: "#f8f8f8",
    gray: "#dedede",
    dark: "#000840",
    orange: "#ff6c0e",
    warning: "#f5a400",
    red: "#FF0000",
    urgentRed: "#E64B38",
    green: "#0DCF5A",
    grayWhite: "#a09f9f",
    lightGray: "#9CA5AD",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    display: "Menlo, monospace",
    body: "system-ui, sans-serif",
    mono: null,
  },
};

const aliases = t => ({
  colors: {
    text: t.colors.dark,
    link: t.colors.accessible,
    background: t.colors.white,
    skyBlue: t.colors.accent,
    error: t.colors.orange,
    success: t.colors.secondary,
    disabled: rgba(`${t.colors.dark}`, 0.25),
    deemphasised: rgba(`${t.colors.white}`, 0.5),
    shadow: rgba("#000", 0.12),
  },
});

export default makeTheme(base, aliases, createBreakpoints);
