import { trackEvent } from "../../common/analytics";

export const STEP_DISCLAIMER = "Step: Disclaimer";
export const STEP_ISSUE = "Step: Issue Type";
export const STEP_WATER = "Step: Water Warning";
export const STEP_GAS = "Step: Gas Warning";
export const STEP_OTHER = "Step: Issue Details - Other";
export const STEP_ACCESS = "Step: Access Details";
export const STEP_NOTIFICATIONS = "Step: Notifications";
export const STEP_SUCCESS = "Step: Confirmation";

export const stripEndingSlash = url => url.replace(/(\/*)$/gi, "");
export const mapStepToGAEventLabel = (url, message) => {
  const path = stripEndingSlash(url);

  const gaEventNames = {
    [STEP_DISCLAIMER]: `${STEP_DISCLAIMER} - ${message}`,
    [STEP_ISSUE]: `${STEP_ISSUE} - ${message}`,
    [STEP_WATER]: `${STEP_WATER} - ${message}`,
    [STEP_GAS]: `${STEP_GAS} - ${message}`,
    [STEP_OTHER]: `${STEP_OTHER} - ${message}`,
    [STEP_ACCESS]: `${STEP_ACCESS} - ${message}`,
    [STEP_NOTIFICATIONS]: `${STEP_NOTIFICATIONS} - ${message}`,
    [STEP_SUCCESS]: `${STEP_SUCCESS} - ${message}`,
  };

  function matchStepFromPath(p) {
    const pathToStepMap = {
      "/create$": STEP_DISCLAIMER,
      "/details$": STEP_ISSUE,
      "/details/water$": STEP_WATER,
      "/details/gas$": STEP_GAS,
      "/details/other$": STEP_OTHER,
      "/access$": STEP_ACCESS,
      "/notifications$": STEP_NOTIFICATIONS,
      "/confirmation$": STEP_SUCCESS,
    };

    const matches = Object.entries(pathToStepMap).filter(([regex]) => {
      const re = RegExp(regex, "gi");
      return re.test(p);
    });

    return matches && matches.length ? matches[0][1] : undefined;
  }

  const step = matchStepFromPath(path);
  return step ? gaEventNames[step] : message;
};

export const trackReportEvent = (
  icpNumber,
  path,
  label,
  nonInteraction,
) => () => {
  let fieldObject = {
    category: "Report",
    action: `Reporting outage for ${icpNumber}`,
    label: mapStepToGAEventLabel(path, label),
  };

  if (nonInteraction) {
    fieldObject = {
      ...fieldObject,
      nonInteraction: true,
    };
  }

  return new Promise(resolve => {
    trackEvent(fieldObject);
    resolve();
  });
};
