import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Text, Box, Flex } from "rebass";
import { Formik, Form, Field } from "formik";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Button } from "../../../components/Button";
import { trackReportEvent } from "../eventTracking";

import {
  isRequired,
  isValidEmail,
  validateWith,
} from "../../../components/RegistrationFormFields/validate";
import { TextField } from "../../../components/Form";
import { isLoggedIn, errorMessage } from "../../../redux/modules/login";
import { ErrorMessage } from "../../../components/ErrorMessage";

import { RegistrationContainer } from "../../RegistrationContainer";
import CancelButton from "./CancelButton";

const formDefaultValues = {
  email: "",
};

export class UnauthenticatedForm extends React.Component {
  state = { isRegisterFormOpen: false };

  handleSubmit = (values, { setSubmitting }) => {
    const {
      history,
      onSendOTP,
      match: { url },
    } = this.props;

    const meta = {
      history,
      redirectTo: "/login/auth",
      navigateBack: url,
      gaCategory: "Report",
    };
    const { email } = values;
    onSendOTP({ email }, meta);

    setSubmitting(false);
  };

  handleToggleShowRegisterForm = () => {
    const { isRegisterFormOpen } = this.state;
    this.setState({ isRegisterFormOpen: !isRegisterFormOpen });
  };

  componentWillUnmount() {
    const { clearLoginError } = this.props;
    clearLoginError();
  }

  render() {
    const {
      // eslint-disable-next-line no-shadow
      isLoggedIn,
      error,
      isLoading,
      match: {
        url,
        params: { icpNumber },
      },
    } = this.props;
    const { isRegisterFormOpen } = this.state;

    if (isLoggedIn) {
      return null;
    }

    if (isRegisterFormOpen) {
      return (
        <>
          <RegistrationContainer {...this.props} navigateBack={url} />
          <CancelButton />
        </>
      );
    }

    return (
      <Flex flexDirection="column" alignItems="center">
        <Box width={[1, null, 3 / 4]}>
          <Formik
            initialValues={formDefaultValues}
            onSubmit={this.handleSubmit}
            render={() => (
              <Form aria-label="Login Form" data-testid="LoginForm" noValidate>
                <Flex flexDirection="column" width={1}>
                  <Box width={1} mb={20} role="alert">
                    <ErrorMessage message={error} fontSize={1} shouldReveal />
                  </Box>
                  <Box width={1}>
                    <Text textAlign="center" fontSize={5} as="p" m={0} mb={3}>
                      do you have an account?
                    </Text>
                  </Box>
                  <Box>
                    <Field
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                      validate={validateWith(isRequired, isValidEmail)}
                      component={TextField}
                      required
                      autoComplete="off"
                      note="We'll send you a verification code to this email"
                    />
                  </Box>
                  <Box width={1} mt={3}>
                    <Button
                      block
                      data-testid="LoginForm__Button--submit"
                      variant="dark"
                      type="submit"
                      disabled={isLoading}
                      isLoading={isLoading}
                      gaTrack={trackReportEvent(
                        icpNumber,
                        url,
                        "Clicked 'report login button' button",
                      )}
                    >
                      Next
                    </Button>
                  </Box>
                  <Box mt={5} mb={2}>
                    <Text fontSize={2} fontWeight="bold" textAlign="center">
                      New user?
                    </Text>
                  </Box>
                  <Box mb={3}>
                    <Text fontSize={2} textAlign="center">
                      With an account you can report your outage and we can send
                      you status updates
                    </Text>
                  </Box>
                  <Box>
                    <Button
                      onClick={this.handleToggleShowRegisterForm}
                      data-testid="LoginForm__RegisterButton"
                      block
                      variant="darkBordered"
                      type="button"
                      disabled={isLoading}
                      isLoading={isLoading}
                      gaTrack={trackReportEvent(
                        icpNumber,
                        url,
                        "Clicked 'report register now button' button",
                      )}
                    >
                      register now
                    </Button>
                  </Box>
                </Flex>
              </Form>
            )}
          />
        </Box>
        <CancelButton />
      </Flex>
    );
  }
}
UnauthenticatedForm.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  onSendOTP: PropTypes.func.isRequired,
  clearLoginError: PropTypes.func.isRequired,
};

UnauthenticatedForm.defaultProps = {
  isLoggedIn: false,
  error: undefined,
  isLoading: false,
};

const enhance = compose(
  withRouter,
  connect(state => ({
    isLoggedIn: isLoggedIn(state),
    error: errorMessage(state),
    isLoading: state.auth.isLoading,
  })),
);

export default enhance(UnauthenticatedForm);
