import { handleActions } from "redux-actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  loginRequest,
  loginSuccess,
  loginFailure,
  clearLoginError,
  logoutFailure,
  logoutSuccess,
  logoutRequest,
  forgotPasswordSuccess,
  forgotPasswordRequest,
  forgotPasswordFailure,
  sendOneTimePasswordRequest,
  oneTimePasswordSent,
  sendOneTimePasswordFailure,
} from "./login.actions";

export const reducer = handleActions(
  {
    [sendOneTimePasswordRequest]: {
      next: (state, action) => ({
        ...state,
        email: action.payload.email,
        isLoading: true,
      }),
      throw: () => ({
        isLoading: false,
      }),
    },
    [oneTimePasswordSent]: {
      next: (state, action) => ({
        ...state,
        isLoading: false,
        error: undefined,
        customerValues: action.meta.customerValues,
      }),
    },
    [sendOneTimePasswordFailure]: {
      throw: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
    },
    [loginRequest]: {
      next: state => ({
        ...state,
        isLoading: true,
      }),
      throw: () => ({
        isLoading: false,
      }),
    },
    [loginSuccess]: {
      next: (state, action) => ({
        ...state,
        userInfo: action.payload,
        isLoading: false,
        loggedIn: true,
        error: undefined,
      }),
    },
    [loginFailure]: {
      throw: (state, action) => ({
        error: action.payload,
        email: state.email,
        isLoading: false,
        loggedIn: false,
      }),
    },
    [clearLoginError]: {
      next: state => ({
        ...state,
        error: undefined,
      }),
    },
    [logoutSuccess]: {
      next: () => ({ isLoading: false, loggedIn: false, error: undefined }),
    },
    [logoutRequest]: {
      next: state => ({
        ...state,
        email: null,
        isLoading: true,
      }),
      throw: () => ({
        isLoading: false,
      }),
    },
    [logoutFailure]: {
      throw: (state, { payload: error }) => ({
        error: error.message,
        isLoading: false,
        loggedIn: false,
      }),
    },
    [forgotPasswordRequest]: (state, { payload }) => ({
      ...state,
      email: payload.email,
      isLoading: true,
    }),
    [forgotPasswordFailure]: {
      throw: (state, { payload: error }) => ({
        error: error.message,
        isLoading: false,
        loggedIn: false,
      }),
    },
    [forgotPasswordSuccess]: state => ({
      ...state,
      isLoading: false,
      resetSuccess: true,
    }),
  },
  {
    isLoading: false,
    loggedIn: false,
    resetSuccess: false,
    error: undefined,
    email: null,
    userInfo: {},
  },
);

const persistConfig = {
  key: "auth",
  storage,
  whitelist: ["loggedIn", "email"],
};

export default persistReducer(persistConfig, reducer);
