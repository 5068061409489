import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Box, Flex } from "rebass";
import { icpStatusRequest } from "../../redux/modules/icps";
import { PageWrapper } from "../../components/PageWrapper";
import { ErrorMessage } from "../../components/ErrorMessage";
import OutageContainer from "../OutageContainer/OutageContainer";
import { Loader } from "../../components/Loader";
import StormZoneDetailsContainer from "../StormZoneDetailsContainer/StormZoneDetailsContainer";

export class IcpStatusContainer extends Component {
  static propTypes = {
    icpStatus: PropTypes.shape({
      icpNumber: PropTypes.string,
      outage: PropTypes.shape({
        id: PropTypes.string,
        address: PropTypes.shape({
          street: PropTypes.string,
          suburb: PropTypes.string,
        }).isRequired,
        subscription: PropTypes.shape({
          ticketId: PropTypes.string.isRequired,
          updateByTxt: PropTypes.bool.isRequired,
          updateByEmail: PropTypes.bool.isRequired,
        }),
        progressionStatus: PropTypes.string.isRequired,
        outageReason: PropTypes.string,
      }),
      stormZone: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        message: PropTypes.string,
        active: PropTypes.bool,
        restorationState: PropTypes.string,
        estimatedTimeRestoration: PropTypes.string,
        center: PropTypes.shape({
          longitude: PropTypes.number,
          latitude: PropTypes.number,
        }),
        shape: PropTypes.object,
      }),
      icpLocation: PropTypes.shape({
        icpNumber: PropTypes.string,
        locationDisplay: PropTypes.string,
        locationPrefix: PropTypes.string,
        street: PropTypes.string,
        suburb: PropTypes.string,
        city: PropTypes.string,
        propertySeparator: PropTypes.string,
        transformerId: PropTypes.string,
        feederId: PropTypes.string,
        stormZoneId: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        geocodeStatus: PropTypes.string,
      }),
    }),
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    getIcpStatus: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  static defaultProps = {
    icpStatus: {},
    isLoading: false,
    error: undefined,
  };

  componentDidMount() {
    const { match, getIcpStatus } = this.props;

    const { params } = match;
    const { icpNumber } = params;

    getIcpStatus(icpNumber);
  }

  render() {
    const { isLoading, error, icpStatus, history } = this.props;

    const { outage, stormZone } = icpStatus;

    const renderLoading = () => (
      <PageWrapper metaTitle="outage map" title="outage map" history={history}>
        <Flex m="auto" data-testid="ICPStatus_Loader">
          <Box width={1}>
            <Loader />
          </Box>
        </Flex>
      </PageWrapper>
    );

    if (isLoading) {
      return renderLoading();
    }

    if (error) {
      return (
        <PageWrapper
          metaTitle="Location Error"
          title="Check an address"
          history={history}
        >
          <ErrorMessage message={error} shouldReveal />
        </PageWrapper>
      );
    }

    if (stormZone) {
      return <StormZoneDetailsContainer origin="address-search" />;
    }

    if (outage) {
      return <OutageContainer />;
    }

    return renderLoading();
  }
}

const enhance = compose(
  withRouter,
  connect(
    state => ({
      isLoading: state.icpStatus.isLoading,
      error: state.icpStatus.error,
      icpStatus: state.icpStatus.icpStatus,
    }),
    {
      getIcpStatus: icpStatusRequest,
    },
  ),
);

export default enhance(IcpStatusContainer);
