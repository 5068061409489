import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field, connect } from "formik";
import { Route, Switch } from "react-router-dom";
import { Box, Flex, Text } from "rebass";
import { RadioButton } from "../../../components/RadioButton";
import { trackReportEvent } from "../eventTracking";
import CancelButton from "./CancelButton";

import DetailsFieldOther from "./DetailsFieldOther";
import Warning from "./Warning";

export const DEFAULT_FIELD_VALUES = {
  details: {
    issue: undefined,
    other: undefined,
  },
};

const DetailFields = connect(
  ({
    match: {
      url,
      params: { icpNumber },
    },
    formik: { handleSubmit },
  }) => (
    <>
      <Box>
        <Text as="p" textAlign="center" fontSize={4} mt={0} mb={10}>
          How would you best describe your outage?
        </Text>
        <Text fontSize={2} textAlign="center">
          This will help us send the correct crew
        </Text>
      </Box>
      <Box my={20}>
        <Field
          name="details.issue"
          label="I have no electricity at all"
          value="No Power"
          type="radio"
          onClick={handleSubmit}
          component={RadioButton}
          labelProps={{ tabIndex: 0 }}
          gaTrack={trackReportEvent(icpNumber, url, "Clicked 'no electricity'")}
        />
      </Box>
      <Box my={20}>
        <Field
          name="details.issue"
          label="I have electricity in some parts of the property but not others"
          value="Partial Power"
          type="radio"
          onClick={handleSubmit}
          component={RadioButton}
          labelProps={{ tabIndex: 0 }}
          gaTrack={trackReportEvent(icpNumber, url, "Clicked 'partial power'")}
        />
      </Box>
      <Box my={20}>
        <Field
          name="details.issue"
          label="I have low power e.g. dim lights"
          value="Fluctuating Power"
          type="radio"
          onClick={handleSubmit}
          component={RadioButton}
          labelProps={{ tabIndex: 0 }}
          gaTrack={trackReportEvent(
            icpNumber,
            url,
            "Clicked 'fluctuating power'",
          )}
        />
      </Box>
      <Box my={20}>
        <Field
          name="details.issue"
          label="I have no hot water"
          value="no-hot-water"
          type="radio"
          onClick={handleSubmit}
          component={RadioButton}
          labelProps={{ tabIndex: 0 }}
          gaTrack={trackReportEvent(icpNumber, url, "Clicked 'no hot water'")}
        />
      </Box>
      <Box my={20}>
        <Field
          name="details.issue"
          label="My issue is gas related"
          value="gas-related"
          type="radio"
          onClick={handleSubmit}
          component={RadioButton}
          labelProps={{ tabIndex: 0 }}
          gaTrack={trackReportEvent(icpNumber, url, "Clicked 'no gas'")}
        />
      </Box>
      <Box my={20}>
        <Field
          name="details.issue"
          label="I'd like to explain my issue"
          value="Unknown"
          type="radio"
          onClick={handleSubmit}
          component={RadioButton}
          labelProps={{ tabIndex: 0 }}
          gaTrack={trackReportEvent(icpNumber, url, "Clicked 'explain issue'")}
        />
      </Box>
      <CancelButton />
    </>
  ),
);

export const DetailsFields = props => {
  const { match } = props;
  return (
    <Flex width={1} flexDirection="column">
      <Box>
        <Switch>
          <Route exact path={match.path} component={DetailFields} />
          <Route path={`${match.path}/other`} component={DetailsFieldOther} />
          <Route
            {...props}
            path={`${match.path}/water`}
            render={p => (
              <Warning
                {...p}
                title="Hot water issues need to be reported by phone."
              />
            )}
          />
          <Route
            {...props}
            path={`${match.path}/gas`}
            render={p => (
              <Warning
                {...p}
                title="For your safety, all gas issues must be reported by phone immediately."
              />
            )}
          />
        </Switch>
      </Box>
    </Flex>
  );
};
DetailsFields.propTypes = {
  formik: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const enhance = compose(connect);

export default enhance(DetailsFields);
