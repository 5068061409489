/* eslint-disable no-unused-vars,arrow-body-style */
import client from "../client";

export const ENDPOINT = "outagereporter/storm-zones";

export class StormZonesService {
  static getStormZones = async () => {
    const params = {
      active: true,
    };
    const { data } = await client.get(ENDPOINT, { params });
    return data;

    // return STORM_ZONES;
  };

  static getStormZone = async id => {
    const { data } = await client.get(`${ENDPOINT}/${id}`);
    return data;
  };
}
