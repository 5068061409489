import React from "react";
import styled from "styled-components/macro";
import { Flex } from "rebass";
import { height } from "styled-system";
import { ReactComponent as Icon } from "./tick.svg";

const IconWrapper = styled(Flex).attrs({
  justifyContent: "center",
  alignItems: "center",
  width: 35,
  height: 35,
  bg: "success",
  mr: 12,
})`
  ${height};
  border-radius: 100%;
  overflow: hidden;
`;

export const SuccessIcon = props => (
  <IconWrapper data-testid="SuccessIcon" {...props}>
    <Icon />
  </IconWrapper>
);

SuccessIcon.propTypes = {};
