import { useState } from "react";
import { getIn } from "formik";

export const useFieldState = (form, field) => {
  const [hasFocus, setFocus] = useState(false);
  const hasBeenTouched = !!getIn(form.touched, field.name);
  const hasError = !!getIn(form.errors, field.name);
  const isFilled = !!getIn(form.values, field.name);
  const shouldShowError = hasBeenTouched && hasError;

  // Determine the display mode/variant for the field
  let variant = "default";
  if (hasFocus) variant = "focus";
  if (shouldShowError) variant = "error";

  return {
    variant,
    // Field state
    isFilled,
    // Focus state
    hasFocus,
    setFocus,
    // Error state
    hasError,
    shouldShowError,
    // Touched state
    hasBeenTouched,
  };
};
