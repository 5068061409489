import { UNKNOWN, UNPLANNED, PLANNED } from "../../../components/EventType";
import {
  OUTSIDE_NETWORK as STATUS_OUTSIDE_NETWORK,
  PLANNED as STATUS_PLANNED,
  UNPLANNED as STATUS_UNPLANNED,
  REPORTED as STATUS_REPORTED,
  UNKNOWN as STATUS_UNKNOWN,
} from "../../../components/OutageStatusType";

const getOutageStatusType = (eventType, outageId, outsideVectorNetwork) => {
  if (outsideVectorNetwork) {
    return STATUS_OUTSIDE_NETWORK;
  }

  if (!outageId && eventType !== UNKNOWN) {
    return STATUS_REPORTED;
  }

  switch (eventType) {
    case UNPLANNED:
      return STATUS_UNPLANNED;

    case PLANNED:
      return STATUS_PLANNED;

    case UNKNOWN:
    default:
      return STATUS_UNKNOWN;
  }
};

export default getOutageStatusType;
