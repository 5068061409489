/* eslint-disable no-shadow */
import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { or } from "airbnb-prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import find from "lodash/find";
import { Box, Flex, Text } from "rebass";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { Loader } from "../../components/Loader";
import { Report } from "../../components/Report";
import { Button } from "../../components/Button";
import {
  getSubscribedOutages,
  isLoading,
} from "../../redux/modules/reports/reports.selectors";
import { reportFetchRequest } from "../../redux/modules/reports";

export class MyReportsContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    onFetchMyReports: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    outages: or([
      PropTypes.array,
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          address: PropTypes.shape({
            street: PropTypes.string,
            suburb: PropTypes.string,
          }).isRequired,
          subscription: PropTypes.shape({
            ticketId: PropTypes.string.isRequired,
            updateByTxt: PropTypes.bool.isRequired,
            updateByEmail: PropTypes.bool.isRequired,
          }),
        }),
      ),
    ]),
  };

  static defaultProps = {
    outages: [],
  };

  componentDidMount() {
    const { onFetchMyReports } = this.props;
    onFetchMyReports();
  }

  onSubscriptionChange = icpNumber => () => {
    const { history, match } = this.props;

    history.push(`/address/${icpNumber}/updates`, { redirectTo: match.url });
  };

  renderEmptyState = () => (
    <>
      <Text as="p" m={0} data-testid="MyReports__no-content--message">
        When you have open outage reports they will appear below.
      </Text>
      <Box
        mt={25}
        width={[1, null, 3 / 4]}
        data-testid="MyReports__no-content--cta"
      >
        <Button block variant="dark" href="/report">
          REPORT NOW
        </Button>
      </Box>
    </>
  );

  renderOutageReport = outage => {
    const { id, workStatus } = outage;

    const activeStatus =
      find(workStatus, {
        active: true,
      }) ||
      find(workStatus, {
        type: "RESOLVED",
        complete: true,
      }) ||
      workStatus[0];

    return (
      <Box
        role="listitem"
        width={1}
        mt={30}
        pb={10}
        key={id}
        css={`
          &:first-child {
            margin-top: 10px;
          }
        `}
      >
        <Report
          outage={outage}
          isLoading
          onSubscriptionClick={this.onSubscriptionChange(id)}
          type={activeStatus.type}
          label={activeStatus.label}
        />
      </Box>
    );
  };

  render() {
    const { isLoading, outages } = this.props;

    if (isLoading) {
      return <Loader data-testid="MyReports__Loader" />;
    }

    return (
      <>
        <Route
          path="/my-reports"
          exact
          component={() => (
            <Flex flexDirection="column" alignItems="center" role="list">
              {outages.length
                ? outages.map(outage => this.renderOutageReport(outage))
                : this.renderEmptyState()}
            </Flex>
          )}
        />
        <Route
          path="/my-reports/cancel"
          component={() => (
            <Flex
              flexDirection="column"
              alignItems="center"
              data-testid="MyReports__Cancel"
            >
              <Text as="p" m={0} textAlign="center">
                If you have reported an outage to us and need to cancel then
                please call us{" "}
              </Text>
              <Box mt={20} width={[1, null, 3 / 4]}>
                <Button block href="tel:0508832867">
                  CALL US NOW
                </Button>
              </Box>
              <Text as="p" my={20} fontSize={5}>
                0508 832 867
              </Text>
            </Flex>
          )}
        />
      </>
    );
  }
}

// istanbul ignore next
const enhance = compose(
  withErrorBoundary(),
  withRouter,
  connect(
    state => ({
      isLoading: isLoading(state),
      outages: getSubscribedOutages(state),
    }),
    {
      onFetchMyReports: reportFetchRequest,
    },
  ),
);

export default enhance(MyReportsContainer);
