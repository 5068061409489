/* eslint-disable react/prop-types */
/* istanbul ignore file */
import React from "react";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components/macro";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { LayoutManager } from "./components/LayoutManager";
import { LSEModal } from "./components/LSEWarningContent";
import { LogoutLinkContainer } from "./containers/LogoutLinkContainer";
import { LSEventProvider } from "./containers/LSEvent";
import { Route, PrivateRoute, enhance } from "./containers/Router";
import { trackEvent } from "./common/analytics";
import {
  BASE_URI as createReportUri,
  ReportOutageContainer,
} from "./containers/ReportOutageContainer";
import { GlobalCSS } from "./globalStyles";
import { persistor, store } from "./redux/store";
import theme from "./theme";

// Views:
import CheckAddressView from "./views/CheckAddress.view";
import MapAddressView from "./views/MapAddress.view";
import LoginView from "./views/Login.view";
import MyAccountView from "./views/MyAccount.view";
import RegistrationView from "./views/Registration.view";
import UpdateMeAboutOutageView from "./views/UpdateMeAboutOutage.view";
import MyReportsView from "./views/MyReports.view";
import PlannedOutagesView from "./views/PlannedOutages.view";
import LoginAuthenticationView from "./views/LoginAuthentication.view";
import MyAccountAuthenticationView from "./views/MyAccountAuthentication.view";
import { StormZoneDetailsContainer } from "./containers/StormZoneDetailsContainer";
import { IcpStatusContainer } from "./containers/IcpStatusContainer";

const HomeViewRedirect = props => {
  const { location } = props;
  const { pathname } = location;

  // analytics event to track redirects
  trackEvent({
    category: "Router",
    action: "Redirect",
    label: pathname,
  });

  return <Redirect to="/map" />;
};

/* istanbul ignore next */
export const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <LSEventProvider>
            <>
              <Helmet
                titleTemplate="%s | Outage Centre"
                defaultTitle="Vector Outage Centre"
              />
              {/* Init our router */}
              <Router>
                <>
                  <LayoutManager>
                    <Switch>
                      <PrivateRoute
                        path="/my-reports"
                        component={enhance(MyReportsView)}
                      />
                      <Route
                        path="/my-account/auth"
                        component={enhance(MyAccountAuthenticationView)}
                      />
                      <PrivateRoute
                        path="/my-account"
                        component={enhance(MyAccountView)}
                      />
                      <Route
                        path="/register/:id"
                        component={enhance(RegistrationView)}
                      />
                      <Route
                        path="/register"
                        component={enhance(RegistrationView)}
                      />
                      <PrivateRoute
                        path="/address/:icpNumber/updates"
                        component={enhance(UpdateMeAboutOutageView)}
                      />
                      <Route
                        path="/logout"
                        component={enhance(LogoutLinkContainer)}
                      />
                      <Route
                        path="/login/auth"
                        component={enhance(LoginAuthenticationView)}
                      />
                      <Route path="/login" component={enhance(LoginView)} />
                      {/* Address/Outage/StormZone Details View */}
                      <Route
                        path="/address/:icpNumber"
                        component={enhance(IcpStatusContainer)}
                      />
                      {/* Check Address Form */}
                      <Route
                        path="/address"
                        component={enhance(CheckAddressView)}
                      />
                      {/* Temporary Map Route */}
                      <Route path="/map" component={enhance(MapAddressView)} />
                      <Redirect from="/check" to="/address" />
                      {/* Create Report UI */}
                      <Route
                        exact
                        path="/report"
                        component={enhance(CheckAddressView)}
                      />
                      <Route
                        path={`${createReportUri}/:step?`}
                        component={enhance(ReportOutageContainer)}
                      />
                      {/* Home View */}
                      <Route path="/" exact render={HomeViewRedirect} />

                      <Route
                        path="/storm-zone/:id"
                        component={enhance(StormZoneDetailsContainer)}
                      />

                      <Route
                        path="/redirect-success"
                        component={() => <Redirect to="/" />}
                      />

                      <Route
                        path="/planned"
                        component={enhance(PlannedOutagesView)}
                      />

                      {/* Not Found */}
                      <Route render={HomeViewRedirect} />
                    </Switch>
                  </LayoutManager>
                  <LSEModal />
                </>
              </Router>
              {/* Injects our global CSS */}
              <GlobalCSS />
            </>
          </LSEventProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

export default App;
