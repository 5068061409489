import { createAction } from "redux-actions";

// These actions are used to determine the local device GPS coords
export const GET_DEVICE_COORDS_REQUEST = "location/device/REQUEST";
export const GET_DEVICE_COORDS_RECEIVE = "location/device/RECEIVE";
export const GET_DEVICE_COORDS_FAILURE = "location/device/FAILURE";
export const GET_CLICK_COORDS_RECEIVE = "location/click/RECEIVE";
export const getDeviceCoordsRequest = createAction(GET_DEVICE_COORDS_REQUEST);
export const getDeviceCoordsReceive = createAction(
  GET_DEVICE_COORDS_RECEIVE,
  position => ({
    lat: position.coords.latitude,
    long: position.coords.longitude,
  }),
);
export const getDeviceCoordsFailure = createAction(GET_DEVICE_COORDS_FAILURE);
export const getClickCoordsReceive = createAction(
  GET_CLICK_COORDS_RECEIVE,
  event => ({
    lat: event.latLng.lat(),
    long: event.latLng.lng(),
  }),
);
