import { useState, useCallback } from "react";

/**
 * useNotchedLabel
 *
 * @param {Object} state - The fieldState object from the `useFieldState()` hook
 * @param {boolean} state.isFilled - Whether the field has a non-empty value
 * @param {boolean} state.hasFocus - If the field is actively focused
 *
 * @return {[Object, number, boolean]}
 */
export const useNotchedLabel = ({ isFilled, hasFocus }) => {
  const isFloating = !!(isFilled || hasFocus);
  const [width, setWidth] = useState(0);

  const resizeListener = useCallback(
    node => {
      if (node !== null) {
        // istanbul ignore next: we cannot test the width of this in JSDOM
        setWidth(isFloating ? node.offsetWidth * 0.75 : 0);
      }
    },
    [isFloating],
  );

  return [resizeListener, width, isFloating];
};
