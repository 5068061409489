import React from "react";
import PropTypes from "prop-types";
import { Text } from "rebass";
import { Button } from "../Button";

export const LoginRedirectMessage = ({ onClick, ...props }) => (
  <Button onClick={onClick} block variant="ghost" py={10} {...props}>
    <Text as="p" m={0} fontSize={2} fontWeight="">
      Already have an account? Login here
    </Text>
  </Button>
);

LoginRedirectMessage.propTypes = {
  onClick: PropTypes.func.isRequired,
};
LoginRedirectMessage.defaultProps = {};

export default LoginRedirectMessage;
