import invariant from "tiny-invariant";
import client from "../client";

const ENDPOINT = "/outagereporter/icp-locations";

function mapIds(data) {
  return {
    byId: data.entities.addresses,
    allIds: data.result,
  };
}

export class LocationService {
  static getAddressByCoords = function getAddressByCoords(lat, long) {
    invariant(typeof lat !== "undefined", `Please provide a valid latitude`);
    invariant(typeof long !== "undefined", `Please provide a valid longitude`);
    return client
      .get(ENDPOINT, {
        params: {
          latitude: lat,
          longitude: long,
        },
      })
      .then(response => mapIds(response.data));
  };

  static getAddressByText = function getAddressByText(address) {
    invariant(typeof address === "string", `Please provide a valid address`);
    return client
      .get(ENDPOINT, { params: { address } })
      .then(response => mapIds(response.data));
  };

  static getLocation = function getLocation(icpNumber) {
    return client
      .get(`${ENDPOINT}/${icpNumber}`)
      .then(response => response.data);
  };
}
