import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FastField as Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Box, Flex } from "rebass";
import { compose } from "recompose";
import get from "lodash/get";
import { Button } from "../../components/Button";
import { Checkbox } from "../../components/Checkbox";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { Label } from "../../components/Label";
import { Loader } from "../../components/Loader";
import { NotificationTimesMessage } from "../../components/NotificationTimesMessage";
import {
  saveSubscriptionPreferencesRequest,
  getSubscriptionRequest,
  getOutageIdForSubscription,
  getSubscriptionDetails,
} from "../../redux/modules/subscription";
import { ErrorMessage } from "../../components/ErrorMessage";
import { clearApiError } from "../../redux/modules/errors";

export const UpdateMeAboutOutageContainer = ({
  initialValues,
  icpNumber,
  outageId,
  error,
  onUnmount,
  onSubmit,
  onFetchDetails,
  history,
  location,
  isSaving,
  isFetching,
}) => {
  useEffect(() => {
    onFetchDetails(icpNumber, { history, redirectTo: `/address/${icpNumber}` });
    return () => onUnmount(null, { slice: "subscriptions" });
  }, [icpNumber]);

  const submit = (values, { setSubmitting }) => {
    onSubmit(
      { outageId, icpNumber, channels: values },
      {
        history,
        redirectTo: get(location, "state.redirectTo", `/address/${icpNumber}`),
      },
    );
    setSubmitting(false);
  };

  if (isFetching) {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        css="min-height:100%;"
      >
        <Loader data-testid="UpdatePreferences__Spinner" />
      </Flex>
    );
  }

  return (
    <Flex
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width={1}
    >
      <Box width={[1, null, 3 / 4]}>
        <Box pb={2} role="alert" aria-atomic="true">
          <ErrorMessage message={error} shouldReveal />
        </Box>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={submit}
          render={() => (
            <Form aria-label="Outage subscription options">
              <Box my={10}>
                <Box my={10}>
                  <Label>Keep me updated via...</Label>
                </Box>
                <Flex>
                  <Box mr={10}>
                    <Field
                      name="updateByTxt"
                      label="TXT message"
                      component={Checkbox}
                    />
                  </Box>
                  <Box ml={5}>
                    <Field
                      name="updateByEmail"
                      label="Email"
                      component={Checkbox}
                    />
                  </Box>
                </Flex>
                <Box>
                  <NotificationTimesMessage />
                </Box>
                <Box my={20}>
                  <Button
                    id="submit"
                    data-testid="SubscriptionForm__Button--submit"
                    block
                    isLoading={isSaving}
                    disabled={isSaving}
                    variant="dark"
                    type="submit"
                  >
                    SAVE
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        />
      </Box>
    </Flex>
  );
};

UpdateMeAboutOutageContainer.propTypes = {
  onUnmount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFetchDetails: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    ticketId: PropTypes.string,
    updateByTxt: PropTypes.bool,
    updateByEmail: PropTypes.bool,
  }).isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  icpNumber: PropTypes.string.isRequired,
  outageId: PropTypes.string.isRequired,
  error: PropTypes.string,
  isSaving: PropTypes.bool,
  isFetching: PropTypes.bool,
};

UpdateMeAboutOutageContainer.defaultProps = {
  error: "",
  isSaving: false,
  isFetching: false,
};

/* istanbul ignore next */
const enhance = compose(
  withErrorBoundary(),
  withRouter,
  connect(
    (state, ownProps) => ({
      icpNumber: ownProps.match.params.icpNumber,
      outageId: getOutageIdForSubscription(
        state,
        ownProps.match.params.icpNumber,
      ),
      initialValues: getSubscriptionDetails(
        state,
        ownProps.match.params.icpNumber,
      ),
      error: state.subscription.error,
      isSaving: state.subscription.isSaving,
      isFetching: state.subscription.isFetching,
    }),
    {
      onUnmount: clearApiError,
      onSubmit: saveSubscriptionPreferencesRequest,
      onFetchDetails: getSubscriptionRequest,
    },
  ),
);

export default enhance(UpdateMeAboutOutageContainer);
