import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";
import { call } from "redux-saga/effects";
import { trackEvent } from "../../common/analytics";

export function* handleRedirect(action) {
  // Ensure history.push and redirectTo are available
  const push = get(action, "meta.history.push");
  const redirectTo = get(action, "meta.redirectTo");
  const meta = omitBy(
    {
      redirectTo: get(action, "meta.navigateBack", undefined),
      gaCategory: get(action, "meta.gaCategory", undefined),
    },
    isUndefined,
  );

  if (push && redirectTo) {
    yield call(push, redirectTo, isEmpty(meta) ? undefined : meta);
  }
}

export function* handleTrackEvent(gaEvent) {
  const gaCategory = get(gaEvent, "category", undefined);
  if (gaCategory) {
    yield call(trackEvent, gaEvent);
  }
}
