/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useGoogleMap } from "@react-google-maps/api";
import { StormZonePopup } from "../../components/StormZonePopup";
import { stormZoneByIdRequest } from "../../redux/modules/storm-zones-id";

const MAP_CENTER_INITIAL_OFFSET = 0.35;
const MAP_CENTER_ZOOM_MULTIPLIER = 2;
const MAP_CENTER_ZOOM_START_LEVEL = 9;

const StormZonePopupContainer = ({
  zoomLevel,
  zoneId,
  zoneName,
  zoneCenterPoint,
  stormZoneDetails,
  stormZoneDetailsLoading,
  getStormZoneById,
  onClose,
}) => {
  const map = useGoogleMap();

  useEffect(() => {
    getStormZoneById(zoneId);
  }, [zoneId]);

  useEffect(() => {
    // logic to offset the center of the pop-up to show it all in view
    const centerOffset =
      MAP_CENTER_INITIAL_OFFSET /
      // eslint-disable-next-line no-restricted-properties
      Math.pow(
        MAP_CENTER_ZOOM_MULTIPLIER,
        zoomLevel - MAP_CENTER_ZOOM_START_LEVEL,
      );

    if (map) {
      map.panTo({
        lat: zoneCenterPoint.latitude + centerOffset,
        lng: zoneCenterPoint.longitude,
      });
      map.setCenter({
        lat: zoneCenterPoint.latitude + centerOffset,
        lng: zoneCenterPoint.longitude,
      });
    }
  }, [zoneCenterPoint]);

  return (
    <StormZonePopup
      loading={stormZoneDetailsLoading}
      centerPoint={zoneCenterPoint}
      zoneName={zoneName}
      stormZoneDetails={stormZoneDetails}
      onClose={onClose}
    />
  );
};

StormZonePopupContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  zoomLevel: PropTypes.number.isRequired,
  zoneId: PropTypes.string.isRequired,
  zoneName: PropTypes.string.isRequired,
  zoneCenterPoint: PropTypes.object.isRequired,
  stormZoneDetails: PropTypes.object,
  stormZoneDetailsLoading: PropTypes.bool,
  getStormZoneById: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

StormZonePopupContainer.defaultProps = {
  stormZoneDetails: {},
  stormZoneDetailsLoading: false,
};

/* istanbul ignore next */
const enhance = compose(
  withRouter,
  connect(
    null,
    {
      getStormZoneById: stormZoneByIdRequest,
    },
  ),
);

export default enhance(StormZonePopupContainer);
