// Ensure the error still surfaces to NewRelic alerts etc
export const logError = (...args) => {
  if (window.newrelic && typeof window.newrelic.noticeError === "function") {
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.warn("logError :: sending error to NewRelic", ...args);
    }
    window.newrelic.noticeError(...args);
  }
};
