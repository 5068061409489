export const getBoundaryCoordinates = async (
  fileName = "assets/vector_boundaries_min.json",
) => {
  try {
    const result = await fetch(fileName, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    /* eslint-disable */
    return await result.json();
  } catch (error) {
    return [];
  }
};
