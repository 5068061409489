import { createSelector } from "reselect";

export const getOutageFromIcpStatus = createSelector(
  [state => state.icpStatus.icpStatus],
  icpStatus => {
    if (!icpStatus) {
      return null;
    }

    return icpStatus.outage;
  },
);

export const getIcpLocation = createSelector(
  [state => state.icpStatus.icpStatus],
  icpStatus => {
    if (!icpStatus) {
      return null;
    }

    return icpStatus.icpLocation;
  },
);
