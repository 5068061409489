import { call, put, takeLatest, fork } from "redux-saga/effects";
import RegisterService from "../../../services/RegisterService";
import { sendOneTimePasswordRequest } from "../login";
import { handleTrackEvent } from "../../sagas/helpers";
import {
  REGISTER_REQUEST,
  registerFailure,
  registerSuccess,
} from "./registration.actions";

export function* processRegisterRequest(action) {
  try {
    const values = action.payload;
    const { history, redirectTo, navigateBack, gaCategory } = action.meta;
    const { data } = yield call(RegisterService.register, values);
    yield put(registerSuccess(data, { gaCategory }));
    yield put(
      sendOneTimePasswordRequest(
        {
          email: values.email,
        },
        { history, redirectTo, navigateBack, gaCategory },
      ),
    );
  } catch (err) {
    const { gaCategory } = action.meta;
    yield put(registerFailure(err.message, { gaCategory }));
  }
}

export function* trackRegisterSuccess({ meta }) {
  yield fork(handleTrackEvent, {
    category: meta.gaCategory,
    action: "service_request_success",
    label: "User successfully registered",
  });
}

export function* trackRegisterFailure({ payload, meta }) {
  yield fork(handleTrackEvent, {
    category: meta.gaCategory,
    action: "service_request_failure",
    label: payload,
  });
}

export function* watchForRegisterRequest() {
  yield takeLatest(REGISTER_REQUEST, processRegisterRequest);
  yield takeLatest(registerSuccess, trackRegisterSuccess);
  yield takeLatest(registerFailure, trackRegisterFailure);
}
