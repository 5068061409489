import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { NavigationItem } from "./NavigationItem";

// TODO: Derive this from global config maybe?
export const NAV_LINKS = [
  { id: 1, label: "Outage map", uri: "/map", isPrivate: false },
  { id: 2, label: "check address", uri: "/address", isPrivate: false },
  {
    id: 3,
    label: "upcoming planned outages",
    uri: "/planned",
    isPrivate: false,
  },
  { id: 8, label: "My Reports", uri: "/my-reports", isPrivate: true },
  { id: 7, label: "My Account", uri: "/my-account", isPrivate: true },
  {
    id: 4,
    label: "Common Questions",
    uri: "https://www.vector.co.nz/personal/outages/popular-questions",
    isPrivate: false,
  },
  {
    id: 5,
    label: "Feedback",
    uri: "https://survey.vector.co.nz/jfe/form/SV_0ON8nLSHRlDrYgZ",
    isPrivate: false,
  },
  {
    id: 6,
    label: "Contact Us",
    uri: "https://www.vector.co.nz/contact-us",
    isPrivate: false,
  },
];

const List = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  background-color: ${({ theme }) => theme.colors.dark};

  > li {
    margin: 5px 0;
  }
`;

export const NavigationList = ({ isLoggedIn, ...props }) => (
  <List data-testid="NavigationList" {...props}>
    {NAV_LINKS.filter(n => !n.isPrivate || n.isPrivate === isLoggedIn).map(
      ({ id, isPrivate, ...link }) => (
        <li key={id} data-testid={`NavigationList__Item--${id}`}>
          <NavigationItem {...link} />
        </li>
      ),
    )}
  </List>
);
NavigationList.propTypes = {
  isLoggedIn: PropTypes.bool,
};
NavigationList.defaultProps = {
  isLoggedIn: false,
};

export default NavigationList;
