/* eslint-disable no-shadow,react/no-unused-state */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { or } from "airbnb-prop-types";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { connect as formikConnect } from "formik";
import { connect as reduxConnect } from "react-redux";
import { compose } from "recompose";
import {
  hasNoExternalError,
  validateWith,
} from "../components/RegistrationFormFields/validate";
import { clearApiError } from "../redux/modules/errors/errors.actions";

export const withAPIErrorHandling = WrappedFormikField => {
  class WithAPIErrorHandling extends React.Component {
    static propTypes = {
      clearApiError: PropTypes.func.isRequired,
      errorMessage: or([PropTypes.string, undefined]).isRequired,
      formik: PropTypes.object.isRequired,
      slice: PropTypes.string.isRequired,
      key: PropTypes.string,
    };

    static defaultProps = {
      key: "error",
    };

    constructor(props) {
      super(props);
      const { slice } = props;

      if (!slice) {
        throw new Error(
          "withAPIErrorHandling :: You must provide a config.slice name",
        );
      }
    }

    state = {
      validateFn: /* istanbul ignore next */ () => {},
    };

    createValidateFn = message => {
      const {
        validate,
        name,
        formik: { validateField },
      } = this.props;

      this.setState(
        {
          validateFn: validateWith(hasNoExternalError(message), validate),
        },
        // After the new validate function is configured and has been re-rendered
        // we need to fire the explicit validate method from formik to make any
        // errors appear in the UI.
        () => validateField(name),
      );
    };

    componentDidMount() {
      const { errorMessage } = this.props;
      this.createValidateFn(errorMessage);
    }

    componentDidUpdate(prevProps) {
      const { errorMessage } = this.props;
      const { errorMessage: prevErrorMessage } = prevProps;

      // If the message has changed (including it now being undefined/removed)
      // istanbul ignore next
      if (!isEqual(prevErrorMessage, errorMessage)) {
        // Recreate the validation rule with the new value, this triggers a render cycle
        this.createValidateFn(errorMessage);
      }
    }

    handleChange = e => {
      const {
        formik,
        errorMessage,
        clearApiError,
        onChange,
        slice,
        key,
      } = this.props;

      if (errorMessage) {
        // Clear API Error via action
        clearApiError(undefined, { slice, key });
      }

      // fire internal handler
      if (typeof onChange === "function") {
        // This is the custom + internal handlers if set by the <Field /> component tag
        onChange(e);
      } else {
        // If the above isn't defined then we just fire the internal handler
        formik.handleChange(e);
      }
    };

    render() {
      // Strip out props we do not want to pass along to the HTML node
      const {
        formik,
        errorMessage,
        clearApiError,
        validate,
        onChange,
        slice,
        key,
        ...props
      } = this.props;

      const { validateFn } = this.state;
      return (
        <WrappedFormikField
          {...props}
          validate={validateFn}
          onChange={this.handleChange}
        />
      );
    }
  }

  // istanbul ignore next
  const enhance = compose(
    reduxConnect(
      (state, { slice, key = "error" }) => {
        const errorMessage = get(state, `${slice}.${key}`, undefined);
        return {
          errorMessage,
          slice,
          key,
        };
      },
      {
        clearApiError,
      },
    ),
    formikConnect,
  );

  // return WithAPIErrorHandling;
  return enhance(WithAPIErrorHandling);
};
