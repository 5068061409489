import React from "react";
import PropTypes from "prop-types";
import { useTransition, animated } from "react-spring";
import { CloseButton } from "../CloseButton";
import { Portal } from "../Portal";
import { NavigationContainer } from "../../containers/NavigationContainer";

export const MenuOverlay = ({ isOpen, onClose, ...props }) => {
  const transitions = useTransition(isOpen, null, {
    from: {
      position: "fixed",
      zIndex: 60000,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      overflow: "auto",
      transform: "translate3d(-105vw, 0, 0)",
    },
    enter: {
      transform: "translate3d(0, 0, 0)",
    },
    leave: {
      transform: "translate3d(-105vw, 0, 0)",
    },
  });

  return (
    <Portal id="MenuOverlay">
      {transitions.map(
        ({ item, props: animationProps, key }) =>
          item && (
            <animated.div {...props} key={key} style={animationProps}>
              <CloseButton onClick={onClose} icononly />
              {/* NOTE: The awkward 120px padding bottom here is to address the iOS Safari/Chrome menu bar overlaying the navigation options */}
              <NavigationContainer pb={[120, 0]} css={{ minHeight: "100%" }} />
            </animated.div>
          ),
      )}
    </Portal>
  );
};
MenuOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MenuOverlay;
