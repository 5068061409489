import { Box, Flex } from "@rebass/grid";
import Macro, { Clone } from "macro-components";
import React from "react";
import styled from "styled-components/macro";
import { fontSize, textAlign } from "styled-system";
import { Button as BaseButton } from "../Button";
import { MenuButton } from "../MenuOverlay";

const Bar = styled(Flex)`
  position: relative;
  z-index: 200;
  min-height: 48px;
  height: 48px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.14);
  background-color: ${props => props.theme.colors.light};
`;
const Item = styled(Box)`
  // First only
  &:first-child {
    position: absolute;
    left: 0;
  }

  // Any in the middle
  &:not(:first-child):not(:last-child) {
    flex: 1;
  }

  // Last only
  &:last-child {
    position: absolute;
    right: 0;
  }

  // First and Last
  // This one is for IE!
  &:first-child,
  &:last-child {
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
`;

const Button = styled(BaseButton)`
  ${fontSize};
  font-weight: normal;
  line-height: 1;
  padding-left: 0;
  text-transform: lowercase;
`;

const Title = styled.h1`
  ${fontSize};
  ${textAlign};
  line-height: 1;
  margin: 0;
  font-weight: normal;
  text-transform: lowercase;
`;

const macro = Macro({
  Button,
  Title,
});

// eslint-disable-next-line no-shadow
const AppBar = macro(({ Button, Title }) => (
  <Bar alignItems="center" justifyContent="space-between">
    <Item pl={25}>
      <Clone element={Button} fontSize={1} variant="text" color="primary" />
    </Item>
    <Item px={80}>
      <Clone element={Title} fontSize={1} textAlign="center" />
    </Item>
    <Item pr={25} fontSize={1}>
      <MenuButton />
    </Item>
  </Bar>
));

export default AppBar;
