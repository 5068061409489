/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import { StormZonePolygon } from "../../../components/StormZonePolygon";

export const StormZoneShapes = ({ stormZones, zoomLevel, onZoneClick }) => (
  <>
    {stormZones.length &&
      stormZones.map(stormZone => (
        <StormZonePolygon
          key={stormZone.id}
          shape={stormZone.shape}
          zoomLevel={zoomLevel}
          onClick={() => onZoneClick(stormZone)}
        />
      ))}
  </>
);

StormZoneShapes.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  stormZones: PropTypes.array,
  zoomLevel: PropTypes.number.isRequired,
  onZoneClick: PropTypes.func.isRequired,
};

StormZoneShapes.defaultProps = {
  stormZones: [],
};

export default StormZoneShapes;
