import React from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "rebass";
import { ErrorIcon } from "../FieldErrors";

const LoginErrorMessage = ({ error }) => (
  <Box width={1} my={20} role="alert" aria-atomic="true">
    {error && (
      <Flex justifyContent="center" alignItems="center" width={1}>
        <Box mr={10}>
          <ErrorIcon />
        </Box>
        <Box>
          <Text
            as="p"
            m={0}
            fontSize={2}
            color="error"
            data-testid="LoginForm__Error"
          >
            {error}
          </Text>
        </Box>
      </Flex>
    )}
  </Box>
);

LoginErrorMessage.propTypes = {
  error: PropTypes.string,
};

LoginErrorMessage.defaultProps = {
  error: undefined,
};

export default LoginErrorMessage;
