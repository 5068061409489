/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Box, Flex } from "rebass";
import styled, { css } from "styled-components/macro";
import { ifProp, ifNotProp } from "styled-tools";
import { bgColor, color, height, width, border } from "styled-system";

const Shape = styled(Flex).attrs(props => ({
  justifyContent: "center",
  alignItems: "center",
  height: props.size,
  width: props.size,
}))`
  border-radius: 100%;
  overflow: hidden;
  ${ifNotProp(
    "flat",
    css`
      box-shadow: 0 4px 9px ${p => p.theme.colors.gray};
    `,
  )};
  ${color};
  ${border};
  ${bgColor};
  ${height};
  ${width};
`;

const Inner = styled(Box)`
  max-width: 100%;
  width: 100%;
  text-align: center;
  font-weight: bold;
  line-height: 0;

  ${ifProp(
    "rotate",
    css`
      transform: rotate(${props => props.rotate}deg);
    `,
  )};

  svg {
    fill: currentColor;
  }
`;

// eslint-disable-next-line no-shadow
export const Circle = ({ size, color, bg, rotate, children, ...props }) => (
  <Shape size={size} color={color} bg={bg} {...props}>
    <Inner rotate={rotate}>{children}</Inner>
  </Shape>
);

Circle.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  bg: PropTypes.string,
  children: PropTypes.element.isRequired,
};

Circle.defaultProps = {
  size: 50,
  color: "white",
  bg: "accent",
};
