import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Flex, Box } from "rebass";
import { AppBar } from "../components/AppBar";
import { Page } from "../components/Page";
import { MyAccountContainer } from "../containers/MyAccountContainer";

const MyAccountView = ({ history }) => (
  <Flex flexDirection="column" width={1}>
    <Page title="My Account" />
    <Box flex="0 1 0">
      <AppBar>
        <AppBar.Button onClick={history.goBack}>
          <span aria-hidden="true">&lt; </span>BACK
        </AppBar.Button>
        <AppBar.Title>MY ACCOUNT</AppBar.Title>
      </AppBar>
    </Box>
    <Box width={1} flex="1 1 auto" bg="light">
      <MyAccountContainer />
    </Box>
  </Flex>
);

MyAccountView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(MyAccountView);
