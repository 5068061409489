/* eslint-disable no-shadow */
import React from "react";
import PropTypes from "prop-types";
import { Box, Text } from "rebass";
import isEmpty from "lodash/isEmpty";
import { Loader } from "../../../components/Loader";
import { Button } from "../../../components/Button";
import { PlannedOutageCardList } from "../../../components/PlannedOutageCardList";

export const PlannedOutagesList = ({ isFetching, outages, error }) => {
  if (isFetching) {
    return <Loader />;
  }

  if (error) {
    return null;
  }

  if (isEmpty(outages)) {
    return (
      <Box px={3}>
        <Box width={1} mt={3}>
          <Text as="p" textAlign="center" fontSize={2} m={0} lineHeight={1.4}>
            We couldn&apos;t find any upcoming planned outages for this address
          </Text>
        </Box>
        <Box width={1} mt={3}>
          <Text
            as="p"
            textAlign="center"
            fontSize={2}
            fontWeight="bold"
            m={0}
            lineHeight={1.4}
          >
            If you think there is a problem please{" "}
            <Button variant="text" href="https://www.vector.co.nz/contact-us">
              contact us.
            </Button>
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box width={1} my={3} px={[2, null, 3]}>
        <Text
          textAlign="center"
          as="p"
          mx={2}
          my={0}
          lineHeight={1.5}
          fontSize={[1, null, 2]}
        >
          Below are planned outages for the date(s) shown. If there is an
          alternative date, this only applies if the original outage is
          postponed.
        </Text>
      </Box>
      <PlannedOutageCardList outages={outages} />
    </>
  );
};

PlannedOutagesList.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  outages: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.string,
};

PlannedOutagesList.defaultProps = {
  error: undefined,
};
