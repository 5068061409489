import { createAction } from "redux-actions";

// Does the API Request
export const LSEVENT_REQUEST = "app/lsEvent/REQUEST";
// Success of API Request:
// - Set hash to current
// - Store hash in byHash map
export const LSEVENT_SUCCESS = "app/lsEvent/SUCCESS";
// Failure of API Request
export const LSEVENT_FAILURE = "app/lsEvent/FAILURE";
// Explicit dismissal of content:
// - Store hash in dismissed[] array
export const LSEVENT_DISMISS = "app/lsEvent/DISMISS";

export const lsEventRequest = createAction(LSEVENT_REQUEST, () => undefined);
export const lsEventSuccess = createAction(LSEVENT_SUCCESS, data => data);
export const lsEventFailure = createAction(LSEVENT_FAILURE);
export const lsEventDismiss = createAction(LSEVENT_DISMISS, hash => hash);
