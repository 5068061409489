import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { PageWrapper } from "../PageWrapper";
import StormZoneDetails from "../StormZoneDetails/StormZoneDetails";
import { AddressBlock } from "../AddressBlock";
import { Map } from "../Map";

const StormZoneDetailsAddressSearch = ({
  history,
  stormZoneDetails,
  icpLocation,
}) => {
  const { locationPrefix, street, suburb } = icpLocation;

  return (
    <PageWrapper
      metaTitle={`${locationPrefix} ${street}, ${suburb}`}
      title="outage map"
      history={history}
      p={4}
    >
      <AddressBlock>
        <AddressBlock.Label>
          {locationPrefix} {street} <br /> {suburb}
        </AddressBlock.Label>
        <AddressBlock.Map>
          <Map type="unknown" />
        </AddressBlock.Map>
      </AddressBlock>

      <StormZoneDetails stormZoneDetails={stormZoneDetails} showZoneName />
    </PageWrapper>
  );
};

StormZoneDetailsAddressSearch.defaultProps = {
  stormZoneDetails: {},
  icpLocation: null,
};

StormZoneDetailsAddressSearch.propTypes = {
  history: PropTypes.object.isRequired,
  stormZoneDetails: PropTypes.object,
  icpLocation: PropTypes.shape({
    icpNumber: PropTypes.string,
    locationDisplay: PropTypes.string,
    locationPrefix: PropTypes.string,
    street: PropTypes.string,
    suburb: PropTypes.string,
    city: PropTypes.string,
    propertySeparator: PropTypes.string,
    transformerId: PropTypes.string,
    feederId: PropTypes.string,
    stormZoneId: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    geocodeStatus: PropTypes.string,
  }),
};

const enhance = compose(withRouter);

export default enhance(StormZoneDetailsAddressSearch);
