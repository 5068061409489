import { takeLatest, call, put, all } from "redux-saga/effects";
import {
  stormZonesRequest,
  stormZonesFailure,
  stormZonesSuccess,
} from "./storm-zones.actions";
import { StormZonesService } from "../../../services/StormZonesService";

export function* getStormZones() {
  try {
    const data = yield call(StormZonesService.getStormZones);
    yield put(stormZonesSuccess(data));
  } catch (err) {
    yield put(stormZonesFailure(err.message));
  }
}

export function* watchForStormZonesRequest() {
  yield all([takeLatest(stormZonesRequest, getStormZones)]);
}
