import React from "react";
import PropTypes from "prop-types";

import { LSEventContext } from "./LSEventContext";

export const LSEventConsumer = ({ render, component: Component, children }) => {
  let createElement = null;

  // Determine if this has a render prop
  if (typeof render === "function") {
    createElement = p => render(p);
  }

  // Check for a component prop
  if (typeof Component === "function") {
    createElement = p => <Component {...p} />;
  }

  // Check for render function as child usage
  if (typeof children === "function") {
    createElement = p => children(p);
  }

  return (
    <LSEventContext.Consumer>
      {ctxProps => createElement(ctxProps)}
    </LSEventContext.Consumer>
  );
};

LSEventConsumer.propTypes = {
  render: PropTypes.func,
  component: PropTypes.element,
  children: PropTypes.func,
};
LSEventConsumer.defaultProps = {
  render: undefined,
  component: undefined,
  children: null,
};
