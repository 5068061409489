import get from "lodash/get";
import { handleActions, combineActions } from "redux-actions";
import {
  requestCustomer,
  requestCustomerSuccess,
  requestCustomerFailure,
  updateCustomer,
  updateCustomerSuccess,
  updateCustomerFailure,
  clearCustomerUpdateMessage,
} from "./customers.actions";

export const INITIAL_STATE = {
  isFetching: false,
  byId: {},
  selected: null,
  error: null,
  successMessage: null,
};

export const reducer = handleActions(
  {
    [requestCustomer]: state => ({ ...state, isFetching: true }),
    [combineActions(
      requestCustomerSuccess,
      requestCustomerFailure,
    )]: state => ({
      ...state,
      isFetching: false,
    }),
    [requestCustomerSuccess]: (state, { payload }) => ({
      ...state,
      byId: {
        ...state.byId,
        ...get(payload, "entities.customers", {}),
      },
      selected: get(payload, "result", null),
    }),
    [updateCustomer]: state => ({
      ...state,
      successMessage: null,
      error: null,
      isLoading: true,
    }),
    [combineActions(updateCustomerSuccess)]: (state, action) => ({
      ...state,
      byId: {
        [state.selected]: {
          ...action.payload,
        },
      },
      successMessage: "Thanks, your account is now updated",
      error: null,
      isLoading: false,
    }),
    [combineActions(updateCustomerFailure, requestCustomerFailure)]: (
      state,
      { payload: error },
    ) => ({
      ...state,
      successMessage: null,
      error,
      isLoading: false,
    }),
    [clearCustomerUpdateMessage]: state => ({
      ...state,
      successMessage: null,
      error: null,
    }),
  },
  INITIAL_STATE,
);

export default reducer;
