import { combineActions, handleActions } from "redux-actions";
import { withApiErrors } from "../errors";

import {
  saveSubscriptionPreferencesRequest,
  saveSubscriptionPreferencesSuccess,
  saveSubscriptionPreferencesFailure,
  getSubscriptionFailure,
  getSubscriptionRequest,
  getSubscriptionSuccess,
} from "./subscription.actions";

const initialState = {
  isSaving: false,
  isFetching: false,
  error: undefined,
  byIcpNumber: {},
  byOutageId: {},
};

export const reducer = handleActions(
  {
    [saveSubscriptionPreferencesRequest]: state => ({
      ...state,
      isSaving: true,
    }),
    [combineActions(
      saveSubscriptionPreferencesSuccess,
      saveSubscriptionPreferencesFailure,
    )]: state => ({
      ...state,
      isSaving: false,
    }),
    [getSubscriptionRequest]: state => ({
      ...state,
      isFetching: true,
    }),
    [combineActions(
      getSubscriptionSuccess,
      getSubscriptionFailure,
    )]: state => ({ ...state, isFetching: false }),
    [getSubscriptionSuccess]: (state, { payload }) => ({
      ...state,
      byIcpNumber: {
        ...state.byIcpNumber,
        [payload.icpNumber]: payload.outageId,
      },
      byOutageId: {
        ...state.byOutageId,
        [payload.outageId]: payload.subscription,
      },
    }),
  },
  initialState,
);

export default withApiErrors(reducer, "subscriptions", {
  capture: [saveSubscriptionPreferencesFailure, getSubscriptionFailure],
  clear: [saveSubscriptionPreferencesSuccess, getSubscriptionSuccess],
});
