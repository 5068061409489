/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import findIndex from "lodash/findIndex";
import { Form, Formik, getIn } from "formik";
import { connect } from "react-redux";
import {
  Link,
  Redirect,
  Route,
  Switch,
  withRouter,
  generatePath,
} from "react-router-dom";
import { Box, Flex, Text } from "rebass";
import { compose } from "recompose";
import { AppBar } from "../../components/AppBar";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { Page } from "../../components/Page";
import { ReportOutageProgress } from "../../components/ReportOutageProgress";
import { getFormValues, saveForm } from "../../redux/modules/forms";
import { lsEventRequest } from "../../redux/modules/lsevent";
import { reportCreate, reportErrorReset } from "../../redux/modules/reports";
import { LSEventConsumer } from "../LSEvent";
// Steps
import AccessContainer, {
  DEFAULT_FIELD_VALUES as defaultAccessFieldValues,
} from "./_/AccessFields";
import { ReactComponent as ActiveIcon } from "./_/arrow-down.svg";
import ConfirmationFields from "./_/ConfirmationFields";
import DetailsFields, {
  DEFAULT_FIELD_VALUES as defaultDetailsFieldValues,
} from "./_/DetailsFields";
import Disclaimer from "./_/Disclaimer";
import NotificationFields, {
  DEFAULT_FIELD_VALUES as defaultNotificationFieldValues,
} from "./_/NotificationFields";
import { ReactComponent as TickIcon } from "./_/tick.svg";

import UnauthenticatedForm from "./_/UnauthenticatedForm";
import {
  sendOneTimePasswordRequest,
  clearLoginError,
} from "../../redux/modules/login";

/**
 * URLs for Create Report
 * - /report/:icpNumber/create/?:step/?:ticketId
 * - /report/:icpNumber/ticket/:ticketId (FUTURE! Will show the current status for a subscription)
 */
export const BASE_URI = "/report/:icpNumber/create";

export const stripDoubleSlashes = str => str.replace(/(\/+)/gi, "/");

export const formDefaultValues = {
  ...defaultDetailsFieldValues,
  ...defaultAccessFieldValues,
  ...defaultNotificationFieldValues,
};

export class ReportOutageContainer extends React.Component {
  static propTypes = {
    values: PropTypes.object,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    resetErrors: PropTypes.func.isRequired,
    lseRequest: PropTypes.func.isRequired,
    onSendOTP: PropTypes.func.isRequired,
  };

  static defaultProps = {
    values: {},
  };

  // eslint-disable-next-line react/destructuring-assignment
  state = { baseUrl: generatePath(BASE_URI, this.props.match.params) };

  static Steps = [
    { path: "", label: null },
    { path: "details", label: "Outage details" },
    { path: "access", label: "Access" },
    { path: "notifications", label: "Updates" },
    { path: "confirmation", label: "Done" },
  ];

  getProgressSteps = (steps = ReportOutageContainer.Steps) => {
    const { match } = this.props;
    const { baseUrl } = this.state;

    /* istanbul ignore next */
    const { step = "" } = match.params;
    const stages = steps.filter(item => !!item.path);
    const currentIndex = findIndex(stages, s => s.path === step);

    function getIcon(i, cur, total) {
      const isLast = i === total;
      if (i < cur || (i === cur && isLast)) {
        return <TickIcon />;
      }
      if (i === cur) {
        return <ActiveIcon />;
      }
      return null;
    }
    function getType(i, cur, total) {
      const isLast = i === total;
      if (i < cur || (i === cur && isLast)) {
        return "complete";
      }
      if (i === cur) {
        return "default";
      }
      return "incomplete";
    }

    return stages.map(({ label, path }, i) => ({
      id: path,
      label:
        i < currentIndex ? (
          <Text
            as="span"
            css="display:inline-block;"
            fontSize={1}
            px="3px"
            textAlign="center"
          >
            <Link to={stripDoubleSlashes(`${baseUrl}/${path}`)}>{label}</Link>
          </Text>
        ) : (
          <Text
            as="span"
            css="display:inline-block;"
            fontSize={1}
            px="3px"
            textAlign="center"
          >
            {label}
          </Text>
        ),
      icon: getIcon(i, currentIndex, stages.length - 1),
      type: getType(i, currentIndex, stages.length - 1),
    }));
  };

  /**
   * Manages going from one step to the next step
   *
   * @param values {Object} Formik form values
   * @return {Function}
   */
  next = values => {
    const steps = ReportOutageContainer.Steps;
    const { baseUrl } = this.state;
    const { match, history } = this.props;
    /* istanbul ignore next */
    const { step = "" } = match.params;

    // Get current and sibling step info
    const currentIdx = findIndex(steps, i => i.path === step);

    const nextIdx =
      currentIdx + 1 <= steps.length
        ? currentIdx + 1
        : /* istanbul ignore next */ currentIdx;
    const nextStep = steps[nextIdx];

    // Determine next step/url
    let nextUrl = `${baseUrl}/${nextStep.path}`;

    // List of intercepts/redirects to warnings etc
    const issueType = getIn(values, "details.issue", null);
    const unknownIssueOther = getIn(values, "details.other", null);

    // eslint-disable-next-line default-case
    switch (issueType) {
      case "Unknown":
        if (!unknownIssueOther) {
          nextUrl = `${baseUrl}/details/other`;
        }
        break;
      case "gas-related":
        nextUrl = `${baseUrl}/details/gas`;
        break;
      case "no-hot-water":
        nextUrl = `${baseUrl}/details/water`;
        break;
    }
    // The URL to go to, this takes into account any intercepts to warnings
    history.push(stripDoubleSlashes(nextUrl));
  };

  /**
   * Submits the entirety of the multi-step form.
   *
   * @param values
   * @param setSubmitting
   */
  handleSubmit = (values, { setSubmitting, setTouched }) => {
    const {
      onNext,
      onSubmit,
      match: {
        params: { step, icpNumber },
      },
      history,
    } = this.props;
    const { baseUrl } = this.state;

    const formName = "createReport";

    if (step === "notifications") {
      // submit form
      onSubmit(values, {
        formName,
        subkey: icpNumber,
        history,
        redirectTo: stripDoubleSlashes(`${baseUrl}/confirmation`),
      });
    } else {
      // trigger onNext redux tasks (save form values)
      onNext(values, { formName, subkey: icpNumber });
      // go to next page
      this.next(values);
      // clear touched so that validation on current page is consistent
      setTouched({});
    }

    setSubmitting(false);
  };

  componentDidMount() {
    const { lseRequest } = this.props;
    lseRequest();
  }

  componentWillUnmount() {
    // We're leaving this container, perhaps for a login redirect etc
    // We are cleaning up any local UI errors that we are showing as they may be
    // resolved by another action
    // eslint-disable-next-line react/destructuring-assignment
    this.props.resetErrors();
  }

  render() {
    const {
      history,
      match: {
        path,
        params: { step, icpNumber },
      },
      values,
      onSendOTP,
      clearLoginErrorMessage,
    } = this.props;

    const initialValues = {
      ...formDefaultValues,
      ...values,
      userAddress: icpNumber,
    };

    return (
      <LSEventConsumer
        render={({ code }) => {
          if (code > 1) {
            return <Redirect to={`/address/${icpNumber}`} />;
          }
          return (
            <Flex flexDirection="column" width={1}>
              <Page title={`Report an outage${step ? `: ${step}` : ""}`} />
              <Box flex="0 1 0">
                <AppBar>
                  <AppBar.Button onClick={history.goBack}>
                    &lt; BACK
                  </AppBar.Button>
                  <AppBar.Title>REPORT AN OUTAGE</AppBar.Title>
                </AppBar>
              </Box>
              {step && (
                <Box width={1}>
                  <ReportOutageProgress data={this.getProgressSteps()} />
                </Box>
              )}
              <Box
                flex="1 1 0"
                width={1}
                px={[20, null, 30]}
                pt={[20, null, 40]}
                pb={[20, null, 60]}
                bg="light"
              >
                <Flex flexDirection="column" alignItems="center">
                  <Box width={[1, null, 3 / 4]}>
                    <Formik
                      initialValues={initialValues}
                      enableReinitialize
                      onSubmit={this.handleSubmit}
                      render={() => (
                        <Form aria-label="Report an outage form" noValidate>
                          <Switch>
                            {/* Details */}
                            <Route
                              path={`${BASE_URI}/details`}
                              component={DetailsFields}
                            />
                            {/* Access Issues */}
                            <Route
                              path={`${BASE_URI}/access`}
                              component={AccessContainer}
                            />
                            {/* Notification Settings */}
                            <Route
                              path={`${BASE_URI}/notifications`}
                              component={NotificationFields}
                            />
                            {/* Confirmation */}
                            <Route
                              path={`${BASE_URI}/confirmation/:reportId?`}
                              component={ConfirmationFields}
                            />
                            {/* Warning, at the bottom as it should only render if no step matches */}
                            <Route exact path={path} component={Disclaimer} />
                          </Switch>
                        </Form>
                      )}
                    />
                  </Box>
                  <Box width={1}>
                    <Route
                      path={`${BASE_URI}/notifications`}
                      render={props => (
                        <UnauthenticatedForm
                          {...props}
                          onSendOTP={onSendOTP}
                          clearLoginError={clearLoginErrorMessage}
                        />
                      )}
                    />
                  </Box>
                </Flex>
              </Box>
            </Flex>
          );
        }}
      />
    );
  }
}

/* istanbul ignore next */
const enhance = compose(
  withErrorBoundary(),
  withRouter,
  connect(
    (state, ownProps) => ({
      values: getFormValues(
        state,
        "createReport",
        ownProps.match.params.icpNumber,
      ),
    }),
    {
      onSubmit: reportCreate,
      onNext: saveForm,
      resetErrors: reportErrorReset,
      lseRequest: lsEventRequest,
      onSendOTP: sendOneTimePasswordRequest,
      clearLoginErrorMessage: clearLoginError,
    },
  ),
);

export default enhance(ReportOutageContainer);
