import { createAction } from "redux-actions";

export const API_ERROR_CLEAR = "api/error/CLEAR";

const metaCreator = (_, { slice, key = "error" } = {}) => ({
  slice,
  key,
});

export const clearApiError = createAction(
  API_ERROR_CLEAR,
  () => undefined,
  metaCreator,
);
