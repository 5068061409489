import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Flex, Box } from "rebass";
import { Loader } from "../../../components/Loader";
import { IconInfo, IconNearby, IconClose } from "../../../assets/icons";
import { withContext } from ".";
import { trackEvent } from "../../../common/analytics";

const ButtonsContainer = styled(Flex)`
  position: absolute;
  top: 0;
  right: 2px;
  height: 100%;
  z-index: 2000;
`;
const FadeToWhite = styled(Box)`
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
`;
const Button = styled.button`
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
`;

export const Buttons = ({
  showClearAddress,
  showDetails,
  showFetching,
  showLocation,
  clearAddress,
  getAddressDetails,
  getCurrentLocation,
  category,
  gaLabel,
}) => {
  const handleClick = (onClick, action, event) => {
    trackEvent({
      category,
      action,
      label: gaLabel,
    });
    onClick(event);
  };
  return (
    <ButtonsContainer alignItems="center">
      {/* Pretty hacky! would welcome more elegant solutions */}
      <FadeToWhite px={2} py={3} color="white" aria-hidden="true" />
      <Flex justifyContent="right" alignItems="center">
        {showDetails && (
          <Box bg="white" p={1} pr={2}>
            <Button
              onClick={
                /* istanbul ignore next */ e =>
                  handleClick(
                    getAddressDetails,
                    "Address Search - View Details",
                    e,
                  )
              }
              title="View address details"
              aria-label="View address details"
              tabIndex={0}
              data-testid="MapAddress__ViewDetails"
            >
              <IconInfo width="27" height="27" aria-hidden="true" />
            </Button>
          </Box>
        )}
        {showLocation && (
          <Box bg="white" p={1} pr={2}>
            <Button
              onClick={e =>
                handleClick(
                  getCurrentLocation,
                  "Address Search - Use Current Location",
                  e,
                )
              }
              title="Find current location"
              aria-label="Find current location"
              tabIndex={0}
              data-testid="MapAddress_FindLocation"
            >
              <IconNearby width="26" height="26" aria-hidden="true" />
            </Button>
          </Box>
        )}
        {showClearAddress && (
          <Box bg="white" p={1} pr={2}>
            <Button
              onClick={e =>
                handleClick(clearAddress, "Address Search - Clear address", e)
              }
              tabIndex={0}
              title="Clear current address"
              aria-label="Clear current address"
              data-testid="MapAddress__ClearAddress"
            >
              <IconClose aria-hidden="true" width="20" height="20" />
            </Button>
          </Box>
        )}
        {showFetching && (
          <Box bg="white" p={1} pr={3}>
            <Loader size={18} />
          </Box>
        )}
      </Flex>
    </ButtonsContainer>
  );
};

Buttons.propTypes = {
  showClearAddress: PropTypes.bool,
  showDetails: PropTypes.bool,
  showFetching: PropTypes.bool,
  showLocation: PropTypes.bool,
  clearAddress: PropTypes.func.isRequired,
  getAddressDetails: PropTypes.func,
  getCurrentLocation: PropTypes.func.isRequired,
  category: PropTypes.string,
  gaLabel: PropTypes.string,
};

Buttons.defaultProps = {
  showClearAddress: false,
  showDetails: false,
  showFetching: false,
  showLocation: false,
  category: null,
  gaLabel: null,
  getAddressDetails: null,
};

export default withContext(Buttons);
