import invariant from "invariant";
import uniq from "lodash/uniq";
import get from "lodash/get";
import { combineActions, handleActions } from "redux-actions";
import { persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import { LSE_MODAL_ID } from "../../../components/LSEWarningContent/LSEModal";
import { openModal } from "../modal";

import {
  lsEventRequest,
  lsEventSuccess,
  lsEventFailure,
  lsEventDismiss,
} from "./lsevent.actions";

export const GLOBAL_KEY = "GLOBAL";

const persistConfig = {
  key: "lsevent",
  storage: localStorage,
  whitelist: "dismissed",
};

export const INITIAL_STATE = {
  isFetching: false,
  dismissed: [], // we should only need to persist this
  byHash: {},
  hash: null,
};

export const reducer = handleActions(
  {
    // API Request
    [lsEventRequest]: state => ({ ...state, isFetching: true }),
    // Handle a link of icpNumber to hash
    [lsEventSuccess]: (state, { payload }) => {
      const hash = get(payload, "hash");

      invariant(
        typeof hash !== "undefined",
        "Requires a hash to be specified but %s was given.",
      );

      return {
        ...state,
        byHash: {
          ...state.byHash,
          [hash]: payload,
        },
        // Pointers
        hash,
      };
    },
    // Handle the completion of the API request
    [combineActions(lsEventSuccess, lsEventFailure)]: state => ({
      ...state,
      isFetching: false,
    }),
    // Handle the explicit dismissal of content
    [lsEventDismiss]: (state, { payload: hash }) => ({
      ...state,
      dismissed: uniq([...state.dismissed, hash]),
    }),
    // Handle the reopening of the modal using the openModal action
    [openModal]: (state, { payload }) => {
      // If this is another modal, do nothing
      if (payload !== LSE_MODAL_ID) return state;

      // Else, remove the current hash we're trying to reopen from the dismissed
      // array so we can open it again.
      return {
        ...state,
        dismissed: state.dismissed.filter(h => h !== state.hash),
      };
    },
  },
  INITIAL_STATE,
);

export default persistReducer(persistConfig, reducer);
