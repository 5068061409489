import React from "react";
import styled from "styled-components/macro";
import { Flex, Box, Text } from "rebass";
import { Button as BaseButton } from "../Button";
import { trackEvent } from "../../common/analytics";

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${p => p.theme.colors.deemphasised};
`;

const Button = styled(BaseButton)`
  text-decoration: none;
  text-transform: lowercase;
  &:hover {
    text-decoration: underline;
  }
`;

export const NavigationFooter = () => (
  <Flex flexDirection="column" width={1} pt={20}>
    <Line />
    <Box>
      <Flex alignItems="center" fontSize={[0, null, null, 1]}>
        <Button
          href="https://www.vector.co.nz/privacy-policy"
          variant="text"
          color="deemphasised"
          py={20}
          pr={10}
          pl={0}
          gaTrack={
            /* istanbul ignore next */ () =>
              trackEvent({
                category: "Menu",
                action: "Select Menu Item",
                label: "Privacy Policy",
              })
          }
        >
          Privacy policy
        </Button>
        <Text color="deemphasised">|</Text>
        <Button
          href="https://www.vector.co.nz/terms-of-use"
          variant="text"
          color="deemphasised"
          py={20}
          px={10}
          gaTrack={
            /* istanbul ignore next */ () =>
              trackEvent({
                category: "Menu",
                action: "Select Menu Item",
                label: "Terms of use",
              })
          }
        >
          Terms of use
        </Button>
      </Flex>
    </Box>
  </Flex>
);
