/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "rebass";
import styled from "styled-components/macro";
import PlannedOutageDates from "./_/PlannedOutageDates";
import PlannedOutageStatus from "./_/PlannedOutageStatus";
import PlannedOutageEventInfo from "./_/PlannedOutageEventInfo";
import { DateFormat } from "../DateFormat";

const Container = styled(Box)`
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.14);
  background-color: ${props => props.theme.colors.light};
`;

const FlexContainer = styled(Flex)`
  flex: 1 1 auto;
  justify-content: flex-start;
`;

export const CANCELLED = "Cancelled";
export const POSTPONED = "Postponed";

const GetChangeReasonPrefix = status => {
  switch (status) {
    case CANCELLED:
      return "Cancelled due to ";
    case POSTPONED:
      return "Postponed due to ";
    default:
      return "";
  }
};

const PlannedOutageChangeReason = ({ status, changeReason }) =>
  GetChangeReasonPrefix(status) + changeReason;

export const PlannedOutageCard = ({ plannedOutage, ...props }) => (
  <Container px={[3, 3, 4]} py={4} {...props}>
    <Flex flexDirection="column">
      <Box>
        <FlexContainer>
          <Box width={[0.6, 0.7]}>
            <PlannedOutageEventInfo
              label="reference"
              fontWeight="bold"
              contentMargin={1}
            >
              <Text as="p" m={0}>
                {plannedOutage.switchingProposalNumber}
              </Text>
            </PlannedOutageEventInfo>
          </Box>

          <Box ml="auto">
            <PlannedOutageStatus status={plannedOutage.status} />
          </Box>
        </FlexContainer>
      </Box>

      <Box mt={3}>
        <Text as="p" m={0} fontSize={4} fontWeight="bold">
          {plannedOutage.suburb}
        </Text>
      </Box>

      <PlannedOutageDates
        switchingProposalNumber={plannedOutage.switchingProposalNumber}
        advertisedStartDate={plannedOutage.advertisedStartDate}
        advertisedEndDate={plannedOutage.advertisedEndDate}
        alternativeStartDate={plannedOutage.alternativeStartDate}
        alternativeEndDate={plannedOutage.alternativeEndDate}
        advertisedTimes={plannedOutage.advertisedTimes}
        alternativeTimes={plannedOutage.alternativeTimes}
        status={plannedOutage.status}
        isPostponed={plannedOutage.postponed}
      />

      <Box mt={4}>
        <PlannedOutageEventInfo
          label="type of work"
          fontWeight="bold"
          contentMargin={1}
        >
          <Text as="p" m={0}>
            {plannedOutage.reason}
          </Text>
        </PlannedOutageEventInfo>
      </Box>

      {plannedOutage.changeReason && (
        <Box mt={3}>
          <PlannedOutageEventInfo
            label="reason for change"
            fontWeight="bold"
            contentMargin={1}
          >
            <Text as="p" m={0}>
              <PlannedOutageChangeReason
                status={plannedOutage.status}
                changeReason={plannedOutage.changeReason}
              />
            </Text>
          </PlannedOutageEventInfo>
        </Box>
      )}

      <Box mt={3} alignSelf="flex-end">
        <Text as="p" m={0} fontSize={0}>
          <b>last updated </b>
          <DateFormat time={plannedOutage.updatedAt} format="h:mma DD/MM/YY" />
        </Text>
      </Box>
    </Flex>
  </Container>
);

PlannedOutageCard.propTypes = {
  plannedOutage: PropTypes.shape({
    switchingProposalNumber: PropTypes.string,
    status: PropTypes.string,
    postponed: PropTypes.bool,
    suburb: PropTypes.string,
    reason: PropTypes.string,
    updatedAt: PropTypes.string,
    advertisedStartDate: PropTypes.string,
    advertisedEndDate: PropTypes.string,
    alternativeStartDate: PropTypes.string,
    alternativeEndDate: PropTypes.string,
    advertisedTimes: PropTypes.array,
    alternativeTimes: PropTypes.array,
  }).isRequired,
};

export default PlannedOutageCard;
