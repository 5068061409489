import React from "react";
import ErrorBoundary from "./ErrorBoundary";

export const withErrorBoundary = (config = {}) => WrappedComponent => props => (
  <ErrorBoundary {...config}>
    <WrappedComponent {...props} />
  </ErrorBoundary>
);
withErrorBoundary.propTypes = {
  ...ErrorBoundary.propTypes,
};
withErrorBoundary.defaultProps = {
  ...ErrorBoundary.defaultProps,
};
