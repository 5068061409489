import React from "react";
import PropTypes from "prop-types";
import { Flex, Text } from "rebass";
import styled, { ThemeConsumer } from "styled-components/macro";
import PlannedOutageDateFormatter from "./PlannedOutageDateFormatter";
import StrikedText from "./StrikedText";

const fullDayFormatWithYear = "dddd, Do MMMM YYYY h:mm A";
const dayFormatWithYear = "dddd, DD/MM/YY h:mm A";

const BoxWrapper = styled(Flex)`
  ${p => p.theme.upFrom.lg`
    flex-basis: 0;
    flex-grow: 0.5;
    min-width: 0;
  `}
`;

const ColumnHeader = styled(Text)`
  flex-basis: 0;
  flex-grow: 0.1;
  min-width: 0;

  ${p => p.theme.upFrom.lg`
    display: none;
  `}
`;

const StrikedTextWrapper = styled(StrikedText)`
  ${p => p.theme.upTo.lg`
    flex-basis: 0;
    flex-grow: 0.9;
    min-width: 0;
  `}
`;

const PlannedOutageDateRange = ({ startDate, endDate, isStriked }) => {
  const { innerWidth } = window;

  return (
    <ThemeConsumer>
      {theme => (
        <Flex flexDirection={["column", "column", "column", "row"]} my={2}>
          {/* Start Date */}
          <BoxWrapper mb={[2, 2, 2, 0]}>
            <ColumnHeader
              as="span"
              fontSize={0}
              fontWeight="500"
              lineHeight={1.7}
              pr={[3, 0, 1, 0]}
            >
              Start
            </ColumnHeader>

            <StrikedTextWrapper striked={isStriked} pr={3}>
              <PlannedOutageDateFormatter
                time={startDate}
                format={
                  innerWidth > theme.breakpoints[2]
                    ? fullDayFormatWithYear
                    : dayFormatWithYear
                }
              />
            </StrikedTextWrapper>
          </BoxWrapper>

          {/* End Date */}
          <BoxWrapper>
            <ColumnHeader
              as="span"
              fontSize={0}
              fontWeight="500"
              lineHeight={1.7}
              pr={[3, 0, 1, 0]}
            >
              End
            </ColumnHeader>

            <StrikedTextWrapper striked={isStriked}>
              <PlannedOutageDateFormatter
                time={endDate}
                format={
                  innerWidth > theme.breakpoints[2]
                    ? fullDayFormatWithYear
                    : dayFormatWithYear
                }
              />
            </StrikedTextWrapper>
          </BoxWrapper>
        </Flex>
      )}
    </ThemeConsumer>
  );
};

PlannedOutageDateRange.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  isStriked: PropTypes.bool.isRequired,
};

export default PlannedOutageDateRange;
