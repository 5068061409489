/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withGATracking } from "../../../common/withGATracking";
import { Button } from "../../../components/Button";
import { cancelForm } from "../../../redux/modules/forms";

export class CancelButton extends React.Component {
  static propTypes = {
    cancel: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  handleCancel = async () => {
    const { cancel, history, onClick } = this.props;

    if (typeof onClick === "function") {
      await onClick();
    }

    cancel(null, {
      formName: "createReport",
      history,
      redirectTo: "/",
    });
  };

  render() {
    const { cancel, history, onClick, ...props } = this.props;
    return (
      <Button
        {...props}
        onClick={this.handleCancel}
        block
        variant="ghost"
        mt={10}
      >
        Cancel
      </Button>
    );
  }
}

/* istanbul ignore next */
const enhance = compose(
  withRouter,
  withGATracking,
  connect(
    null,
    {
      cancel: cancelForm,
    },
  ),
);

export default enhance(CancelButton);
