import React from "react";
import { Marker } from "@react-google-maps/api";

export const NetworkBoundaryIcons = ({ iconProperties }) => (
  <>
    <Marker
      position={iconProperties.vectorPosition}
      icon={iconProperties.vectorIcon}
    />
    <Marker
      position={iconProperties.competitorPosition}
      icon={iconProperties.competitorIcon}
    />
  </>
);
