/* eslint-disable no-shadow */
import PropTypes from "prop-types";
import React from "react";
import MediaQuery from "react-responsive";
import { ThemeConsumer } from "styled-components/macro";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Flex, Box } from "rebass";
import { Button } from "../../components/Button";
import { Logo } from "../../assets/icons";
import { LSE_MODAL_ID } from "../../components/LSEWarningContent/LSEModal";
import { NavigationFooter } from "../../components/NavigationFooter";
import { NavigationList } from "../../components/NavigationList";
import { NavigationItem } from "../../components/NavigationList/NavigationItem";
import { isLoggedIn, logoutRequest } from "../../redux/modules/login";
import { openModal } from "../../redux/modules/modal";
import { LSEventConsumer } from "../LSEvent";

export const NavigationContainer = ({
  isLoggedIn,
  onLogout,
  onOpenModal,
  // eslint-disable-next-line react/prop-types
  values,
  ...props
}) => (
  <ThemeConsumer>
    {theme => (
      <MediaQuery
        query={`(max-width: ${theme.breakpoints[1]}px)`}
        values={values}
      >
        {isMatch => (
          <Flex
            role="navigation"
            aria-label="Main Navigation"
            flexDirection="column"
            width={1}
            css="height:100%;"
            bg="dark"
            px={[25, null, 30, 40]}
            pt={[20, null, 15, 20]}
            pb={[10, null, 15, 20]}
            {...props}
          >
            <Box flex="1 1 auto">
              {isMatch && (
                <Box mb={20} mt={10}>
                  <Logo data-testid="mobile_logo" />
                </Box>
              )}

              <LSEventConsumer
                render={({ code }) =>
                  code > 0 && (
                    <Box my={10}>
                      <Button
                        type="text"
                        fontSize={1}
                        border="none"
                        color="text"
                        bg="warning"
                        block
                        lineHeight={[null, 1]}
                        onClick={onOpenModal}
                        px={10}
                        py={15}
                      >
                        important information. read more &#x3E;
                      </Button>
                    </Box>
                  )
                }
              />
              <Box>
                <NavigationList isLoggedIn={isLoggedIn} />
              </Box>
              <Box>
                {/* Logout/Login Button */}
                {isLoggedIn ? (
                  <NavigationItem
                    uri="/logout"
                    onClick={e => {
                      e.preventDefault();
                      onLogout();
                    }}
                    label="Logout"
                  />
                ) : (
                  <NavigationItem uri="/login" label="Login" />
                )}
              </Box>
            </Box>
            <NavigationFooter />
          </Flex>
        )}
      </MediaQuery>
    )}
  </ThemeConsumer>
);
NavigationContainer.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
};
/* istanbul ignore next */
const enhance = compose(
  // Get Routing Props for outage id
  withRouter,
  // Then run redux connect to query for said ID in store
  connect(
    state => ({ isLoggedIn: isLoggedIn(state) }),
    {
      onLogout: logoutRequest,
      onOpenModal: () => openModal(LSE_MODAL_ID),
    },
  ),
);

export default enhance(NavigationContainer);
