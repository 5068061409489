import styled from "styled-components/macro";
import { Text } from "rebass";

const HeaderText = styled(Text).attrs({
  textAlign: "center",
  as: "p",
  m: 0,
  lineHeight: 1.4,
})``;

export default HeaderText;
