import React, { Component } from "react";
import PropTypes from "prop-types";
import GoogleAnalytics from "./analytics";

export const withPageTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options,
    });

    GoogleAnalytics.send({ hitType: "pageview", page });
  };

  // eslint-disable-next-line
  const HOC = class extends Component {
    static propTypes = {
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string,
      }).isRequired,
    };

    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        // eslint-disable-next-line
        this.props.location.pathname + this.props.location.search;

      // istanbul ignore else
      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

// eslint-disable-next-line react/no-multi-comp
export class TrackedModalContent extends React.PureComponent {
  static propTypes = {
    gaTrackingId: PropTypes.string.isRequired,
    children: PropTypes.element,
  };

  static defaultProps = {
    children: null,
  };

  componentDidMount() {
    const { gaTrackingId } = this.props;
    if (!gaTrackingId) {
      throw new Error(
        `A modal is missing a Google Analytics tracking id. Please add one to continue.`,
      );
    }

    GoogleAnalytics.send({
      hitType: "pageview",
      page: `/modal/${gaTrackingId}`,
    });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}
