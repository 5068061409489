import React from "react";
import PropTypes from "prop-types";
import { Box, Flex, Text } from "rebass";
import styled from "styled-components/macro";
import { Button } from "../Button/index";
import { DateFormat } from "../DateFormat/index";
import { ReactComponent as IconWarning } from "./icon-lse-warning.svg";

const LSEContentBody = styled(Flex)`
  height: 100%;
`;

/* eslint-disable react/prop-types, no-shadow */
export const LSEWarningContent = ({ close, content }) => (
  <LSEContentBody
    bg="warning"
    color="text"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    width={1}
    py={[30, null, 50]}
    px={[20, null, 50]}
  >
    {/* Modal Content, starts at top and expands down to fill */}
    <Box flex="1 1 auto" width={1}>
      {/* Warning Icon */}
      <Flex width={1} alignItems="center" justifyContent="center">
        <IconWarning width="125px" height="125px" />
      </Flex>

      {/* Timestamp */}
      <Box width={1} mt={20}>
        <Text
          data-testid="Modal__Timestamp"
          fontSize={2}
          textAlign="center"
          fontWeight="normal"
        >
          LAST UPDATED
          <br />
          <DateFormat time={content.updatedAt} />
        </Text>
      </Box>

      {/* Content (Title + Text) */}
      <Box width={1}>
        <Text
          as="h1"
          fontSize={6}
          textAlign="center"
          data-testid="Modal__Title"
        >
          {content.title}
        </Text>
        {content.description.map((text, i) => (
          <Text
            /* eslint-disable-next-line react/no-array-index-key */
            key={`paragraph-${i}`}
            as="p"
            fontSize={4}
            textAlign="center"
            data-testid="Modal__Text"
          >
            {text}
          </Text>
        ))}
      </Box>
    </Box>

    {/* Action Buttons, always at the bottom of the container */}
    <Box width={1} mt={30}>
      {content.actionLabel && content.actionUrl && (
        <Box width={1} py="5px">
          <Button
            block
            variant="eventWarning"
            href={content.actionUrl}
            data-testid="Modal__Button--action"
          >
            {content.actionLabel}
          </Button>
        </Box>
      )}
      <Box width={1} alignSelf="center" py="5px">
        <Button
          block
          variant="eventTransparent"
          onClick={close}
          data-testid="Modal__Button--close"
        >
          CONTINUE
        </Button>
      </Box>
    </Box>
  </LSEContentBody>
);

LSEWarningContent.propTypes = {
  close: PropTypes.func.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.arrayOf(PropTypes.string),
    actionLabel: PropTypes.string,
    actionUrl: PropTypes.string,
    updatedAt: PropTypes.string,
  }).isRequired,
};

export default LSEWarningContent;
