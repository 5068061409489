import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { fontSize } from "styled-system";
import { Button } from "../Button";
import { isExternalUrl, isLocalProtocol } from "../../common/url-helpers";
import { trackEvent } from "../../common/analytics";

const NavItem = styled.p`
  ${fontSize};
  margin: 0;
  display: block;
  text-decoration: none;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.5;
  margin-top: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid transparent;
  text-transform: lowercase;

  &:focus {
    text-decoration: underline;
    outline: 0;
  }

  &.active {
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
    padding-bottom: 3px;
    margin-bottom: 17px;
  }
`;

export const NavigationItem = ({ label, uri, onClick, ...props }) => {
  const isLocalLink = !isExternalUrl(uri) && !isLocalProtocol(uri);
  /* istanbul ignore next */
  const gaTrack = e => {
    if (onClick) {
      onClick(e);
    }
    trackEvent({
      category: "Menu",
      action: "Select Menu Item",
      label,
    });
  };

  // TODO: See if we can clean this up without a conditional render
  //       Ideally we would be able to use the Button tag and support the "as" prop.
  return isLocalLink ? (
    <NavItem
      {...props}
      fontSize={[4, 3, null, 4]}
      to={uri}
      onClick={e => gaTrack(e)}
      as={NavLink}
    >
      {label}
    </NavItem>
  ) : (
    <NavItem
      {...props}
      fontSize={[4, 3, null, 4]}
      href={uri}
      variant="text"
      textAlign="left"
      as={Button}
      gaTrack={/* istanbul ignore next */ e => gaTrack(e)}
    >
      {label}
    </NavItem>
  );
};

NavigationItem.defaultProps = {
  onClick: null,
};

NavigationItem.propTypes = {
  label: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
