import isEmpty from "lodash/isEmpty";
import omitBy from "lodash/omitBy";
import { createAction } from "redux-actions";

export const REPORT_CREATE = "app/report/CREATE";
export const REPORT_SUCCESS = "app/report/SUCCESS";
export const REPORT_FAILURE = "app/report/FAILURE";
export const REPORT_RESET_ERROR = "app/report/RESET_ERROR";

export const REPORT_FETCH_REQUEST = "app/report/FETCH_REQUEST";
export const REPORT_FETCH_SUCCESS = "app/report/FETCH_SUCCESS";
export const REPORT_FETCH_FAILURE = "app/report/FETCH_FAILURE";

export const reportCreate = createAction(
  REPORT_CREATE,
  formValues =>
    // Filter out empty keys that are defined without values due to the default values in Formik
    Object.entries(formValues).reduce((acc, [key, values]) => {
      const result =
        // If this is a fieldset (object of [fieldname: value])
        typeof values === "object"
          ? // Strip out explicitly undefined ones but keep all other values
            omitBy(values, item => typeof item === "undefined")
          : // if this is not an object just return the value, e.g. strings
            values;

      // If the above filter has resulted in an empty object (no values in the fieldset)
      if (isEmpty(result)) {
        // strip it from the final payload by removing the key
        return acc;
      }

      // If the above is a non-empty object or value, return the filtered result
      return {
        ...acc,
        [key]: result,
      };
    }, {}),
  (_, meta) => meta,
);
export const reportSuccess = createAction(
  REPORT_SUCCESS,
  null,
  (_, meta) => meta,
);
export const reportFailure = createAction(REPORT_FAILURE);
export const reportErrorReset = createAction(REPORT_RESET_ERROR);

export const reportFetchRequest = createAction(REPORT_FETCH_REQUEST);
export const reportFetchSuccess = createAction(REPORT_FETCH_SUCCESS);
export const reportFetchFailure = createAction(REPORT_FETCH_FAILURE);
