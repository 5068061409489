import React from "react";
import styled from "styled-components/macro";
import { Flex, Box, Text } from "rebass";
import { lineHeight } from "styled-system";
import { ReactComponent as LockIcon } from "./_/locked.svg";

const IconBox = styled(Box)`
  ${lineHeight};
`;

export const PrivacyPolicy = () => (
  <Flex alignItems="center">
    <IconBox mr={10} lineHeight={0}>
      <LockIcon width={22} height={27} />
    </IconBox>
    <Box>
      <Text as="p" fontSize={0} m={0}>
        We will only use your information to communicate with you about outages
        and maintenance on our network.
      </Text>
    </Box>
  </Flex>
);
