import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import styled, { ThemeConsumer } from "styled-components";
import { Flex, Box } from "rebass";
import { trackEvent } from "../../common/analytics";
import {
  closeOverlay,
  getOverlayStatus,
  openOverlay,
} from "../../redux/modules/overlays";
import { ReactComponent as MenuIcon } from "../../assets/icons/icon-menu.svg";
import { Button } from "../Button";
import { MenuOverlay } from "./MenuOverlay";

const MenuText = styled(Box)`
  text-transform: lowercase;
  padding-bottom: 2px;
  button & {
    text-decoration: none;
  }
`;

export const MenuButton = ({
  onOpen,
  onClose,
  isOpen,
  values,
  match: { url },
  dataTestid,
}) => {
  useEffect(() => onClose, []);

  const trackClick = useCallback(() => {
    trackEvent({
      category: "App Bar",
      action: "Clicked 'Menu' Button",
      label: url,
    });
  }, [url]);

  return (
    <ThemeConsumer>
      {theme => (
        <MediaQuery
          query={`(max-width: ${theme.breakpoints[1]}px)`}
          values={values}
        >
          {isMatch =>
            isMatch && (
              <>
                <Button
                  data-testid={dataTestid}
                  p={0}
                  gaTrack={trackClick}
                  onClick={onOpen}
                  variant="text"
                  color="primary"
                >
                  <Flex as="span" alignItems="center">
                    <Box mr={1}>
                      <MenuIcon />
                    </Box>
                    <MenuText>Menu</MenuText>
                  </Flex>
                </Button>
                <MenuOverlay
                  data-testid="MenuOverlay"
                  onClose={onClose}
                  isOpen={isOpen}
                />
              </>
            )
          }
        </MediaQuery>
      )}
    </ThemeConsumer>
  );
};
MenuButton.propTypes = {
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  values: PropTypes.object,
  match: PropTypes.object.isRequired,
  // eslint-disable-next-line react/require-default-props
  dataTestid: PropTypes.string,
};
MenuButton.defaultProps = {
  isOpen: false,
  values: undefined,
  dataTestid: "MenuButton",
};

/* istanbul ignore next */
const enhance = compose(
  withRouter,
  connect(
    state => ({
      isOpen: getOverlayStatus(state, "menuOverlay"),
    }),
    {
      onOpen: () => openOverlay("menuOverlay"),
      onClose: () => closeOverlay("menuOverlay"),
    },
  ),
);

export default enhance(MenuButton);
