import { Box, Flex, Text } from "rebass";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components/macro";
import { Button } from "../../Button";
import StyledSwitch from "./StyledSwitch";

const ChangeText = styled(Text)`
  text-transform: uppercase;
`;

const DefaultSubscriptionControl = ({
  disabled,
  handleToogleChange,
  onClickChangeButton,
  checked,
  checkedText,
}) => (
  <Flex alignItems="center" data-testId="SubscriptionControl">
    <Box pl="3px" pr={15}>
      <StyledSwitch
        color="default"
        checked={checked}
        disabled={disabled}
        inputProps={{
          "data-testid": "toogle",
        }}
        onChange={handleToogleChange}
      />
    </Box>
    <Box>
      <Flex flexDirection="column">
        <Box>
          <Text fontSize={2}>
            updates are
            <Text as="span" ml={1}>
              {checkedText}
            </Text>
          </Text>
        </Box>
        <Box>
          <ChangeText fontSize={0} mt={1}>
            <Button
              p={0}
              variant="ghost"
              onClick={onClickChangeButton}
              data-testid="subscriptionControl__change_btn"
            >
              change
            </Button>
          </ChangeText>
        </Box>
      </Flex>
    </Box>
  </Flex>
);

/* istanbul ignore next */
DefaultSubscriptionControl.defaultProps = {
  disabled: false,
};

DefaultSubscriptionControl.propTypes = {
  disabled: PropTypes.bool,
  handleToogleChange: PropTypes.func.isRequired,
  onClickChangeButton: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  checkedText: PropTypes.string.isRequired,
};

export default DefaultSubscriptionControl;
