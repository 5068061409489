export const errorResponseInterceptor = [
  r => r,
  () => error => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const errorStatus = Error(error.response.data.message);
      errorStatus.status = error.response.status;
      throw errorStatus;
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error("Communication error. Check your internet connection");
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error(error);
    }
  },
];
