import ReactGA from "react-ga4";

// istanbul ignore next
ReactGA.initialize(window.config.gaMeasurementId || "used-on-unit-test", {
  testMode: process.env.NODE_ENV === "test",
});

export function trackEvent({ category, action, label }) {
  // Fill out the fieldObject
  let fieldObject = {
    category,
    action,
  };

  // Add the label value
  if (label) fieldObject = { ...fieldObject, label };

  // Hit GA with the custom event data
  ReactGA.event(fieldObject);
}

export default ReactGA;
