/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import { Text } from "rebass";
import styled from "styled-components";

const DecapitalizeText = styled(Text)`
  text-transform: lowercase;
`;

const EventInfo = ({ label, children, compact, contentMargin, ...props }) => (
  <>
    <DecapitalizeText
      data-testid="EventInfo__Label"
      fontSize={compact ? 0 : [1, null, 2]}
      {...props}
    >
      {label}
    </DecapitalizeText>
    <Text
      as="p"
      m={0}
      p={0}
      mt={contentMargin}
      data-testid="EventInfo__Content"
      fontSize={compact ? 1 : [2, null, 4]}
    >
      {children}
    </Text>
  </>
);

EventInfo.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  compact: PropTypes.bool,
  contentMargin: PropTypes.number,
};

EventInfo.defaultProps = {
  compact: false,
  contentMargin: 0,
};

export default EventInfo;
