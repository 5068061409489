import client from "../client";

class LoginService {
  static login({ email, password, passwordless }) {
    return client.post("outagereporter/login", {
      username: email,
      password,
      passwordless,
    }); // eslint-disable-line import/no-named-as-default-member
  }

  static sendOnetimePassword({ email }) {
    return client.post("outagereporter/sendpassword", { email });
  }

  static isLoggedIn() {
    return client.get("outagereporter/loggedin");
  }

  static userInfo() {
    return client.get("outagereporter/userinfo");
  }

  static logout() {
    return client.get("outagereporter/logout"); // eslint-disable-line import/no-named-as-default-member
  }

  static resetPassword({ email }) {
    return client.post("outagereporter/resetpassword", { email }); // eslint-disable-line import/no-named-as-default-member
  }
}

export default LoginService;
