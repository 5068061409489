import { createAction } from "redux-actions";

export const SAVE_FORM = "form/SAVE";
export const CANCEL_FORM = "form/CANCEL";

export const saveForm = createAction(
  SAVE_FORM,
  values => values,
  (_, meta) => meta,
);

export const cancelForm = createAction(CANCEL_FORM, null, (_, meta) => meta);
