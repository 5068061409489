/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { Page } from "../Page";
import { AppBar } from "../AppBar/index";

export const PageWrapper = ({ metaTitle, title, history, children }) => (
  <Flex flexDirection="column" width={1}>
    <Page title={metaTitle} />
    <AppBar>
      <AppBar.Button onClick={history.goBack}>
        <span aria-hidden="true">&lt; </span>BACK
      </AppBar.Button>
      <AppBar.Title>{title}</AppBar.Title>
    </AppBar>
    {children}
  </Flex>
);

PageWrapper.propTypes = {
  metaTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};
