import { createAction } from "redux-actions";

export const OUTAGES_REQUEST = "app/outages/REQUEST";
export const OUTAGES_SUCCESS = "app/outages/SUCCESS";
export const OUTAGES_FAILURE = "app/outages/FAILURE";
export const outagesRequest = createAction(OUTAGES_REQUEST);
export const outagesSuccess = createAction(OUTAGES_SUCCESS);
export const outagesFailure = createAction(OUTAGES_FAILURE);

export const OUTAGES_TICKET_UPDATE = "app/outages/TICKET_UPDATE";
export const OUTAGES_TICKET_UPDATE_SUCCESS =
  "app/outages/TICKET_UPDATE_SUCCESS";
export const OUTAGES_TICKET_UPDATE_FAILURE =
  "app/outages/TICKET_UPDATE_FAILURE";
export const OUTAGES_TICKET_UPDATE_DONE = "app/outages/TICKET_UPDATE_DONE";

export const outagesUpdateTicket = createAction(
  OUTAGES_TICKET_UPDATE,
  (outageId, icpNumber, isResolved) => ({ outageId, icpNumber, isResolved }),
);
export const outagesUpdateTicketSuccess = createAction(
  OUTAGES_TICKET_UPDATE_SUCCESS,
  (outageId, icpNumber, isResolved) => ({ outageId, icpNumber, isResolved }),
);
export const outagesUpdateTicketFailure = createAction(
  OUTAGES_TICKET_UPDATE_FAILURE,
  (outageId, icpNumber, isResolved, err) => ({
    outageId,
    icpNumber,
    isResolved,
    err,
  }),
);
export const outagesUpdateTicketDone = createAction(
  OUTAGES_TICKET_UPDATE_DONE,
  icpNumber => icpNumber,
);

export const OUTAGES_SHAPES_REQUEST = "app/map/shapes/REQUEST";
export const OUTAGES_SHAPES_SUCCESS = "app/map/shapes/SUCCESS";
export const OUTAGES_SHAPES_FAILURE = "app/map/shapes/FAILURE";
export const outageShapesRequest = createAction(OUTAGES_SHAPES_REQUEST);
export const outageShapesSuccess = createAction(OUTAGES_SHAPES_SUCCESS);
export const outageShapesFailure = createAction(OUTAGES_SHAPES_FAILURE);
