import React from "react";
import PropTypes from "prop-types";
import { Box, Flex, Text } from "rebass";
import styled, { css } from "styled-components/macro";
import { prop, switchProp } from "styled-tools";
import { ReactComponent as IconWarning } from "./icon-warning.svg";
import { Button } from "../Button";

const Shape = styled(Flex)`
  border: 3px solid;
  border-radius: 100%;
  padding: 12px 0px 12px 0px;
  width: 75px;
  z-index: 10;
  position: relative;
  top: 12px;

  ${switchProp(prop("type"), {
    WARNING: css`
      color: ${/* istanbul ignore next */ ({ theme }) => theme.colors.text};
      background-color: ${/* istanbul ignore next */ ({ theme }) =>
        theme.colors.white};
      border-color: ${/* istanbul ignore next */ ({ theme }) =>
        theme.colors.warning};
    `,
    ALERT: css`
      color: ${/* istanbul ignore next */ ({ theme }) => theme.colors.orange};
      background-color: ${/* istanbul ignore next */ ({ theme }) =>
        theme.colors.white};
    `,
  })}
`;

const TextBox = styled(Box)`
  border-radius: 5px;

  ${switchProp(prop("type"), {
    WARNING: css`
      color: ${/* istanbul ignore next */ ({ theme }) => theme.colors.text};
      background-color: ${/* istanbul ignore next */ ({ theme }) =>
        theme.colors.warning};
    `,
    ALERT: css`
      color: ${/* istanbul ignore next */ ({ theme }) => theme.colors.white};
      background-color: ${/* istanbul ignore next */ ({ theme }) =>
        theme.colors.error};
    `,
  })}
`;

const Icon = styled(IconWarning)`
  ${switchProp(prop("type"), {
    WARNING: css`
      fill: ${/* istanbul ignore next */ ({ theme }) => theme.colors.warning};
      stroke: ${/* istanbul ignore next */ ({ theme }) => theme.colors.warning};
    `,
    ALERT: css`
      fill: ${/* istanbul ignore next */ ({ theme }) => theme.colors.orange};
      stroke: ${/* istanbul ignore next */ ({ theme }) => theme.colors.orange};
    `,
  })};
`;

const StormWarning = ({ onClick, ...props }) => {
  /* istanbul ignore next */
  const eventType = "WARNING";

  return (
    <Flex zIndex={1000} justifyContent="flex-end" {...props}>
      <Box>
        <Flex alignItems="center" justifyContent="center">
          <Shape type={eventType} alignItems="center" justifyContent="center">
            <Icon type={eventType} />
          </Shape>
        </Flex>
        <TextBox type={eventType} px={13} pt={15} pb={9}>
          <Text as="p" textAlign="center" fontSize={1} m={0}>
            Important
            <br />
            information
          </Text>
        </TextBox>
        <Flex alignItems="center" justifyContent="center" py={1}>
          <Button onClick={onClick} fontSize={0} variant="text">
            <Text as="span" fontSize={0}>
              read more &gt;
            </Text>
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

StormWarning.propTypes = {
  onClick: PropTypes.func,
};
StormWarning.defaultProps = {
  onClick: /* istanbul ignore next */ () => {},
};

export default StormWarning;
