import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  icpStatusFailure,
  icpStatusRequest,
  icpStatusSuccess,
} from "./icps.actions";
import { IcpsService } from "../../../services/IcpsService";
import { outagesSuccess } from "../outages";

export function* getIcpStatus(action) {
  const { icpNumber } = action.payload;

  try {
    const icpStatus = yield call(IcpsService.getIcpStatus, icpNumber);
    yield all([
      put(icpStatusSuccess(icpStatus)),
      put(outagesSuccess({ [icpStatus.outage.id]: icpStatus.outage })),
    ]);
  } catch (err) {
    yield put(icpStatusFailure(err.message));
  }
}

export function* watchForIcpStatusRequest() {
  yield all([takeLatest(icpStatusRequest, getIcpStatus)]);
}
