/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Box, Flex } from "rebass";
import { explicitNull, or } from "airbnb-prop-types";
import { DateFormat } from "../../DateFormat";
import { EventInfo } from "../../EventInfo";

const ReportHeader = ({ subscription, outageStart, address, ...rest }) => (
  <Flex flexDirection="row" width={1} {...rest}>
    <Box width={1 / 2} data-testid="Report__Ticket-id">
      <EventInfo label="REPORT ID" compact>
        {subscription.ticketId}
      </EventInfo>
    </Box>
    <Box width={1 / 2} data-testid="Report__Start">
      <EventInfo label="REPORTED" contentFontSize={1} compact>
        <DateFormat time={outageStart} />
      </EventInfo>
    </Box>
  </Flex>
);

ReportHeader.propTypes = {
  subscription: or([
    explicitNull(),
    PropTypes.shape({
      ticketId: PropTypes.string,
      updateByTxt: PropTypes.bool,
      updateByEmail: PropTypes.bool,
    }),
  ]).isRequired,
  outageId: PropTypes.string.isRequired,
  outageStart: PropTypes.string.isRequired,
};

export default ReportHeader;
