import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Field } from "formik";
import { Box } from "rebass";
import { TextField } from "../Form";

import {
  isRequired,
  isValidEmail,
  isValidPhone,
  maxLength,
  validateWith,
} from "./validate";
import { withAPIErrorHandling } from "../../common/withAPIErrorHandling";

const Row = styled(Box).attrs({ my: 20, width: 1 })``;

export const DEFAULT_FIELD_VALUES = {
  registration: {
    firstName: undefined,
    lastName: undefined,
    phone: undefined,
    email: undefined,
  },
};

const EnhancedField = withAPIErrorHandling(Field);

const RegistrationFormFields = ({ slice }) => (
  <>
    <Row>
      <Field
        name="registration.firstName"
        placeholder="First name"
        validate={validateWith(isRequired, maxLength(50))}
        component={TextField}
        autoComplete="given-name"
        required
      />
    </Row>
    <Row>
      <Field
        name="registration.lastName"
        placeholder="Last name"
        validate={validateWith(isRequired, maxLength(50))}
        component={TextField}
        autoComplete="family-name"
        required
      />
    </Row>
    <Row>
      <Field
        type="tel"
        name="registration.phone"
        placeholder="Mobile number"
        note="We need this in case our repair crew needs to contact you. We can also TXT updates to this number, if you choose"
        validate={validateWith(isRequired, isValidPhone, maxLength(20))}
        component={TextField}
        autoComplete="tel"
        required
      />
    </Row>
    <Row>
      <EnhancedField
        type="email"
        name="registration.email"
        slice={slice}
        placeholder="Email"
        note="This is what you use to login plus we can email updates if you choose"
        validate={validateWith(isRequired, isValidEmail)}
        component={TextField}
        autoComplete="email"
        required
      />
    </Row>
  </>
);

RegistrationFormFields.propTypes = {
  slice: PropTypes.string,
};
RegistrationFormFields.defaultProps = {
  slice: "register",
};

export default RegistrationFormFields;
