import { fork, takeLatest } from "redux-saga/effects";
import { handleRedirect } from "../../sagas/helpers";
import { CANCEL_FORM } from "./form.actions";

export function* cancelFormFn(action) {
  yield fork(handleRedirect, action);
}

export function* watchForFormRequest() {
  yield takeLatest(CANCEL_FORM, cancelFormFn);
}
