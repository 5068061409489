import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useGoogleMap } from "@react-google-maps/api";

export const Outages = /* istanbul ignore next */ ({
  shapes,
  zoomLevel,
  styles,
}) => {
  const addUnplannedOutageBorders = featureCollection => {
    if (!featureCollection) {
      return [];
    }

    featureCollection.features.forEach(feature => {
      // create a border feature for each unplanned outage coordinate
      const { geometry } = feature;
      if (feature.properties.outageType === "Unplanned") {
        geometry.coordinates.forEach(coordinate => {
          featureCollection.features.push({
            ...feature,
            properties: {
              outageType: "UnplannedBorder",
            },
            geometry: {
              coordinates: coordinate,
              type:
                geometry.type === "MultiPolygon"
                  ? "MultiLineString"
                  : "LineString",
            },
          });
        });
      }
    });

    return featureCollection;
  };

  const map = useGoogleMap();

  const [outages] = useState(() => addUnplannedOutageBorders(shapes));

  const updateShapeStyle = feature => {
    const strokeWeight = zoomLevel - 12 > 0 ? zoomLevel - 12 : 0;
    const iconStyle = {};

    if (zoomLevel < 15) {
      iconStyle.scale = 1;
      iconStyle.repeat = "5px";
    } else if (zoomLevel > 15) {
      iconStyle.scale = 3;
      iconStyle.repeat = "20px";
    } else {
      iconStyle.scale = 1.5;
      iconStyle.repeat = "12px";
    }

    const outageStyles = {
      Unplanned: {
        ...styles.default,
        fillColor: styles.unplanned.bg,
        fillOpacity: styles.unplanned.opacity,
      },
      UnplannedBorder: {
        ...styles.default,
        zIndex: 1,
        icons: [
          {
            icon: {
              path: "M 0,-1 0,1",
              strokeOpacity: styles.unplanned.borderOpacity,
              scale: iconStyle.scale,
              strokeWeight,
              strokeColor: styles.unplanned.borderColor,
            },
            offset: "0",
            repeat: iconStyle.repeat,
          },
        ],
      },
      Planned: {
        ...styles.default,
        fillColor: styles.planned.bg,
        fillOpacity: styles.planned.opacity,
        strokeColor: styles.planned.borderColor,
        strokeWeight,
        strokeOpacity: styles.planned.borderOpacity,
      },
    };

    return outageStyles[feature.getProperty("outageType")];
  };

  const updateOutagesStyling = () => {
    map.data.forEach(feature => {
      const updateStyle = ["Unplanned", "UnplannedBorder", "Planned"].includes(
        feature.getProperty("outageType"),
      );

      if (updateStyle) {
        map.data.revertStyle(feature);
        map.data.overrideStyle(feature, updateShapeStyle(feature));
      }
    });
  };

  useEffect(() => {
    map.data.setStyle(feature => updateShapeStyle(feature));
    map.data.addGeoJson(outages);
  }, []);

  useEffect(() => {
    updateOutagesStyling();
  }, [zoomLevel]);

  return null;
};

Outages.propTypes = {
  shapes: PropTypes.object,
  styles: PropTypes.object.isRequired,
};

Outages.defaultProps = {
  shapes: {},
};

export default Outages;
