/* eslint-disable react/prop-types */
import React from "react";
import PropType from "prop-types";
import { Marker as BaseMarker } from "@react-google-maps/api";
import Icon from "../../../assets/icons/icon-mapmarker-pin.svg";

export const Marker = /* istanbul ignore next */ ({
  position,
  children,
  ...props
}) => (
  <BaseMarker
    position={position}
    icon={{
      url: Icon,
      scaledSize: new window.google.maps.Size(50, 50),
    }}
    {...props}
  >
    {children}
  </BaseMarker>
);

Marker.propTypes = {
  position: PropType.object.isRequired,
};
