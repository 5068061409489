import React from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "rebass";
import { or, explicitNull } from "airbnb-prop-types";
import { DateFormat } from "../../DateFormat";
import { ETRRange } from "../../ETRRange";
import { SubscriptionControl } from "../../SubscriptionControl";
import {
  IconTick,
  IconTruck,
  IconHat,
  IconClock,
  IconReported,
} from "../../../assets/icons";
import { EventInfo } from "../../EventInfo";
import { Address } from "../../Address";
import { Circle } from "../../Circle";
import TextLowercase from "./TextLowercase";

const getIcon = type => {
  const props = {
    height: "65%",
    width: "65%",
  };
  switch (type) {
    case "REPORTED":
      return <IconReported data-testid="Icon--reported" {...props} />;
    case "RESOLVED":
      return <IconTick data-testid="Icon--tick" {...props} />;
    case "ENROUTE":
      return <IconTruck data-testid="Icon--truck" {...props} />;
    case "WORKING":
      return <IconHat data-testid="Icon--hat" {...props} />;
    case "INVESTIGATING":
      return <IconClock data-testid="Icon--clock" {...props} />;
    default:
      return <IconHat data-testid="Icon--hat" {...props} />;
  }
};

const ReportDetail = ({
  address,
  etrStart,
  etrEnd,
  updatedAt,
  subscription,
  type,
  label,
  onSubscriptionClick,
  ...rest
}) => {
  const isResolved = type === "RESOLVED";
  return (
    <Flex flexDirection="row" justifyContent="space-between" mt={20} {...rest}>
      <Box pr={20} width={[null, null, null, 1 / 2]}>
        <Address mb={20} data-testid="Report__Address">
          <Text as="p" m={0}>
            {address.street} <br /> {address.suburb}
          </Text>
        </Address>
        {!isResolved && (
          <EventInfo label="Estimated Restoration" compact>
            <Text as="span" fontSize={18}>
              <ETRRange start={etrStart} end={etrEnd} />
            </Text>
          </EventInfo>
        )}
        <Box mt={20}>
          <SubscriptionControl
            subscription={subscription}
            onClickChangeButton={onSubscriptionClick}
            handleToogleChange={onSubscriptionClick}
          />
        </Box>
        {updatedAt && (
          <Box mt={10} ml={65}>
            <EventInfo label="Last Update" compact>
              <DateFormat time={updatedAt} />
            </EventInfo>
          </Box>
        )}
      </Box>
      <Box width={[null, null, null, 1 / 2]}>
        <Flex flexDirection="column" alignItems="center" width={95}>
          <Box>
            <Circle
              bg={isResolved ? "secondary" : "transparent"}
              size={70}
              flat
              border={isResolved ? null : "2px solid black"}
            >
              {getIcon(type)}
            </Circle>
          </Box>
          <TextLowercase as="p" mt={10} fontSize={2} textAlign="center">
            {label}
          </TextLowercase>
        </Flex>
      </Box>
    </Flex>
  );
};

ReportDetail.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string,
    suburb: PropTypes.string,
  }).isRequired,
  etrStart: or([explicitNull(), PropTypes.string]).isRequired,
  etrEnd: or([explicitNull(), PropTypes.string]).isRequired,
  updatedAt: or([explicitNull(), PropTypes.string]).isRequired,
  subscription: PropTypes.shape({
    ticketId: PropTypes.string,
    updateByTxt: PropTypes.bool,
    updateByEmail: PropTypes.bool,
  }).isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onSubscriptionClick: PropTypes.func.isRequired,
};

export default ReportDetail;
