import get from "lodash/get";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { LSEventService } from "../../../services/LSEventService";
import {
  lsEventRequest,
  lsEventSuccess,
  lsEventFailure,
} from "./lsevent.actions";

export function* getLsEventData(action) {
  try {
    const context = get(action, "meta.context");
    const response = yield call(LSEventService.getStatus, context);

    if (response.data) {
      yield put(lsEventSuccess(response.data, context));
    } else {
      throw new Error("Response did not contain any data");
    }
  } catch (err) {
    yield put(lsEventFailure(err));
  }
}

export function* watchForLsEventActions() {
  yield all([takeLatest(lsEventRequest, getLsEventData)]);
}
