import styled from "styled-components/macro";

export default styled.div`
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
