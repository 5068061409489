const PLANNED = {
  id: "0123456789",
  address: {
    street: "60 Access Road",
    suburb: "Kumeu",
    hasAvailableProducts: true,
  },
  eventType: "Planned",
  etrStart: "2019-02-01T02:30:00Z",
  etrEnd: "2019-02-01T03:30:00Z",
  outageStart: "2019-01-30T19:37:00Z",
  workStatus: [
    { label: "Work starts", type: "START", complete: true, active: false },
    {
      label: "Planned maintenance",
      type: "WORKING",
      complete: false,
      active: true,
    },
    {
      label: "Work finishes",
      type: "RESOLVED",
      complete: false,
      active: false,
    },
  ],
  outageResolvedFlag: "",
  transformerLongitude: "",
  transformerLatitude: "",
  outageId: "12343",
  subscription: null,
};

const UNPLANNED_RESOLVED = {
  id: "0123456789",
  address: {
    street: "101 CARLTON GORE ROAD",
    suburb: "Newmarket",
    hasAvailableProducts: true,
  },
  eventType: "UnPlanned",
  etrStart: "2019-01-15T21:15:00Z",
  etrEnd: "2019-01-15T22:15:00Z",
  outageStart: "2019-01-14T22:01:33.859Z",
  workStatus: [
    {
      label: "Outage reported",
      type: "REPORTED",
      complete: true,
      active: false,
    },
    {
      label: "Outage processed",
      type: "START",
      complete: true,
      active: false,
    },
    {
      label: "On our way",
      type: "ENROUTE",
      complete: true,
      active: false,
    },
    {
      label: "Resolving",
      type: "WORKING",
      complete: true,
      active: false,
    },
    {
      label: "Outage resolved",
      type: "RESOLVED",
      complete: true,
      active: false,
    },
  ],
  outageResolvedFlag: "",
  transformerLongitude: "",
  transformerLatitude: "",
  outageId: "1-1HM3PRE",
  subscription: null,
};
const UNPLANNED_INVESTIGATING = {
  id: "1001536952",
  address: {
    street: "101 CARLTON GORE ROAD",
    suburb: "Newmarket",
    hasAvailableProducts: true,
  },
  eventType: "UnPlanned",
  etrStart: "2019-01-15T21:15:00Z",
  etrEnd: undefined,
  outageStart: "2019-01-14T22:01:33.859Z",
  holdingMessage: {
    type: "Cause unconfirmed",
    shortMessage: "Difficulty in identifying outage cause",
    longMessage:
      "We know it’s been a while; we can’t provide you with an estimated time to restore yet, as it’s taking longer than expected to identify the cause for this outage. We will provide an update as soon as we can.",
  },
  workStatus: [
    {
      label: "Outage reported",
      type: "REPORTED",
      complete: true,
      active: false,
    },
    {
      label: "Outage processed",
      type: "START",
      complete: true,
      active: false,
    },
    {
      label: "On our way",
      type: "ENROUTE",
      complete: true,
      active: false,
    },
    {
      label: "Resolving",
      type: "WORKING",
      complete: false,
      active: true,
    },
    {
      label: "Outage resolved",
      type: "RESOLVED",
      complete: false,
      active: false,
    },
  ],
  outageResolvedFlag: "",
  transformerLongitude: "",
  transformerLatitude: "",
  outageId: "1-1HM3PRE",
  subscription: null,
};

const RESOLVED_SUBSCRIBED = {
  ...UNPLANNED_RESOLVED,
  subscription: { ticketId: "5043", updateByTxt: true, updateByEmail: false },
};

const UNKNOWN = {
  id: "0123456789",
  address: {
    street: "6 Summer Street",
    suburb: "Ponsonby",
    hasAvailableProducts: true,
  },
  eventType: "UNKNOWN",
  etrStart: null,
  etrEnd: null,
  outageStart: null,
  workStatus: null,
  outageResolvedFlag: "",
  transformerLongitude: "",
  transformerLatitude: "",
  outageId: "",
  subscription: null,
};
const NOPRODUCTS = {
  id: "0123456789",
  address: {
    street: "6 Summer Street",
    suburb: "Ponsonby",
    hasAvailableProducts: false,
  },
  eventType: null,
  etrStart: null,
  etrEnd: null,
  outageStart: null,
  workStatus: null,
  outageResolvedFlag: null,
  transformerLongitude: null,
  transformerLatitude: null,
  outageId: null,
  subscription: null,
};

export default {
  PLANNED,
  UNKNOWN,
  UNPLANNED_RESOLVED,
  RESOLVED_SUBSCRIBED,
  UNPLANNED_INVESTIGATING,
  NOPRODUCTS,
};
