import PropTypes from "prop-types";
import React from "react";
import { Box, Flex, Text } from "rebass";
import { Field, connect, getIn } from "formik";
import styled from "styled-components/macro";
import { Button } from "../../../components/Button";
import { Checkbox } from "../../../components/Checkbox";
import {
  isOptionRequired,
  isRequired,
  isValidMobileAndLandLinePhone,
  maxLength,
  validateOnCondition,
  validateWith,
} from "../../../components/RegistrationFormFields/validate";
import { TextField } from "../../../components/Form";
import { RadioButton } from "../../../components/RadioButton";
import { trackReportEvent } from "../eventTracking";
import CancelButton from "./CancelButton";
import { ErrorMessage } from "../../../components/FieldErrors";

export const DEFAULT_FIELD_VALUES = {
  access: {
    dog: undefined,
    lockedGate: undefined,
    meterInside: undefined,
    contactOthers: undefined,
    contactName: undefined,
    contactPhone: undefined,
    extra: undefined,
    covid: undefined,
  },
};

const LineWrapper = styled.hr`
  opacity: 0.2;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grayWhite};
  border: none;
`;

const BoxWrapper = styled(Box)`
  flex-grow: 0.5;
`;

export const AccessFields = ({
  formik: { values, validateForm },
  match: {
    url,
    params: { icpNumber },
  },
}) => (
  <Flex bg="light" flexWrap="wrap" justifyContent="center" alignItems="center">
    <Box width={1}>
      <Flex flexDirection="column">
        <Box mb={20}>
          <Text fontSize={4} textAlign="center" mb={10}>
            Do any of the below
            <br />
            apply to your property?
          </Text>
          <Text fontSize={2} textAlign="center">
            This will help our repair crew gain access as quickly as possible,
            if required
          </Text>
        </Box>
        <Box my={10}>
          <Field
            name="access.dog"
            label="I have a dog(s) on my property"
            component={Checkbox}
          />
        </Box>
        <Box my={10}>
          <Field
            name="access.lockedGate"
            label="I have a locked gate"
            component={Checkbox}
          />
        </Box>
        <Box my={10}>
          <Field
            name="access.meterInside"
            label="My meter is inside the house"
            component={Checkbox}
          />
        </Box>
        <Box my={10}>
          <Field
            name="access.contactOthers"
            onChange={() => setTimeout(validateForm, 0)} // while ugly, we need to force the validation to run AFTER the next render, setTimeout with 0ms delay will execute this function in the next render cycle
            label={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Box>
                <Text fontSize={2}>
                  Someone else needs to be contacted if access is required
                </Text>
                <Text fontSize={1}>
                  e.g. building manager, receptionist, flatmate etc
                </Text>
              </Box>
            }
            component={Checkbox}
            multiline
          />
        </Box>
        <Box pl={35} my={15}>
          <Box mb={10}>
            <Field
              name="access.contactName"
              disabled={!getIn(values, "access.contactOthers")}
              required={getIn(values, "access.contactOthers")}
              validate={validateOnCondition(
                getIn(values, "access.contactOthers"),
              )(isRequired, maxLength(50))}
              placeholder="Contact Name"
              component={TextField}
            />
          </Box>
          <Box>
            <Field
              name="access.contactPhone"
              disabled={!getIn(values, "access.contactOthers")}
              required={getIn(values, "access.contactOthers")}
              validate={validateOnCondition(
                getIn(values, "access.contactOthers"),
              )(isRequired, isValidMobileAndLandLinePhone)}
              placeholder="Contact Phone"
              component={TextField}
            />
          </Box>
        </Box>
        <Box mt={15}>
          <Text fontSize={4}>Anything else to add?</Text>
        </Box>
        <Box mt={10} mb={4}>
          <Field
            name="access.extra"
            label="Did you see or hear anything like loud bangs or flashes?"
            component={TextField}
            multiline
          />
        </Box>
        <LineWrapper />
        <Flex flexDirection="column" mt={4}>
          <Box mb={3}>
            <Text fontSize={4}>Important Covid information</Text>
          </Box>
          <Box>
            <Text fontSize={2} mb={3}>
              Is anyone at your property in isolation, a confirmed or probable
              case, or awaiting a Covid test result?
            </Text>
          </Box>
          <Flex flexDirection="column">
            <Flex my={10} justifyContent="space-between">
              <BoxWrapper mr={3}>
                <Field
                  name="access.covid"
                  label="Yes"
                  validate={validateWith(isOptionRequired)}
                  value="yes"
                  type="radio"
                  variant="ghost"
                  component={RadioButton}
                />
              </BoxWrapper>
              <BoxWrapper>
                <Field
                  name="access.covid"
                  label="No"
                  validate={validateWith(isOptionRequired)}
                  value="no"
                  type="radio"
                  variant="ghost"
                  component={RadioButton}
                />
              </BoxWrapper>
            </Flex>
            <ErrorMessage name="access.covid" />
          </Flex>
        </Flex>
        <Box alignSelf="center" width={1} mt={5}>
          <Button
            gaTrack={trackReportEvent(icpNumber, url, "Clicked 'Next' button")}
            type="submit"
            variant="dark"
            block
          >
            NEXT
          </Button>
        </Box>
        <CancelButton />
      </Flex>
    </Box>
  </Flex>
);

AccessFields.propTypes = {
  formik: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default connect(AccessFields);
