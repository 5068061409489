import { useState, useCallback } from "react";
import moment from "moment";

export const usePlannedOutageDateToggle = (
  advertisedStartDate,
  advertisedEndDate,
  alternativeStartDate,
  alternativeEndDate,
  advertisedTimes,
  alternativeTimes,
  status,
  isPostponed,
) => {
  const [isAlternateDateVisible, setAlternateDateVisible] = useState(false);

  const toggleDate = useCallback(() => {
    setAlternateDateVisible(!isAlternateDateVisible);
  }, [isAlternateDateVisible]);

  const isCancelled = status === "Cancelled";
  const showAlternateDate = isAlternateDateVisible || isPostponed;

  const plannedOutageStartDate = showAlternateDate
    ? alternativeStartDate
    : advertisedStartDate;

  const plannedOutageEndDate = showAlternateDate
    ? alternativeEndDate
    : advertisedEndDate;

  const selectedOutageTimes = showAlternateDate
    ? alternativeTimes
    : advertisedTimes;

  const plannedOutageDateTimes = selectedOutageTimes.map(time => {
    const startDate = moment(
      `${plannedOutageStartDate} ${time.start}`,
      "YYYY-MM-DD HH:mm",
    ).toISOString();

    const endDate = moment(
      `${plannedOutageEndDate} ${time.end}`,
      "YYYY-MM-DD HH:mm",
    ).toISOString();

    return {
      startDate,
      endDate,
    };
  });

  const advertisedOutageDateTimes = advertisedTimes.map(time => {
    const startDate = moment(
      `${advertisedStartDate} ${time.start}`,
      "YYYY-MM-DD HH:mm",
    ).toISOString();

    const endDate = moment(
      `${advertisedEndDate} ${time.end}`,
      "YYYY-MM-DD HH:mm",
    ).toISOString();

    return {
      startDate,
      endDate,
    };
  });

  const showAlternateDateToggleButton =
    !(isPostponed || isCancelled) && !!alternativeStartDate;

  const toggleButtonLabel = isAlternateDateVisible
    ? "show original outage date(s) & time(s)"
    : "show alternative date(s) & time(s)";

  return [
    toggleButtonLabel,
    toggleDate,
    isCancelled,
    showAlternateDateToggleButton,
    plannedOutageDateTimes,
    advertisedOutageDateTimes,
  ];
};
