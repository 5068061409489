import { combineActions, handleActions } from "redux-actions";
import { withApiErrors } from "../errors";
import {
  clearAddress,
  failureAddressByCoords,
  failureAddressByText,
  requestAddressByCoords,
  requestAddressByText,
  selectAddress,
  successAddressByCoords,
  successAddressByText,
  requestAddressCoords,
  requestAddressCoordsSuccess,
  requestAddressCoordsFailure,
  updateAddressText,
  clearAddressId,
} from "./addresses.actions";
import { getDeviceCoordsRequest, getDeviceCoordsFailure } from "../location";

export const INITIAL_STATE = {
  byId: {},
  allIds: [],
  isFetching: false,
  selected: null,
  text: "",
  coordinates: {},
};
export const reducer = handleActions(
  {
    [combineActions(
      requestAddressByCoords,
      requestAddressByText,
      getDeviceCoordsRequest,
      requestAddressCoords,
    )]: state => ({
      ...state,
      // byId: {},
      // allIds: [],
      isFetching: true,
    }),
    [combineActions(
      successAddressByCoords,
      successAddressByText,
      failureAddressByCoords,
      failureAddressByText,
      requestAddressCoordsFailure,
      getDeviceCoordsFailure,
    )]: state => ({
      ...state,
      isFetching: false,
    }),
    [combineActions(successAddressByCoords, successAddressByText)]: (
      state,
      action,
    ) => ({
      ...state,
      // Overrides as these data points are only relevant while active
      byId: action.payload.byId,
      allIds: action.payload.allIds,
    }),
    // TODO: Refactor this hook so that the "selectAddress" is used consistently
    //       or so that the naming of this action type reflects its "GsuccessAddressByCoordsPS" use better
    [successAddressByCoords]: (state, action) => ({
      ...state,
      selected: action.payload.allIds[0],
    }),
    [selectAddress]: (state, action) => ({
      ...state,
      selected: action.payload.id,
    }),
    [updateAddressText]: (state, action) => ({
      ...state,
      text: action.payload.address,
    }),
    [clearAddress]: state => ({
      ...state,
      byId: {},
      allIds: [],
      isFetching: false,
      selected: null,
      text: "",
    }),
    [clearAddressId]: state => ({
      ...state,
      byId: {},
      allIds: [],
      isFetching: false,
      selected: null,
    }),
    [requestAddressCoordsSuccess]: (state, action) => ({
      ...state,
      isFetching: false,
      coordinates: {
        ...state.coordinates,
        [action.meta.icpNumber]: action.payload,
      },
    }),
  },
  INITIAL_STATE,
);

export default withApiErrors(reducer, "addresses", {
  clear: [
    successAddressByCoords,
    successAddressByText,
    requestAddressByCoords,
    requestAddressByText,
    clearAddress,
  ],
  capture: [failureAddressByCoords, failureAddressByText],
});
