import React from "react";
import PropTypes from "prop-types";
import { or, explicitNull } from "airbnb-prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Flex, Box, Text } from "rebass";
import { withTheme } from "styled-components/macro";
import { Button } from "../../components/Button";
import { Circle } from "../../components/Circle";
import { Loader } from "../../components/Loader";
import {
  getHistoricEvents,
  getIsFetching,
  getIsOpen,
  getLatestEvent,
  toggleEventHistoryDisplay,
  requestEventHistory,
} from "../../redux/modules/event-history";
import { EventHistoryList } from "./_/EventHistoryList";
import {
  EventHistoryItem,
  propTypes as EventHistoryItemPropTypes,
} from "./_/EventHistoryItem";
import { ReactComponent as IconChevron } from "./back-chevron.svg";

export class EventHistoryContainer extends React.Component {
  static propTypes = {
    latestEvent: or([
      explicitNull(),
      PropTypes.shape(EventHistoryItemPropTypes),
    ]).isRequired,
    historicEvents: PropTypes.arrayOf(
      PropTypes.shape(EventHistoryItemPropTypes),
    ).isRequired,
    icpNumber: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    isFetching: PropTypes.bool,
    onRequestEventHistory: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isOpen: false,
    isFetching: false,
  };

  componentDidMount() {
    const { onRequestEventHistory, icpNumber } = this.props;

    onRequestEventHistory(icpNumber);
  }

  renderEmptyState = () => (
    <Flex data-testid="EventHistory">
      <Text>
        There are currently no updates for this outage. Please check back again
        later.
      </Text>
    </Flex>
  );

  renderToggle = () => {
    const { isOpen, onToggle, theme } = this.props;
    const text = isOpen ? "Close" : "View";

    return (
      <Button
        data-testid="EventHistory__ToggleButton"
        onClick={onToggle}
        variant="text"
        py={3}
      >
        <Flex as="span" flexWrap="nowrap" alignItems="center">
          <Box as="span" flex="0 0 auto" mr={8}>
            <Circle
              as="span"
              flat
              size={17}
              rotate={isOpen ? 180 : 0}
              color={theme.colors.accent}
              bg={theme.colors.white}
              border={`1px solid ${theme.colors.accent}`}
            >
              <IconChevron />
            </Circle>
          </Box>
          <Box as="span">{text} previous updates</Box>
        </Flex>
      </Button>
    );
  };

  render() {
    const { latestEvent, historicEvents, isFetching, isOpen } = this.props;

    // Loader
    if (isFetching) {
      return <Loader data-testid="EventHistory" size={30} />;
    }

    // Empty State
    if (!latestEvent) {
      return this.renderEmptyState();
    }

    // Data List
    return (
      <Flex
        role="list"
        aria-label="Outage Event History"
        data-testid="EventHistory"
        flexDirection="column"
        flexWrap="nowrap"
        alignItems="stretch"
      >
        {/* First item */}
        <Box>
          <EventHistoryItem
            role="listitem"
            url={latestEvent.url}
            timestamp={latestEvent.timestamp}
            id="latestEventItem"
          >
            {latestEvent.message}
          </EventHistoryItem>
        </Box>
        {/* Toggle */}
        {!!historicEvents.length && <Box>{this.renderToggle()}</Box>}
        {/* List */}
        {isOpen && (
          <Box>
            <EventHistoryList>
              {historicEvents.map(item => (
                <EventHistoryItem
                  role="listitem"
                  timestamp={item.timestamp}
                  url={item.url}
                >
                  {item.message}
                </EventHistoryItem>
              ))}
            </EventHistoryList>
          </Box>
        )}
      </Flex>
    );
  }
}

/* istanbul ignore next */
const enhance = compose(
  withTheme,
  connect(
    (state, ownProps) => ({
      latestEvent: getLatestEvent(state, ownProps.icpNumber),
      historicEvents: getHistoricEvents(state, ownProps.icpNumber),
      isOpen: getIsOpen(state),
      isFetching: getIsFetching(state),
    }),
    {
      onToggle: toggleEventHistoryDisplay,
      onRequestEventHistory: requestEventHistory,
    },
  ),
);

export default enhance(EventHistoryContainer);
