import React from "react";
import PropTypes from "prop-types";
import { DateFormat } from "../../DateFormat";

const PlannedOutageDateFormatter = ({ time, format }) => (
  <DateFormat
    time={time}
    format={format}
    prioritiseRelativeDay={false}
    relativeDayWithTime={false}
  />
);

PlannedOutageDateFormatter.defaultProps = {
  time: null,
  format: null,
};

PlannedOutageDateFormatter.propTypes = {
  time: PropTypes.string,
  format: PropTypes.string,
};

export default PlannedOutageDateFormatter;
