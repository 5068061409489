import { style, propTypes } from "styled-system";
import { Box } from "rebass";
import styled from "styled-components/macro";

const fontStyle = style({
  prop: "fontStyle",
  cssProperty: "fontStyle",
});

const Address = styled(Box).attrs({ as: "address" })`
  ${fontStyle};
`;

Address.propTypes = {
  fontSize: propTypes.responsive,
  ...fontStyle.propTypes,
};

Address.defaultProps = {
  fontSize: 4,
  fontStyle: "normal",
};

export default Address;
