import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Flex, Box } from "rebass";
import { AppBar } from "../components/AppBar";
import { Page } from "../components/Page";
import { MyReportsContainer } from "../containers/MyReportsContainer";

const MyReportsView = ({ history }) => (
  <Flex flexDirection="column" width={1}>
    <Page title="My Reports" />
    <Box flex="0 1 0">
      <AppBar>
        <AppBar.Button onClick={history.goBack}>
          <span aria-hidden="true">&lt; </span>BACK
        </AppBar.Button>
        <AppBar.Title>MY REPORTS</AppBar.Title>
      </AppBar>
    </Box>
    <Box width={1} flex="1 1 auto" bg="light" p={25}>
      <MyReportsContainer />
    </Box>
  </Flex>
);

MyReportsView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(MyReportsView);
