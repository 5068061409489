/* eslint-disable react/prop-types */
/* istanbul ignore next */
import PropTypes from "prop-types";
import { useGoogleMap } from "@react-google-maps/api";

const Center = ({ position, ...props }) => {
  const map = useGoogleMap();
  if (map) {
    map.panTo(position);
    map.setCenter(position);
  }
  return props.children || null;
};

Center.propTypes = {
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
};

export default Center;
