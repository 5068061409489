import { createSelector } from "reselect";

export const isLoading = createSelector(
  [state => state.reports.loading],
  loading => !!loading,
);

export const getSubscribedOutages = createSelector(
  [state => state.reports.outages, state => state.outages.byId],
  (ids, outages) => {
    if (!ids) {
      return [];
    }

    return ids.map(id => outages[id]).filter(outage => outage.subscription);
  },
);
