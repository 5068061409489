import { createSelector } from "reselect";

export const getStormZoneDetails = createSelector(
  [state => state.icpStatus.icpStatus, state => state.stormZonesId.stormZone],
  (icpStatus, stormZone) => {
    if (!icpStatus && !stormZone) {
      return null;
    }

    if (icpStatus && icpStatus.stormZone) {
      return icpStatus.stormZone;
    }

    return stormZone;
  },
);
