import moment from "moment";

export const DATE_FORMAT_LONG = "DD/MM/YYYY";
export const DATE_FORMAT_TIME = "h.mma";
export const DATE_FORMAT_FULL = `${DATE_FORMAT_LONG} ${DATE_FORMAT_TIME}`;

export const DATE_FORMAT_TODAY = `[Today]`;
export const DATE_FORMAT_TOMORROW = `[Tomorrow]`;
export const DATE_FORMAT_YESTERDAY = `[Yesterday]`;

export const DATETIME_FORMAT_TODAY = `${DATE_FORMAT_TIME} [today]`;
export const DATETIME_FORMAT_TOMORROW = `${DATE_FORMAT_TIME} [tomorrow]`;
export const DATETIME_FORMAT_YESTERDAY = `${DATE_FORMAT_TIME} [yesterday]`;

// Helpers

/**
 * Returns a boolean indicating if the needle is within the upper/lower bounds
 *
 * @param needle - UTC Timestamp
 * @param lower - UTC Timestamp
 * @param upper - UTC Timestamp
 * @return {boolean}
 */
export function isWithinRange(needle, lower, upper) {
  return moment.utc(needle).isBetween(lower, upper, null, "[)");
}
export function startOfToday() {
  return moment()
    .startOf("day")
    .utc();
}
export function endOfToday() {
  return moment()
    .endOf("day")
    .utc();
}
export function startOfTomorrow() {
  return moment()
    .add(1, "day")
    .startOf("day")
    .utc();
}
export function endOfTomorrow() {
  return moment()
    .add(1, "day")
    .endOf("day")
    .utc();
}
export function startOfYesterday() {
  return moment()
    .subtract(1, "day")
    .startOf("day")
    .utc();
}
export function endOfYesterday() {
  return moment()
    .subtract(1, "day")
    .endOf("day")
    .utc();
}
export function isYesterday(time) {
  return isWithinRange(time, startOfYesterday(), endOfYesterday());
}
export function isToday(time) {
  return isWithinRange(time, startOfToday(), endOfToday());
}
export function isTomorrow(time) {
  return isWithinRange(time, startOfTomorrow(), endOfTomorrow());
}

/**
 * Return a boolean indicating whether the timestamp falls within our -1|0|+1
 * "nearby" range that uses worded [today] [yesterday] [tomorrow] descriptors
 * @param time - UTC input date
 * @return {Boolean}
 */
export const isNearbyDate = time =>
  isYesterday(time) || isToday(time) || isTomorrow(time);

/**
 * Date Formatting Rules:
 * - TIMESTAMP within 'today' => 'Today'
 * - TIMESTAMP within 'tomorrow' => 'Tomorrow'
 * - TIMESTAMP within 'yesterday' => 'Yesterday'
 * - TIMESTAMP outside of '-1 / 0 / +1' => Formatted Date string (date + time)
 *
 * For the patterns look at: https://momentjs.com/docs/#/displaying/format/
 *
 * @param {string} timestamp - ISO 8601 datetime string
 * @param {string} format - Moment.js formatter string
 * @param {boolean} prioritiseRelativeDay - return values Today, Yesterday, or Tomorrow if relevant to the provided timestamp
 * @param {boolean} relativeDayWithTime - return the relative day with or without the time
 * @return {string}
 */
export const getFormattedDateTime = (
  timestamp,
  format = null,
  prioritiseRelativeDay = false,
  relativeDayWithTime = true,
) => {
  // Normalize the input to UTC
  const time = moment.utc(timestamp);

  let formatter = format || DATE_FORMAT_FULL;

  // Get the flags for whether the input falls within today, tomorrow or yesterday
  const today = isToday(time);
  const tomorrow = isTomorrow(time);
  const yesterday = isYesterday(time);

  // Switch formatter based on flags
  if (!format || prioritiseRelativeDay) {
    if (yesterday)
      formatter = relativeDayWithTime
        ? DATETIME_FORMAT_YESTERDAY
        : DATE_FORMAT_YESTERDAY;
    if (tomorrow)
      formatter = relativeDayWithTime
        ? DATETIME_FORMAT_TOMORROW
        : DATE_FORMAT_TOMORROW;
    if (today)
      formatter = relativeDayWithTime
        ? DATETIME_FORMAT_TODAY
        : DATE_FORMAT_TODAY;
  }

  // Return formatted string
  return time.local().format(formatter);
};

/**
 * Returns a boolean if the input UTC datetime strings are within the same day
 * @param t1 - UTC Timestamp
 * @param t2 - UTC Timestamp
 * @return {boolean}
 */
export const isSameDay = (t1, t2) =>
  moment
    .utc(t1) // set it as UTC as we know the input is in UTC
    .local() // convert to local timezone
    .isSame(
      moment
        .utc(t2) // input as UTC
        .local(), // convert to local time
      "day",
    );

/**
 * Return a formatted date range text according to the business rules:
 *  - If the start and end are on the same day:
 *    1. within a -1 | 0 | +1 range: Only print "[time] - [time] [keyword]"
 *    2. outside the range: Only print "[date] [time] - [time]"
 *  - If the start and end days are on different days print according to regular rules:
 *    1. Yesterday = "[time] yesterday"
 *    2. Today = "[time] today"
 *    3. Tomorrow = "[time] tomorrow"
 *    4. Future or Past = "[date] [time}"
 * @param t1 - UTC Timestamp
 * @param t2 - UTC Timestamp
 * @return {string}
 */
export const getFormattedDateTimeRange = (t1, t2) => {
  const isStartNearby = isNearbyDate(t1);
  const isEndNearby = isNearbyDate(t2);

  let startFormatter = null;
  let endFormatter = null;

  // Apply special formatting only when date ranges are on the same day
  if (isSameDay(t1, t2)) {
    if (
      // When start and end dates are within the window
      // AND the same day
      isStartNearby &&
      isEndNearby
    ) {
      // the first datetime should only show time and the keyword should be printed last
      startFormatter = DATE_FORMAT_TIME;
    } else {
      // same day but not within the window, only print the date once at the start
      endFormatter = DATE_FORMAT_TIME;
    }
  }

  // Return the formatted date time range
  return `${getFormattedDateTime(t1, startFormatter)} - ${getFormattedDateTime(
    t2,
    endFormatter,
  )}`;
};
