import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const StyledSwitch = withStyles({
  root: {
    width: "auto",
  },
  icon: { color: "#f1f1f1", marginLeft: "-28px" },
  iconChecked: { color: "#1f79ff", marginLeft: "0px" },

  bar: {
    background: "rgba(34, 31, 31, 0.26)",
    opacity: 1,
  },
  checked: {
    "& + $bar": {
      background: "#1f79ff",
      opacity: 0.7,
    },
  },
})(Switch);

export default StyledSwitch;
