import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import { ifProp } from "styled-tools";
import { Flex } from "@rebass/grid";

// Strip out or custom prop "fill" so it's not passed on to the real DOM
// as an invalid HTML attribute which would cause a console.warn
// eslint-disable-next-line react/prop-types
const Base = ({ fill, ...props }) => <Flex {...props} />;

// Extend the base with our custom props
export const Container = styled(Base)`
  min-height: 100%;
  ${ifProp(
    "fill",
    css`
      min-height: 100vh;
    `,
  )};
  ${props => props.theme.upTo.md`
    min-height: calc(100vh - 48px);
  `};
`;

Container.propTypes = {
  ...Flex.propTypes,
  fill: PropTypes.bool,
};

Container.defaultProps = {
  ...Flex.defaultProps,
  fill: false,
};

export default Container;
