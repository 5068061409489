/* eslint-disable react/prop-types */
import React from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import styled, { ThemeConsumer } from "styled-components/macro";
import { Flex, Box } from "rebass";
import { minHeight } from "styled-system";
import { Container } from "../Container";
import { NavigationContainer } from "../../containers/NavigationContainer";
import { SkipToContent } from "../SkipToContent";
import { Logo } from "../../assets/icons";

const Background = styled(Container)`
  background-color: ${p => p.theme.colors.light};
  background-color: #f1f1f1;
`;
const OuterContainer = styled(Box)`
  width: 100%;
  max-width: 1500px;
`;
const PageWrapper = styled(Flex)`
  ${minHeight};
  height: 100%;
  max-height: 100%;

  ${props => props.theme.upFrom.md`
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  `};
`;
const NavigationPane = styled(Box)`
  background-color: ${p => p.theme.colors.dark};
  position: relative;
  z-index: 100;
  box-shadow: 0 2px 9px 8px rgba(0, 0, 0, 0.15);
`;
const ContentPane = styled(Flex)`
  min-height: calc(100vh - 48px);

  ${props => props.theme.upFrom.md`
    min-height: auto;
  `};
`;
const BlockLink = styled(Link)`
  display: block;
`;
const VectorLogo = styled(Logo)`
  #leftText path {
    fill: ${p => p.theme.colors.dark};
  }
`;

const LayoutManager = ({ isLoggedIn, children, ...props }) => (
  <ThemeConsumer>
    {theme => (
      // breakpoint below maps to "medium" size (tablet portrait and up)
      <MediaQuery query={`(min-width: ${theme.breakpoints[1]}px)`} {...props}>
        {isMatch => (
          <Background
            fill
            alignItems="flex-start"
            justifyContent="center"
            pb={[0, 20]}
          >
            <SkipToContent />
            <OuterContainer pt={[0, null, 35]}>
              {isMatch && (
                <Flex
                  alignItems="flex-end"
                  justifyContent="space-between"
                  as="header"
                  role="banner"
                >
                  <Box mx={40} my={20}>
                    <BlockLink to="/">
                      <VectorLogo data-testid="vector_logo" />
                    </BlockLink>
                  </Box>
                </Flex>
              )}
              <Flex flexWrap="wrap">
                <Box mx={[null, null, 25]} width={1}>
                  <PageWrapper minHeight={[null, null, 590]}>
                    {isMatch && (
                      <NavigationPane width={1 / 3}>
                        <NavigationContainer />
                      </NavigationPane>
                    )}
                    {/* Content, shows on all device sizes */}
                    <ContentPane
                      id="main"
                      tabIndex="-1"
                      as="main"
                      bg="light"
                      flex="1"
                    >
                      {children}
                    </ContentPane>
                  </PageWrapper>
                </Box>
              </Flex>
            </OuterContainer>
          </Background>
        )}
      </MediaQuery>
    )}
  </ThemeConsumer>
);

export default LayoutManager;
