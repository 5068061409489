export const STORM_ZONES = [
  {
    id: "1",
    name: "Zone 1",
    description:
      "The primary suburbs for this zone include Mangawhai, Pakiri and Wellsford",
    message:
      "Cyclone XXX has done a lot of damage on the network, and the suburbs of YYY, ZZZ and HHH are particularly affected.",
    active: true,
    restorationState: "50%",
    estimatedTimeRestoration: "1 hour",
    center: {
      longitude: 174.6053,
      latitude: -36.2345,
    },
    shape: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            stormZoneType: "stormZone",
          },
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [174.4648, -36.2187],
                [174.4654, -36.2183],
                [174.4654, -36.2182],
                [174.4655, -36.2181],
                [174.4658, -36.2178],
                [174.466, -36.2175],
                [174.4661, -36.2173],
                [174.4662, -36.2172],
                [174.4663, -36.2171],
                [174.4664, -36.217],
                [174.4666, -36.217],
                [174.4672, -36.2169],
                [174.4672, -36.2169],
                [174.4673, -36.2169],
                [174.4678, -36.2168],
                [174.4682, -36.2165],
                [174.4683, -36.2165],
                [174.4684, -36.2164],
                [174.4687, -36.2163],
                [174.4694, -36.2163],
                [174.47, -36.2161],
                [174.4703, -36.2155],
                [174.4701, -36.2149],
                [174.4699, -36.2146],
                [174.4698, -36.2142],
                [174.4692, -36.2136],
                [174.4691, -36.2136],
                [174.469, -36.2136],
                [174.4688, -36.2135],
                [174.4684, -36.2132],
                [174.4683, -36.2129],
                [174.4682, -36.2128],
                [174.4682, -36.2126],
                [174.4682, -36.2126],
                [174.4686, -36.2122],
                [174.4687, -36.2121],
                [174.4688, -36.2121],
                [174.4693, -36.2121],
                [174.47, -36.2118],
                [174.4703, -36.2113],
                [174.4706, -36.2112],
                [174.4709, -36.2111],
                [174.4715, -36.2106],
                [174.4714, -36.2097],
                [174.4712, -36.2095],
                [174.4712, -36.2095],
                [174.471, -36.209],
                [174.4705, -36.2087],
                [174.4704, -36.2085],
                [174.4705, -36.2083],
                [174.4713, -36.2077],
                [174.4717, -36.2072],
                [174.4718, -36.207],
                [174.472, -36.2067],
                [174.473, -36.206],
                [174.4731, -36.206],
                [174.4743, -36.2051],
                [174.4745, -36.2049],
                [174.4748, -36.2046],
                [174.4749, -36.2045],
                [174.475, -36.2044],
                [174.4751, -36.2044],
                [174.4754, -36.2043],
                [174.4758, -36.2042],
                [174.476, -36.2041],
                [174.4762, -36.204],
                [174.4765, -36.2039],
                [174.4765, -36.2038],
                [174.4769, -36.2037],
                [174.477, -36.2037],
                [174.4771, -36.2036],
                [174.4772, -36.2036],
                [174.4774, -36.2035],
                [174.4783, -36.2035],
                [174.4784, -36.2036],
                [174.4786, -36.2038],
                [174.4792, -36.2042],
                [174.4793, -36.2042],
                [174.48, -36.204],
                [174.4803, -36.2035],
                [174.4804, -36.2033],
                [174.4803, -36.2027],
                [174.4801, -36.2024],
                [174.4802, -36.2022],
                [174.4803, -36.2018],
                [174.4804, -36.2018],
                [174.4807, -36.2012],
                [174.4805, -36.2005],
                [174.4805, -36.2005],
                [174.4805, -36.2003],
                [174.4805, -36.2003],
                [174.4806, -36.2001],
                [174.4807, -36.1993],
                [174.4802, -36.1988],
                [174.4801, -36.1987],
                [174.48, -36.1986],
                [174.4798, -36.1983],
                [174.4797, -36.1983],
                [174.4796, -36.1981],
                [174.4797, -36.1979],
                [174.48, -36.1973],
                [174.48, -36.1971],
                [174.4801, -36.1966],
                [174.4801, -36.1966],
                [174.4803, -36.1954],
                [174.4803, -36.195],
                [174.4801, -36.1943],
                [174.4798, -36.1939],
                [174.4797, -36.1938],
                [174.4796, -36.1936],
                [174.4793, -36.193],
                [174.4793, -36.1924],
                [174.4793, -36.1924],
                [174.4793, -36.192],
                [174.479, -36.1915],
                [174.4784, -36.1914],
                [174.478, -36.1914],
                [174.478, -36.1914],
                [174.4769, -36.1914],
                [174.4767, -36.1914],
                [174.4764, -36.1913],
                [174.4761, -36.191],
                [174.4757, -36.19],
                [174.4754, -36.1896],
                [174.4754, -36.1896],
                [174.4751, -36.1895],
                [174.4744, -36.1893],
                [174.4744, -36.1893],
                [174.4727, -36.1889],
                [174.4723, -36.1889],
                [174.472, -36.189],
                [174.4719, -36.189],
                [174.4711, -36.1893],
                [174.4709, -36.1893],
                [174.4703, -36.1892],
                [174.4701, -36.1891],
                [174.4698, -36.1889],
                [174.4697, -36.1889],
                [174.4691, -36.1886],
                [174.4689, -36.1884],
                [174.4688, -36.1881],
                [174.4685, -36.1863],
                [174.4685, -36.1861],
                [174.4687, -36.1857],
                [174.4686, -36.185],
                [174.4676, -36.1828],
                [174.4676, -36.1826],
                [174.4677, -36.1824],
                [174.4807, -36.1775],
                [174.4925, -36.173],
                [174.4926, -36.173],
                [174.4931, -36.1726],
                [174.4931, -36.1726],
                [174.4936, -36.172],
                [174.4936, -36.172],
                [174.4939, -36.1714],
                [174.4937, -36.1708],
                [174.4936, -36.1707],
                [174.4937, -36.1702],
                [174.4937, -36.17],
                [174.4939, -36.1696],
                [174.494, -36.1695],
                [174.494, -36.1695],
                [174.494, -36.1693],
                [174.4942, -36.1688],
                [174.4946, -36.1687],
                [174.4952, -36.1687],
                [174.4954, -36.1687],
                [174.4957, -36.1686],
                [174.4958, -36.1686],
                [174.496, -36.1686],
                [174.4963, -36.1687],
                [174.497, -36.1686],
                [174.4974, -36.168],
                [174.4975, -36.1676],
                [174.4975, -36.1674],
                [174.4975, -36.1673],
                [174.4976, -36.1671],
                [174.4976, -36.1671],
                [174.4978, -36.1672],
                [174.5107, -36.1797],
                [174.5112, -36.18],
                [174.5118, -36.1798],
                [174.5129, -36.179],
                [174.5131, -36.1788],
                [174.5132, -36.1787],
                [174.5134, -36.1786],
                [174.5137, -36.1784],
                [174.5141, -36.1784],
                [174.5177, -36.1796],
                [174.5184, -36.1795],
                [174.5189, -36.1789],
                [174.5199, -36.1747],
                [174.52, -36.1746],
                [174.5202, -36.1745],
                [174.5276, -36.1758],
                [174.5283, -36.1756],
                [174.5286, -36.1751],
                [174.529, -36.1737],
                [174.5289, -36.1729],
                [174.5283, -36.1725],
                [174.525, -36.172],
                [174.5246, -36.1718],
                [174.5245, -36.1717],
                [174.5244, -36.1713],
                [174.5247, -36.1698],
                [174.5247, -36.1693],
                [174.5242, -36.1679],
                [174.5242, -36.1677],
                [174.5243, -36.1675],
                [174.5244, -36.1675],
                [174.5248, -36.1674],
                [174.5269, -36.1678],
                [174.5273, -36.1678],
                [174.5274, -36.1677],
                [174.5276, -36.1677],
                [174.5398, -36.1697],
                [174.5405, -36.1695],
                [174.5408, -36.1688],
                [174.5405, -36.1682],
                [174.5397, -36.1675],
                [174.5396, -36.1669],
                [174.5397, -36.1665],
                [174.5397, -36.166],
                [174.5396, -36.1656],
                [174.5396, -36.1654],
                [174.5396, -36.164],
                [174.5396, -36.1638],
                [174.5399, -36.1629],
                [174.54, -36.1628],
                [174.5402, -36.1627],
                [174.5463, -36.1638],
                [174.5463, -36.1638],
                [174.5511, -36.1646],
                [174.5517, -36.1644],
                [174.5521, -36.1639],
                [174.5525, -36.1626],
                [174.5525, -36.1626],
                [174.5525, -36.1625],
                [174.5526, -36.1621],
                [174.5525, -36.1615],
                [174.5522, -36.1609],
                [174.5502, -36.1592],
                [174.5501, -36.1589],
                [174.5501, -36.1588],
                [174.5497, -36.1582],
                [174.5494, -36.158],
                [174.5493, -36.1579],
                [174.5485, -36.1566],
                [174.5483, -36.1564],
                [174.548, -36.1562],
                [174.5474, -36.156],
                [174.5468, -36.1561],
                [174.5466, -36.1561],
                [174.5453, -36.1556],
                [174.5451, -36.1556],
                [174.5442, -36.1555],
                [174.5441, -36.1554],
                [174.5441, -36.1554],
                [174.5441, -36.1552],
                [174.5456, -36.1531],
                [174.546, -36.1529],
                [174.5464, -36.1529],
                [174.5466, -36.1529],
                [174.5473, -36.1527],
                [174.5474, -36.1527],
                [174.5475, -36.1527],
                [174.5478, -36.1526],
                [174.5487, -36.1523],
                [174.5489, -36.1523],
                [174.5497, -36.1523],
                [174.55, -36.1523],
                [174.5513, -36.1519],
                [174.5515, -36.1519],
                [174.5517, -36.152],
                [174.5529, -36.1543],
                [174.5533, -36.1547],
                [174.5539, -36.1547],
                [174.5568, -36.1541],
                [174.5571, -36.154],
                [174.5593, -36.1525],
                [174.5597, -36.1518],
                [174.5594, -36.1511],
                [174.5557, -36.1475],
                [174.5556, -36.1473],
                [174.5557, -36.1471],
                [174.5724, -36.1326],
                [174.5728, -36.1325],
                [174.5732, -36.1325],
                [174.5734, -36.1325],
                [174.5752, -36.1319],
                [174.5754, -36.1319],
                [174.576, -36.1319],
                [174.5762, -36.1319],
                [174.5768, -36.1322],
                [174.577, -36.1324],
                [174.5771, -36.1329],
                [174.5767, -36.1336],
                [174.5766, -36.1337],
                [174.5757, -36.1347],
                [174.5756, -36.1348],
                [174.5754, -36.135],
                [174.575, -36.1355],
                [174.5751, -36.1362],
                [174.5755, -36.1369],
                [174.576, -36.1373],
                [174.5767, -36.1373],
                [174.5769, -36.1371],
                [174.577, -36.1371],
                [174.5783, -36.1368],
                [174.5788, -36.1364],
                [174.5795, -36.1356],
                [174.5796, -36.1355],
                [174.5798, -36.1354],
                [174.5799, -36.1353],
                [174.5804, -36.1348],
                [174.5807, -36.1343],
                [174.5808, -36.1331],
                [174.5808, -36.1329],
                [174.5808, -36.1325],
                [174.581, -36.1323],
                [174.5819, -36.132],
                [174.582, -36.132],
                [174.5826, -36.1316],
                [174.5827, -36.1315],
                [174.5829, -36.131],
                [174.5828, -36.1306],
                [174.5828, -36.1306],
                [174.5826, -36.13],
                [174.5827, -36.1298],
                [174.5826, -36.1293],
                [174.5826, -36.1292],
                [174.5824, -36.1289],
                [174.5814, -36.1279],
                [174.5813, -36.1275],
                [174.5814, -36.1268],
                [174.5814, -36.1267],
                [174.5818, -36.1262],
                [174.582, -36.126],
                [174.5846, -36.1246],
                [174.5847, -36.1245],
                [174.586, -36.1234],
                [174.5861, -36.1234],
                [174.5868, -36.123],
                [174.587, -36.1229],
                [174.5918, -36.1223],
                [174.592, -36.1223],
                [174.5937, -36.1216],
                [174.594, -36.1214],
                [174.5976, -36.1183],
                [174.5976, -36.1183],
                [174.5985, -36.1176],
                [174.5987, -36.1174],
                [174.601, -36.1143],
                [174.6011, -36.1142],
                [174.602, -36.112],
                [174.6021, -36.1116],
                [174.6021, -36.1109],
                [174.6021, -36.1107],
                [174.6018, -36.11],
                [174.6017, -36.1097],
                [174.6015, -36.1093],
                [174.6014, -36.1091],
                [174.6012, -36.1084],
                [174.6011, -36.1081],
                [174.6007, -36.1075],
                [174.6006, -36.1074],
                [174.6001, -36.1061],
                [174.6001, -36.1059],
                [174.6, -36.1041],
                [174.6, -36.1041],
                [174.6002, -36.1025],
                [174.6003, -36.1022],
                [174.6026, -36.0997],
                [174.6028, -36.0992],
                [174.603, -36.0983],
                [174.603, -36.0981],
                [174.603, -36.0973],
                [174.6029, -36.0968],
                [174.6023, -36.0957],
                [174.6022, -36.0956],
                [174.6012, -36.0944],
                [174.6011, -36.0943],
                [174.5998, -36.0933],
                [174.5997, -36.0933],
                [174.5985, -36.0925],
                [174.5983, -36.0923],
                [174.5979, -36.0917],
                [174.5978, -36.0915],
                [174.5979, -36.0913],
                [174.5984, -36.0909],
                [174.5988, -36.0907],
                [174.5994, -36.0907],
                [174.5995, -36.0907],
                [174.6002, -36.0909],
                [174.6004, -36.091],
                [174.6023, -36.0925],
                [174.6024, -36.0925],
                [174.6039, -36.094],
                [174.6043, -36.0945],
                [174.6044, -36.0946],
                [174.6047, -36.095],
                [174.6047, -36.0951],
                [174.6049, -36.0955],
                [174.605, -36.0956],
                [174.6051, -36.0958],
                [174.6061, -36.0979],
                [174.6063, -36.0982],
                [174.6064, -36.0983],
                [174.6065, -36.0984],
                [174.6069, -36.0991],
                [174.6069, -36.0992],
                [174.6069, -36.0993],
                [174.6071, -36.0996],
                [174.6072, -36.0998],
                [174.6073, -36.0999],
                [174.6075, -36.1004],
                [174.6075, -36.1005],
                [174.6082, -36.1016],
                [174.6082, -36.1017],
                [174.6097, -36.1046],
                [174.6097, -36.1047],
                [174.6118, -36.1083],
                [174.6118, -36.1084],
                [174.6119, -36.1087],
                [174.6121, -36.109],
                [174.6123, -36.1093],
                [174.6124, -36.1095],
                [174.6125, -36.1097],
                [174.6126, -36.11],
                [174.6128, -36.1105],
                [174.6129, -36.1107],
                [174.6129, -36.1109],
                [174.6132, -36.1114],
                [174.6132, -36.1114],
                [174.6133, -36.1115],
                [174.6137, -36.1121],
                [174.6137, -36.1122],
                [174.6141, -36.113],
                [174.6141, -36.1131],
                [174.6145, -36.1138],
                [174.6145, -36.1139],
                [174.6149, -36.1147],
                [174.6149, -36.1148],
                [174.6153, -36.1154],
                [174.6153, -36.1155],
                [174.6154, -36.1158],
                [174.6156, -36.1161],
                [174.6161, -36.1166],
                [174.6161, -36.1167],
                [174.6164, -36.117],
                [174.6164, -36.1171],
                [174.6167, -36.1175],
                [174.6167, -36.1176],
                [174.617, -36.1179],
                [174.617, -36.118],
                [174.6171, -36.1182],
                [174.6173, -36.1184],
                [174.6177, -36.119],
                [174.6178, -36.1191],
                [174.6178, -36.1192],
                [174.6179, -36.1193],
                [174.6189, -36.1207],
                [174.6193, -36.1209],
                [174.6193, -36.1209],
                [174.6196, -36.1212],
                [174.6197, -36.1215],
                [174.6198, -36.1218],
                [174.6199, -36.1219],
                [174.6205, -36.1226],
                [174.6205, -36.1227],
                [174.6208, -36.1232],
                [174.621, -36.1234],
                [174.621, -36.1235],
                [174.6211, -36.1236],
                [174.6224, -36.1255],
                [174.6225, -36.1256],
                [174.6228, -36.1259],
                [174.6229, -36.126],
                [174.6232, -36.1265],
                [174.6232, -36.1266],
                [174.6238, -36.1276],
                [174.6238, -36.1277],
                [174.6261, -36.131],
                [174.6261, -36.1311],
                [174.6275, -36.1331],
                [174.6276, -36.1332],
                [174.6278, -36.1336],
                [174.6278, -36.1337],
                [174.628, -36.134],
                [174.628, -36.1341],
                [174.6284, -36.1346],
                [174.6284, -36.1347],
                [174.6301, -36.1369],
                [174.6301, -36.1369],
                [174.6306, -36.1376],
                [174.6306, -36.1376],
                [174.6322, -36.1399],
                [174.6322, -36.1399],
                [174.6343, -36.1428],
                [174.6343, -36.1429],
                [174.6347, -36.1435],
                [174.6347, -36.1436],
                [174.635, -36.1441],
                [174.6351, -36.1442],
                [174.6353, -36.1446],
                [174.6352, -36.145],
                [174.6354, -36.1456],
                [174.636, -36.146],
                [174.6364, -36.1461],
                [174.6366, -36.1462],
                [174.6367, -36.1463],
                [174.6374, -36.1475],
                [174.6375, -36.1476],
                [174.639, -36.1493],
                [174.6391, -36.1495],
                [174.6391, -36.1496],
                [174.6393, -36.1499],
                [174.6394, -36.1501],
                [174.6395, -36.1502],
                [174.6399, -36.1505],
                [174.6403, -36.151],
                [174.6404, -36.1511],
                [174.6429, -36.1544],
                [174.6429, -36.1545],
                [174.6463, -36.1583],
                [174.6464, -36.1584],
                [174.6477, -36.1594],
                [174.6479, -36.1596],
                [174.6479, -36.1596],
                [174.6484, -36.1597],
                [174.649, -36.1596],
                [174.6493, -36.1597],
                [174.6494, -36.1598],
                [174.6495, -36.1599],
                [174.6495, -36.16],
                [174.6497, -36.1605],
                [174.6501, -36.1608],
                [174.6503, -36.1609],
                [174.6504, -36.1609],
                [174.6504, -36.1609],
                [174.6507, -36.1612],
                [174.6507, -36.1613],
                [174.6507, -36.1615],
                [174.6508, -36.1615],
                [174.6512, -36.1622],
                [174.6514, -36.1623],
                [174.6517, -36.1627],
                [174.6521, -36.1629],
                [174.6521, -36.1629],
                [174.6524, -36.1631],
                [174.6525, -36.1631],
                [174.6529, -36.1632],
                [174.653, -36.1633],
                [174.6531, -36.1634],
                [174.653, -36.1638],
                [174.6529, -36.1638],
                [174.6525, -36.1641],
                [174.6525, -36.1642],
                [174.6522, -36.1643],
                [174.6522, -36.1643],
                [174.6519, -36.1645],
                [174.6516, -36.1647],
                [174.6515, -36.1648],
                [174.6514, -36.1649],
                [174.651, -36.1653],
                [174.6509, -36.1658],
                [174.651, -36.166],
                [174.6511, -36.1663],
                [174.6516, -36.1672],
                [174.6516, -36.1673],
                [174.6519, -36.168],
                [174.6522, -36.1686],
                [174.6522, -36.1687],
                [174.6525, -36.1693],
                [174.6535, -36.1712],
                [174.6535, -36.1713],
                [174.6537, -36.1715],
                [174.6537, -36.1716],
                [174.6542, -36.1723],
                [174.6542, -36.1724],
                [174.6546, -36.173],
                [174.6546, -36.1731],
                [174.6547, -36.1732],
                [174.6548, -36.1735],
                [174.6565, -36.1761],
                [174.6565, -36.1762],
                [174.6574, -36.1774],
                [174.6574, -36.1775],
                [174.6583, -36.1785],
                [174.6583, -36.1785],
                [174.6589, -36.1792],
                [174.6589, -36.1792],
                [174.6601, -36.1808],
                [174.6602, -36.1809],
                [174.6609, -36.1815],
                [174.6611, -36.1817],
                [174.6611, -36.1818],
                [174.6618, -36.1827],
                [174.6619, -36.1828],
                [174.6624, -36.1832],
                [174.6624, -36.1833],
                [174.6633, -36.1843],
                [174.6633, -36.1843],
                [174.6656, -36.1869],
                [174.6656, -36.187],
                [174.6686, -36.1899],
                [174.6688, -36.1902],
                [174.6689, -36.1902],
                [174.6697, -36.1909],
                [174.6697, -36.1909],
                [174.6709, -36.1922],
                [174.671, -36.1923],
                [174.6714, -36.1926],
                [174.6715, -36.1926],
                [174.6721, -36.1932],
                [174.6721, -36.1933],
                [174.6726, -36.1938],
                [174.6726, -36.1938],
                [174.6737, -36.195],
                [174.6737, -36.195],
                [174.6743, -36.1956],
                [174.6748, -36.1961],
                [174.6753, -36.1967],
                [174.6754, -36.1968],
                [174.6756, -36.1971],
                [174.6757, -36.1972],
                [174.6765, -36.1979],
                [174.6766, -36.198],
                [174.6771, -36.1987],
                [174.6772, -36.1988],
                [174.6778, -36.1994],
                [174.6779, -36.1995],
                [174.6784, -36.1999],
                [174.6785, -36.2],
                [174.6787, -36.2003],
                [174.6787, -36.2004],
                [174.6794, -36.2013],
                [174.6796, -36.2015],
                [174.6799, -36.2017],
                [174.68, -36.2017],
                [174.6803, -36.2019],
                [174.6804, -36.202],
                [174.6806, -36.2022],
                [174.6807, -36.2023],
                [174.6811, -36.2025],
                [174.681, -36.2025],
                [174.6817, -36.2029],
                [174.6818, -36.203],
                [174.6835, -36.2046],
                [174.6837, -36.2048],
                [174.6837, -36.2048],
                [174.6838, -36.2049],
                [174.6843, -36.2053],
                [174.6844, -36.2054],
                [174.6845, -36.2056],
                [174.6846, -36.2057],
                [174.6852, -36.2062],
                [174.6856, -36.2066],
                [174.6856, -36.2066],
                [174.6861, -36.2072],
                [174.6863, -36.2074],
                [174.6876, -36.2084],
                [174.6877, -36.2085],
                [174.6882, -36.2089],
                [174.6892, -36.21],
                [174.6895, -36.2105],
                [174.6897, -36.2108],
                [174.6899, -36.211],
                [174.6905, -36.2117],
                [174.6906, -36.2117],
                [174.6921, -36.2131],
                [174.6922, -36.2132],
                [174.6929, -36.214],
                [174.693, -36.2141],
                [174.6945, -36.2155],
                [174.6946, -36.2155],
                [174.6951, -36.216],
                [174.6952, -36.216],
                [174.6956, -36.2164],
                [174.6964, -36.2173],
                [174.6965, -36.2174],
                [174.6971, -36.2178],
                [174.6972, -36.2178],
                [174.6977, -36.2182],
                [174.6987, -36.219],
                [174.6987, -36.219],
                [174.7009, -36.221],
                [174.7009, -36.221],
                [174.7014, -36.2216],
                [174.7015, -36.2216],
                [174.7031, -36.2231],
                [174.7032, -36.2231],
                [174.7035, -36.2234],
                [174.7036, -36.2235],
                [174.7039, -36.2238],
                [174.704, -36.2239],
                [174.7056, -36.2252],
                [174.7057, -36.2252],
                [174.7065, -36.2261],
                [174.7066, -36.2262],
                [174.7081, -36.2274],
                [174.7082, -36.2274],
                [174.7087, -36.2279],
                [174.7087, -36.2279],
                [174.7097, -36.2291],
                [174.7098, -36.2292],
                [174.7117, -36.2305],
                [174.7118, -36.2305],
                [174.713, -36.2315],
                [174.7131, -36.2315],
                [174.714, -36.2324],
                [174.7141, -36.2325],
                [174.7147, -36.2329],
                [174.7148, -36.233],
                [174.7154, -36.2335],
                [174.7154, -36.2335],
                [174.7178, -36.2359],
                [174.7179, -36.236],
                [174.721, -36.2382],
                [174.7211, -36.2382],
                [174.7234, -36.2403],
                [174.7235, -36.2404],
                [174.7241, -36.2407],
                [174.7242, -36.2407],
                [174.725, -36.2414],
                [174.7251, -36.2414],
                [174.7254, -36.2418],
                [174.7255, -36.2418],
                [174.7263, -36.2425],
                [174.7263, -36.2425],
                [174.7268, -36.243],
                [174.7269, -36.243],
                [174.7273, -36.2433],
                [174.7276, -36.2436],
                [174.7277, -36.2437],
                [174.7285, -36.2444],
                [174.7287, -36.2446],
                [174.729, -36.2448],
                [174.7291, -36.2448],
                [174.7298, -36.2454],
                [174.7299, -36.2454],
                [174.7303, -36.2457],
                [174.7304, -36.2457],
                [174.7315, -36.2466],
                [174.7315, -36.2466],
                [174.7324, -36.2475],
                [174.7326, -36.2476],
                [174.7326, -36.2476],
                [174.733, -36.2479],
                [174.7331, -36.248],
                [174.7333, -36.2481],
                [174.7334, -36.2481],
                [174.7336, -36.2483],
                [174.7343, -36.2487],
                [174.7344, -36.2487],
                [174.7361, -36.2501],
                [174.7362, -36.2501],
                [174.7374, -36.2509],
                [174.7375, -36.2509],
                [174.738, -36.2512],
                [174.7381, -36.2512],
                [174.7391, -36.252],
                [174.7392, -36.252],
                [174.74, -36.2525],
                [174.7401, -36.2525],
                [174.7404, -36.2527],
                [174.7405, -36.2527],
                [174.7413, -36.2533],
                [174.7414, -36.2533],
                [174.7426, -36.2543],
                [174.7427, -36.2544],
                [174.743, -36.2545],
                [174.7431, -36.2545],
                [174.7435, -36.2548],
                [174.7436, -36.2548],
                [174.7442, -36.2552],
                [174.7443, -36.2552],
                [174.7451, -36.2558],
                [174.7452, -36.2558],
                [174.7459, -36.2565],
                [174.746, -36.2566],
                [174.7466, -36.2569],
                [174.7467, -36.2569],
                [174.7474, -36.2573],
                [174.7475, -36.2574],
                [174.7478, -36.2576],
                [174.7481, -36.2578],
                [174.7484, -36.2579],
                [174.7485, -36.2579],
                [174.7491, -36.2581],
                [174.7492, -36.2581],
                [174.7509, -36.259],
                [174.751, -36.259],
                [174.7526, -36.2595],
                [174.7527, -36.2595],
                [174.7529, -36.2597],
                [174.7531, -36.2597],
                [174.7535, -36.2598],
                [174.7541, -36.2598],
                [174.7544, -36.2597],
                [174.7547, -36.2595],
                [174.755, -36.2591],
                [174.7553, -36.259],
                [174.7556, -36.2589],
                [174.7558, -36.2589],
                [174.7561, -36.259],
                [174.7564, -36.2591],
                [174.7568, -36.2596],
                [174.758, -36.2601],
                [174.7581, -36.2601],
                [174.7584, -36.2602],
                [174.7584, -36.2602],
                [174.759, -36.2604],
                [174.7591, -36.2604],
                [174.7605, -36.2608],
                [174.7606, -36.2608],
                [174.7608, -36.2609],
                [174.7612, -36.261],
                [174.7614, -36.261],
                [174.7616, -36.2611],
                [174.7618, -36.2611],
                [174.763, -36.2613],
                [174.7631, -36.2613],
                [174.7631, -36.2613],
                [174.7635, -36.2613],
                [174.7637, -36.2613],
                [174.7638, -36.2613],
                [174.7642, -36.2613],
                [174.7645, -36.2613],
                [174.7646, -36.2612],
                [174.7648, -36.2612],
                [174.7658, -36.2613],
                [174.7658, -36.2613],
                [174.7664, -36.2613],
                [174.7666, -36.2612],
                [174.7668, -36.261],
                [174.767, -36.2608],
                [174.7672, -36.2608],
                [174.7674, -36.2608],
                [174.7674, -36.2608],
                [174.7679, -36.261],
                [174.7683, -36.261],
                [174.7686, -36.2611],
                [174.7687, -36.2613],
                [174.7688, -36.2614],
                [174.7694, -36.262],
                [174.7695, -36.262],
                [174.7699, -36.2621],
                [174.7702, -36.2622],
                [174.7702, -36.2623],
                [174.7705, -36.2625],
                [174.7708, -36.2626],
                [174.7709, -36.2626],
                [174.7713, -36.2627],
                [174.7714, -36.2627],
                [174.7714, -36.2628],
                [174.7717, -36.263],
                [174.7719, -36.2633],
                [174.7719, -36.2634],
                [174.772, -36.2635],
                [174.7722, -36.2637],
                [174.7726, -36.264],
                [174.7727, -36.2642],
                [174.7734, -36.2645],
                [174.7735, -36.2645],
                [174.7743, -36.2648],
                [174.7744, -36.2648],
                [174.7755, -36.2654],
                [174.7756, -36.2655],
                [174.7759, -36.2657],
                [174.776, -36.2659],
                [174.7761, -36.2662],
                [174.7761, -36.267],
                [174.7761, -36.2672],
                [174.7761, -36.2673],
                [174.7764, -36.2679],
                [174.777, -36.2681],
                [174.7773, -36.2681],
                [174.7774, -36.2682],
                [174.7775, -36.2683],
                [174.7776, -36.2684],
                [174.7779, -36.2688],
                [174.7784, -36.2692],
                [174.7784, -36.2692],
                [174.7786, -36.2694],
                [174.7788, -36.2696],
                [174.7788, -36.2696],
                [174.7789, -36.2696],
                [174.7789, -36.2696],
                [174.7794, -36.2698],
                [174.7804, -36.2698],
                [174.7807, -36.2697],
                [174.7812, -36.2695],
                [174.7813, -36.2695],
                [174.7818, -36.2694],
                [174.7821, -36.2693],
                [174.7823, -36.2692],
                [174.7826, -36.2691],
                [174.7836, -36.2691],
                [174.7839, -36.2692],
                [174.7847, -36.2697],
                [174.7848, -36.2697],
                [174.7849, -36.2698],
                [174.785, -36.2699],
                [174.7851, -36.2701],
                [174.785, -36.2706],
                [174.7848, -36.271],
                [174.7844, -36.2712],
                [174.7843, -36.2713],
                [174.784, -36.2717],
                [174.7839, -36.2717],
                [174.7835, -36.2721],
                [174.7834, -36.2721],
                [174.7831, -36.2726],
                [174.7825, -36.2745],
                [174.7826, -36.2753],
                [174.7832, -36.2757],
                [174.7837, -36.2758],
                [174.7839, -36.2759],
                [174.7839, -36.2761],
                [174.7836, -36.2773],
                [174.7836, -36.2779],
                [174.784, -36.2783],
                [174.7841, -36.2784],
                [174.7842, -36.2785],
                [174.7844, -36.2788],
                [174.7846, -36.279],
                [174.7847, -36.2791],
                [174.7848, -36.2793],
                [174.7849, -36.2794],
                [174.7851, -36.2796],
                [174.7852, -36.2797],
                [174.7854, -36.2799],
                [174.7855, -36.2799],
                [174.7857, -36.2802],
                [174.786, -36.2803],
                [174.7861, -36.2804],
                [174.7867, -36.281],
                [174.7869, -36.2812],
                [174.7869, -36.2812],
                [174.787, -36.2813],
                [174.7871, -36.2816],
                [174.7878, -36.2822],
                [174.7879, -36.2824],
                [174.7879, -36.2826],
                [174.785, -36.2868],
                [174.7848, -36.2869],
                [174.7846, -36.2869],
                [174.7815, -36.2855],
                [174.7809, -36.2855],
                [174.7804, -36.2858],
                [174.7769, -36.291],
                [174.7767, -36.2911],
                [174.7765, -36.2911],
                [174.7764, -36.291],
                [174.7756, -36.2909],
                [174.7751, -36.291],
                [174.7751, -36.291],
                [174.7745, -36.2913],
                [174.774, -36.292],
                [174.7735, -36.2922],
                [174.7685, -36.2913],
                [174.7678, -36.2914],
                [174.7675, -36.2919],
                [174.7659, -36.2978],
                [174.7659, -36.298],
                [174.7659, -36.2992],
                [174.7658, -36.2994],
                [174.7656, -36.2995],
                [174.7607, -36.2995],
                [174.7601, -36.2998],
                [174.7598, -36.3005],
                [174.7599, -36.3009],
                [174.7601, -36.3014],
                [174.7602, -36.3014],
                [174.7603, -36.3017],
                [174.7606, -36.3027],
                [174.7606, -36.3029],
                [174.7604, -36.3031],
                [174.76, -36.3033],
                [174.7596, -36.3041],
                [174.7597, -36.3046],
                [174.7596, -36.3048],
                [174.7595, -36.3049],
                [174.7589, -36.3051],
                [174.7584, -36.3054],
                [174.7582, -36.3059],
                [174.7582, -36.3062],
                [174.7582, -36.3062],
                [174.758, -36.3063],
                [174.7568, -36.306],
                [174.7564, -36.306],
                [174.7558, -36.3062],
                [174.7556, -36.3062],
                [174.7553, -36.3061],
                [174.7549, -36.3059],
                [174.7549, -36.3058],
                [174.7548, -36.3057],
                [174.7546, -36.3055],
                [174.7541, -36.3053],
                [174.7539, -36.3053],
                [174.7537, -36.3052],
                [174.7536, -36.3049],
                [174.7536, -36.3049],
                [174.7537, -36.3046],
                [174.7536, -36.3046],
                [174.7536, -36.3038],
                [174.7532, -36.3031],
                [174.7532, -36.303],
                [174.7531, -36.3028],
                [174.7529, -36.3026],
                [174.7528, -36.3025],
                [174.7528, -36.3024],
                [174.7523, -36.3023],
                [174.7523, -36.3022],
                [174.7517, -36.302],
                [174.7515, -36.302],
                [174.7515, -36.302],
                [174.7515, -36.302],
                [174.7515, -36.302],
                [174.7511, -36.3018],
                [174.7504, -36.3009],
                [174.7503, -36.3008],
                [174.7499, -36.3004],
                [174.7498, -36.3003],
                [174.7493, -36.3],
                [174.749, -36.2998],
                [174.7483, -36.2996],
                [174.7479, -36.2992],
                [174.7478, -36.2989],
                [174.7471, -36.2983],
                [174.7428, -36.2974],
                [174.7428, -36.2974],
                [174.7383, -36.2963],
                [174.7381, -36.2962],
                [174.738, -36.296],
                [174.7381, -36.2958],
                [174.7381, -36.2957],
                [174.7381, -36.2956],
                [174.738, -36.2949],
                [174.7379, -36.2948],
                [174.7376, -36.2942],
                [174.7376, -36.294],
                [174.7376, -36.2939],
                [174.7375, -36.2937],
                [174.7376, -36.2937],
                [174.7375, -36.293],
                [174.7375, -36.2929],
                [174.7375, -36.2929],
                [174.7375, -36.2928],
                [174.7374, -36.2923],
                [174.7374, -36.2923],
                [174.7372, -36.2916],
                [174.7372, -36.2916],
                [174.7372, -36.2914],
                [174.7369, -36.291],
                [174.7363, -36.2908],
                [174.7321, -36.2906],
                [174.732, -36.2905],
                [174.7319, -36.2904],
                [174.7318, -36.2899],
                [174.7315, -36.2894],
                [174.7309, -36.2892],
                [174.7252, -36.2889],
                [174.725, -36.2889],
                [174.7246, -36.2887],
                [174.7244, -36.2886],
                [174.724, -36.2883],
                [174.7238, -36.2881],
                [174.7237, -36.288],
                [174.723, -36.2869],
                [174.7228, -36.2867],
                [174.7217, -36.2857],
                [174.7217, -36.2857],
                [174.721, -36.2854],
                [174.7208, -36.2855],
                [174.7205, -36.2856],
                [174.7202, -36.2858],
                [174.7201, -36.2858],
                [174.7197, -36.286],
                [174.7192, -36.2866],
                [174.7192, -36.2866],
                [174.7188, -36.287],
                [174.7186, -36.287],
                [174.7182, -36.2872],
                [174.7182, -36.2872],
                [174.718, -36.2873],
                [174.7157, -36.2868],
                [174.715, -36.2869],
                [174.7146, -36.2874],
                [174.714, -36.2896],
                [174.7139, -36.2897],
                [174.7137, -36.2898],
                [174.7036, -36.2888],
                [174.7036, -36.2888],
                [174.6995, -36.2888],
                [174.6994, -36.2888],
                [174.6988, -36.2886],
                [174.6934, -36.2895],
                [174.693, -36.2893],
                [174.6915, -36.2879],
                [174.6915, -36.2879],
                [174.6873, -36.2835],
                [174.6872, -36.2834],
                [174.6857, -36.2808],
                [174.6857, -36.2807],
                [174.6856, -36.2805],
                [174.6857, -36.2803],
                [174.6857, -36.2803],
                [174.6859, -36.2802],
                [174.6894, -36.2813],
                [174.6902, -36.2811],
                [174.6905, -36.2803],
                [174.6903, -36.2781],
                [174.6904, -36.2779],
                [174.6904, -36.2779],
                [174.6908, -36.278],
                [174.6908, -36.278],
                [174.6911, -36.2781],
                [174.6913, -36.2781],
                [174.6914, -36.2781],
                [174.6917, -36.2781],
                [174.6922, -36.2782],
                [174.6925, -36.2784],
                [174.6926, -36.2785],
                [174.6928, -36.2786],
                [174.6932, -36.2789],
                [174.6933, -36.2789],
                [174.6936, -36.279],
                [174.6936, -36.279],
                [174.6942, -36.2791],
                [174.6947, -36.2788],
                [174.695, -36.2782],
                [174.695, -36.278],
                [174.6946, -36.2774],
                [174.6934, -36.2763],
                [174.6928, -36.2761],
                [174.6928, -36.2761],
                [174.6926, -36.276],
                [174.6926, -36.276],
                [174.6926, -36.2759],
                [174.6909, -36.2709],
                [174.6905, -36.2704],
                [174.6898, -36.2704],
                [174.6872, -36.2714],
                [174.6871, -36.2714],
                [174.6845, -36.2717],
                [174.6845, -36.2717],
                [174.6732, -36.2728],
                [174.673, -36.2727],
                [174.6729, -36.2725],
                [174.6731, -36.2612],
                [174.6732, -36.2588],
                [174.673, -36.2582],
                [174.6724, -36.2579],
                [174.6718, -36.258],
                [174.6636, -36.2624],
                [174.6632, -36.263],
                [174.6634, -36.2638],
                [174.6655, -36.2664],
                [174.6656, -36.2666],
                [174.6655, -36.2668],
                [174.6585, -36.2706],
                [174.6586, -36.2707],
                [174.6582, -36.271],
                [174.658, -36.2714],
                [174.6579, -36.2717],
                [174.6576, -36.2746],
                [174.6576, -36.2746],
                [174.6572, -36.2773],
                [174.6574, -36.278],
                [174.6583, -36.2792],
                [174.6583, -36.2793],
                [174.6595, -36.2808],
                [174.6595, -36.281],
                [174.6594, -36.2812],
                [174.6587, -36.2819],
                [174.6587, -36.2819],
                [174.6577, -36.2827],
                [174.6574, -36.2831],
                [174.6574, -36.2832],
                [174.6572, -36.2835],
                [174.657, -36.2841],
                [174.6573, -36.2847],
                [174.6603, -36.2871],
                [174.6609, -36.2873],
                [174.6615, -36.2873],
                [174.6619, -36.2874],
                [174.665, -36.2899],
                [174.6651, -36.2901],
                [174.6651, -36.2901],
                [174.665, -36.2903],
                [174.6586, -36.2933],
                [174.6583, -36.2935],
                [174.6543, -36.2983],
                [174.6541, -36.2989],
                [174.6543, -36.2995],
                [174.6556, -36.3008],
                [174.6557, -36.301],
                [174.6556, -36.3012],
                [174.6453, -36.3084],
                [174.6453, -36.3084],
                [174.6388, -36.3127],
                [174.6387, -36.3127],
                [174.6354, -36.3154],
                [174.6353, -36.3154],
                [174.633, -36.3176],
                [174.6329, -36.3176],
                [174.6289, -36.3204],
                [174.6288, -36.3205],
                [174.624, -36.3222],
                [174.6237, -36.3224],
                [174.6232, -36.3227],
                [174.6231, -36.3228],
                [174.6189, -36.324],
                [174.6187, -36.324],
                [174.6126, -36.3232],
                [174.6122, -36.3232],
                [174.6112, -36.3235],
                [174.6111, -36.3235],
                [174.608, -36.3236],
                [174.608, -36.3236],
                [174.604, -36.3234],
                [174.604, -36.3234],
                [174.6004, -36.3233],
                [174.6002, -36.3233],
                [174.5989, -36.3226],
                [174.5983, -36.3226],
                [174.5884, -36.3252],
                [174.5881, -36.3252],
                [174.5875, -36.3254],
                [174.5872, -36.3261],
                [174.5872, -36.3265],
                [174.5871, -36.3267],
                [174.5869, -36.3268],
                [174.5856, -36.3269],
                [174.5854, -36.3268],
                [174.5853, -36.3267],
                [174.5851, -36.3256],
                [174.5847, -36.325],
                [174.584, -36.3249],
                [174.584, -36.3249],
                [174.5839, -36.3249],
                [174.5835, -36.325],
                [174.5831, -36.3253],
                [174.5829, -36.3255],
                [174.5828, -36.3256],
                [174.5827, -36.3259],
                [174.5826, -36.3265],
                [174.5828, -36.3275],
                [174.5827, -36.3279],
                [174.5818, -36.3292],
                [174.5817, -36.3292],
                [174.5812, -36.33],
                [174.5812, -36.3301],
                [174.5812, -36.3301],
                [174.5812, -36.3304],
                [174.5812, -36.3315],
                [174.5811, -36.3317],
                [174.5809, -36.3318],
                [174.5801, -36.3319],
                [174.5794, -36.3321],
                [174.5792, -36.3328],
                [174.5792, -36.334],
                [174.5791, -36.3342],
                [174.5789, -36.3343],
                [174.5764, -36.3344],
                [174.5763, -36.3343],
                [174.5756, -36.3343],
                [174.5751, -36.3347],
                [174.573, -36.3386],
                [174.573, -36.3394],
                [174.5736, -36.3399],
                [174.5739, -36.34],
                [174.5741, -36.3402],
                [174.5748, -36.3403],
                [174.5753, -36.3402],
                [174.5755, -36.3402],
                [174.5784, -36.3404],
                [174.5786, -36.3405],
                [174.5786, -36.3405],
                [174.5786, -36.3407],
                [174.578, -36.3416],
                [174.5776, -36.3418],
                [174.5774, -36.3418],
                [174.5772, -36.3418],
                [174.5767, -36.342],
                [174.5766, -36.342],
                [174.5759, -36.3423],
                [174.5758, -36.3424],
                [174.5755, -36.3427],
                [174.5751, -36.3428],
                [174.575, -36.3428],
                [174.5748, -36.3428],
                [174.5738, -36.3429],
                [174.5735, -36.3431],
                [174.5729, -36.3434],
                [174.5727, -36.3435],
                [174.5726, -36.3435],
                [174.5722, -36.3438],
                [174.5722, -36.3438],
                [174.5718, -36.344],
                [174.5713, -36.3448],
                [174.5713, -36.3451],
                [174.5712, -36.3453],
                [174.5712, -36.3453],
                [174.571, -36.3452],
                [174.5707, -36.3449],
                [174.5706, -36.3448],
                [174.5702, -36.3446],
                [174.5699, -36.3445],
                [174.5697, -36.3444],
                [174.5691, -36.3444],
                [174.5686, -36.3448],
                [174.5623, -36.3559],
                [174.5622, -36.3565],
                [174.5625, -36.3571],
                [174.5656, -36.3596],
                [174.5657, -36.3598],
                [174.5656, -36.36],
                [174.5634, -36.3654],
                [174.5633, -36.3655],
                [174.5632, -36.3656],
                [174.5514, -36.3656],
                [174.5508, -36.3654],
                [174.5508, -36.3654],
                [174.5504, -36.3652],
                [174.5498, -36.3653],
                [174.5498, -36.3653],
                [174.5498, -36.3653],
                [174.5496, -36.3652],
                [174.5495, -36.365],
                [174.5496, -36.3647],
                [174.5496, -36.3646],
                [174.5496, -36.3646],
                [174.5497, -36.3645],
                [174.5499, -36.3641],
                [174.55, -36.3639],
                [174.5501, -36.3638],
                [174.5501, -36.3636],
                [174.5505, -36.3633],
                [174.5507, -36.3631],
                [174.5509, -36.3628],
                [174.5509, -36.3628],
                [174.5513, -36.3621],
                [174.551, -36.3613],
                [174.551, -36.3613],
                [174.5509, -36.3612],
                [174.5507, -36.3608],
                [174.5507, -36.3609],
                [174.5505, -36.3605],
                [174.55, -36.3602],
                [174.5497, -36.3602],
                [174.5496, -36.3601],
                [174.5496, -36.3601],
                [174.5496, -36.3599],
                [174.5517, -36.3564],
                [174.5518, -36.3556],
                [174.5512, -36.3551],
                [174.5424, -36.3519],
                [174.5422, -36.3516],
                [174.5422, -36.3515],
                [174.5425, -36.3513],
                [174.5426, -36.3512],
                [174.5427, -36.351],
                [174.543, -36.3509],
                [174.5433, -36.3508],
                [174.5434, -36.3508],
                [174.5436, -36.3508],
                [174.5439, -36.3508],
                [174.5443, -36.3506],
                [174.5448, -36.3503],
                [174.5449, -36.3497],
                [174.5448, -36.349],
                [174.5449, -36.3485],
                [174.5449, -36.3485],
                [174.545, -36.3482],
                [174.545, -36.3481],
                [174.5451, -36.3479],
                [174.5452, -36.3478],
                [174.5453, -36.3477],
                [174.5454, -36.3476],
                [174.5458, -36.3474],
                [174.546, -36.3473],
                [174.5462, -36.3472],
                [174.5465, -36.3471],
                [174.5466, -36.347],
                [174.547, -36.3465],
                [174.5469, -36.3459],
                [174.5464, -36.3455],
                [174.5463, -36.3454],
                [174.5462, -36.3454],
                [174.546, -36.3453],
                [174.5457, -36.3451],
                [174.5456, -36.3449],
                [174.5455, -36.3448],
                [174.5453, -36.3442],
                [174.5453, -36.3442],
                [174.5452, -36.3438],
                [174.5452, -36.3438],
                [174.5451, -36.3433],
                [174.545, -36.343],
                [174.5449, -36.3428],
                [174.5449, -36.3427],
                [174.5448, -36.3426],
                [174.5447, -36.3423],
                [174.5447, -36.3422],
                [174.5447, -36.3419],
                [174.5446, -36.3418],
                [174.5446, -36.3416],
                [174.544, -36.3409],
                [174.5404, -36.3395],
                [174.5403, -36.3394],
                [174.5403, -36.3394],
                [174.5403, -36.3392],
                [174.5408, -36.3391],
                [174.5411, -36.339],
                [174.5413, -36.3388],
                [174.5414, -36.3388],
                [174.5416, -36.3387],
                [174.542, -36.3388],
                [174.5428, -36.3385],
                [174.5429, -36.3384],
                [174.5431, -36.3379],
                [174.543, -36.3373],
                [174.543, -36.337],
                [174.543, -36.3369],
                [174.5431, -36.3367],
                [174.543, -36.3362],
                [174.543, -36.3362],
                [174.5429, -36.3358],
                [174.5429, -36.3353],
                [174.543, -36.3349],
                [174.5433, -36.3346],
                [174.5433, -36.3346],
                [174.5439, -36.3342],
                [174.5443, -36.3336],
                [174.5443, -36.3335],
                [174.5444, -36.3332],
                [174.5445, -36.3332],
                [174.5446, -36.3331],
                [174.5446, -36.333],
                [174.5446, -36.3326],
                [174.5447, -36.3326],
                [174.5448, -36.3321],
                [174.5452, -36.3316],
                [174.5452, -36.3316],
                [174.5455, -36.3312],
                [174.5456, -36.331],
                [174.5456, -36.3304],
                [174.5452, -36.3299],
                [174.5446, -36.3298],
                [174.5446, -36.3298],
                [174.5444, -36.3297],
                [174.5443, -36.3295],
                [174.5444, -36.3294],
                [174.5445, -36.3293],
                [174.5451, -36.3287],
                [174.5448, -36.3279],
                [174.5428, -36.3258],
                [174.5428, -36.3258],
                [174.5422, -36.3252],
                [174.5421, -36.325],
                [174.542, -36.3242],
                [174.5419, -36.324],
                [174.5413, -36.3227],
                [174.5413, -36.3226],
                [174.5412, -36.3222],
                [174.5411, -36.322],
                [174.5411, -36.3219],
                [174.5409, -36.3214],
                [174.5408, -36.32],
                [174.5408, -36.32],
                [174.5407, -36.3193],
                [174.5405, -36.319],
                [174.5405, -36.3189],
                [174.5402, -36.3184],
                [174.54, -36.3182],
                [174.5392, -36.3173],
                [174.5391, -36.3173],
                [174.5383, -36.3165],
                [174.5382, -36.3164],
                [174.5372, -36.3158],
                [174.5369, -36.3157],
                [174.5357, -36.3155],
                [174.5352, -36.3155],
                [174.5336, -36.3162],
                [174.5336, -36.3162],
                [174.5307, -36.3174],
                [174.5303, -36.3174],
                [174.5297, -36.317],
                [174.5296, -36.3169],
                [174.5293, -36.3166],
                [174.5292, -36.3165],
                [174.5291, -36.3163],
                [174.529, -36.3161],
                [174.5289, -36.3158],
                [174.5289, -36.3158],
                [174.5287, -36.3155],
                [174.5286, -36.3151],
                [174.5286, -36.3149],
                [174.5286, -36.3148],
                [174.5288, -36.3145],
                [174.5289, -36.3144],
                [174.5293, -36.3139],
                [174.5295, -36.3136],
                [174.5295, -36.3135],
                [174.5295, -36.313],
                [174.5295, -36.313],
                [174.529, -36.3124],
                [174.5282, -36.3124],
                [174.5278, -36.3126],
                [174.5275, -36.3127],
                [174.5273, -36.3126],
                [174.5271, -36.3125],
                [174.5264, -36.3122],
                [174.5261, -36.3123],
                [174.5257, -36.3122],
                [174.5257, -36.3122],
                [174.5254, -36.3119],
                [174.5251, -36.3117],
                [174.525, -36.3117],
                [174.5236, -36.311],
                [174.5235, -36.311],
                [174.5228, -36.3106],
                [174.5224, -36.3105],
                [174.5222, -36.3105],
                [174.522, -36.3105],
                [174.5213, -36.3102],
                [174.5211, -36.3102],
                [174.5197, -36.31],
                [174.5191, -36.3102],
                [174.519, -36.3104],
                [174.5186, -36.3106],
                [174.5185, -36.3105],
                [174.5184, -36.3104],
                [174.5183, -36.3103],
                [174.518, -36.3098],
                [174.5175, -36.3096],
                [174.5174, -36.3096],
                [174.5173, -36.3094],
                [174.5171, -36.3082],
                [174.5172, -36.3078],
                [174.5172, -36.3075],
                [174.5172, -36.3075],
                [174.5172, -36.3074],
                [174.5171, -36.3073],
                [174.5171, -36.3072],
                [174.5171, -36.3071],
                [174.5171, -36.3072],
                [174.5169, -36.3067],
                [174.5164, -36.3064],
                [174.5164, -36.3064],
                [174.5163, -36.3063],
                [174.5163, -36.3063],
                [174.5162, -36.3062],
                [174.516, -36.3058],
                [174.516, -36.3058],
                [174.5158, -36.3053],
                [174.5158, -36.3052],
                [174.5157, -36.3049],
                [174.5156, -36.3049],
                [174.5154, -36.3045],
                [174.5148, -36.3042],
                [174.5145, -36.3042],
                [174.5138, -36.3045],
                [174.5136, -36.3051],
                [174.5135, -36.3072],
                [174.5134, -36.3074],
                [174.5132, -36.3075],
                [174.5068, -36.3075],
                [174.5066, -36.3074],
                [174.5065, -36.3072],
                [174.5065, -36.3023],
                [174.5063, -36.3017],
                [174.5057, -36.3014],
                [174.5032, -36.3011],
                [174.5031, -36.3011],
                [174.5024, -36.3011],
                [174.5023, -36.301],
                [174.5022, -36.3009],
                [174.5006, -36.2971],
                [174.5006, -36.2971],
                [174.5009, -36.2967],
                [174.5101, -36.2924],
                [174.5105, -36.2918],
                [174.5104, -36.291],
                [174.5098, -36.2903],
                [174.5098, -36.2903],
                [174.5097, -36.2902],
                [174.5095, -36.29],
                [174.5094, -36.29],
                [174.5089, -36.2894],
                [174.5088, -36.2892],
                [174.5082, -36.2887],
                [174.5072, -36.2884],
                [174.507, -36.2883],
                [174.507, -36.2881],
                [174.5093, -36.2849],
                [174.5095, -36.2848],
                [174.5095, -36.2848],
                [174.5097, -36.2849],
                [174.51, -36.2856],
                [174.5101, -36.2857],
                [174.5104, -36.286],
                [174.5108, -36.2863],
                [174.511, -36.2864],
                [174.5111, -36.2865],
                [174.5112, -36.2866],
                [174.512, -36.2868],
                [174.512, -36.2868],
                [174.5124, -36.2869],
                [174.5128, -36.2871],
                [174.5134, -36.2872],
                [174.5138, -36.2869],
                [174.5147, -36.2861],
                [174.515, -36.2856],
                [174.5149, -36.285],
                [174.5147, -36.2848],
                [174.5148, -36.2848],
                [174.5144, -36.2844],
                [174.5144, -36.2842],
                [174.5145, -36.2841],
                [174.5148, -36.2836],
                [174.5147, -36.283],
                [174.5143, -36.2827],
                [174.5142, -36.2824],
                [174.5142, -36.2822],
                [174.5142, -36.2821],
                [174.5144, -36.2815],
                [174.5141, -36.281],
                [174.5135, -36.2807],
                [174.5135, -36.2807],
                [174.5133, -36.2806],
                [174.5132, -36.2804],
                [174.5128, -36.2801],
                [174.5128, -36.2801],
                [174.5126, -36.2799],
                [174.5126, -36.2797],
                [174.5126, -36.2792],
                [174.5122, -36.2788],
                [174.5121, -36.2788],
                [174.5115, -36.2786],
                [174.511, -36.279],
                [174.5104, -36.2798],
                [174.5102, -36.2799],
                [174.51, -36.2799],
                [174.5097, -36.2798],
                [174.5094, -36.2797],
                [174.5092, -36.2797],
                [174.5086, -36.2797],
                [174.5086, -36.2797],
                [174.5082, -36.2797],
                [174.5082, -36.2797],
                [174.5079, -36.2797],
                [174.5075, -36.28],
                [174.5075, -36.28],
                [174.5069, -36.2802],
                [174.5068, -36.2801],
                [174.5068, -36.2801],
                [174.5068, -36.2799],
                [174.507, -36.2796],
                [174.507, -36.2796],
                [174.5107, -36.2746],
                [174.5109, -36.274],
                [174.5107, -36.2734],
                [174.5101, -36.2731],
                [174.5088, -36.2729],
                [174.5085, -36.2727],
                [174.5078, -36.2719],
                [174.5074, -36.2716],
                [174.5067, -36.2713],
                [174.5065, -36.2712],
                [174.5057, -36.2704],
                [174.5056, -36.2702],
                [174.5057, -36.27],
                [174.5066, -36.2685],
                [174.5066, -36.2677],
                [174.5063, -36.267],
                [174.5059, -36.2665],
                [174.5056, -36.2664],
                [174.5055, -36.2662],
                [174.5055, -36.266],
                [174.5064, -36.2643],
                [174.5063, -36.2634],
                [174.5063, -36.2633],
                [174.5062, -36.2631],
                [174.506, -36.2618],
                [174.506, -36.2616],
                [174.5064, -36.2606],
                [174.5066, -36.2602],
                [174.5066, -36.2602],
                [174.5068, -36.2601],
                [174.5069, -36.2595],
                [174.5068, -36.2593],
                [174.5068, -36.2592],
                [174.5066, -36.2588],
                [174.5062, -36.2585],
                [174.5062, -36.2585],
                [174.5061, -36.2583],
                [174.5059, -36.258],
                [174.506, -36.2577],
                [174.5059, -36.2573],
                [174.5055, -36.2568],
                [174.5045, -36.256],
                [174.504, -36.2559],
                [174.5009, -36.2555],
                [174.5005, -36.2556],
                [174.4991, -36.2561],
                [174.4987, -36.2561],
                [174.4979, -36.2557],
                [174.4978, -36.2557],
                [174.4939, -36.2542],
                [174.4936, -36.254],
                [174.4935, -36.2538],
                [174.4931, -36.2534],
                [174.4929, -36.2533],
                [174.4929, -36.2533],
                [174.4924, -36.2531],
                [174.4919, -36.2531],
                [174.4917, -36.253],
                [174.4916, -36.2529],
                [174.4913, -36.2527],
                [174.4912, -36.2526],
                [174.4905, -36.2521],
                [174.4854, -36.2517],
                [174.4849, -36.2519],
                [174.4845, -36.2524],
                [174.4846, -36.253],
                [174.4846, -36.2531],
                [174.4847, -36.2538],
                [174.4847, -36.254],
                [174.4844, -36.2559],
                [174.4842, -36.2562],
                [174.4818, -36.2583],
                [174.4816, -36.2584],
                [174.476, -36.2601],
                [174.4758, -36.2601],
                [174.4734, -36.2595],
                [174.4731, -36.2593],
                [174.4726, -36.2587],
                [174.4725, -36.2584],
                [174.4724, -36.2573],
                [174.4723, -36.257],
                [174.4719, -36.2562],
                [174.4719, -36.256],
                [174.4718, -36.2551],
                [174.4719, -36.2548],
                [174.4723, -36.2541],
                [174.4727, -36.2538],
                [174.4731, -36.2538],
                [174.4733, -36.2538],
                [174.4734, -36.2539],
                [174.4739, -36.2539],
                [174.4744, -36.2536],
                [174.4746, -36.253],
                [174.4746, -36.2528],
                [174.4754, -36.2512],
                [174.4755, -36.2509],
                [174.4756, -36.2505],
                [174.4753, -36.2498],
                [174.4749, -36.2494],
                [174.4748, -36.249],
                [174.4748, -36.2487],
                [174.4749, -36.2486],
                [174.475, -36.2485],
                [174.4761, -36.248],
                [174.4766, -36.2475],
                [174.4766, -36.2467],
                [174.4763, -36.2463],
                [174.4761, -36.2461],
                [174.4748, -36.2449],
                [174.4747, -36.2448],
                [174.4744, -36.2442],
                [174.4743, -36.2438],
                [174.4745, -36.2427],
                [174.4744, -36.242],
                [174.4739, -36.2417],
                [174.4734, -36.2413],
                [174.473, -36.24],
                [174.4728, -36.2397],
                [174.4727, -36.2396],
                [174.4725, -36.2392],
                [174.4725, -36.239],
                [174.4727, -36.2386],
                [174.4727, -36.2386],
                [174.4731, -36.2384],
                [174.4733, -36.2384],
                [174.474, -36.2383],
                [174.4743, -36.2377],
                [174.4741, -36.237],
                [174.474, -36.2369],
                [174.4739, -36.2365],
                [174.4739, -36.2365],
                [174.474, -36.2361],
                [174.4749, -36.2351],
                [174.4751, -36.2349],
                [174.4753, -36.2345],
                [174.4753, -36.2344],
                [174.4755, -36.2338],
                [174.4756, -36.2338],
                [174.4762, -36.2336],
                [174.4763, -36.2335],
                [174.4763, -36.2334],
                [174.4768, -36.2329],
                [174.477, -36.2325],
                [174.4771, -36.2321],
                [174.4773, -36.2318],
                [174.4777, -36.2315],
                [174.478, -36.2311],
                [174.478, -36.231],
                [174.4784, -36.2303],
                [174.4787, -36.23],
                [174.4793, -36.2298],
                [174.4797, -36.2293],
                [174.4798, -36.2287],
                [174.4794, -36.2282],
                [174.4788, -36.2281],
                [174.4786, -36.2281],
                [174.4784, -36.2281],
                [174.4777, -36.2284],
                [174.4775, -36.2284],
                [174.4751, -36.2281],
                [174.4749, -36.2281],
                [174.4745, -36.2281],
                [174.474, -36.2284],
                [174.4737, -36.2289],
                [174.4739, -36.2295],
                [174.4748, -36.2308],
                [174.4748, -36.2308],
                [174.4749, -36.231],
                [174.475, -36.2313],
                [174.475, -36.232],
                [174.4749, -36.2323],
                [174.4748, -36.2324],
                [174.4747, -36.2331],
                [174.4747, -36.2331],
                [174.4742, -36.2331],
                [174.4732, -36.2336],
                [174.473, -36.2338],
                [174.4727, -36.2339],
                [174.4725, -36.234],
                [174.4723, -36.234],
                [174.472, -36.234],
                [174.4716, -36.2339],
                [174.4712, -36.2336],
                [174.4711, -36.2334],
                [174.471, -36.2334],
                [174.4709, -36.2334],
                [174.4704, -36.2332],
                [174.4703, -36.2331],
                [174.4699, -36.2329],
                [174.4698, -36.2326],
                [174.4697, -36.2323],
                [174.4697, -36.2321],
                [174.4696, -36.2317],
                [174.4695, -36.2315],
                [174.4693, -36.2312],
                [174.4691, -36.2308],
                [174.469, -36.2308],
                [174.4687, -36.2303],
                [174.4686, -36.2304],
                [174.4682, -36.23],
                [174.4675, -36.2297],
                [174.4673, -36.2296],
                [174.4662, -36.2286],
                [174.4662, -36.2286],
                [174.4609, -36.2227],
                [174.4608, -36.2221],
                [174.4611, -36.2214],
                [174.4612, -36.2211],
                [174.4622, -36.2204],
                [174.4624, -36.2203],
                [174.4628, -36.2202],
                [174.4631, -36.22],
                [174.4632, -36.2199],
                [174.4634, -36.2197],
                [174.4637, -36.2194],
                [174.4638, -36.2193],
                [174.4642, -36.219],
                [174.4643, -36.2189],
                [174.4648, -36.2187],
              ],
            ],
          },
        },
      ],
    },
    updatedAt: "2023-09-15T09:31:02.613307+12:00",
  },
  {
    id: "10",
    name: "Zone 10",
    description:
      "The primary suburbs for this zone include Mangawhai, Pakiri and Wellsford",
    message:
      "Cyclone XXX has done a lot of damage on the network, and the suburbs of YYY, ZZZ and HHH are particularly affected.",
    active: true,
    restorationState: "50%",
    estimatedTimeRestoration: "10 hours",
    center: {
      longitude: 174.340815,
      latitude: -36.636827,
    },
    shape: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            stormZoneType: "stormZone",
          },
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [174.1897, -36.454],
                [174.1891, -36.4542],
                [174.1888, -36.4548],
                [174.1887, -36.4552],
                [174.1888, -36.4556],
                [174.189, -36.4562],
                [174.1892, -36.4566],
                [174.1901, -36.4574],
                [174.1904, -36.4576],
                [174.1911, -36.4579],
                [174.1912, -36.458],
                [174.1921, -36.4587],
                [174.1922, -36.4588],
                [174.1932, -36.4605],
                [174.1932, -36.4606],
                [174.1934, -36.4615],
                [174.1934, -36.4616],
                [174.1942, -36.4635],
                [174.1942, -36.4636],
                [174.1943, -36.4638],
                [174.1947, -36.4641],
                [174.1947, -36.4642],
                [174.195, -36.4644],
                [174.1951, -36.4646],
                [174.1952, -36.4649],
                [174.1952, -36.4665],
                [174.1952, -36.4667],
                [174.1948, -36.4677],
                [174.1947, -36.468],
                [174.1947, -36.4681],
                [174.1947, -36.4683],
                [174.1938, -36.4702],
                [174.1938, -36.4707],
                [174.194, -36.4719],
                [174.194, -36.472],
                [174.195, -36.4752],
                [174.1954, -36.4756],
                [174.196, -36.4758],
                [174.1966, -36.4755],
                [174.197, -36.475],
                [174.197, -36.4749],
                [174.1972, -36.4747],
                [174.1974, -36.4746],
                [174.1977, -36.4746],
                [174.1984, -36.4744],
                [174.1987, -36.4737],
                [174.1987, -36.4735],
                [174.1987, -36.4735],
                [174.1988, -36.4728],
                [174.1988, -36.4728],
                [174.1989, -36.4721],
                [174.1989, -36.4719],
                [174.1988, -36.4715],
                [174.1988, -36.4714],
                [174.1988, -36.4709],
                [174.1988, -36.4707],
                [174.1989, -36.4705],
                [174.1989, -36.4705],
                [174.1992, -36.4697],
                [174.1992, -36.4696],
                [174.1997, -36.4679],
                [174.1997, -36.4677],
                [174.1997, -36.4672],
                [174.1996, -36.4666],
                [174.1991, -36.4663],
                [174.1986, -36.466],
                [174.1983, -36.4653],
                [174.1982, -36.4651],
                [174.1982, -36.4632],
                [174.1983, -36.4629],
                [174.1985, -36.4627],
                [174.1986, -36.4626],
                [174.1987, -36.4625],
                [174.199, -36.462],
                [174.1991, -36.4618],
                [174.1991, -36.4616],
                [174.1991, -36.461],
                [174.1991, -36.4607],
                [174.199, -36.4606],
                [174.199, -36.4605],
                [174.1988, -36.4597],
                [174.1988, -36.4596],
                [174.1988, -36.4595],
                [174.1988, -36.4593],
                [174.199, -36.4586],
                [174.199, -36.4585],
                [174.199, -36.4585],
                [174.1991, -36.4582],
                [174.1992, -36.4576],
                [174.1992, -36.4575],
                [174.1994, -36.4571],
                [174.1994, -36.457],
                [174.1995, -36.4568],
                [174.1995, -36.4564],
                [174.1994, -36.4562],
                [174.1994, -36.456],
                [174.1995, -36.4553],
                [174.1995, -36.455],
                [174.1994, -36.4548],
                [174.1994, -36.4539],
                [174.1994, -36.4537],
                [174.2002, -36.4518],
                [174.2002, -36.4517],
                [174.2007, -36.4509],
                [174.2007, -36.4508],
                [174.2008, -36.4506],
                [174.2008, -36.4505],
                [174.2011, -36.4499],
                [174.2011, -36.4498],
                [174.2016, -36.4491],
                [174.2017, -36.449],
                [174.2018, -36.4489],
                [174.2019, -36.4487],
                [174.2019, -36.4487],
                [174.2021, -36.4484],
                [174.2022, -36.4483],
                [174.2023, -36.4482],
                [174.2024, -36.448],
                [174.2025, -36.4479],
                [174.2027, -36.4476],
                [174.2027, -36.4476],
                [174.2029, -36.4474],
                [174.2032, -36.4469],
                [174.2032, -36.4468],
                [174.2036, -36.4464],
                [174.2039, -36.4462],
                [174.2045, -36.446],
                [174.2049, -36.4459],
                [174.2052, -36.4457],
                [174.2053, -36.4456],
                [174.2053, -36.4455],
                [174.2055, -36.4453],
                [174.2056, -36.4451],
                [174.2057, -36.445],
                [174.2058, -36.4449],
                [174.2061, -36.4441],
                [174.2059, -36.4433],
                [174.206, -36.4431],
                [174.2061, -36.4429],
                [174.2063, -36.4429],
                [174.2063, -36.4428],
                [174.2068, -36.4425],
                [174.2069, -36.4425],
                [174.2069, -36.4425],
                [174.2073, -36.4423],
                [174.2074, -36.4423],
                [174.2078, -36.4422],
                [174.2081, -36.4421],
                [174.2082, -36.4421],
                [174.2084, -36.4418],
                [174.2086, -36.4416],
                [174.2087, -36.4413],
                [174.2088, -36.4409],
                [174.2091, -36.4405],
                [174.2092, -36.4399],
                [174.2089, -36.4393],
                [174.2083, -36.4391],
                [174.2083, -36.4391],
                [174.2082, -36.4391],
                [174.2079, -36.4391],
                [174.2078, -36.439],
                [174.2077, -36.4389],
                [174.2078, -36.4387],
                [174.2084, -36.4381],
                [174.2085, -36.438],
                [174.2086, -36.4378],
                [174.209, -36.4375],
                [174.2129, -36.4375],
                [174.2134, -36.4374],
                [174.2303, -36.4276],
                [174.2306, -36.4275],
                [174.2323, -36.4276],
                [174.2324, -36.4276],
                [174.2327, -36.4277],
                [174.2327, -36.4277],
                [174.2334, -36.4279],
                [174.2335, -36.4279],
                [174.2339, -36.428],
                [174.2343, -36.4279],
                [174.2343, -36.4279],
                [174.2345, -36.4278],
                [174.2347, -36.4278],
                [174.235, -36.4279],
                [174.2352, -36.4279],
                [174.2353, -36.4279],
                [174.2357, -36.428],
                [174.236, -36.4283],
                [174.2361, -36.4284],
                [174.2363, -36.4286],
                [174.2363, -36.4287],
                [174.2364, -36.429],
                [174.2366, -36.4293],
                [174.2367, -36.4294],
                [174.237, -36.4299],
                [174.237, -36.4299],
                [174.2372, -36.4303],
                [174.2373, -36.4305],
                [174.2374, -36.4307],
                [174.2375, -36.4309],
                [174.2375, -36.4309],
                [174.2376, -36.4312],
                [174.2376, -36.4314],
                [174.2377, -36.4314],
                [174.2379, -36.4318],
                [174.2381, -36.4319],
                [174.2382, -36.432],
                [174.2386, -36.4325],
                [174.2386, -36.4326],
                [174.2388, -36.433],
                [174.2388, -36.4331],
                [174.2388, -36.4331],
                [174.239, -36.4333],
                [174.2393, -36.4336],
                [174.2395, -36.4339],
                [174.2396, -36.434],
                [174.2399, -36.4344],
                [174.24, -36.4347],
                [174.24, -36.435],
                [174.2405, -36.4358],
                [174.2406, -36.4359],
                [174.2407, -36.4359],
                [174.2408, -36.436],
                [174.241, -36.4363],
                [174.2411, -36.4367],
                [174.2413, -36.437],
                [174.2415, -36.4372],
                [174.2415, -36.4372],
                [174.2417, -36.4376],
                [174.2418, -36.4376],
                [174.2422, -36.438],
                [174.2424, -36.4386],
                [174.2425, -36.4387],
                [174.2428, -36.439],
                [174.2428, -36.4391],
                [174.243, -36.4395],
                [174.2433, -36.44],
                [174.2435, -36.4403],
                [174.2436, -36.4404],
                [174.2438, -36.4406],
                [174.244, -36.441],
                [174.2441, -36.4411],
                [174.2442, -36.4412],
                [174.2445, -36.4418],
                [174.2446, -36.4419],
                [174.245, -36.4423],
                [174.245, -36.4424],
                [174.2457, -36.4435],
                [174.2459, -36.4437],
                [174.246, -36.4438],
                [174.247, -36.4449],
                [174.2471, -36.445],
                [174.2474, -36.4453],
                [174.2477, -36.4457],
                [174.2477, -36.4458],
                [174.2479, -36.4461],
                [174.2481, -36.4463],
                [174.2482, -36.4464],
                [174.2482, -36.4464],
                [174.249, -36.4473],
                [174.249, -36.4474],
                [174.2491, -36.4474],
                [174.2497, -36.448],
                [174.2497, -36.4481],
                [174.2508, -36.4495],
                [174.2509, -36.4496],
                [174.2513, -36.4504],
                [174.2513, -36.4505],
                [174.2514, -36.4508],
                [174.2514, -36.451],
                [174.2514, -36.4514],
                [174.2514, -36.4514],
                [174.2515, -36.4519],
                [174.252, -36.4523],
                [174.2593, -36.4546],
                [174.2596, -36.4548],
                [174.265, -36.4628],
                [174.265, -36.4629],
                [174.2696, -36.4731],
                [174.2696, -36.4732],
                [174.2712, -36.4789],
                [174.2714, -36.4792],
                [174.2848, -36.4946],
                [174.2848, -36.4946],
                [174.2851, -36.495],
                [174.2855, -36.4952],
                [174.2858, -36.4953],
                [174.2861, -36.4958],
                [174.2863, -36.496],
                [174.2876, -36.4969],
                [174.2877, -36.4969],
                [174.2885, -36.4976],
                [174.2885, -36.4976],
                [174.2888, -36.498],
                [174.2889, -36.4981],
                [174.2898, -36.4994],
                [174.2898, -36.4994],
                [174.2902, -36.5002],
                [174.2904, -36.5003],
                [174.2904, -36.5004],
                [174.2905, -36.5005],
                [174.2906, -36.5007],
                [174.2908, -36.5009],
                [174.2913, -36.5015],
                [174.2913, -36.5015],
                [174.2921, -36.5024],
                [174.2921, -36.5025],
                [174.2922, -36.5027],
                [174.2923, -36.5028],
                [174.2928, -36.5034],
                [174.2929, -36.5036],
                [174.2929, -36.5036],
                [174.2929, -36.504],
                [174.2927, -36.5043],
                [174.2926, -36.5046],
                [174.2925, -36.5047],
                [174.2922, -36.5051],
                [174.2921, -36.5052],
                [174.2919, -36.5056],
                [174.2919, -36.5057],
                [174.2918, -36.5058],
                [174.2917, -36.5061],
                [174.2913, -36.5064],
                [174.2911, -36.5066],
                [174.2911, -36.5067],
                [174.2909, -36.5072],
                [174.291, -36.5078],
                [174.2911, -36.5083],
                [174.2915, -36.5088],
                [174.292, -36.5091],
                [174.2931, -36.5094],
                [174.2932, -36.5094],
                [174.2933, -36.5095],
                [174.2934, -36.5096],
                [174.2935, -36.5097],
                [174.2936, -36.5098],
                [174.3, -36.5212],
                [174.3, -36.5213],
                [174.3048, -36.5292],
                [174.3052, -36.5295],
                [174.3166, -36.534],
                [174.3167, -36.534],
                [174.3269, -36.537],
                [174.3269, -36.537],
                [174.3428, -36.5416],
                [174.3429, -36.5416],
                [174.3465, -36.5432],
                [174.3468, -36.5434],
                [174.3474, -36.5436],
                [174.3479, -36.5436],
                [174.3504, -36.5444],
                [174.3505, -36.5444],
                [174.3509, -36.5447],
                [174.3514, -36.5448],
                [174.352, -36.5448],
                [174.3522, -36.5449],
                [174.3542, -36.5458],
                [174.3543, -36.5459],
                [174.3549, -36.5465],
                [174.355, -36.5466],
                [174.3558, -36.548],
                [174.3559, -36.5482],
                [174.3561, -36.5502],
                [174.356, -36.5505],
                [174.3552, -36.5519],
                [174.3548, -36.5522],
                [174.3544, -36.5527],
                [174.3545, -36.5533],
                [174.3549, -36.5537],
                [174.3551, -36.5538],
                [174.3553, -36.554],
                [174.3557, -36.5541],
                [174.3557, -36.5541],
                [174.3564, -36.5544],
                [174.3564, -36.5544],
                [174.3569, -36.5546],
                [174.3576, -36.5545],
                [174.3578, -36.5545],
                [174.3583, -36.5547],
                [174.3586, -36.5548],
                [174.3587, -36.5549],
                [174.3588, -36.5552],
                [174.3591, -36.5562],
                [174.3598, -36.5568],
                [174.3608, -36.557],
                [174.3612, -36.5572],
                [174.3613, -36.5572],
                [174.3615, -36.5572],
                [174.3614, -36.5573],
                [174.3619, -36.5575],
                [174.3621, -36.558],
                [174.3621, -36.558],
                [174.3626, -36.5586],
                [174.3628, -36.5586],
                [174.3631, -36.5587],
                [174.3634, -36.5587],
                [174.3637, -36.5588],
                [174.3652, -36.5599],
                [174.3653, -36.56],
                [174.3657, -36.5605],
                [174.3659, -36.5609],
                [174.3656, -36.5624],
                [174.3656, -36.5627],
                [174.3656, -36.5628],
                [174.3659, -36.5633],
                [174.3664, -36.5635],
                [174.3667, -36.5634],
                [174.3668, -36.5634],
                [174.3673, -36.5633],
                [174.3687, -36.5635],
                [174.3688, -36.5635],
                [174.3694, -36.5635],
                [174.3696, -36.5635],
                [174.3699, -36.5634],
                [174.3706, -36.5629],
                [174.3706, -36.5627],
                [174.371, -36.5624],
                [174.3712, -36.5623],
                [174.3713, -36.5623],
                [174.3734, -36.5623],
                [174.3736, -36.5623],
                [174.3759, -36.5632],
                [174.376, -36.5633],
                [174.3773, -36.5644],
                [174.3774, -36.5647],
                [174.3778, -36.5657],
                [174.3778, -36.5658],
                [174.3789, -36.5678],
                [174.3789, -36.5679],
                [174.3792, -36.5687],
                [174.3792, -36.5689],
                [174.3792, -36.5689],
                [174.3791, -36.5692],
                [174.3791, -36.5699],
                [174.3793, -36.5702],
                [174.3793, -36.5704],
                [174.3793, -36.5717],
                [174.3793, -36.5719],
                [174.3782, -36.5742],
                [174.3781, -36.5744],
                [174.3778, -36.5746],
                [174.3777, -36.5747],
                [174.374, -36.5784],
                [174.3739, -36.5785],
                [174.3649, -36.5827],
                [174.3647, -36.5827],
                [174.3634, -36.5829],
                [174.3632, -36.5829],
                [174.363, -36.5828],
                [174.3623, -36.5829],
                [174.3619, -36.5834],
                [174.362, -36.5841],
                [174.3621, -36.5845],
                [174.3622, -36.5847],
                [174.3622, -36.5856],
                [174.3622, -36.5858],
                [174.3618, -36.5865],
                [174.3619, -36.5875],
                [174.362, -36.5875],
                [174.3621, -36.5879],
                [174.3621, -36.5887],
                [174.362, -36.5889],
                [174.362, -36.5891],
                [174.3619, -36.5899],
                [174.3624, -36.5902],
                [174.3623, -36.5902],
                [174.3629, -36.5906],
                [174.3633, -36.5916],
                [174.3634, -36.5918],
                [174.3634, -36.5927],
                [174.3632, -36.5931],
                [174.3629, -36.5933],
                [174.3628, -36.5934],
                [174.3625, -36.5935],
                [174.3619, -36.5942],
                [174.3603, -36.6014],
                [174.3603, -36.6017],
                [174.3608, -36.6056],
                [174.3607, -36.606],
                [174.3567, -36.6109],
                [174.3565, -36.6114],
                [174.3561, -36.6141],
                [174.3562, -36.6147],
                [174.3567, -36.6151],
                [174.3635, -36.6178],
                [174.3636, -36.6178],
                [174.3733, -36.6228],
                [174.3738, -36.6229],
                [174.3792, -36.6223],
                [174.3794, -36.6223],
                [174.3859, -36.62],
                [174.3863, -36.6198],
                [174.3883, -36.6176],
                [174.3889, -36.6175],
                [174.3984, -36.6205],
                [174.3985, -36.6205],
                [174.405, -36.6233],
                [174.4057, -36.6234],
                [174.4168, -36.6189],
                [174.4172, -36.6189],
                [174.4208, -36.6204],
                [174.4211, -36.6208],
                [174.4216, -36.6241],
                [174.4218, -36.6247],
                [174.4266, -36.6291],
                [174.4267, -36.6292],
                [174.4309, -36.6342],
                [174.431, -36.6343],
                [174.4327, -36.6383],
                [174.4339, -36.6411],
                [174.4342, -36.6415],
                [174.4361, -36.6428],
                [174.4362, -36.643],
                [174.4405, -36.6447],
                [174.4406, -36.6448],
                [174.4413, -36.6454],
                [174.4415, -36.6459],
                [174.4415, -36.6459],
                [174.4415, -36.6461],
                [174.4415, -36.6461],
                [174.4415, -36.6465],
                [174.4412, -36.6478],
                [174.4412, -36.648],
                [174.4412, -36.6482],
                [174.4411, -36.6484],
                [174.4412, -36.6491],
                [174.4418, -36.6495],
                [174.4424, -36.6497],
                [174.4491, -36.6563],
                [174.4492, -36.6567],
                [174.4493, -36.6587],
                [174.4493, -36.659],
                [174.4495, -36.6594],
                [174.4495, -36.6607],
                [174.4495, -36.6609],
                [174.4497, -36.6618],
                [174.4497, -36.6618],
                [174.4498, -36.6622],
                [174.4497, -36.6626],
                [174.4497, -36.6627],
                [174.4496, -36.663],
                [174.4493, -36.6632],
                [174.4489, -36.6636],
                [174.4489, -36.6641],
                [174.4489, -36.6643],
                [174.4489, -36.6644],
                [174.4488, -36.6646],
                [174.4488, -36.6647],
                [174.4488, -36.6648],
                [174.4487, -36.6649],
                [174.4482, -36.6652],
                [174.4482, -36.6653],
                [174.4482, -36.6653],
                [174.4481, -36.6653],
                [174.4478, -36.6655],
                [174.4476, -36.6655],
                [174.447, -36.6656],
                [174.447, -36.6656],
                [174.4465, -36.6657],
                [174.4463, -36.6657],
                [174.446, -36.6659],
                [174.4459, -36.6659],
                [174.4444, -36.6662],
                [174.4443, -36.6662],
                [174.4437, -36.6664],
                [174.4436, -36.6664],
                [174.4433, -36.6666],
                [174.4432, -36.6666],
                [174.4428, -36.6669],
                [174.4424, -36.6676],
                [174.442, -36.6677],
                [174.4419, -36.6679],
                [174.4417, -36.668],
                [174.4414, -36.6683],
                [174.4413, -36.6687],
                [174.4412, -36.669],
                [174.4412, -36.6692],
                [174.4413, -36.6696],
                [174.4414, -36.6698],
                [174.4416, -36.67],
                [174.4417, -36.6702],
                [174.4418, -36.6703],
                [174.4419, -36.6703],
                [174.4422, -36.6704],
                [174.4423, -36.6705],
                [174.4425, -36.6705],
                [174.4426, -36.6705],
                [174.4428, -36.6705],
                [174.4432, -36.6704],
                [174.4433, -36.6704],
                [174.4436, -36.6703],
                [174.4437, -36.6703],
                [174.4441, -36.6702],
                [174.4444, -36.67],
                [174.4446, -36.6698],
                [174.4447, -36.6698],
                [174.4454, -36.6693],
                [174.4455, -36.6693],
                [174.4457, -36.6693],
                [174.4459, -36.6695],
                [174.4463, -36.6698],
                [174.4469, -36.6698],
                [174.4473, -36.6696],
                [174.4474, -36.6696],
                [174.4476, -36.6696],
                [174.448, -36.6694],
                [174.4481, -36.6694],
                [174.4483, -36.6694],
                [174.4486, -36.6695],
                [174.4487, -36.6695],
                [174.449, -36.6698],
                [174.4491, -36.6702],
                [174.4491, -36.6704],
                [174.4491, -36.6707],
                [174.4491, -36.6709],
                [174.4489, -36.6714],
                [174.4488, -36.6717],
                [174.4487, -36.672],
                [174.4486, -36.6726],
                [174.4486, -36.6727],
                [174.4485, -36.6729],
                [174.4485, -36.6731],
                [174.4485, -36.6738],
                [174.4485, -36.6738],
                [174.4486, -36.6748],
                [174.4486, -36.6749],
                [174.4487, -36.6754],
                [174.4487, -36.6754],
                [174.4488, -36.6765],
                [174.4488, -36.6765],
                [174.4488, -36.6771],
                [174.4488, -36.6772],
                [174.4487, -36.6776],
                [174.4489, -36.6782],
                [174.4489, -36.6783],
                [174.4491, -36.6786],
                [174.449, -36.6792],
                [174.4489, -36.6794],
                [174.4488, -36.6797],
                [174.4487, -36.6801],
                [174.4487, -36.6802],
                [174.4487, -36.6802],
                [174.4487, -36.6806],
                [174.4487, -36.6807],
                [174.4487, -36.681],
                [174.4489, -36.6813],
                [174.4491, -36.6817],
                [174.4492, -36.6822],
                [174.4492, -36.6823],
                [174.4492, -36.6827],
                [174.4491, -36.6828],
                [174.4489, -36.6837],
                [174.4489, -36.6837],
                [174.4489, -36.6839],
                [174.4488, -36.6841],
                [174.4481, -36.6844],
                [174.4479, -36.6844],
                [174.4467, -36.6845],
                [174.4464, -36.6846],
                [174.446, -36.6847],
                [174.4457, -36.6848],
                [174.4454, -36.685],
                [174.4449, -36.6854],
                [174.4447, -36.6857],
                [174.4445, -36.6861],
                [174.4445, -36.6863],
                [174.4444, -36.6867],
                [174.4444, -36.6869],
                [174.4444, -36.6872],
                [174.4444, -36.6874],
                [174.4443, -36.6876],
                [174.4442, -36.6879],
                [174.444, -36.688],
                [174.4439, -36.6881],
                [174.4435, -36.6883],
                [174.4434, -36.6883],
                [174.4431, -36.6884],
                [174.4428, -36.6884],
                [174.4424, -36.6882],
                [174.4424, -36.6882],
                [174.4419, -36.688],
                [174.4418, -36.6879],
                [174.4415, -36.6877],
                [174.4414, -36.6874],
                [174.4413, -36.6872],
                [174.4415, -36.6868],
                [174.4417, -36.6866],
                [174.4418, -36.6865],
                [174.4426, -36.686],
                [174.4427, -36.686],
                [174.4428, -36.6859],
                [174.4433, -36.6854],
                [174.4433, -36.6847],
                [174.4428, -36.6843],
                [174.4427, -36.6842],
                [174.4426, -36.6841],
                [174.4421, -36.6839],
                [174.4415, -36.6837],
                [174.4414, -36.6837],
                [174.441, -36.6835],
                [174.4409, -36.6835],
                [174.4404, -36.6832],
                [174.4403, -36.6831],
                [174.4396, -36.6825],
                [174.4395, -36.6824],
                [174.4394, -36.6823],
                [174.4392, -36.6821],
                [174.4388, -36.6817],
                [174.4385, -36.6815],
                [174.4384, -36.6815],
                [174.4383, -36.6815],
                [174.4382, -36.6815],
                [174.4376, -36.6816],
                [174.437, -36.6819],
                [174.4368, -36.6821],
                [174.4366, -36.6822],
                [174.4365, -36.6823],
                [174.4361, -36.6829],
                [174.4361, -36.683],
                [174.4359, -36.6833],
                [174.4358, -36.6839],
                [174.4361, -36.6844],
                [174.4365, -36.6847],
                [174.4369, -36.6851],
                [174.437, -36.6852],
                [174.4373, -36.6853],
                [174.4374, -36.6854],
                [174.4375, -36.6854],
                [174.4376, -36.6857],
                [174.4377, -36.6859],
                [174.4377, -36.6861],
                [174.4377, -36.6863],
                [174.4377, -36.6865],
                [174.4376, -36.6866],
                [174.4375, -36.6869],
                [174.4371, -36.6871],
                [174.437, -36.6872],
                [174.4368, -36.6873],
                [174.4366, -36.6873],
                [174.4364, -36.6873],
                [174.4364, -36.6873],
                [174.4349, -36.6872],
                [174.4349, -36.6872],
                [174.434, -36.6872],
                [174.4338, -36.6872],
                [174.4337, -36.6873],
                [174.4332, -36.6876],
                [174.4327, -36.6882],
                [174.4326, -36.6883],
                [174.4325, -36.6886],
                [174.4325, -36.6887],
                [174.4321, -36.6896],
                [174.432, -36.6898],
                [174.4318, -36.6901],
                [174.4316, -36.6903],
                [174.4314, -36.6908],
                [174.4313, -36.6915],
                [174.4318, -36.692],
                [174.4325, -36.692],
                [174.4326, -36.692],
                [174.4333, -36.6917],
                [174.4334, -36.6917],
                [174.4338, -36.6916],
                [174.4339, -36.6916],
                [174.4344, -36.6916],
                [174.4346, -36.6916],
                [174.4348, -36.6917],
                [174.4351, -36.6918],
                [174.4351, -36.6919],
                [174.4352, -36.692],
                [174.4354, -36.6924],
                [174.4354, -36.6925],
                [174.4357, -36.6934],
                [174.4357, -36.6936],
                [174.4357, -36.6938],
                [174.4358, -36.6941],
                [174.4359, -36.6945],
                [174.4359, -36.6945],
                [174.436, -36.6947],
                [174.436, -36.6948],
                [174.4361, -36.6955],
                [174.4361, -36.6956],
                [174.4363, -36.6964],
                [174.4363, -36.6965],
                [174.4363, -36.6966],
                [174.4363, -36.6969],
                [174.4363, -36.6969],
                [174.4363, -36.6974],
                [174.4367, -36.6978],
                [174.4373, -36.6979],
                [174.4374, -36.6979],
                [174.4375, -36.6979],
                [174.4391, -36.6974],
                [174.4394, -36.6973],
                [174.4403, -36.6968],
                [174.4404, -36.6967],
                [174.4408, -36.6965],
                [174.4411, -36.6965],
                [174.4411, -36.6965],
                [174.4415, -36.6964],
                [174.4428, -36.6959],
                [174.443, -36.6959],
                [174.443, -36.6959],
                [174.443, -36.6959],
                [174.443, -36.6961],
                [174.4425, -36.6966],
                [174.4422, -36.6972],
                [174.4424, -36.6978],
                [174.4429, -36.698],
                [174.4429, -36.698],
                [174.4432, -36.6982],
                [174.4433, -36.6983],
                [174.4432, -36.6985],
                [174.4432, -36.6986],
                [174.443, -36.6988],
                [174.4431, -36.6988],
                [174.4428, -36.6995],
                [174.4432, -36.7002],
                [174.4432, -36.7002],
                [174.4433, -36.7003],
                [174.4432, -36.7004],
                [174.4431, -36.7005],
                [174.4408, -36.7009],
                [174.4402, -36.7012],
                [174.44, -36.7019],
                [174.4401, -36.7024],
                [174.4401, -36.7024],
                [174.4401, -36.7027],
                [174.4401, -36.7029],
                [174.44, -36.7031],
                [174.44, -36.7031],
                [174.4398, -36.7036],
                [174.4398, -36.7037],
                [174.4396, -36.7039],
                [174.4396, -36.704],
                [174.4395, -36.7041],
                [174.4394, -36.7045],
                [174.4394, -36.7049],
                [174.4394, -36.7051],
                [174.4395, -36.7056],
                [174.4395, -36.7057],
                [174.4395, -36.7059],
                [174.4399, -36.7064],
                [174.44, -36.7065],
                [174.4404, -36.7066],
                [174.4406, -36.7067],
                [174.4408, -36.7067],
                [174.4408, -36.7067],
                [174.4413, -36.7066],
                [174.4416, -36.7064],
                [174.4418, -36.7062],
                [174.442, -36.7061],
                [174.4421, -36.706],
                [174.4426, -36.7058],
                [174.4426, -36.7058],
                [174.4429, -36.7057],
                [174.4429, -36.7057],
                [174.4431, -36.7056],
                [174.4436, -36.7057],
                [174.4436, -36.7057],
                [174.4439, -36.7058],
                [174.4443, -36.7057],
                [174.4445, -36.7057],
                [174.4448, -36.7058],
                [174.4448, -36.7058],
                [174.445, -36.7059],
                [174.4451, -36.706],
                [174.4452, -36.7061],
                [174.4452, -36.7062],
                [174.4451, -36.7064],
                [174.4452, -36.7068],
                [174.4452, -36.7069],
                [174.4455, -36.7074],
                [174.4464, -36.7081],
                [174.4464, -36.7081],
                [174.4468, -36.7085],
                [174.4469, -36.7086],
                [174.4473, -36.7091],
                [174.4474, -36.7092],
                [174.4473, -36.7098],
                [174.4468, -36.7104],
                [174.4468, -36.7105],
                [174.4464, -36.7111],
                [174.4463, -36.7116],
                [174.4465, -36.7121],
                [174.4468, -36.7125],
                [174.4469, -36.7126],
                [174.4473, -36.7129],
                [174.4475, -36.7131],
                [174.4476, -36.7133],
                [174.4475, -36.7134],
                [174.4472, -36.7136],
                [174.4472, -36.7136],
                [174.4465, -36.7143],
                [174.4464, -36.7146],
                [174.4464, -36.7146],
                [174.446, -36.7148],
                [174.446, -36.7147],
                [174.4459, -36.7146],
                [174.4451, -36.7143],
                [174.4444, -36.7146],
                [174.4443, -36.7148],
                [174.4441, -36.7154],
                [174.4444, -36.716],
                [174.4445, -36.7161],
                [174.4449, -36.7166],
                [174.445, -36.7167],
                [174.445, -36.7168],
                [174.445, -36.7172],
                [174.445, -36.7172],
                [174.4449, -36.7174],
                [174.4448, -36.7179],
                [174.445, -36.7184],
                [174.445, -36.7184],
                [174.4458, -36.7188],
                [174.446, -36.7188],
                [174.4462, -36.7188],
                [174.4464, -36.7189],
                [174.4466, -36.7189],
                [174.4468, -36.7189],
                [174.447, -36.7189],
                [174.4472, -36.719],
                [174.4474, -36.719],
                [174.4479, -36.719],
                [174.4481, -36.719],
                [174.4484, -36.7191],
                [174.4485, -36.7191],
                [174.4486, -36.7192],
                [174.4487, -36.7193],
                [174.4491, -36.7198],
                [174.4492, -36.7199],
                [174.45, -36.7205],
                [174.45, -36.7205],
                [174.45, -36.7206],
                [174.4502, -36.721],
                [174.4504, -36.7213],
                [174.4506, -36.7215],
                [174.4508, -36.7217],
                [174.4511, -36.7219],
                [174.4511, -36.7219],
                [174.4515, -36.7223],
                [174.4517, -36.7226],
                [174.4516, -36.7226],
                [174.4517, -36.7232],
                [174.4518, -36.7236],
                [174.4517, -36.7238],
                [174.4516, -36.7244],
                [174.4516, -36.7245],
                [174.4514, -36.725],
                [174.4513, -36.7251],
                [174.4513, -36.7257],
                [174.4516, -36.7261],
                [174.4515, -36.7261],
                [174.4518, -36.7264],
                [174.4518, -36.7266],
                [174.4517, -36.7267],
                [174.4516, -36.7268],
                [174.4514, -36.727],
                [174.4512, -36.7272],
                [174.4511, -36.7273],
                [174.4511, -36.7274],
                [174.451, -36.7276],
                [174.4505, -36.728],
                [174.4505, -36.728],
                [174.4501, -36.7284],
                [174.4501, -36.7284],
                [174.45, -36.7285],
                [174.4496, -36.7292],
                [174.4496, -36.7292],
                [174.4491, -36.7298],
                [174.449, -36.7305],
                [174.4494, -36.7311],
                [174.4522, -36.7326],
                [174.4523, -36.7328],
                [174.4524, -36.733],
                [174.4522, -36.7338],
                [174.4522, -36.7339],
                [174.452, -36.7342],
                [174.452, -36.7343],
                [174.4518, -36.7345],
                [174.4516, -36.7351],
                [174.4518, -36.7356],
                [174.4519, -36.7358],
                [174.4521, -36.7371],
                [174.452, -36.7373],
                [174.4518, -36.7374],
                [174.4479, -36.737],
                [174.4479, -36.737],
                [174.4474, -36.7369],
                [174.447, -36.737],
                [174.447, -36.737],
                [174.4468, -36.737],
                [174.4466, -36.737],
                [174.4466, -36.737],
                [174.4459, -36.7369],
                [174.4445, -36.7372],
                [174.444, -36.7376],
                [174.4439, -36.7382],
                [174.4439, -36.7384],
                [174.4439, -36.7386],
                [174.4438, -36.7388],
                [174.4439, -36.7392],
                [174.4439, -36.7395],
                [174.4439, -36.7397],
                [174.4438, -36.7398],
                [174.4438, -36.7398],
                [174.443, -36.7406],
                [174.4428, -36.7414],
                [174.4429, -36.7417],
                [174.443, -36.7425],
                [174.4428, -36.7429],
                [174.4426, -36.743],
                [174.4424, -36.7431],
                [174.442, -36.7433],
                [174.4417, -36.7434],
                [174.4404, -36.7442],
                [174.4402, -36.7444],
                [174.4398, -36.7449],
                [174.4396, -36.7455],
                [174.44, -36.7461],
                [174.4407, -36.7463],
                [174.4407, -36.7463],
                [174.4407, -36.7463],
                [174.4418, -36.7461],
                [174.4418, -36.7461],
                [174.443, -36.7459],
                [174.4432, -36.7459],
                [174.4439, -36.7462],
                [174.4439, -36.7462],
                [174.4445, -36.7464],
                [174.4447, -36.7466],
                [174.4446, -36.7471],
                [174.4445, -36.7473],
                [174.4444, -36.7476],
                [174.444, -36.7484],
                [174.444, -36.7485],
                [174.4438, -36.7487],
                [174.4437, -36.7488],
                [174.4436, -36.7489],
                [174.4434, -36.7493],
                [174.4433, -36.7496],
                [174.4433, -36.7496],
                [174.4433, -36.7505],
                [174.4434, -36.7505],
                [174.4434, -36.7506],
                [174.4433, -36.7509],
                [174.4434, -36.7517],
                [174.4441, -36.752],
                [174.4447, -36.7521],
                [174.445, -36.7523],
                [174.445, -36.7524],
                [174.4449, -36.7526],
                [174.4322, -36.759],
                [174.4317, -36.7596],
                [174.4318, -36.7601],
                [174.4318, -36.7601],
                [174.4319, -36.7605],
                [174.4319, -36.7608],
                [174.4322, -36.7613],
                [174.4327, -36.7616],
                [174.4328, -36.7616],
                [174.433, -36.7616],
                [174.4332, -36.7616],
                [174.4334, -36.7616],
                [174.4342, -36.7614],
                [174.4344, -36.7614],
                [174.4351, -36.7616],
                [174.4351, -36.7616],
                [174.4356, -36.7617],
                [174.4357, -36.7617],
                [174.4364, -36.762],
                [174.4367, -36.7621],
                [174.4368, -36.7623],
                [174.4369, -36.7626],
                [174.4369, -36.7642],
                [174.4369, -36.7644],
                [174.4371, -36.7652],
                [174.4371, -36.7652],
                [174.4372, -36.7659],
                [174.4377, -36.7665],
                [174.4379, -36.7666],
                [174.438, -36.7667],
                [174.4381, -36.7669],
                [174.438, -36.7671],
                [174.438, -36.7671],
                [174.4379, -36.7673],
                [174.4377, -36.768],
                [174.438, -36.7683],
                [174.438, -36.7685],
                [174.4379, -36.7687],
                [174.4373, -36.769],
                [174.437, -36.7694],
                [174.4369, -36.7696],
                [174.4368, -36.7697],
                [174.4365, -36.7702],
                [174.4363, -36.771],
                [174.4365, -36.7716],
                [174.4365, -36.7718],
                [174.4364, -36.7719],
                [174.434, -36.7727],
                [174.4338, -36.7727],
                [174.4335, -36.773],
                [174.4334, -36.773],
                [174.4326, -36.7732],
                [174.4323, -36.7734],
                [174.4302, -36.7752],
                [174.4301, -36.7753],
                [174.4288, -36.7767],
                [174.4286, -36.7773],
                [174.4286, -36.7782],
                [174.4285, -36.7785],
                [174.4285, -36.7787],
                [174.4285, -36.7794],
                [174.4283, -36.7799],
                [174.4284, -36.78],
                [174.4283, -36.7804],
                [174.4287, -36.7811],
                [174.4293, -36.7814],
                [174.4294, -36.7815],
                [174.4297, -36.7817],
                [174.4302, -36.7819],
                [174.4327, -36.7823],
                [174.4334, -36.7821],
                [174.4337, -36.7818],
                [174.4338, -36.7818],
                [174.4364, -36.7807],
                [174.4366, -36.7807],
                [174.4382, -36.7808],
                [174.4384, -36.7808],
                [174.4384, -36.7808],
                [174.4388, -36.7809],
                [174.4391, -36.7808],
                [174.4394, -36.7807],
                [174.4403, -36.7803],
                [174.4404, -36.7803],
                [174.441, -36.7801],
                [174.4414, -36.7802],
                [174.4417, -36.7803],
                [174.4418, -36.7804],
                [174.4419, -36.7804],
                [174.4424, -36.7806],
                [174.4427, -36.7806],
                [174.4431, -36.7807],
                [174.4434, -36.781],
                [174.4435, -36.7812],
                [174.4434, -36.7814],
                [174.443, -36.7818],
                [174.4427, -36.7826],
                [174.4427, -36.7829],
                [174.4425, -36.7831],
                [174.4415, -36.7834],
                [174.441, -36.7837],
                [174.4408, -36.784],
                [174.4407, -36.7841],
                [174.4406, -36.7842],
                [174.4405, -36.7843],
                [174.4392, -36.786],
                [174.439, -36.7866],
                [174.4393, -36.7873],
                [174.4396, -36.7875],
                [174.4397, -36.7877],
                [174.4396, -36.7879],
                [174.4396, -36.788],
                [174.4395, -36.788],
                [174.4391, -36.7883],
                [174.439, -36.7884],
                [174.4386, -36.7888],
                [174.4384, -36.7892],
                [174.4378, -36.7897],
                [174.4377, -36.7898],
                [174.4367, -36.7902],
                [174.4365, -36.7902],
                [174.4363, -36.7902],
                [174.4361, -36.7901],
                [174.4355, -36.7901],
                [174.4351, -36.7903],
                [174.435, -36.7903],
                [174.4335, -36.7905],
                [174.4332, -36.7906],
                [174.432, -36.7912],
                [174.4319, -36.7912],
                [174.4313, -36.7916],
                [174.4312, -36.7916],
                [174.4298, -36.7923],
                [174.4297, -36.7923],
                [174.4295, -36.7924],
                [174.4294, -36.7924],
                [174.4287, -36.7925],
                [174.4283, -36.7928],
                [174.4271, -36.7939],
                [174.4269, -36.794],
                [174.4265, -36.7942],
                [174.4263, -36.7942],
                [174.425, -36.7942],
                [174.4249, -36.7942],
                [174.4246, -36.7941],
                [174.4242, -36.7942],
                [174.4234, -36.7945],
                [174.4233, -36.7945],
                [174.4225, -36.795],
                [174.4224, -36.795],
                [174.4223, -36.7951],
                [174.4219, -36.7958],
                [174.4218, -36.7976],
                [174.4218, -36.7977],
                [174.4216, -36.7983],
                [174.4219, -36.7991],
                [174.4219, -36.7992],
                [174.422, -36.7993],
                [174.4221, -36.7994],
                [174.4226, -36.7997],
                [174.4226, -36.7997],
                [174.4227, -36.7997],
                [174.4231, -36.7998],
                [174.4235, -36.7997],
                [174.4237, -36.7997],
                [174.4241, -36.7997],
                [174.4243, -36.7997],
                [174.4249, -36.7998],
                [174.4258, -36.7994],
                [174.4259, -36.7994],
                [174.4267, -36.7992],
                [174.4268, -36.7992],
                [174.4274, -36.799],
                [174.4275, -36.799],
                [174.4279, -36.7988],
                [174.4281, -36.7988],
                [174.4288, -36.7987],
                [174.4293, -36.7986],
                [174.4299, -36.7981],
                [174.4302, -36.798],
                [174.4303, -36.7978],
                [174.4305, -36.7976],
                [174.4308, -36.7975],
                [174.4312, -36.7975],
                [174.4312, -36.7975],
                [174.4324, -36.7977],
                [174.4326, -36.7978],
                [174.4331, -36.7981],
                [174.4332, -36.7981],
                [174.4336, -36.7984],
                [174.4337, -36.7984],
                [174.4348, -36.7993],
                [174.4349, -36.7996],
                [174.4349, -36.7996],
                [174.4349, -36.8],
                [174.4349, -36.8001],
                [174.4344, -36.8005],
                [174.4344, -36.8005],
                [174.4343, -36.8006],
                [174.4339, -36.8011],
                [174.4333, -36.8018],
                [174.4331, -36.8024],
                [174.4332, -36.8027],
                [174.4332, -36.8027],
                [174.4333, -36.8031],
                [174.4332, -36.8033],
                [174.4331, -36.8034],
                [174.4328, -36.8039],
                [174.4327, -36.8044],
                [174.4327, -36.8045],
                [174.4326, -36.8047],
                [174.4323, -36.8049],
                [174.4323, -36.8049],
                [174.4323, -36.8049],
                [174.4323, -36.8049],
                [174.4321, -36.805],
                [174.4317, -36.805],
                [174.4317, -36.805],
                [174.4312, -36.8051],
                [174.4309, -36.8053],
                [174.4306, -36.8057],
                [174.4305, -36.8059],
                [174.4304, -36.806],
                [174.4296, -36.8071],
                [174.4293, -36.8072],
                [174.4279, -36.8077],
                [174.4277, -36.8077],
                [174.4268, -36.8074],
                [174.4264, -36.8074],
                [174.4208, -36.8085],
                [174.4206, -36.8085],
                [174.4187, -36.8081],
                [174.4174, -36.8078],
                [174.417, -36.8076],
                [174.417, -36.8075],
                [174.4166, -36.8072],
                [174.416, -36.8071],
                [174.4142, -36.8075],
                [174.4137, -36.8073],
                [174.4122, -36.8053],
                [174.4122, -36.8052],
                [174.4118, -36.8048],
                [174.4118, -36.8047],
                [174.4105, -36.8028],
                [174.4105, -36.8027],
                [174.4092, -36.8005],
                [174.4092, -36.8004],
                [174.4023, -36.7902],
                [174.4023, -36.7901],
                [174.4014, -36.7887],
                [174.4014, -36.7886],
                [174.4012, -36.788],
                [174.4011, -36.7877],
                [174.3974, -36.7822],
                [174.3974, -36.7821],
                [174.3962, -36.78],
                [174.3962, -36.7799],
                [174.3916, -36.7735],
                [174.3916, -36.7734],
                [174.3902, -36.771],
                [174.3902, -36.7709],
                [174.3869, -36.7665],
                [174.3869, -36.7664],
                [174.3865, -36.7658],
                [174.3856, -36.7642],
                [174.3856, -36.7641],
                [174.3811, -36.7576],
                [174.381, -36.7575],
                [174.3799, -36.7563],
                [174.3798, -36.7562],
                [174.3785, -36.7542],
                [174.3785, -36.7541],
                [174.3738, -36.7476],
                [174.3737, -36.7475],
                [174.3732, -36.7461],
                [174.373, -36.7458],
                [174.3711, -36.7434],
                [174.3711, -36.7433],
                [174.3707, -36.7425],
                [174.3706, -36.7424],
                [174.3695, -36.7411],
                [174.3695, -36.741],
                [174.3692, -36.7405],
                [174.3692, -36.7404],
                [174.3643, -36.7335],
                [174.3643, -36.7334],
                [174.3605, -36.7286],
                [174.3605, -36.7285],
                [174.3542, -36.719],
                [174.3541, -36.7189],
                [174.3525, -36.7172],
                [174.3525, -36.7171],
                [174.3516, -36.7159],
                [174.3516, -36.7158],
                [174.3501, -36.7133],
                [174.35, -36.7132],
                [174.3491, -36.7123],
                [174.3491, -36.7122],
                [174.3463, -36.7084],
                [174.3463, -36.7083],
                [174.3431, -36.7042],
                [174.3431, -36.7041],
                [174.3423, -36.7025],
                [174.3421, -36.7023],
                [174.3413, -36.7014],
                [174.3412, -36.7013],
                [174.3391, -36.6981],
                [174.3391, -36.698],
                [174.3323, -36.6892],
                [174.3323, -36.6891],
                [174.3302, -36.6857],
                [174.33, -36.6855],
                [174.329, -36.6845],
                [174.3289, -36.6844],
                [174.3281, -36.6834],
                [174.3281, -36.6833],
                [174.3275, -36.6818],
                [174.3274, -36.6817],
                [174.3266, -36.6806],
                [174.3265, -36.6805],
                [174.325, -36.6791],
                [174.3249, -36.679],
                [174.3243, -36.6778],
                [174.3242, -36.6777],
                [174.3227, -36.6759],
                [174.3227, -36.6758],
                [174.3219, -36.6744],
                [174.3217, -36.6742],
                [174.3213, -36.6738],
                [174.3212, -36.6737],
                [174.3192, -36.6711],
                [174.3191, -36.671],
                [174.3187, -36.6701],
                [174.3185, -36.6698],
                [174.3177, -36.669],
                [174.3176, -36.6689],
                [174.3172, -36.6681],
                [174.3171, -36.668],
                [174.3139, -36.664],
                [174.3139, -36.6639],
                [174.3119, -36.6609],
                [174.3117, -36.6607],
                [174.311, -36.6601],
                [174.3109, -36.66],
                [174.3103, -36.6591],
                [174.3101, -36.6589],
                [174.3098, -36.6587],
                [174.3096, -36.6584],
                [174.3092, -36.6574],
                [174.309, -36.6571],
                [174.3051, -36.6528],
                [174.305, -36.6527],
                [174.301, -36.6465],
                [174.3009, -36.6464],
                [174.3001, -36.6455],
                [174.3, -36.6454],
                [174.2997, -36.6446],
                [174.2995, -36.6445],
                [174.2974, -36.6417],
                [174.2972, -36.6415],
                [174.2963, -36.6407],
                [174.2962, -36.6406],
                [174.2918, -36.6346],
                [174.2917, -36.6345],
                [174.2906, -36.6334],
                [174.2905, -36.6333],
                [174.2883, -36.6303],
                [174.2881, -36.6301],
                [174.2877, -36.6297],
                [174.2876, -36.6296],
                [174.2864, -36.6277],
                [174.2864, -36.6276],
                [174.2828, -36.6232],
                [174.2828, -36.6231],
                [174.2819, -36.6218],
                [174.2817, -36.6216],
                [174.2814, -36.6213],
                [174.2813, -36.6212],
                [174.2809, -36.6207],
                [174.2807, -36.6205],
                [174.2802, -36.6201],
                [174.2801, -36.62],
                [174.2748, -36.6131],
                [174.2747, -36.613],
                [174.2742, -36.6125],
                [174.2741, -36.6124],
                [174.2739, -36.6122],
                [174.2739, -36.6121],
                [174.2738, -36.6118],
                [174.2736, -36.6116],
                [174.2735, -36.6114],
                [174.2733, -36.6112],
                [174.2724, -36.6104],
                [174.2723, -36.6103],
                [174.2721, -36.61],
                [174.272, -36.6099],
                [174.2718, -36.6097],
                [174.2717, -36.6096],
                [174.2715, -36.6093],
                [174.2714, -36.6091],
                [174.2713, -36.6088],
                [174.2708, -36.6082],
                [174.2705, -36.6081],
                [174.2703, -36.6079],
                [174.2703, -36.6079],
                [174.2697, -36.6072],
                [174.2697, -36.6071],
                [174.2694, -36.6068],
                [174.2693, -36.6067],
                [174.2689, -36.6064],
                [174.2688, -36.6064],
                [174.2685, -36.6061],
                [174.2683, -36.6059],
                [174.2682, -36.6056],
                [174.2681, -36.6052],
                [174.2679, -36.6048],
                [174.2679, -36.6048],
                [174.2674, -36.6045],
                [174.2672, -36.6044],
                [174.2669, -36.6043],
                [174.2668, -36.6042],
                [174.2668, -36.6042],
                [174.2666, -36.6038],
                [174.2666, -36.6038],
                [174.2661, -36.6033],
                [174.2661, -36.6033],
                [174.266, -36.6033],
                [174.266, -36.6033],
                [174.2657, -36.603],
                [174.2657, -36.6029],
                [174.2654, -36.6025],
                [174.2653, -36.6024],
                [174.2652, -36.6023],
                [174.2554, -36.5913],
                [174.2554, -36.5913],
                [174.2548, -36.5906],
                [174.2548, -36.5905],
                [174.2544, -36.5902],
                [174.2544, -36.5902],
                [174.2481, -36.583],
                [174.2444, -36.5787],
                [174.2444, -36.5786],
                [174.2419, -36.5761],
                [174.2419, -36.5761],
                [174.2389, -36.5727],
                [174.2389, -36.5726],
                [174.2377, -36.5709],
                [174.2376, -36.5708],
                [174.2318, -36.5645],
                [174.2317, -36.5644],
                [174.2309, -36.5637],
                [174.2308, -36.5636],
                [174.2279, -36.5605],
                [174.2279, -36.5605],
                [174.2272, -36.5597],
                [174.2272, -36.5596],
                [174.2268, -36.5593],
                [174.2258, -36.5583],
                [174.2258, -36.5583],
                [174.2245, -36.5568],
                [174.2245, -36.5568],
                [174.2233, -36.5555],
                [174.2233, -36.5554],
                [174.2214, -36.5531],
                [174.2214, -36.553],
                [174.2138, -36.5447],
                [174.2138, -36.5447],
                [174.2138, -36.5446],
                [174.2137, -36.5445],
                [174.2128, -36.5432],
                [174.2127, -36.5431],
                [174.2024, -36.5323],
                [174.2024, -36.5323],
                [174.2007, -36.5306],
                [174.2007, -36.5306],
                [174.1876, -36.5163],
                [174.1876, -36.5163],
                [174.1821, -36.5096],
                [174.1821, -36.5095],
                [174.1786, -36.506],
                [174.1786, -36.5059],
                [174.178, -36.5052],
                [174.1779, -36.5049],
                [174.1778, -36.5045],
                [174.1777, -36.504],
                [174.1773, -36.5036],
                [174.1771, -36.5034],
                [174.1761, -36.5028],
                [174.176, -36.5027],
                [174.1735, -36.5001],
                [174.1735, -36.5001],
                [174.1697, -36.4954],
                [174.1697, -36.4953],
                [174.1668, -36.4914],
                [174.1668, -36.4913],
                [174.1649, -36.4872],
                [174.1649, -36.4871],
                [174.1633, -36.4818],
                [174.1633, -36.4817],
                [174.1627, -36.4731],
                [174.1627, -36.4731],
                [174.1622, -36.4682],
                [174.1622, -36.4682],
                [174.1622, -36.4678],
                [174.1622, -36.4676],
                [174.1623, -36.4675],
                [174.1623, -36.4671],
                [174.1622, -36.4668],
                [174.1622, -36.4667],
                [174.1622, -36.4656],
                [174.1622, -36.4656],
                [174.1626, -36.4628],
                [174.1626, -36.4627],
                [174.1633, -36.4604],
                [174.1633, -36.4603],
                [174.1639, -36.4588],
                [174.1639, -36.4587],
                [174.1655, -36.4557],
                [174.1656, -36.4556],
                [174.1661, -36.4551],
                [174.1662, -36.455],
                [174.1664, -36.4547],
                [174.1665, -36.4546],
                [174.1667, -36.4544],
                [174.1668, -36.4543],
                [174.1674, -36.4533],
                [174.1674, -36.4532],
                [174.1678, -36.4528],
                [174.1678, -36.4527],
                [174.1696, -36.451],
                [174.1697, -36.4509],
                [174.1702, -36.4505],
                [174.1703, -36.4505],
                [174.1713, -36.4497],
                [174.1714, -36.4497],
                [174.1723, -36.4491],
                [174.1724, -36.4491],
                [174.1731, -36.4486],
                [174.1739, -36.4481],
                [174.174, -36.4481],
                [174.175, -36.4475],
                [174.1751, -36.4475],
                [174.1756, -36.4472],
                [174.1757, -36.4472],
                [174.1759, -36.447],
                [174.176, -36.447],
                [174.1772, -36.4464],
                [174.1773, -36.4464],
                [174.183, -36.4443],
                [174.1831, -36.4443],
                [174.1851, -36.4433],
                [174.1852, -36.4433],
                [174.1876, -36.4419],
                [174.1878, -36.4417],
                [174.1884, -36.4411],
                [174.1886, -36.4411],
                [174.1888, -36.4411],
                [174.1907, -36.4427],
                [174.1908, -36.4429],
                [174.1908, -36.4429],
                [174.191, -36.4439],
                [174.1914, -36.4443],
                [174.1915, -36.4444],
                [174.1916, -36.4447],
                [174.1917, -36.4449],
                [174.1917, -36.4453],
                [174.1917, -36.4455],
                [174.1915, -36.4459],
                [174.1915, -36.446],
                [174.1914, -36.4463],
                [174.1914, -36.4465],
                [174.1914, -36.447],
                [174.1915, -36.4473],
                [174.1916, -36.4476],
                [174.1916, -36.4477],
                [174.1918, -36.448],
                [174.192, -36.4482],
                [174.192, -36.4483],
                [174.1921, -36.4488],
                [174.1919, -36.4497],
                [174.1919, -36.4498],
                [174.1911, -36.4515],
                [174.191, -36.4519],
                [174.191, -36.4529],
                [174.1908, -36.4533],
                [174.1901, -36.4539],
                [174.1897, -36.454],
                [174.1897, -36.454],
              ],
            ],
          },
        },
      ],
    },
    updatedAt: "2023-09-15T09:32:24.632487+12:00",
  },
];

const EMPTY_STORM_ZONES = {
  stormZones: [],
};

export default {
  STORM_ZONES,
  EMPTY_STORM_ZONES,
};
