/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { or, explicitNull } from "airbnb-prop-types";
import { Flex, Box, Text } from "rebass";
import { logError } from "../../common/logError";
import { Button } from "../Button";

export class ErrorBoundary extends React.Component {
  state = { error: null, retries: 0, isRetrying: false };

  static getDerivedStateFromError(err) {
    return { error: err };
  }

  componentDidCatch(error, info) {
    logError(error, info);
  }

  handleRetry = () => {
    const { retryLimit } = this.props;

    this.setState(state => ({
      error: null,
      retries: retryLimit != null ? state.retries + 1 : state.retries,
      isRetrying: true,
    }));
  };

  // Do a pass-through only
  renderRetryButton = () => {
    const { allowRetry, retryLimit } = this.props;
    const { retries, isRetrying } = this.state;

    if (!allowRetry || (retryLimit != null && retries >= retryLimit)) {
      return null;
    }

    return (
      <Box pt={20}>
        <Button
          variant="dark"
          onClick={this.handleRetry}
          isLoading={isRetrying}
          disabled={isRetrying}
        >
          Retry
        </Button>
      </Box>
    );
  };

  render() {
    const { children, allowRetry, retryLimit, ...props } = this.props;
    const { error } = this.state;

    return error ? (
      <Flex
        {...props}
        flexDirection="column"
        flex="1 0 auto"
        justifyContent="center"
        alignItems="center"
        py={[20, null, 30]}
        px={25}
        width={1}
      >
        <Box width={[1, null, 3 / 4]}>
          {/* Display error details */}
          <Text as="h1" mt={0} textAlign="center">
            Oops, something has gone wrong
          </Text>
          <Text as="p" fontSize={3} textAlign="center">
            {error.message}
          </Text>
        </Box>

        {/* Conditionally render the retry button */}
        {this.renderRetryButton()}
      </Flex>
    ) : (
      children
    );
  }
}

ErrorBoundary.propTypes = {
  allowRetry: PropTypes.bool,
  retryLimit: or([explicitNull(), PropTypes.number]),
};
ErrorBoundary.defaultProps = {
  allowRetry: true,
  retryLimit: null,
};

export default ErrorBoundary;
