/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "rebass";
import PropTypes from "prop-types";
import { EventInfo } from "../EventInfo";
import { PLANNED } from "../EventType";

const REPORTED_UNKNOWN_REASON = "uncertain at this time";
const ENROUTE_UNKNOWN_REASON = "uncertain at this time";
const INVESTIGATING_UNKNOWN_REASON = "investigation underway";
const WORKING_UNKNOWN_REASON = "investigation underway";

const OutageReason = ({
  subscription,
  outage: { eventType, outageId, outageReasonDescription, workStatus },
}) => {
  let outageLabel = "Outage Reason";
  if (eventType === PLANNED) {
    outageLabel = "Reference";
  }

  let outageReason = outageReasonDescription;

  if (!outageReasonDescription && !workStatus) {
    outageReason = "uncertain at this time";
  } else if (!outageReasonDescription) {
    if (workStatus.type === "REPORTED") {
      outageReason = REPORTED_UNKNOWN_REASON;
    } else if (workStatus.type === "ENROUTE") {
      outageReason = ENROUTE_UNKNOWN_REASON;
    } else if (workStatus.type === "INVESTIGATING") {
      outageReason = INVESTIGATING_UNKNOWN_REASON;
    } else if (workStatus.type === "WORKING") {
      outageReason = WORKING_UNKNOWN_REASON;
    } else if (workStatus.type === "RESOLVED") {
      outageReason = "";
    } else {
      outageReason = "uncertain at this time";
    }
  }

  return (
    <Box py={[null, null, 10]} pr={[20, null, 25]} width={1} mt={3}>
      {// eslint-disable-next-line no-nested-ternary
      outageId && eventType !== PLANNED ? (
        outageReason && (
          <EventInfo label={outageLabel} mb={2} fontWeight="bold">
            <span data-testid="OutageReason__Content">{outageReason}</span>
          </EventInfo>
        )
      ) : !outageId && eventType !== PLANNED ? (
        outageReason && (
          <EventInfo label={outageLabel} mb={2} fontWeight="bold">
            <span data-testid="OutageReason__Content">{outageReason}</span>
          </EventInfo>
        )
      ) : subscription && subscription.ticketId ? (
        <EventInfo label="Report ID" fontWeight="bold">
          {subscription.ticketId}
        </EventInfo>
      ) : (
        <EventInfo label={outageLabel} fontWeight="bold">
          {outageId}
        </EventInfo>
      )}
    </Box>
  );
};

OutageReason.propTypes = {
  subscription: PropTypes.shape({
    ticketId: PropTypes.string,
  }).isRequired,
  outage: PropTypes.shape({
    etrStart: PropTypes.string,
    etrEnd: PropTypes.string,
    eventType: PropTypes.string,
    outageId: PropTypes.string,
    workStatus: PropTypes.shape({
      label: PropTypes.oneOf([
        "Outage reported",
        "On our way",
        "Investigating",
        "Resolving",
        "Outage resolved",
      ]),
      type: PropTypes.oneOf([
        "REPORTED",
        "ENROUTE",
        "INVESTIGATING",
        "WORKING",
        "RESOLVED",
      ]),
      active: PropTypes.bool,
      complete: PropTypes.bool,
    }),
  }).isRequired,
};

export default OutageReason;
