import { handleActions } from "redux-actions";
import { closeOverlay, openOverlay } from "./overlays.actions";

export const reducer = handleActions(
  {
    [openOverlay]: (state, action) => ({
      ...state,
      [action.meta.overlayId]: true,
    }),
    [closeOverlay]: (state, action) => ({
      ...state,
      [action.meta.overlayId]: false,
    }),
  },
  {},
);

export default reducer;
