import {
  UNPLANNED,
  PLANNED,
  UNKNOWN,
  OUTSIDE_NETWORK,
  REPORTED,
} from "../../../components/OutageStatusType";

export const getOutageStatusText = eventType => {
  switch (eventType) {
    case REPORTED: {
      return "An outage has been reported for this address";
    }

    case OUTSIDE_NETWORK:
      return "Address not on the Vector network";

    case UNKNOWN:
      return "We're not aware of an outage here";

    case UNPLANNED:
      return "Outage in your area";

    case PLANNED:
      return "Planned work in your area";

    default:
      return "";
  }
};
