/*
  Options for Google Map
  shapeStyles controls the color/opacity for outage areas
  defaultMapCenter is where the map will be centered before location services kick in
  defaultMapOptions are the default options the map is instantiated with
  defaultZoom sets the zoom level the map will start at
*/
export const shapeStyles = {
  default: {
    clickable: false,
    strokeWeight: 0,
  },
  planned: {
    bg: "#f7be00",
    opacity: 0.5,
    borderColor: "#ffa400",
    borderOpacity: 1,
    borderStyle: "solid",
    zIndex: 1,
  },
  unplanned: {
    bg: "#ff6c0e",
    opacity: 0.5,
    borderColor: "#ff6c0e",
    borderOpacity: 1,
    borderStyle: "dashed",
  },
  non_vector: {
    bg: "#BFC1CF",
    opacity: 0.7,
    borderColor: "#000840",
    borderOpacity: 1,
  },
  stormZone: {
    bg: "#E64B38",
    opacity: 0.5,
    hooverOpacity: 0.7,
    borderColor: "#E64B38",
    borderOpacity: 1,
    borderStyle: "solid",
  },
};

export const defaultMapCenter = {
  lat: -36.8443949, // Britomart
  lng: 174.7675977,
};

export const defaultMapZoom = {
  locationTurnedOn: 16,
  locationTurnedOff: 9,
};

export const defaultMapOptions = {
  minZoom: 9,
  maxZoom: 16,
  gestureHandling: "greedy",
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  styles: [
    {
      featureType: "administrative.locality",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "landscape",
      elementType: "geometry.fill",
    },
    {
      featureType: "landscape",
      elementType: "geometry.stroke",
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
    },
  ],
};
