import isNil from "lodash/isNil";
import isNaN from "lodash/isNaN";
import { call, put, all, takeLatest, throttle } from "redux-saga/effects";
import { LocationService } from "../../../services/LocationService";
import {
  GET_DEVICE_COORDS_RECEIVE,
  GET_CLICK_COORDS_RECEIVE,
} from "../location";
import {
  successAddressByCoords,
  failureAddressByCoords,
  successAddressByText,
  failureAddressByText,
  ADDRESS_BY_TEXT_REQUEST,
  selectAddress,
  requestAddressCoords,
  requestAddressCoordsSuccess,
  requestAddressCoordsFailure,
} from ".";

export const GPS_ERROR_MESSAGE = `We could not determine your location.\nPlease search for an address by typing in the field below`;

function isIncompatibleLatLongValue(val) {
  return isNil(val) || isNaN(val);
}

export function* getAddressByCoords(action) {
  try {
    const { lat, long } = action.payload;

    // CO-467: Some devices seem to have a broken implementation of the browser's geolocation API spec
    //         this causes undefined values to be coming back from the "success" handler which cause API issuses
    if (isIncompatibleLatLongValue(lat) || isIncompatibleLatLongValue(long)) {
      throw new Error(GPS_ERROR_MESSAGE);
    }

    const addresses = yield call(LocationService.getAddressByCoords, lat, long);

    // TODO: Refactor to perhaps present a UI picker to choose from multiple results?
    if (addresses && addresses.allIds && addresses.allIds.length > 0) {
      yield put(successAddressByCoords(addresses));
      yield put(requestAddressCoords(addresses.allIds[0]));
    } else {
      throw new Error(
        "We were unable to find an address here. Please try again.",
      );
    }
  } catch (err) {
    yield put(failureAddressByCoords(err));
  }
}

export function* getAddressFromText(action) {
  try {
    const addresses = yield call(
      LocationService.getAddressByText,
      action.payload,
    );

    if (addresses) {
      yield put(successAddressByText(addresses));

      if (addresses.allIds.length === 0) {
        const err = new Error(
          "Unfortunately, we’re unable to match your address with our recorded addresses. This could be because your address is on a private network. Please contact <a href='tel:+640508832867'>0508 832 867</a>.",
        );
        yield put(failureAddressByCoords(err));
      }
    } else {
      throw new Error(`Some location error here`);
    }
  } catch (err) {
    yield put(failureAddressByText(err));
  }
}

export function* getSelectedAddressCoordinates(action) {
  // eslint-disable-next-line prefer-destructuring
  const location = window.location;

  const { id } = action.payload;
  try {
    const icpLocation = yield call(LocationService.getLocation, id);

    if (
      icpLocation.latitude &&
      icpLocation.longitude &&
      icpLocation.geocodeStatus === "No Coordinates"
    ) {
      icpLocation.geocodeStatus = "Ok";
    }

    if (icpLocation.geocodeStatus !== "No Coordinates") {
      yield put(
        requestAddressCoordsSuccess(
          { longitude: icpLocation.longitude, latitude: icpLocation.latitude },
          id,
        ),
      );

      if (
        icpLocation.geocodeStatus === "Approximate" ||
        icpLocation.geocodeStatus === "Transformer Approximate"
      ) {
        if (location.href.endsWith("/map")) {
          const err = new Error(
            `The location of the pin is approximate. Clicking view details provides information for the address above.`,
          );
          yield put(failureAddressByCoords(err));
        }
      }
    } else if (location.href.endsWith("/map")) {
      const err = new Error(
        "We were unable to find this address on the map. Please search for your address using the 'check address' tab.",
      );
      yield put(failureAddressByCoords(err));
    }
  } catch (err) {
    yield put(requestAddressCoordsFailure(err));
  }
}

export function* watchForAddressQueries() {
  yield all([
    // Auto fills the field when the GPS button was used successfully
    takeLatest(GET_DEVICE_COORDS_RECEIVE, getAddressByCoords),
    takeLatest(GET_CLICK_COORDS_RECEIVE, getAddressByCoords),
    // Picks up on change events of the search field
    throttle(250, ADDRESS_BY_TEXT_REQUEST, getAddressFromText),
    takeLatest(selectAddress, getSelectedAddressCoordinates),
    takeLatest(requestAddressCoords, getSelectedAddressCoordinates),
  ]);
}
