/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import {
  DATE_FORMAT_FULL,
  getFormattedDateTime,
} from "../../common/date-formatter";

const isNullOrUndefined = input =>
  typeof input === "undefined" || input === null;

const DateFormat = ({
  time,
  format,
  prioritiseRelativeDay,
  relativeDayWithTime,
}) => {
  if (isNullOrUndefined(time)) {
    return null;
  }

  return getFormattedDateTime(
    time,
    format,
    prioritiseRelativeDay,
    relativeDayWithTime,
  );
};

DateFormat.defaultProps = {
  time: null,
  format: DATE_FORMAT_FULL,
  prioritiseRelativeDay: false,
  relativeDayWithTime: true,
};
DateFormat.propTypes = {
  time: PropTypes.string,
  format: PropTypes.string,
  prioritiseRelativeDay: PropTypes.bool,
  relativeDayWithTime: PropTypes.bool,
};

export default DateFormat;
