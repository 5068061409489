import { createAction } from "redux-actions";

export const REGISTER_REQUEST = "app/register/REQUEST";
export const REGISTER_SUCCESS = "app/register/SUCCESS";
export const REGISTER_FAILURE = "app/register/FAILURE";
export const REGISTER_CLEAR_MESSAGES = "app/register/CLEAR_MESSAGES";

export const registerRequest = createAction(
  REGISTER_REQUEST,
  values => values,
  (_, meta) => meta,
);
export const registerSuccess = createAction(
  REGISTER_SUCCESS,
  payload => payload,
  (_, meta) => meta,
);
export const registerFailure = createAction(
  REGISTER_FAILURE,
  error => error,
  (_, meta) => meta,
);
export const registerClearMessages = createAction(REGISTER_CLEAR_MESSAGES);
