/* eslint-disable no-param-reassign */
import { persistReducer } from "redux-persist";
import produce from "immer";
import storageSession from "redux-persist/lib/storage/session";
import get from "lodash/get";
import has from "lodash/has";
import { combineActions, handleActions } from "redux-actions";
import { saveForm, cancelForm } from "./form.actions";
import { reportCreate, reportSuccess } from "../reports";

const formsPersistConfig = {
  key: "forms",
  storage: storageSession,
  whitelist: ["createReport"],
};

export const reducer = handleActions(
  {
    [combineActions(saveForm, reportCreate)]: (
      state,
      { payload, meta = {} },
    ) => {
      const { formName, subkey } = meta;

      // Bail if we're missing meta information
      if (!formName) {
        return state;
      }

      const formData = get(state, formName, {});

      // Handle optional subkeys
      // Resolve data structure to store, this changes based on destination
      if (subkey) {
        const keyData = get(formData, subkey, {});
        return {
          ...state,
          [formName]: {
            ...formData,
            [subkey]: {
              ...keyData,
              ...payload,
            },
          },
        };
      }

      // If we are dealing with no subkeys return the flatter structure
      return {
        ...state,
        [formName]: {
          ...formData,
          ...payload,
        },
      };
    },
    [combineActions(cancelForm, reportSuccess)]: (state, { meta = {} }) => {
      const { formName, subkey } = meta;

      return produce(state, draft => {
        const hasFormState = has(draft, formName);
        const hasSubkeyState = has(draft, `${formName}.${subkey}`);

        if (subkey && hasSubkeyState) {
          delete draft[formName][subkey];
        }

        if (formName && !subkey && hasFormState) {
          delete draft[formName];
        }
      });
    },
  },
  {},
);

export default persistReducer(formsPersistConfig, reducer);
