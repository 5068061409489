import React from "react";
import { Box, Flex } from "rebass";
import styled from "styled-components";
import { AppBar } from "../components/AppBar/index";
import { Page } from "../components/Page";
import { CheckAddressContainer } from "../containers/CheckAddressContainer";

const AppBarButton = styled(AppBar.Button)`
  text-transform: lowercase;
`;

const AppBarTitle = styled(AppBar.Title)`
  font-weight: normal;
  text-transform: lowercase;
`;

const CheckAddressView = () => (
  <>
    <Flex flexDirection="column" width={1}>
      <Box flex="0 1 0">
        <Page title="Check Address" />
        <AppBar>
          <AppBarButton href="/">Home</AppBarButton>
          <AppBarTitle>Check an address</AppBarTitle>
        </AppBar>
      </Box>
      <Flex
        flexDirection="column"
        alignItems="center"
        flex="1 1 auto"
        width={1}
        bg="light"
      >
        <CheckAddressContainer />
      </Flex>
    </Flex>
  </>
);
CheckAddressView.propTypes = {};

export default CheckAddressView;
