import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useGoogleMap } from "@react-google-maps/api";

export const NetworkBoundary = ({
  networkBoundaryShapes,
  zoomLevel,
  styles,
}) => {
  const map = useGoogleMap();
  const [networkBoundary] = useState(networkBoundaryShapes);

  const updateShapeStyle = feature => {
    const strokeWeight = 3;
    const iconStyle = {};

    if (zoomLevel < 15) {
      iconStyle.scale = 1;
      iconStyle.repeat = "8px";
    } else if (zoomLevel > 15) {
      iconStyle.scale = 3;
      iconStyle.repeat = "20px";
    } else {
      iconStyle.scale = 1.5;
      iconStyle.repeat = "12px";
    }

    const networkBoundaryStyles = {
      boundaryFill: {
        ...styles.default,
        fillColor: styles.non_vector.bg,
        fillOpacity: styles.non_vector.opacity,
      },
      boundaryBorder: {
        ...styles.default,
        zIndex: 1,
        icons: [
          {
            icon: {
              path: "M 0,-1 0,1",
              strokeOpacity: styles.non_vector.borderOpacity,
              scale: iconStyle.scale,
              strokeWeight,
              strokeColor: styles.non_vector.borderColor,
            },
            offset: "0",
            repeat: iconStyle.repeat,
          },
        ],
      },
    };

    return networkBoundaryStyles[feature.getProperty("type")];
  };

  const updateNetworkBoundaryStyling = () => {
    map.data.forEach(feature => {
      const updateStyle = ["boundaryBorder", "boundaryFill"].includes(
        feature.getProperty("type"),
      );

      if (updateStyle) {
        map.data.revertStyle(feature);
        map.data.overrideStyle(feature, updateShapeStyle(feature));
      }
    });
  };

  useEffect(() => {
    if (networkBoundary) {
      map.data.setStyle(feature => updateShapeStyle(feature));
      map.data.addGeoJson(networkBoundary);
    }
  }, [networkBoundary]);

  useEffect(() => {
    updateNetworkBoundaryStyling();
  }, [zoomLevel]);

  return null;
};

NetworkBoundary.propTypes = {
  styles: PropTypes.object.isRequired,
};
