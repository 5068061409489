/* eslint-disable no-shadow */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import isEmpty from "lodash/isEmpty";
import { Flex, Box, Text } from "rebass";
import { withRouter } from "react-router-dom";
import { IconUpcomingPlanned } from "../../assets/icons";
import { plannedOutagesRequest } from "../../redux/modules/planned-outages";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { PlannedOutageCardList } from "../../components/PlannedOutageCardList";
import { Loader } from "../../components/Loader";

export class AddressPlannedOutagesContainer extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    outages: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.string,
    icpNumber: PropTypes.string.isRequired,
    plannedOutagesRequest: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isFetching: false,
    error: null,
    outages: [],
  };

  componentDidMount() {
    const { icpNumber, plannedOutagesRequest } = this.props;
    plannedOutagesRequest(icpNumber);
  }

  render() {
    const { outages, error, isFetching, ...props } = this.props;

    // Loader
    if (isFetching) {
      return <Loader data-testid="AddressPlannedOutages" size={30} />;
    }

    if (isEmpty(outages)) {
      return null;
    }

    return (
      <Box {...props}>
        <Flex flex="0 1 0" alignItems="center">
          <Box>
            <IconUpcomingPlanned />
          </Box>
          <Box ml={2}>
            <Text as="p" fontSize={4} m={0}>
              upcoming planned outages
            </Text>
          </Box>
        </Flex>
        <PlannedOutageCardList outages={outages} error={error} px={0} />
      </Box>
    );
  }
}

/* istanbul ignore next */
const enhance = compose(
  withErrorBoundary(),
  withRouter,
  connect(
    state => ({
      isFetching: state.plannedOutages.isFetching,
      outages: state.plannedOutages.outages,
      error: state.plannedOutages.error,
    }),
    {
      plannedOutagesRequest,
    },
  ),
);

export default enhance(AddressPlannedOutagesContainer);
