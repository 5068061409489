import { takeLatest, put, call, all, fork } from "redux-saga/effects";
import { CustomersService } from "../../../services/CustomersService";
import { handleRedirect } from "../../sagas/helpers";
import {
  requestCustomer,
  requestCustomerSuccess,
  requestCustomerFailure,
  updateCustomer,
  updateCustomerSuccess,
  updateCustomerFailure,
} from "./customers.actions";

function* fetchCustomerFromApi() {
  try {
    const response = yield call(CustomersService.getCustomer);

    if (response.data) {
      yield put(requestCustomerSuccess(response.data));
    } else {
      throw new Error("Unexpected data received from GET /customers");
    }
  } catch (err) {
    yield put(requestCustomerFailure(err.message));
  }
}

function* updateCustomerDetails(action) {
  try {
    const { values, oldValues, currentPassword, passwordless } = action.payload;

    if (
      typeof values === "undefined" ||
      typeof oldValues === "undefined" ||
      typeof currentPassword === "undefined"
    ) {
      throw new Error("Missing required data");
    }

    const response = yield call(
      CustomersService.saveDetails,
      values,
      oldValues,
      currentPassword,
      passwordless,
    );

    if (response.status === 200) {
      // NOTE: If we need to track the user by email, we will do a GET /customers here to get the updated details
      yield put(updateCustomerSuccess(values));
      yield fork(handleRedirect, action);
    } else {
      throw new Error(response.message);
    }
  } catch (err) {
    if (err.message && err.message.includes("already have an account")) {
      // This specific error should redirect
      yield fork(handleRedirect, action);
    }
    yield put(updateCustomerFailure(err.message));
  }
}

export function* watchForCustomerActions() {
  yield all([
    takeLatest(requestCustomer, fetchCustomerFromApi),
    takeLatest(updateCustomer, updateCustomerDetails),
  ]);
}
