import PropTypes from "prop-types";
import { Box } from "rebass";
import styled, { css } from "styled-components/macro";
import { ifProp } from "styled-tools";

export const Label = styled(Box)`
  line-height: 1;
  position: absolute;
  display: inline-block;
  pointer-events: none;
  transition: transform 0.15s ease-in-out;
  transform-origin: top left;

  ${ifProp(
    "isFloating",
    css`
      // Shrunk label
      transform: translate3d(0, -6px, 0) scale(0.75);
    `,
    css`
      transform: translate3d(0, 20px, 0) scale(1);
    `,
  )};
`;

Label.propTypes = {
  ...Box.propTypes,
  "data-testid": PropTypes.string,
  isFloating: PropTypes.bool,
  as: PropTypes.string,
};
Label.defaultProps = {
  "data-testid": "Label",
  isFloating: false,
  as: "label",
  px: 2,
  fontSize: 2,
};
