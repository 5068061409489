import React from "react";
import PropTypes from "prop-types";
import { Flex, Text } from "rebass";
import styled from "styled-components/macro";

export const Link = styled(Flex)`
  overflow: hidden;
  position: absolute;
  top: -9999px;
  top: -101vh;
  left: -9999px;
  left: -101vw;

  &:focus {
    top: 0;
    left: 0;

    // Styling on the page when visible
    width: 100%;
    width: 100vw;
    text-transform: uppercase;
    text-decoration: none;
  }
`;

export const SkipToContent = ({ href, ...props }) => (
  <Link
    as="a"
    href={href}
    px={20}
    py={10}
    bg="primary"
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    <Text as="span" textAlign="center" fontSize={1} color="white">
      Skip to content
    </Text>
  </Link>
);

SkipToContent.propTypes = {
  href: PropTypes.string,
};
SkipToContent.defaultProps = {
  href: "#main",
};
