import { createSelector } from "reselect";
import get from "lodash/get";

export const getFormValues = createSelector(
  [
    (state, formName, subkey) => {
      const formState = get(state, `forms.${formName}`);
      if (formState && subkey) {
        return formState[subkey];
      }
      return formState;
    },
  ],
  formValues => formValues || {},
);
