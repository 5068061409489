/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components/macro";
import { Box, Flex } from "rebass";
import { prop } from "styled-tools";

const StatusIcon = styled.svg`
  height: 20px;
  width: 20px;
  margin-right: 8px;
  fill: ${prop("fill")};
`;
const StatusLabel = styled(Box)`
  font-weight: bold;
  color: ${prop("color")};
`;
const StatusChildText = styled(Box)``;

export const CANCELLED = "Cancelled";
export const POSTPONED = "Postponed";
export const SCHEDULED = "Planning Approved";

const DisplayStatus = ({
  label,
  labelColor,
  childText,
  iconColor,
  ...props
}) => (
  <div>
    <Flex flexDirection="row" {...props}>
      <Box>
        <StatusIcon fill={iconColor}>
          <circle cx="10" cy="10" r="10" />
        </StatusIcon>
      </Box>
      <Flex flexDirection="column" fontSize={1}>
        <StatusLabel color={labelColor}>{label}</StatusLabel>
        {childText && (
          <StatusChildText>
            {childText.split("\\n").map(item => (
              <span key={item}>
                {item}
                <br />
              </span>
            ))}
          </StatusChildText>
        )}
      </Flex>
    </Flex>
  </div>
);

DisplayStatus.propTypes = {
  label: PropTypes.string.isRequired,
  labelColor: PropTypes.string,
  iconColor: PropTypes.string.isRequired,
  childText: PropTypes.string,
};

DisplayStatus.defaultProps = {
  labelColor: null,
  childText: null,
};

export const PlannedOutageStatus = ({ status, theme, ...props }) => {
  switch (status) {
    case CANCELLED:
      return (
        <DisplayStatus
          label="cancelled"
          labelColor={theme.colors.red}
          iconColor={theme.colors.red}
          {...props}
        />
      );
    case POSTPONED:
      return (
        <DisplayStatus
          label="postponed"
          labelColor={theme.colors.orange}
          childText="moved to\nalternative date"
          iconColor={theme.colors.orange}
          {...props}
        />
      );
    case SCHEDULED:
    default:
      return (
        <DisplayStatus
          label="planned"
          iconColor={theme.colors.green}
          {...props}
        />
      );
  }
};
PlannedOutageStatus.propTypes = {
  status: PropTypes.string.isRequired,
};
export default withTheme(PlannedOutageStatus);
