import React from "react";
import PropTypes from "prop-types";
import { or, explicitNull } from "airbnb-prop-types";
import StormNotification from "./_/StormNotification";

const LSENotificationContainer = ({ outageId, lsEvent, onActionClick }) => {
  const { code, content } = lsEvent;

  if (code === 1) {
    return (
      <StormNotification
        type="WARNING"
        actionLabel={content.actionLabel}
        onClick={onActionClick}
        data-testid="storm-important-notification"
      >
        {!outageId && "We're not aware of an outage here. "}
        Important information available.
      </StormNotification>
    );
  }

  if (code === 2) {
    return (
      <StormNotification
        type="ALERT"
        actionLabel={content.actionLabel}
        onClick={onActionClick}
        data-testid="storm-warning"
      >
        WARNING IN PLACE
      </StormNotification>
    );
  }

  return null;
};

LSENotificationContainer.propTypes = {
  outageId: PropTypes.string.isRequired,
  lsEvent: PropTypes.object.isRequired,
  onActionClick: or([explicitNull(), PropTypes.func]).isRequired,
};

export default LSENotificationContainer;
