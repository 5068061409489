import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import { AuthenticationContainer } from "../AuthenticationContainer";
import { withErrorBoundary } from "../../components/ErrorBoundary";

import {
  isLoggedIn,
  clearLoginError,
  sendOneTimePasswordRequest,
  authEmail,
} from "../../redux/modules/login";
import {
  updateCustomer,
  getCustomerError,
  getCustomerUpdateSuccessMessage,
} from "../../redux/modules/customers";
import { trackEvent } from "../../common/analytics";

const GA_CATEGORY_CODE = "MyAccount_Code";

export class MyAccountAuthenticationContainer extends React.Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    customerValues: PropTypes.shape({
      values: PropTypes.object,
      oldValues: PropTypes.object,
    }),
    onSendOTP: PropTypes.func.isRequired,
    submitRequest: PropTypes.func.isRequired,
    clearError: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    successMessage: PropTypes.string,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    error: null,
    successMessage: null,
    isLoading: false,
    customerValues: {},
  };

  handleSubmit = password => {
    const { history, location, customerValues, submitRequest } = this.props;

    const redirectTo = get(location, "state.redirectTo", "/");

    submitRequest(
      {
        values: customerValues.values,
        oldValues: customerValues.oldValues,
        currentPassword: password,
        passwordless: true,
      },
      { history, redirectTo },
    );
  };

  handleResendCode = setFieldValue => {
    const { email, onSendOTP, customerValues } = this.props;

    trackEvent({
      category: GA_CATEGORY_CODE,
      action: "Click",
      label: "Resend Code",
    });
    onSendOTP({ email }, { customerValues });
    setFieldValue("password", "");
  };

  handleClearError = () => {
    const { clearError } = this.props;
    clearError();
  };

  render() {
    const {
      email,
      error,
      history,
      // eslint-disable-next-line no-shadow
      isLoggedIn,
      isLoading,
      successMessage,
    } = this.props;

    if (!isLoggedIn || !email) {
      history.push("/login");
    }

    return (
      <AuthenticationContainer
        successMessage={successMessage}
        error={error}
        email={email}
        isLoading={isLoading}
        gaCode={GA_CATEGORY_CODE}
        onSubmit={this.handleSubmit}
        clearError={this.handleClearError}
        onSendOTP={this.handleResendCode}
      />
    );
  }

  componentWillUnmount() {
    this.handleClearError();
  }
}

/* istanbul ignore next */
const enhance = compose(
  withErrorBoundary(),
  withRouter,
  connect(
    state => ({
      isLoggedIn: isLoggedIn(state),
      error: getCustomerError(state),
      isLoading: state.customers.isLoading,
      email: authEmail(state),
      customerValues: state.auth.customerValues,
      successMessage: getCustomerUpdateSuccessMessage(state),
    }),
    {
      submitRequest: updateCustomer,
      onSendOTP: sendOneTimePasswordRequest,
      clearError: clearLoginError,
    },
  ),
);

export default enhance(MyAccountAuthenticationContainer);
