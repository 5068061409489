/* eslint-disable react/prop-types */
import React, { useCallback } from "react";

export const withGATracking = WrappedComponent => ({
  gaTrack,
  onClick,
  ...props
}) => {
  const handleClick = useCallback(async () => {
    if (typeof gaTrack === "function") {
      await gaTrack();
    }
    if (typeof onClick === "function") {
      await onClick();
    }
  }, [onClick, gaTrack]);

  return (
    <WrappedComponent onClick={gaTrack ? handleClick : onClick} {...props} />
  );
};
