import uniq from "lodash/uniq";
import { combineActions, handleActions } from "redux-actions";
import {
  outagesRequest,
  outagesSuccess,
  outagesFailure,
  outagesUpdateTicket,
  outagesUpdateTicketSuccess,
  outagesUpdateTicketFailure,
  outagesUpdateTicketDone,
  outageShapesRequest,
  outageShapesSuccess,
  outageShapesFailure,
} from "./outages.actions";
import { reportFetchSuccess } from "../reports";

export const INITIAL_STATE = {
  // Outages
  isLoading: false,
  byId: {},
  allIds: [],
  error: undefined,
  // Tickets
  isUpdatingTicket: false,
  tickets: {},
};
export default handleActions(
  {
    [outagesRequest]: state => ({
      ...state,
      isLoading: true,
    }),
    [outagesSuccess]: (state, action) => ({
      ...state,
      byId: {
        ...state.byId,
        ...action.payload,
      },
      allIds: uniq([...state.allIds, ...Object.keys(action.payload)]),
      isLoading: false,
      error: undefined,
    }),
    [outagesFailure]: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    [outagesUpdateTicket]: state => ({ ...state, isUpdatingTicket: true }),
    [combineActions(
      outagesUpdateTicketSuccess,
      outagesUpdateTicketFailure,
    )]: state => ({
      ...state,
      isUpdatingTicket: false,
    }),
    [outagesUpdateTicketSuccess]: (
      state,
      { payload: { icpNumber, isResolved } },
    ) => ({
      ...state,
      tickets: {
        ...state.tickets,
        [icpNumber]: {
          ...state.tickets[icpNumber],
          status: isResolved ? "RESOLVED" : "REOPEN",
        },
      },
    }),
    [outagesUpdateTicketFailure]: (state, { payload: { icpNumber, err } }) => ({
      ...state,
      tickets: {
        ...state.tickets,
        [icpNumber]: {
          ...state.tickets[icpNumber],
          error: err.message,
        },
      },
    }),
    [outagesUpdateTicketDone]: (state, { payload: icpNumber }) => ({
      ...state,
      tickets: {
        ...state.tickets,
        [icpNumber]: {
          status: null,
          error: null,
        },
      },
    }),
    [reportFetchSuccess]: (state, { payload }) => ({
      ...state,
      byId: {
        ...state.byId,
        ...payload.byId,
      },
      allIds: uniq([...state.allIds, ...payload.allIds]),
    }),
    [outageShapesRequest]: state => ({
      ...state,
      isLoading: true,
    }),
    [outageShapesSuccess]: (state, action) => ({
      ...state,
      shapes: {
        ...action.payload,
      },
      isLoading: false,
      error: undefined,
    }),
    [outageShapesFailure]: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
  },
  INITIAL_STATE,
);
