/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { Loader } from "../../components/Loader";
import { AddressSearchContextContainer } from "../AddressSearchContextContainer";
import {
  getCurrentAddressCoords,
  getSelectedAddress,
} from "../../redux/modules/addresses";
import {
  getOutageShapes,
  outageShapesRequest,
} from "../../redux/modules/outages";
import {
  getDeviceCoordsRequest,
  getClickCoordsReceive,
} from "../../redux/modules/location";
import {
  defaultMapCenter,
  defaultMapOptions,
  defaultMapZoom,
  shapeStyles,
  ViewDetailsButton,
  Marker,
  Center,
  Legend,
  Outages,
  NetworkBoundary,
} from "./_";
import { trackEvent } from "../../common/analytics";
import { NetworkBoundaryIcons } from "./_/NetworkBoundaryIcons";
import { getBoundaryCoordinates } from "../../services/NetworkBoundaryReaderService";
import { StormZoneContainer } from "../StormZoneContainer";
import {
  getAllActiveStormZones,
  stormZonesRequest,
} from "../../redux/modules/storm-zones";

export const GENERIC_MAP_ERROR =
  "There seems to be an error loading the map, but you can still search for an address above";
export const GA_CATEGORY = "Map";

/* istanbul ignore next */
const disableButton = button => {
  button.setAttribute("disabled", true);
  button.style.background = "lightgray";
  button.style.opacity = 0.4;
  button.style.cursor = "not-allowed";
};
/* istanbul ignore next */
const enableButton = button => {
  button.removeAttribute("disabled");
  button.style.background = "none";
  button.style.opacity = 1;
  button.style.cursor = "pointer";
};
/* istanbul ignore next */
const handleOnDragEnd = () => {
  trackEvent({
    category: GA_CATEGORY,
    action: "Drag",
  });
};

export const MapContainer = ({
  coords,
  shapes,
  getGPSCoords,
  getClickCoords,
  getAllOutageShapes,
  selectedAddress,
  history,
  size,
  stormZones,
  getActiveStormZones,
}) => {
  const theMap = useRef(null);
  const prevAddressCoords = useRef(null);

  const [mapError, setMapError] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(
    isEmpty(coords)
      ? defaultMapZoom.locationTurnedOff
      : defaultMapZoom.locationTurnedOn,
  );
  const [addressCoords, setAddressCoords] = useState(false);
  const [initialMapZoom, setInitialMapZoom] = useState(
    isEmpty(coords)
      ? defaultMapZoom.locationTurnedOff
      : defaultMapZoom.locationTurnedOn,
  );

  const [networkBoundaryData, setNetworkBoundaryData] = useState(undefined);

  const [isStormZoneActive, setIsStormZoneActive] = useState(false);

  useEffect(() => {
    getActiveStormZones();
  }, []);

  useEffect(() => {
    setIsStormZoneActive(stormZones.length !== 0);
  }, [stormZones]);

  const mapOptions = {
    ...defaultMapOptions,
    minZoom: window.config.mapMinZoom
      ? window.config.mapMinZoom
      : defaultMapOptions.minZoom,
    maxZoom: window.config.mapMaxZoom
      ? window.config.mapMaxZoom
      : defaultMapOptions.maxZoom,
  };

  const trackClick = useCallback(
    /* istanbul ignore next */
    args => {
      trackEvent({
        category: GA_CATEGORY,
        ...args,
      });
    },
    [selectedAddress],
  );

  /* istanbul ignore next */

  const viewDetails = () => {
    history.push(`/address/${selectedAddress.id}`);
  };
  /* istanbul ignore next */

  const handleOnLoad = map => {
    if (!selectedAddress) {
      getGPSCoords();
    }

    /*
      With some errors (eg API key misconfiguration), Google Maps still instantiates and pretends
      to be a functioning map. The only way to find out it is not working is to make a call to getBounds.
      This yucky setTimeout is because there is no callback for these post-instantiation errors
    */
    setTimeout(() => {
      if (map.getBounds() === undefined) {
        setMapError(GENERIC_MAP_ERROR);
      }
    }, 3000);
  };
  /* istanbul ignore next */

  const handleOnClick = e => {
    getClickCoords(e);
    trackClick({ action: "Set Marker By Click" });
  };
  /* istanbul ignore next */

  const handleOnError = err => setMapError(err);
  /* istanbul ignore next */

  const handleOnZoom = () => {
    if (theMap && theMap.current) {
      const { map } = theMap.current.state;
      const zoomIn = theMap.current.mapRef.querySelector("[title='Zoom in']");
      const zoomOut = theMap.current.mapRef.querySelector("[title='Zoom out']");

      if (!zoomIn || !zoomOut) return;
      trackClick({ action: "Zoom" });
      const currentZoom = map.getZoom();
      setZoomLevel(currentZoom);

      if (currentZoom === map.maxZoom) {
        disableButton(zoomIn);
        trackClick({ action: "Max Zoom Reached" });
      } else {
        enableButton(zoomIn);
      }
      if (currentZoom === map.minZoom) {
        disableButton(zoomOut);
        trackClick({ action: "Min Zoom Reached" });
      } else {
        enableButton(zoomOut);
      }
    }
  };

  const renderBoundariesAndOutagesComponents = () => {
    const vectorNorthBorderPositions = {
      9: { lat: -36.193199, lng: 174.834888 },
      10: { lat: -36.193199, lng: 174.834888 },
      11: { lat: -36.133764, lng: 174.736478 },
      12: { lat: -36.109451, lng: 174.699697 },
      13: { lat: -36.098793, lng: 174.64476 },
    };
    const vectorSouthBorderPositions = {
      9: { lat: -36.991066, lng: 174.287445 },
      10: { lat: -36.979315, lng: 174.287525 },
      11: { lat: -37.010809, lng: 174.392123 },
      12: { lat: -37.040427, lng: 174.426886 },
      13: { lat: -37.039, lng: 174.454114 },
    };
    const northPowerPositions = {
      9: { lat: -35.98693, lng: 174.788646 },
      10: { lat: -35.98693, lng: 174.788646 },
      11: { lat: -36.013444, lng: 174.712548 },
      12: { lat: -36.0259, lng: 174.651038 },
      13: { lat: -36.035092, lng: 174.621676 },
    };
    const countiesPositions = {
      9: { lat: -37.205573, lng: 174.41541 },
      10: { lat: -37.205573, lng: 174.41541 },
      11: { lat: -37.137652, lng: 174.449671 },
      12: { lat: -37.092719, lng: 174.48082 },
      13: { lat: -37.092719, lng: 174.48082 },
    };

    const northBorderIconProps = {
      vectorPosition: vectorNorthBorderPositions[zoomLevel]
        ? vectorNorthBorderPositions[zoomLevel]
        : vectorNorthBorderPositions[13],
      competitorPosition: northPowerPositions[zoomLevel]
        ? northPowerPositions[zoomLevel]
        : northPowerPositions[13],
      vectorIcon: `assets/images/map-logo-vector${
        zoomLevel > 9 ? "-2x" : ""
      }.png`,
      competitorIcon: `assets/images/map-logo-northpower${
        zoomLevel > 9 ? "-2x" : ""
      }.png`,
    };

    const southBorderIconProps = {
      vectorPosition: vectorSouthBorderPositions[zoomLevel]
        ? vectorSouthBorderPositions[zoomLevel]
        : vectorSouthBorderPositions[13],
      competitorPosition: countiesPositions[zoomLevel]
        ? countiesPositions[zoomLevel]
        : countiesPositions[13],
      vectorIcon: `assets/images/map-logo-vector${
        zoomLevel > 9 ? "-2x" : ""
      }.png`,
      competitorIcon: `assets/images/map-logo-counties${
        zoomLevel > 9 ? "-2x" : ""
      }.png`,
    };

    return (
      <>
        {networkBoundaryData && (
          <NetworkBoundary
            networkBoundaryShapes={networkBoundaryData}
            zoomLevel={zoomLevel}
            styles={shapeStyles}
          />
        )}
        <NetworkBoundaryIcons iconProperties={northBorderIconProps} />
        <NetworkBoundaryIcons iconProperties={southBorderIconProps} />
        {shapes.type && (
          <Outages shapes={shapes} zoomLevel={zoomLevel} styles={shapeStyles} />
        )}
      </>
    );
  };

  const centerMapAtCoordinate = () => {
    if (addressCoords !== prevAddressCoords.current) {
      return (
        <Center position={{ lat: coords.latitude, lng: coords.longitude }} />
      );
    }

    return <></>;
  };

  useEffect(() => {
    if (!shapes.type) {
      getAllOutageShapes();
    }
  }, []);

  useEffect(() => {
    (async () => {
      const boundaryFill = await getBoundaryCoordinates();
      const boundaryBorder = await getBoundaryCoordinates(
        "assets/vector_boundary_lines.json",
      );

      boundaryFill.features = boundaryFill.features.concat(
        boundaryBorder.features,
      );

      setNetworkBoundaryData(boundaryFill);
    })();
  }, []);

  useEffect(() => {
    if (isEmpty(coords)) {
      setInitialMapZoom(defaultMapZoom.locationTurnedOff);
    } else {
      // remove the zoom in on default map selection
      // setInitialMapZoom(defaultMapZoom.locationTurnedOn);
    }

    setAddressCoords(coords);
  }, [coords]);

  // makes map not center oddly during zooming after selecting a coordinate
  useEffect(() => {
    prevAddressCoords.current = addressCoords;
  }, [addressCoords]);

  return (
    <>
      <AddressSearchContextContainer
        size={size}
        otherError={mapError}
        handleSubmit={viewDetails}
        category={GA_CATEGORY}
        p={3}
        pt={[4, null, 3]}
      />
      <LoadScript
        version={window.config.googleMapsApiVersion}
        googleMapsApiKey={window.config.mapkey}
        loadingElement={<Loader />}
      >
        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
        <GoogleMap
          id="outageMap"
          data-testid="outageMap"
          ref={theMap}
          mapContainerStyle={{
            width: `${size.width}px`,
            height: `${size.height}px`,
          }}
          center={defaultMapCenter}
          options={mapOptions}
          zoom={initialMapZoom}
          clickableIcons={false}
          onLoad={handleOnLoad}
          onClick={handleOnClick}
          onZoomChanged={handleOnZoom}
          onError={handleOnError}
          onDragEnd={handleOnDragEnd}
        >
          <StormZoneContainer
            styles={shapeStyles}
            zoomLevel={zoomLevel}
            stormZones={stormZones}
          />

          {renderBoundariesAndOutagesComponents()}

          {/* istanbul ignore next */ coords.latitude && (
            <>
              {centerMapAtCoordinate()}
              <Marker
                clickable={false}
                position={{ lat: coords.latitude, lng: coords.longitude }}
              >
                <ViewDetailsButton
                  coords={coords}
                  onClick={() => {
                    trackClick({
                      action: "Map Popup - View Details",
                      label: `${selectedAddress.id} | ${selectedAddress.label}`,
                    });
                    viewDetails();
                  }}
                />
              </Marker>
            </>
          )}
          {!mapError && (
            <Legend isStormZoneActive={isStormZoneActive} {...shapeStyles} />
          )}
        </GoogleMap>
      </LoadScript>
    </>
  );
};

MapContainer.propTypes = {
  coords: PropTypes.object,
  getAllOutageShapes: PropTypes.func.isRequired,
  getClickCoords: PropTypes.func.isRequired,
  getGPSCoords: PropTypes.func.isRequired,
  shapes: PropTypes.object,
  size: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  stormZones: PropTypes.array,
  getActiveStormZones: PropTypes.func.isRequired,
};

MapContainer.defaultProps = {
  coords: {},
  shapes: {},
  size: { width: 500, height: 500 },
  stormZones: [],
};

/* istanbul ignore next */
const enhance = compose(
  withRouter,
  connect(
    state => ({
      selectedAddress: getSelectedAddress(state),
      coords: getCurrentAddressCoords(state),
      shapes: getOutageShapes(state),
      stormZones: getAllActiveStormZones(state),
    }),
    {
      getClickCoords: getClickCoordsReceive,
      getGPSCoords: getDeviceCoordsRequest,
      getAllOutageShapes: outageShapesRequest,
      getActiveStormZones: stormZonesRequest,
    },
  ),
);

export default enhance(MapContainer);
