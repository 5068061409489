import { createSelector } from "reselect";

export const getOutageIdForSubscription = createSelector(
  [(state, icpNumber) => state.subscription.byIcpNumber[icpNumber]],
  outageId => outageId || null,
);

export const getSubscriptionDetails = createSelector(
  [getOutageIdForSubscription, state => state.subscription.byOutageId],
  (outageId, subscriptions) => subscriptions[outageId] || null,
);
