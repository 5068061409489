import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  outagesRequest,
  outagesFailure,
  outagesSuccess,
  outagesUpdateTicket,
  outagesUpdateTicketSuccess,
  outagesUpdateTicketFailure,
  outageShapesRequest,
  outageShapesFailure,
  outageShapesSuccess,
} from "./outages.actions";
import { OutageService } from "../../../services/OutageService";
import { IcpsService } from "../../../services/IcpsService";

export function* requestOutage(action) {
  try {
    const outageData = yield call(IcpsService.getIcpStatus, action.payload);

    yield put(outagesSuccess(outageData.outage));
  } catch (err) {
    yield put(outagesFailure(err.message));
  }
}

export function* confirmOutageResolvedStatus(action) {
  const {
    payload: { outageId, icpNumber, isResolved },
  } = action;

  try {
    const response = yield call(
      OutageService.confirmOutageTicketStatus,
      outageId,
      isResolved,
    );

    if (response.status === 200) {
      // Note: We're calling the function directly with the action as opposed to
      //       going through dispatch/PUT in order to avoid the isLoading on the page
      yield call(requestOutage, outagesRequest(icpNumber));
      // Mark it as done, renders the new success dialog in the UI
      yield put(outagesUpdateTicketSuccess(outageId, icpNumber, isResolved));
    } else {
      throw new Error("Sorry, something has gone wrong. Please try again.");
    }
  } catch (err) {
    yield put(outagesUpdateTicketFailure(outageId, icpNumber, isResolved, err));
  }
}

export function* requestOutageShapes() {
  try {
    const outageShapesData = yield call(OutageService.getOutageShapes);

    yield put(outageShapesSuccess(outageShapesData));
  } catch (err) {
    yield put(outageShapesFailure(err.message));
  }
}

export function* watchForOutagesRequest() {
  yield all([
    takeLatest(outagesRequest, requestOutage),
    takeLatest(outagesUpdateTicket, confirmOutageResolvedStatus),
    takeLatest(outageShapesRequest, requestOutageShapes),
  ]);
}
