import PropTypes from "prop-types";
import React from "react";
import { Box, Flex, Text } from "rebass";
import { Button } from "../../../components/Button";
import { trackReportEvent } from "../eventTracking";

const Warning = ({
  title,
  match: {
    url,
    params: { icpNumber },
  },
}) => (
  <Flex flexDirection="column">
    <Box width={1} my={20}>
      <Text as="p" m={0} fontSize={4} textAlign="center">
        {title}
      </Text>
    </Box>
    <Box width={1} alignSelf="center">
      <Button
        gaTrack={trackReportEvent(
          icpNumber,
          url,
          "Clicked 'Call us now' button",
        )}
        href="tel:0508832867"
        block
      >
        CALL US NOW
      </Button>
    </Box>
    <Box width={1} my={20}>
      <Text as="p" fontSize={4} m={0} textAlign="center">
        0508 832 867
      </Text>
    </Box>
  </Flex>
);
Warning.propTypes = {
  title: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
};

export default Warning;
