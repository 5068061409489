/* istanbul ignore file */
import { all } from "redux-saga/effects";

// Import sagas from ./sagas/**
import { watchForCustomerActions } from "../modules/customers";
import { watchLoginRequest } from "../modules/login";
import { watchForLocationRequest } from "../modules/location";
import { watchForOutagesRequest } from "../modules/outages";
import { watchForAddressQueries } from "../modules/addresses";
import { watchForRegisterRequest } from "../modules/registration";
import { watchForReportRequests } from "../modules/reports";
import { watchForSaveSubscriptionRequest } from "../modules/subscription";
import { watchForLsEventActions } from "../modules/lsevent";
import { watchForFormRequest } from "../modules/forms";
import { watchForEventHistoryRequest } from "../modules/event-history";
import { watchForPlannedOutagesRequest } from "../modules/planned-outages";
import { watchForStormZonesRequest } from "../modules/storm-zones";
import { watchForStormZoneByIdRequest } from "../modules/storm-zones-id";
import { watchForIcpStatusRequest } from "../modules/icps";

export function* rootSaga() {
  return yield all([
    // init your imported sagas from the sagas/** folder here
    watchLoginRequest(),
    watchForLocationRequest(),
    watchForAddressQueries(),
    watchForOutagesRequest(),
    watchForRegisterRequest(),
    watchForReportRequests(),
    watchForSaveSubscriptionRequest(),
    watchForLsEventActions(),
    watchForFormRequest(),
    watchForEventHistoryRequest(),
    watchForCustomerActions(),
    watchForPlannedOutagesRequest(),
    watchForStormZonesRequest(),
    watchForStormZoneByIdRequest(),
    watchForIcpStatusRequest(),
  ]);
}
