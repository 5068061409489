import { logoutRequest } from "../../redux/modules/login";

const ignoredUrls = ["/login", "/userinfo"];

export const authResponseInterceptor = [
  r => r,
  store => error => {
    if (ignoredUrls.find(url => error.config.url.endsWith(url))) {
      return Promise.reject(error);
    }

    if ([401, 403].includes(error.response.status)) {
      store.dispatch(logoutRequest());
    }

    return Promise.reject(error);
  },
];
