import { takeLatest, call, put, all } from "redux-saga/effects";
import {
  stormZoneByIdSuccess,
  stormZoneByIdFailure,
  stormZoneByIdRequest,
} from "./storm-zones-id.actions";
import { StormZonesService } from "../../../services/StormZonesService";

export function* getStormZoneById(action) {
  const { id } = action.payload;
  try {
    const stormZone = yield call(StormZonesService.getStormZone, id);
    yield put(stormZoneByIdSuccess(stormZone));
  } catch (err) {
    yield put(stormZoneByIdFailure(err.message));
  }
}

export function* watchForStormZoneByIdRequest() {
  yield all([takeLatest(stormZoneByIdRequest, getStormZoneById)]);
}
