import React from "react";
import PropTypes from "prop-types";
import { or, explicitNull } from "airbnb-prop-types";
import { Box, Flex } from "rebass";
import styled, { css } from "styled-components/macro";
import { ifProp, switchProp } from "styled-tools";

export const Shape = styled(Flex)`
  border: 2px solid;
  border-radius: 100%;
  width: 100%;
  height: 100%;

  ${switchProp("type", {
    default: css`
      border-color: ${({ theme }) => theme.colors.dark};
    `,
    complete: css`
      border-color: ${({ theme }) => theme.colors.secondary};
      background-color: ${({ theme }) => theme.colors.secondary};
    `,
    incomplete: css`
      border-color: ${({ theme }) => theme.colors.disabled};
    `,
  })};
`;

export const ShapeWrapper = styled(Box)`
  margin: 18px 18px 0 18px;
  width: 30px;
  height: 30px;
  position: relative;

  ${ifProp(
    "isActive",
    css`
      &,
      & > ${Shape} {
        width: 66px;
        height: 66px;
        margin: 0;
      }
    `,
  )};

  &:before,
  &:after {
    content: "";
    display: block;
    height: 2px;
    width: 1000px;
    background-color: #111111;
    position: absolute;
    top: 50%;
  }

  &:before {
    right: calc(100% + 5px);
  }
  &:after {
    left: calc(100% + 5px);
  }

  // Ensure the line colours are according to the status type
  ${ifProp(
    "lineColorLeft",
    css`
      &:before {
        background-color: ${props => props.theme.colors[props.lineColorLeft]};
      }
    `,
  )};
  ${ifProp(
    "lineColorRight",
    css`
      &:after {
        background-color: ${props => props.theme.colors[props.lineColorRight]};
      }
    `,
  )};
`;

const ProgressIcon = ({
  type,
  isActive,
  lineColorLeft,
  lineColorRight,
  children,
}) => (
  <ShapeWrapper
    type={type}
    lineColorLeft={lineColorLeft}
    lineColorRight={lineColorRight}
    isActive={isActive}
  >
    <Shape type={type} alignItems="center" justifyContent="center">
      {children}
    </Shape>
  </ShapeWrapper>
);

ProgressIcon.propTypes = {
  type: PropTypes.oneOf(["default", "active", "incomplete", "complete"])
    .isRequired,
  lineColorLeft: or([PropTypes.string, explicitNull()]).isRequired,
  lineColorRight: or([PropTypes.string, explicitNull()]).isRequired,
  isActive: PropTypes.bool,
  children: PropTypes.node,
};
ProgressIcon.defaultProps = {
  children: null,
  isActive: false,
};

export default ProgressIcon;
