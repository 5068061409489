import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Box, Flex } from "rebass";
import { SizeMe } from "react-sizeme";
import { AppBar } from "../components/AppBar";
import { Page } from "../components/Page";
import { PlannedOutagesContainer } from "../containers/PlannedOutagesContainer";

const PlannedOutagesView = ({ history }) => (
  <Flex flexDirection="column" width={1}>
    <Page title="Upcoming Planned Outages" />
    <Box flex="0 1 0">
      <AppBar>
        <AppBar.Button onClick={history.goBack}>
          <span aria-hidden="true">&lt; </span>BACK
        </AppBar.Button>
        <AppBar.Title>upcoming outages</AppBar.Title>
      </AppBar>
    </Box>
    <SizeMe monitorHeight>
      {({ size }) => (
        <Flex
          flexDirection="column"
          alignItems="center"
          flex="1 1 auto"
          width={1}
          height={1}
          css="position: relative;"
          bg="white"
          py={25}
        >
          <PlannedOutagesContainer size={size} />
        </Flex>
      )}
    </SizeMe>
  </Flex>
);
PlannedOutagesView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(PlannedOutagesView);
