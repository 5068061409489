import React from "react";
import PropTypes from "prop-types";
import KnownOutageEventNotification from "../../../components/KnownOutageEventNotification/KnownOutageEventNotification";
import OutageStatusNotification from "../../../components/OutageStatusNotification/OutageStatusNotification";
import {
  PLANNED,
  UNPLANNED,
  UNKNOWN,
  OUTSIDE_NETWORK,
  REPORTED,
} from "../../../components/OutageStatusType";
import { getOutageStatusText } from "./GetOutageStatusText";
import getOutageStatusType from "./OutageStatusTypeMapper";

export const OutageStatusBar = ({
  eventType,
  outageId,
  outsideVectorNetwork,
}) => {
  const outageStatusType = getOutageStatusType(
    eventType,
    outageId,
    outsideVectorNetwork,
  );

  const outageStatus = getOutageStatusText(outageStatusType);

  switch (outageStatusType) {
    case REPORTED:
    case UNKNOWN:
    case OUTSIDE_NETWORK:
      return (
        <OutageStatusNotification
          variant="warning"
          notification={outageStatus}
        />
      );

    case UNPLANNED:
    case PLANNED:
      return (
        <KnownOutageEventNotification
          outageId={outageId}
          outageStatus={outageStatus}
        />
      );

    default:
      return null;
  }
};

OutageStatusBar.propTypes = {
  eventType: PropTypes.oneOf(["Planned", "UnPlanned", "UNKNOWN"]).isRequired,
  outageId: PropTypes.string.isRequired,
  outsideVectorNetwork: PropTypes.bool.isRequired,
};
