/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import { Text, Box } from "rebass";

const PlannedOutageEventInfo = ({
  label,
  children,
  contentMargin,
  ...props
}) => (
  <>
    <Text data-testid="PlannedOutage__EventInfo__Label" fontSize={1} {...props}>
      {label}
    </Text>
    <Box
      m={0}
      p={0}
      mt={contentMargin}
      data-testid="PlannedOutage__EventInfo__Content"
      fontSize={2}
    >
      {children}
    </Box>
  </>
);

PlannedOutageEventInfo.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  contentMargin: PropTypes.number,
};

PlannedOutageEventInfo.defaultProps = {
  contentMargin: 0,
};

export default PlannedOutageEventInfo;
