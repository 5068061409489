import { handleActions } from "redux-actions";
import { openModal, closeModal } from "./modal.actions";

const initialState = {};

export default handleActions(
  {
    [openModal]: (state, action) => ({
      ...state,
      [action.payload]: true,
    }),
    [closeModal]: (state, action) => ({
      ...state,
      [action.payload]: false,
    }),
  },
  initialState,
);
