import React from "react";
import styled from "styled-components/macro";
import { Flex } from "rebass";
import { height } from "styled-system";
import { ReactComponent as Icon } from "./_/exclamation.svg";

// Circle with exclamation mark

const IconWrapper = styled(Flex).attrs({
  justifyContent: "center",
  alignItems: "center",
  width: 35,
  height: 35,
  bg: "error",
  mr: 12,
})`
  ${height};
  border-radius: 100%;
  overflow: hidden;
`;

export const ErrorIcon = props => (
  <IconWrapper data-testid="ErrorIcon" {...props}>
    <Icon />
  </IconWrapper>
);

ErrorIcon.propTypes = {};
