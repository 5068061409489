// Helpers
export const validateWith = (...rules) => val => {
  const errors = rules.reduce((errs, rule) => {
    let errorMessage;

    /* istanbul ignore else */
    if (Array.isArray(rule)) {
      // Support for custom error messages per-field by specifying a tuple
      const fn = rule[0];
      const customMessage = rule[1];

      errorMessage = fn(val) ? customMessage : undefined;
    } else if (typeof rule === "function") {
      // Regular function, call and use the internal message
      errorMessage = rule(val);
    }

    if (errorMessage) {
      return [...errs, errorMessage];
    }

    return errs;
  }, []);

  if (errors.length) {
    // NOTE: This only returns the first error encountered which means the order of the rules matters!
    return errors[0];
  }

  return undefined;
};
export const validateOnCondition = condition => (...rules) => {
  if (!condition) {
    return undefined;
  }

  return validateWith(...rules);
};

// Rules
const trimPhoneNumber = (val = "") => val.replace(/[+ .()-]*/gi, "");

export const isRequired = val => (!val ? "This is required" : undefined);
export const isOptionRequired = val =>
  !val ? "Please select an option" : undefined;
export const maxLength = max => val =>
  typeof val === "string" && val.length > max
    ? `Please enter ${max} characters or fewer`
    : undefined;
export const minLength = min => val =>
  typeof val === "string" && val.length < min
    ? `Please enter more than ${min} characters`
    : undefined;
export const containsNumber = val =>
  /[0-9]/.test(val) ? undefined : "Please include at least 1 digit";
export const containsUppercase = val =>
  /[A-Z]/.test(val)
    ? undefined
    : "Please include at least 1 uppercase character";
export const containsLowercase = val =>
  /[a-z]/.test(val)
    ? undefined
    : "Please include at least 1 lowercase character";
export const containsSpecialCharacter = val =>
  /[^A-Za-z0-9]/.test(val)
    ? undefined
    : "Please include at least 1 special character";

export const isValidPhone = val => {
  const trimmed = trimPhoneNumber(val);
  return !/^02([ ]*[0-9]){7,9}$/.test(trimmed)
    ? "Only NZ mobile numbers starting with 02 are valid."
    : undefined;
};

export const isValidMobileAndLandLinePhone = val => {
  const trimmed = trimPhoneNumber(val);
  return !/^0[1-9]([0-9]){7,9}$/.test(trimmed)
    ? "Only NZ mobile numbers starting with 02 and landlines starting with 07, 09, etc are valid."
    : undefined;
};

export const isValidEmail = val =>
  !/^(?!.*(?:''|\.\.))[A-Za-z0-9_'.%+-]*[A-Za-z0-9]@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/i.test(
    val,
  )
    ? "This email address is not valid"
    : undefined;

/**
 * 1. At least 12 characters
 */
// This returns the most first error message encountered, for a generic message
// when ANY of these validations have failed see/use `isValidPassword` below
export const isValidPassword = val => {
  const errorMessage = "Your password must have a minimum of 12 characters";
  const lengthCheck = minLength(12);
  return lengthCheck(val) === undefined ? undefined : errorMessage;
};

export const hasNoExternalError = externalErrorMessage => () =>
  typeof externalErrorMessage !== "undefined"
    ? externalErrorMessage
    : undefined;
