/* eslint-disable no-unused-vars,arrow-body-style */
import jstz from "jstz";
import client from "../client";
import mockResponse from "./__fixture__/planned-outages";

export const ENDPOINT = "v2/planned-outages";

export class PlannedOutageService {
  static getPlannedOutages = async icpNumber => {
    // return mockResponse.PLANNED_OUTAGES;
    const url = ENDPOINT;
    const params = {
      groupByTimezone: jstz.determine().name(),
      icpNumber,
    };

    const { data } = await client.get(url, { params });
    return data;
  };
}
