import { createAction } from "redux-actions";

export const ICP_STATUS_REQUEST = "app/icps/status/REQUEST";
export const ICP_STATUS_SUCCESS = "app/icps/status/SUCCESS";
export const ICP_STATUS_FAILURE = "app/icps/status/FAILURE";

export const icpStatusRequest = createAction(ICP_STATUS_REQUEST, icpNumber => ({
  icpNumber,
}));

export const icpStatusSuccess = createAction(ICP_STATUS_SUCCESS, data => ({
  icpStatus: data,
}));

export const icpStatusFailure = createAction(ICP_STATUS_FAILURE);
