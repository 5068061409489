import { createSelector } from "reselect";

export const getHash = createSelector(
  [state => state.lsevents.hash],
  hash => hash,
);

export const getCurrentLSE = createSelector(
  [getHash, state => state.lsevents.byHash],
  (hash = 0, byHash = {}) => byHash[hash] || {},
);

export const getIsDismissed = createSelector(
  [getHash, state => state.lsevents.dismissed],
  (hash, dismissed) => dismissed.includes(hash),
);

export const getEventLevel = createSelector(
  [getCurrentLSE],
  lse => (lse.hash > 0 ? 1 : 0),
);
