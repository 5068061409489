/* eslint-disable react/prop-types */
import React from "react";
import { Field } from "formik";
import { Box, Text } from "rebass";
import { Button } from "../../../components/Button";
import { TextField } from "../../../components/Form";
import {
  isRequired,
  validateWith,
} from "../../../components/RegistrationFormFields/validate";
import { trackReportEvent } from "../eventTracking";
import CancelButton from "./CancelButton";

export const DetailsFieldOther = ({
  match: {
    url,
    params: { icpNumber },
  },
}) => (
  <>
    <Box>
      <Text as="p" textAlign="center" fontSize={4} mt={0} mb={10}>
        How would you best describe your outage?
      </Text>
      <Text fontSize={2} textAlign="center">
        This will help us send the correct crew
      </Text>
    </Box>
    <Box my={20}>
      <Field
        name="details.other"
        label="Please explain your issue"
        minHeight={190}
        component={TextField}
        multiline
        validate={validateWith(isRequired)}
        required
      />
    </Box>
    <Box my={20}>
      <Button
        gaTrack={trackReportEvent(icpNumber, url, "Clicked 'Next' button")}
        type="submit"
        variant="dark"
        block
      >
        NEXT
      </Button>
    </Box>
    <CancelButton />
  </>
);

export default DetailsFieldOther;
