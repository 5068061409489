import { handleActions } from "redux-actions";
import {
  stormZonesSuccess,
  stormZonesRequest,
  stormZonesFailure,
} from "./storm-zones.actions";

export const INITIAL_STATE = {
  isFetching: false,
  stormZones: [],
  error: undefined,
};

export const reducer = handleActions(
  {
    [stormZonesRequest]: state => ({
      ...state,
      isFetching: true,
      error: undefined,
    }),
    [stormZonesSuccess]: (state, action) => ({
      ...state,
      isFetching: false,
      stormZones: [...action.payload.stormZones],
      error: undefined,
    }),
    [stormZonesFailure]: (state, action) => ({
      ...state,
      isFetching: false,
      stormZones: [],
      error: action.payload,
    }),
  },
  INITIAL_STATE,
);

export default reducer;
