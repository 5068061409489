import { takeLatest, call, put } from "redux-saga/effects";
import {
  requestEventHistory,
  requestEventHistorySuccess,
  requestEventHistoryFailure,
} from "./event-history.actions";
import { OutageService } from "../../../services/OutageService";

export function* getEventHistoryForIcpNumber(action) {
  try {
    const { icpNumber } = action.payload;
    const events = yield call(OutageService.getOutageEventHistory, icpNumber);
    yield put(requestEventHistorySuccess(events, icpNumber));
  } catch (e) {
    yield put(requestEventHistoryFailure(e));
  }
}

export function* watchForEventHistoryRequest() {
  yield takeLatest(requestEventHistory, getEventHistoryForIcpNumber);
}
