import React from "react";
import PropTypes from "prop-types";
import { or, explicitNull } from "airbnb-prop-types";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { Box, Flex } from "rebass";
import { compose } from "recompose";
import styled from "styled-components/macro";
import { CloseButton } from "../CloseButton";
import { TrackedModalContent } from "../../common/withAnalytics";
import {
  openModal,
  closeModal,
  getModalState,
} from "../../redux/modules/modal";

const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 60000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  content: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "0 1 auto",
    padding: 0,
    border: 0,
    overflow: "inherits",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    backgroundColor: "transparent",
    height: "100%",
  },
};

const ModalContainer = styled(Flex)`
  // Mobile
  position: relative;
  overflow: hidden;
  height: 100%;
  overflow-y: auto;
  max-width: 960px;
  -ms-overflow-style: scrollbar;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  // Tablet and up
  ${p => p.theme.upFrom.md`
    border-radius: 8px;
    height: initial;
  `};
`;

const ModalInner = styled(Box)`
  width: 100%;
  height: 100%;
`;

export class Modal extends React.Component {
  static propTypes = {
    /* defines under which key this modal instance is tracked in redux AND how Google Analytics reports this content */
    name: PropTypes.string.isRequired,
    /* content of the modal */
    children: PropTypes.func.isRequired,

    /* PUBLIC HOOKS */
    /* optional: fires in the internal handleClose */
    onClose: or([PropTypes.func, explicitNull()]),
    /* optional: fires in the internal handleOpen */
    onOpen: or([PropTypes.func, explicitNull()]),
    /* optional: fires in the internal componentDidMount */
    onMount: or([PropTypes.func, explicitNull()]),
    /* optional: fires in the internal componentWillUnmount */
    onUnmount: or([PropTypes.func, explicitNull()]),

    /* REDUX INTERNAL HOOKS & DATA */
    /* redux-store object that tracks all app modals */
    modalStore: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onClose: null,
    onOpen: null,
    onMount: null,
    onUnmount: null,
  };

  callHookWithState = hook => {
    if (typeof hook === "function") {
      hook.call(this, this.gatherInternalState());
    }
  };

  handleClose = () => {
    const { onClose } = this.props;
    // Calls the hook if provided
    this.callHookWithState(onClose);
  };

  handleOpen = () => {
    const { onOpen } = this.props;
    // Calls the hook if provided
    this.callHookWithState(onOpen);
  };

  componentDidMount() {
    const { onMount } = this.props;
    // Calls the hook if provided
    this.callHookWithState(onMount);
  }

  componentWillUnmount() {
    const { onUnmount } = this.props;
    // Calls the hook if provided
    this.callHookWithState(onUnmount);
  }

  // Composes the regular hook call + dispatching the correct redux action
  // this method is passed to the Child as a Function of the <Modal> tag so
  // it can be called from content bindings.
  handleInternalOpen = () => {
    // eslint-disable-next-line no-shadow
    const { name, openModal } = this.props;
    this.handleOpen();
    openModal(name);
  };

  // Composes the regular hook call + dispatching the correct redux action
  // this method is passed to the Child as a Function of the <Modal> tag so
  // it can be called from content bindings.
  handleInternalClose = () => {
    // eslint-disable-next-line no-shadow
    const { name, closeModal } = this.props;
    this.handleClose();
    closeModal(name);
  };

  gatherInternalState = () => {
    const { modalStore, name } = this.props;
    return {
      isOpen: !!modalStore[name],
      open: this.handleInternalOpen,
      close: this.handleInternalClose,
    };
  };

  render() {
    const { name, children: Component } = this.props;
    const internalState = this.gatherInternalState();

    return (
      <ReactModal
        isOpen={internalState.isOpen}
        onRequestClose={this.handleClose}
        onAfterOpen={this.handleOpen}
        style={customStyles}
        ariaHideApp={false}
        parentSelector={() => document.body}
      >
        <ModalContainer width={1} maxWidth={[1, null, 500]}>
          <CloseButton onClick={this.handleInternalClose} />
          <ModalInner>
            <TrackedModalContent gaTrackingId={name}>
              {Component(internalState)}
            </TrackedModalContent>
          </ModalInner>
        </ModalContainer>
      </ReactModal>
    );
  }
}

/* istanbul ignore next */
const enhance = compose(
  connect(
    state => ({
      modalStore: getModalState(state),
    }),
    {
      closeModal,
      openModal,
    },
  ),
);

export default enhance(Modal);
