import { createAction } from "redux-actions";

export const OPEN_OVERLAY = "app/overlay/OPEN";
export const CLOSE_OVERLAY = "app/overlay/CLOSE";

export const openOverlay = createAction(
  OPEN_OVERLAY,
  () => undefined,
  oid => ({ overlayId: oid }),
);
export const closeOverlay = createAction(
  CLOSE_OVERLAY,
  () => undefined,
  oid => ({ overlayId: oid }),
);
