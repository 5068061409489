import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_DEVICE_COORDS_REQUEST,
  getDeviceCoordsFailure,
  getDeviceCoordsReceive,
} from "./location.actions";

export const ERROR_MESSAGE = "Your device does not support the Geolocation API";

export function getDeviceLocation() {
  return new Promise((resolve, reject) => {
    try {
      /* istanbul ignore next */
      navigator.geolocation.getCurrentPosition(
        pos => resolve(pos),
        err => reject(err),
        {
          enableHighAccuracy: true,
          timeout: 15000, // 15s
        },
      );
    } catch (err) {
      reject(err);
    }
  });
}

export function* getLocationCoords() {
  try {
    const position = yield call(getDeviceLocation);
    yield put(getDeviceCoordsReceive(position));
  } catch (err) {
    yield put(getDeviceCoordsFailure(err));
  }
}

export function* watchForLocationRequest() {
  // GPS Flow:
  yield takeLatest(GET_DEVICE_COORDS_REQUEST, getLocationCoords);
}
